/* eslint-disable no-param-reassign */
import React, { useContext, createContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import moment from "moment";
import ShopContext from "services/ShopContext";
import ApiContext from "./ApiContext";

const PaymentContext = createContext();

export function PaymentProvider({ children }) {
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const { isSuccess, data } = useQuery(
    `/shop/${shopID}/Order`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order`, { params: { limitPerPage: 10000000 } })
  );

  //   Get Total Order Sales
  let totalPayment = 0;
  if (isSuccess) {
    totalPayment = data.data.Result?.reduce(
      (a, b) => a + b.TotalOrderCost,
      0,
      0
    );
  }
  // ===============================================================================
  // Code to  Get Today's day Order Sales Amount
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  let todayOrder = [];
  if (isSuccess) {
    todayOrder = data.data.Result?.filter(
      (CurrentdayOrder) => new Date(CurrentdayOrder.OrderDate) >= today
    );
  }

  // seperate each time with price eg: 62d7b576466f086f718a0c1a: {09: 824.83}
  const tOrder = todayOrder?.reduce((acc, e) => {
    const date = moment(e.OrderDate).format("HH");
    if (!acc[e.ID]) {
      acc[e.ID] = {
        [date]: e.TotalOrderCost,
      };
    } else {
      acc[e.ID][date] = e.TotalOrderCost;
    }
    return acc;
  }, {});
  //   Get the Order time for the current day
  const tOrderTime = tOrder
    ? [
        ...new Set(
          Object.keys(tOrder)
            .map((i) => Object.keys(tOrder[i]))
            .flat()
        ),
      ]
    : [];
  // Get Total sum for Per hour order for the current day
  const totalSumPerHour = {};
  tOrderTime.forEach((time) => {
    const sumOnDate = Object.values(tOrder).reduce((acc, curr) => {
      acc += curr[time] ? curr[time] : 0;
      return acc;
    }, 0);
    totalSumPerHour[[time]] = sumOnDate;
  });
  const todaydata = Object.values(totalSumPerHour);
  const todayOtime = Object.keys(totalSumPerHour).sort();
  //   Total Currentday Order Sales Amount
  const Currentday = todayOrder?.reduce((a, b) => a + b.TotalOrderCost, 0, 0);

  // ===============================================================================
  // code to Get Yesterday's Order Sales Amount
  const yesterday = new Date(today - 1);
  yesterday.setHours(0, 0, 0, 0);
  let yesterdayOrder = [];
  if (isSuccess) {
    yesterdayOrder = data.data.Result?.filter(
      (PdayOrder) =>
        new Date(PdayOrder.OrderDate) >= yesterday &&
        new Date(PdayOrder.OrderDate) < today
    );
  }
  //  Get the Sum of Yesterdays Ordered
  let Previousday = [];
  Previousday = yesterdayOrder?.reduce((a, b) => a + b.TotalOrderCost, 0, 0);

  // ===============================================================================
  /*
Code to Get Last 7 Days Sales of the Current Month
*/
  // Get Last 7 days date from current day
  const startDates = moment();
  let datett = [];
  const dArray = [];
  for (let i = 7; i > 0; i -= 1) {
    datett = startDates.subtract(1, "day");
    dArray.push(datett.format("DD/MM"));
  }

  let CweekOrder = [];
  if (isSuccess) {
    CweekOrder = data.data.Result?.filter(
      (OldOrder) =>
        new Date(OldOrder.OrderDate).getMonth() <= yesterday.getMonth()
    );
  }
  // seperate each date with price eg: 62d7b576466f086f718a0c1a: {24/07: 824.83}
  const Cweek = CweekOrder?.reduce((acc, e) => {
    const date = moment(e.OrderDate).format("DD/MM");
    if (!acc[e.ID]) {
      acc[e.ID] = {
        [date]: e.TotalOrderCost,
      };
    } else {
      acc[e.ID][date] = e.TotalOrderCost;
    }
    return acc;
  }, {});

  const totalSumPerDate = {};
  dArray.sort((a, b) => (new Date(a) > new Date(b) ? 1 : -1));
  dArray.forEach((date) => {
    const sumOnDate =
      typeof Cweek === "object"
        ? Object.values(Cweek)?.reduce((acc, curr) => {
            acc += curr[date] ? curr[date] : 0;
            return acc;
          }, 0)
        : 0;
    totalSumPerDate[[date]] = sumOnDate;
  });
  const sevenDaydata = Object.values(totalSumPerDate);
  const sevenDaydate = Object.keys(totalSumPerDate);
  //   Get total sum of the last 7 days
  const sumSevenDays = sevenDaydata?.reduce((a, b) => a + b, 0, 0);
  /*
end of Code to Get Last 7 Days Sales of the Current Month
*/
  // ===============================================================================
  //  Code to Get Sum of Order Per day of the Month
  // Get current months orders
  let CmonthOrder = [];
  if (isSuccess) {
    CmonthOrder = data.data.Result?.filter(
      (OldOrder) =>
        new Date(OldOrder.OrderDate).getMonth() === yesterday.getMonth()
    );
  }
  // seperate each date with price eg: 62d7b576466f086f718a0c1a: {24/07: 824.83}
  const Cmonth = CmonthOrder?.reduce((acc, e) => {
    const date = moment(e.OrderDate).format("DD/MM");
    if (!acc[e.ID]) {
      acc[e.ID] = {
        [date]: e.TotalOrderCost,
      };
    } else {
      acc[e.ID][date] = e.TotalOrderCost;
    }
    return acc;
  }, {});
  //   Get the Order Date data for the current month
  const CmonthOrderdate = [
    ...new Set(
      Object.keys(Cmonth ?? {}) // Using optional chaining to check for null or undefined
        .map((i) => Object.keys(Cmonth[i]))
        .flat()
    ),
  ];
  const currDate = new Date().getDate();
  const startDate = moment().clone().startOf("month").format("YYYY-MM-DD ");
  const labels = [];
  let Cdate = [];
  for (let i = 0; i < currDate; i += 1) {
    const date = moment(startDate).add(i, "days").format("DD/MM");
    labels.push(date.toString());
    CmonthOrderdate.push(date.toString());
    Cdate = [...new Set(CmonthOrderdate)].sort();
  }

  const totalSumPerDateM = {};
  Cdate.forEach((date) => {
    const sumOnDate = Object.values(Cmonth || {}).reduce((acc, curr) => {
      acc += curr[date] ? curr[date] : 0;
      return acc;
    }, 0);
    totalSumPerDateM[date] = sumOnDate;
  });
  const Cmonthdata = totalSumPerDateM ? Object.values(totalSumPerDateM) : [];
  const Cmonthdate = totalSumPerDateM
    ? Object.keys(totalSumPerDateM).sort()
    : [];
  //   Get Sum Current Month Sales Amount
  let CmonthOtotal = 0;
  CmonthOtotal = CmonthOrder?.reduce((a, b) => a + b.TotalOrderCost, 0, 0);

  // ===============================================================================
  /* Get Monthly sales for current year
   */
  let oOrder = [];
  if (isSuccess) {
    oOrder = data.data.Result?.filter(
      (OldOrder) => new Date(OldOrder.OrderDate) < today
    );
  }
  //   Get Monthly Total Order Sales
  let monthlySales = 0;
  if (isSuccess) {
    monthlySales = oOrder?.reduce((a, b) => a + b.TotalOrderCost, 0, 0);
  }
  /* Get 1 year MOnthly sales for current year
   */
  // seperate each time with price eg: 62d7b576466f086f718a0c1a: {09: 824.83}
  const OldOrder = oOrder?.reduce((acc, e) => {
    const month = moment(e.OrderDate).format("MMM");
    if (!acc[e.ID]) {
      acc[e.ID] = {
        [month]: e.TotalOrderCost,
      };
    } else {
      acc[e.ID][month] = e.TotalOrderCost;
    }
    return acc;
  }, {});
  //   Get The months before the currebt months Jan- CurrentMonth
  const prevMonthName = moment()
    .clone()
    .startOf("month")
    .format("YYYY-MM-DD hh:mm");
  const monthMinusOneName = moment()
    .subtract(1, "month")
    .startOf("month")
    .format("M");

  const months = [];
  for (let i = monthMinusOneName; i >= 0; i -= 1) {
    const month = moment(prevMonthName).subtract(i, "month").format("MMM");
    months.push(month);
  }
  const totalSumPerMonth = {};
  months.forEach((date) => {
    const sumOnDate = OldOrder
      ? Object.values(OldOrder)?.reduce((acc, curr) => {
          acc += curr[date] ? curr[date] : 0;
          return acc;
        }, 0)
      : 0;
    totalSumPerMonth[[date]] = sumOnDate;
  });
  const monthdata = Object.values(totalSumPerMonth);
  const monthly = Object.keys(totalSumPerMonth);

  /* End Get 1 year MOnthly sales for current year
   */
  // ==============================================================
  /* Start Get Custom date sales for current year
   */
  const startD = dateRange[0]; // start date
  const endD = dateRange[1]; // end date
  const start = startD && startD.getDate();
  const end = endD && endD.getDate();
  const rangeDates = moment(startD);
  let dates = [];
  const daterray = [];
  for (let i = start; i <= end; i += 1) {
    // get the date range selected
    if (i === start) {
      dates = rangeDates;
    } else {
      dates = rangeDates.add(1, "day");
    }
    daterray.push(dates.format("DD/MM"));
  }
  // get the total sales pre date
  const totalSumCusDate = {};
  daterray.forEach((date) => {
    const sumOnDate =
      (Cweek &&
        Object.values(Cweek)?.reduce((acc, curr) => {
          acc += curr[date] ? curr[date] : 0;
          return acc;
        }, 0)) ||
      0;
    totalSumCusDate[[date]] = sumOnDate;
  });
  const CusDaydata = Object.values(totalSumCusDate);
  const CusDaydate = Object.keys(totalSumCusDate);
  const CustomDaysSum = CusDaydata?.reduce((a, b) => a + b, 0, 0); // Total of Selected dates

  /* End Get Custom date sales  
  =================================================================
   */
  const contextValue = useMemo(
    () => ({
      totalPayment,
      Currentday,
      Previousday,
      todaydata,
      todayOtime,
      CmonthOtotal,
      Cmonthdate,
      Cmonthdata,
      sevenDaydata,
      sevenDaydate,
      sumSevenDays,
      monthdata,
      monthly,
      monthlySales,
      CusDaydate,
      CusDaydata,
      CustomDaysSum,
      dateRange,
      setDateRange,
    }),
    [
      totalPayment,
      Currentday,
      Previousday,
      todaydata,
      todayOtime,
      CmonthOtotal,
      Cmonthdate,
      Cmonthdata,
      sevenDaydata,
      sevenDaydate,
      sumSevenDays,
      monthdata,
      monthly,
      monthlySales,
      CusDaydate,
      CusDaydata,
      CustomDaysSum,
    ]
  );

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
}
PaymentProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PaymentContext;
