import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import { Button, Col, Container, Form, Row } from "reactstrap";
import { toast } from "react-toastify";
import ApiContext from "services/ApiContext";

function ResetPasswordLink(props) {
  const { email } = props;
  const Axios = useContext(ApiContext);

  const sendResetLink = async () => {
    try {
      await Axios.post("/public/Account/PasswordReset", { Email: email });
      // Handle success
      toast.success("Email sent successfully!");
    } catch (err) {
      // Handle error
      toast.error("Failed to send email:", err);
    }
  };

  const formik = useFormik({
    initialValues: {
      Email: email,
    },

    onSubmit: (data) => {
      sendResetLink(data);
    },
  });

  return (
    <div>
      <div>
        <Container>
          <Row>
            <Col md="12">
              <div className="text-center p-3">
                <Form onSubmit={formik.handleSubmit}>
                  <Button className="text-center w-100 mt-4 p-2 btn btn-primary">
                    Send Reset Link
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

ResetPasswordLink.propTypes = {
  email: PropTypes.string.isRequired,
};

export default ResetPasswordLink;
