import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserContext from "services/UserContext";
import { useMutation } from "react-query";

function ShopMember(props) {
  const { user } = useContext(UserContext);
  const { setMessage, setVisible, shopData, shopID } = props;
  const Axios = useContext(ApiContext);
  const [smallNotice, setSmallNotice] = useState({
    open: false,
    field: "",
  });
  const [shopmemberEdit, setShopmemberEdit] = useState(false);

  const validationSchema = Yup.object().shape({
    ShopMemberList: Yup.string().required("ShopMember ID is required"),
  });

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };
  const { mutate, isLoading } = useMutation(
    (data) =>
      Axios.post(`/shop/${shopID}/member`, {
        OwnerID: user.ID,
        ShopMemberList: [data.ShopMemberList],
      }),
    {
      onError: async (err) => {
        setMessage({
          data: `${err.response.data.MESSAGE}`,
          type: "alert-danger",
        });
        onShowAlert();
      },

      onSuccess: async (res) => {
        setMessage({
          data: `${res.data.MESSAGE}`,
          type: "alert-success",
        });
        onShowAlert();
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      ShopMemberList: "",
    },
    validationSchema,

    onSubmit: (data) => {
      setSmallNotice({ open: false, field: "" });
      mutate(data);
    },
  });

  const Rmember = async (removeM) => {
    setSmallNotice({ open: false, field: "" });
    const data3 = {
      ShopMemberList: [removeM],
    };

    Axios.delete(`/shop/${shopID}/member`, {
      headers: {
        "content-type": "application/json",
      },
      data: data3,
    })
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        setMessage({
          data: err.response.data.MESSAGE,
          type: "alert-danger",
        });
        onShowAlert();
      });
  };

  return (
    <div>
      <Form>
        <Card className="card-dark">
          <CardHeader>
            <FormGroup className="pb-0">
              {!shopmemberEdit ? (
                <>
                  <a
                    href="#edit"
                    className="float-right font-weight-normal"
                    onClick={() => setShopmemberEdit(true)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true" />
                    Edit
                  </a>
                  <CardTitle className="font-weight-bold" tag="h5">
                    Shop Member
                  </CardTitle>
                </>
              ) : (
                <CardTitle className="font-weight-bold" tag="h5">
                  Edit Shop Member
                </CardTitle>
              )}
            </FormGroup>
          </CardHeader>
          <CardBody>
            {!shopmemberEdit && (
              <div>
                <Row>
                  {shopData.data.ShopMemberList.map((sm) => (
                    <Col lg="4" md="4" sm="4" className="">
                      <ul>
                        <li>
                          {sm.Name}
                          <Button
                            className="btn-link text-white"
                            value={sm.ID}
                            onClick={() => {
                              Rmember(sm.ID);
                            }}
                          >
                            <small className="text-danger">Remove</small>
                          </Button>
                        </li>
                      </ul>
                    </Col>
                  ))}
                </Row>
              </div>
            )}

            {shopmemberEdit && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="ShopMemberList">Shop Member</Label>
                    <Input
                      className={`${!shopmemberEdit ? "text-white" : null} ${
                        formik.errors.ShopMemberList ? "is-invalid" : "is-valid"
                      }`}
                      name="ShopMemberList"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.ShopMemberList}
                      plaintext={!shopmemberEdit}
                      readOnly={!shopmemberEdit}
                    />
                    <div className="text-danger">
                      {shopmemberEdit && formik.errors.ShopMemberList
                        ? formik.errors.ShopMemberList
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
            {shopmemberEdit && (
              <Row className="text-left pt-2 font-weight-bold justify-content-end">
                <Col sm="9">
                  <div className="text-center">
                    <Button
                      className="mt-3 button-dark-template"
                      disabled={
                        isLoading || Object.values(formik.errors).length > 0
                      }
                      loading={isLoading}
                      onClick={() => {
                        setSmallNotice({ open: true, field: "bank" });
                      }}
                    >
                      Save Edit
                    </Button>

                    <Button
                      className=" ml-3 mt-3 button-template"
                      onClick={() => setShopmemberEdit(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Form>
      <Modal
        size="sm"
        isOpen={smallNotice.open}
        toggle={() => setSmallNotice({ open: false, field: "" })}
      >
        <ModalBody className="text-center">
          {smallNotice.field === "bank"
            ? "Confirm to Update Bank Details?"
            : null}
        </ModalBody>
        <ModalFooter>
          <div className="left-side" style={{ width: "48%" }}>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              Confirm
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side" style={{ width: "48%" }}>
            <Button
              color="secondary"
              onClick={() => setSmallNotice({ open: false, field: "" })}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
ShopMember.propTypes = {
  shopData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  setMessage: PropTypes.string.isRequired,
  setVisible: PropTypes.string.isRequired,
  shopID: PropTypes.string.isRequired,
};
export default ShopMember;
