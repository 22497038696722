/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useContext, useState, useEffect } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../sales.css";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TradeInStatusUpdate from "./TradeInStatusUpdate";

const columns = [
  {
    name: "TradeIn ID",
    selector: (row) => <p>{`#${row.TradeInID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "TradeIn Date",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
  },
  {
    name: "Name",
    selector: (row) => row.FullName,
    sortable: true,
    wrap: true,
  },
  {
    name: "Contact No",
    selector: (row) => row.Contact,
    sortable: true,
    wrap: true,
  },
  {
    name: "Describe Item(s)",
    selector: (row) => (
      <div>
        {row.TradeInProductList.map((item) => (
          <p>{item.Type}</p>
        ))}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.Quantity,
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        {row.TotalApprovedAmount === null ? (
          <CurrencyFormat decimal={2} price={0} currency="MYR" />
        ) : (
          <CurrencyFormat
            decimal={2}
            price={row.TotalApprovedAmount}
            currency="MYR"
          />
        )}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [showModal, setShowModal] = useState({
        tID: "",
        open: false,
      });
      if (!row) return null;

      return (
        <div>
          <p className="mt-3">{row.TradeInProductList[0].InternalStatus}</p>
          {row.TradeInProductList[0].InternalStatus ===
          "Trade In Parcel Transferred to QC" ? (
            <Button
              id="itemhover"
              key={row.TradeInID}
              className="pl-1 pr-1 btn btn-success"
              onClick={() => {
                setShowModal({
                  tID: row.TradeInID,
                  open: !showModal.open,
                });
              }}
            >
              Received
            </Button>
          ) : (
            <Button
              id="itemhover"
              key={row.TradeInID}
              className="pl-1 pr-1 btn btn-default"
              tag={Link}
              to={`/shopowner/sales/viewtradeindetails/${row.TradeInID}`}
            >
              Action
            </Button>
          )}
          <Modal
            size="lg"
            isOpen={showModal.open}
            toggle={() =>
              setShowModal({
                ...showModal,
                open: !showModal.open,
              })
            }
          >
            <ModalBody className="text-center">
              <TradeInStatusUpdate
                tID={showModal.tID}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            </ModalBody>
          </Modal>
        </div>
      );
    },
    sortable: true,
    wrap: true,
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function TradeInsales() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [dataResult, setDataResult] = React.useState([]);

  const {
    isLoading: isDataLoading,
    data,
    isSuccess,
    refetch,
  } = useQuery(
    `/shop/${shopID}/GetAllTradeInRequest`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetAllTradeInRequest`, {
        params: {
          limitPerPage: 9999,
          Status: "TradeIn Pending",
        },
      }).then((res) => {
        if (isSuccess) {
          const filteredData = res.data.ShopTradeInRequestGetAllDtoList.filter(
            (item) =>
              item.TradeInProductList.some(
                (items) =>
                  items.InternalStatus ===
                    "Trade In Parcel Transferred to QC" ||
                  items.InternalStatus === "Pending Validation"
              )
          );
          setFilterDataResult(filteredData);
          setDataResult(filteredData);
          refetch();
        }

        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (isDataLoading) {
      setFilterDataResult();
      refetch();
    }
  }, [isDataLoading]);

  const resetFilter = () => {
    setDateRange([null, null]);

    setFilterDataResult(dataResult);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.ShopTradeInRequestGetAllDtoList.filter(
        (item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
      )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.TradeInID &&
              item.TradeInID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  if (isDataLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Trade In Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isDataLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default TradeInsales;
