/* eslint-disable new-cap */
import React, { useContext } from "react";
import PropTypes from "prop-types";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  TabContent,
  TabPane,
} from "reactstrap";

// core components
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "./packaging.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { toast } from "react-toastify";
import ImageUploader from "components/CustomUpload/MultipleImgUpload";
import { Link } from "react-router-dom";
import moment from "moment";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick, onChange }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function DashBoardDisplay({
  columns,
  isLoading,
  data,
  isSuccess,
  filterList,
  refetch,
  // define the field from api and this for pdf
  // (different api had different field name)
  columnsForTable,
  //
  forCategory,
  // define the field from api (different api had different field name)
  trackingNo,
  rowState,
  invoice,
  dateShow,
  customerName,
}) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState(data);
  const [selectedData, setSelectedData] = React.useState([]);

  const [imgUploadList, setImgUploadList] = React.useState([]);

  const [tabActive, setTabActive] = React.useState("1");
  const [modal, setModal] = React.useState({
    isOpen: false,
    OrderID: "",
    TrackingNo: "",
    currentStt: "",
  });
  const [modalImg, setModalImg] = React.useState({
    isOpen: false,
    imgList: [],
  });

  const [dropItemList, setDropItemList] = React.useState(
    filterList.reduce((acc, key) => {
      acc[key.field] = [];
      return acc;
    }, {})
  );

  const [dropFilter, setDropFilter] = React.useState(
    filterList.reduce((acc, key) => {
      acc[key.field] = key.label;
      return acc;
    }, {})
  );

  const [dropOpen, setDropOpen] = React.useState("");

  const dropDownOptionGet = (res, id) => {
    const set = new Set();
    res?.forEach((row) => {
      if (row[id] !== "") set.add(row[id]);
    });
    return [...set.values()];
  };

  React.useEffect(() => {
    if (isSuccess) {
      setDropItemList(
        filterList.reduce((acc, key) => {
          acc[key.field] = dropDownOptionGet(data, key.field);
          return acc;
        }, {})
      );
      setFilterDataResult(data);
    }
  }, [data, isSuccess]);

  const columnsData = React.useMemo(() => {
    columns.push({
      name: "Status",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div className="w-100">
          <div className="d-flex w-100 justify-contents-between align-items-center">
            <div className="w-100">{row[rowState]}</div>
            <Button
              className="w-100"
              onClick={() => {
                setModal({
                  isOpen: true,
                  OrderID: forCategory === "tradein" ? row.TradeInID : row.ID,
                  TrackingNo: row[trackingNo],
                  currentStt: row[rowState],
                });
              }}
            >
              Select
            </Button>
          </div>

          {forCategory === "order" && (
            <div className="d-flex w-100 justify-content-around align-items-center">
              <div>Photos</div>
              {row[rowState] !== "Delivered-Logistic" && (
                <Button
                  outline
                  color="primary"
                  className="btn-icon"
                  type="button"
                  onClick={() => {
                    setModalImg({
                      isOpen: true,
                      imgList:
                        forCategory === "order"
                          ? row.OrderPackagingImageList
                          : [],
                    });
                  }}
                >
                  <img
                    src={require("assets/img/icon/Picture1.png")}
                    alt=""
                    className="p-1"
                  />
                </Button>
              )}
            </div>
          )}
          {forCategory === "tradein" && (
            <div className="d-flex w-100 justify-content-around align-items-center">
              <div>Details</div>
              <Link to={`/shopOwner/packaging-trade-detail/${row.TradeInID}`}>
                <Button color="primary" className="btn-icon" type="button">
                  <img
                    src={require("assets/img/icon/Picture2.png")}
                    alt=""
                    className="p-1"
                  />
                </Button>
              </Link>
            </div>
          )}
        </div>
      ),
      wrap: true,
      grow: 3,
    });
    return columns;
  }, [columns]);

  const dropdownToggle = (filter) => {
    if (filter === dropOpen) {
      setDropOpen("");
    } else {
      setDropOpen(filter);
    }
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setSearchQuery("");
    setDropFilter(
      filterList.reduce((acc, key) => {
        acc[key.field] = key.label;
        return acc;
      }, {})
    );
    setFilterDataResult(data);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data
        .filter((item) =>
          startDate !== null
            ? new Date(item[dateShow]).setHours(0, 0, 0, 0) >=
              new Date(startDate).setHours(0, 0, 0, 0)
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item[dateShow]).setHours(0, 0, 0, 0) <=
              new Date(endDate).setHours(0, 0, 0, 0)
            : item
        )
        .filter((item) =>
          filterList.every((filterItem) => {
            if (dropFilter[filterItem.field] !== filterItem.label) {
              return item[filterItem.field] === dropFilter[filterItem.field];
            }
            return true; // If dropFilter doesn't match, consider it as true
          })
        )
        .filter(
          (item) =>
            (item[customerName] &&
              item[customerName]
                .toLowerCase()
                .includes(searchQuery.toLowerCase())) ||
            (item[invoice] &&
              item[invoice].toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const convertToCSV = (data2) => {
    const header = Object.keys(data2[0]);
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (Array.isArray(row[fieldName])) {
              return row[fieldName].join(";");
            }
            if (fieldName === "OrderDate") {
              return JSON.stringify(moment(row.OrderDate).format("DD/MM/YYYY"));
            }
            return JSON.stringify(row[fieldName]);
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportPDF = (jsondata) => {
    const doc = new jsPDF({ orientation: "landscape", size: "A4" });
    doc.setFontSize(12);
    let bodydata = jsondata.map((d) =>
      columnsForTable.map((e) => {
        if (e === "Courier" && d[e].trim() === "") {
          return d.DeliveryType;
        }
        return d[e];
      })
    );
    function isISODate(dateString) {
      const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      return isoDatePattern.test(dateString);
    }

    bodydata = bodydata.map((item) => {
      const updatedItem = Object.keys(item).reduce((acc, key) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (isISODate(item[key])) {
            acc[key] = new Date(item[key]).toLocaleDateString("en-GB");
          } else if (key === "Courier" && item[key].trim() === "") {
            acc[key] = item.DeliveryType;
          } else if (Array.isArray(item[key]) && item[key].length <= 0) {
            acc[key] = "";
          } else {
            acc[key] = item[key];
          }
        }
        return acc;
      }, {});

      return updatedItem;
    });

    autoTable(doc, {
      head: [columnsData.map((c) => c.name)],
      body: bodydata,
    });
    doc.save("table.pdf");
  };

  const imageCallback = (file) => {
    setImgUploadList(file);
  };

  const statusUpdate = (OrderStatus) => {
    switch (forCategory) {
      case "tradein":
        if (OrderStatus === "QC") {
          Axios.put(`shop/${shopID}/TradeInTransferToQC/${modal.OrderID}`)
            .then((res) => {
              toast.success("Status Updated");
              refetch();
              return res;
            })
            .catch((err) => {
              toast.error(err.response.data.MESSAGE);
              return err;
            });
        } else if (OrderStatus === "Dispatch") {
          const data2 = new FormData();
          imgUploadList.forEach((img) => {
            data2.append("AttachmentList", img.file);
          });
          Axios.put(
            `shop/${shopID}/TradeInPackagingTransferParcelToDispatch/${modal.OrderID}`,
            data2
          )
            .then((res) => {
              toast.success("Status Updated");
              refetch();
              return res;
            })
            .catch((err) => {
              toast.error(err.response.data.MESSAGE);
              return err;
            });
        }
        setModal({ ...modal, isOpen: false });
        break;
      case "order":
        Axios.put(`/shop/${shopID}/Order/Packaging/Order`, {
          OrderID: modal.OrderID,
          TrackingNo: modal.TrackingNo,
          OrderStatus,
        })
          .then((res) => {
            if (imgUploadList.length > 0) {
              const data2 = new FormData();
              imgUploadList.forEach((img) => {
                data2.append("OrderPackagingImageList", img.file);
              });
              Axios.post(
                `/shop/${shopID}/Order/${modal.OrderID}/Packaging/Image`,
                data2
              )
                .then((res2) => {
                  toast.success("Status Updated");
                  refetch();
                  return res2;
                })
                .catch((err) => {
                  toast.error(err.response.data.MESSAGE);
                  return err;
                });
            } else {
              toast.success("Status Updated");
              refetch();
            }
            return res;
          })
          .catch((err) => {
            toast.error(err.response.data.MESSAGE);
            return err;
          });
        setModal({ ...modal, isOpen: false });
        break;
      case "return":
        if (OrderStatus === "QC") {
          Axios.put(`shop/${shopID}/TransferToQC/${modal.OrderID}`, true, {
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              toast.success("Status Updated");
              refetch();
              return res;
            })
            .catch((err) => {
              toast.error(err.response.data.MESSAGE);
              return err;
            });
        } else if (OrderStatus === "Dispatch") {
          const data2 = new FormData();
          data2.append("transferred", true);
          imgUploadList.forEach((img) => {
            data2.append("AttachmentList", img.file);
          });
          Axios.put(
            `shop/${shopID}/ShipBackTranferToDispatch/${modal.OrderID}`,
            data2
          )
            .then((res) => {
              toast.success("Status Updated");
              refetch();
              return res;
            })
            .catch((err) => {
              toast.error(err.response.data.MESSAGE);
              return err;
            });
        }
        setModal({ ...modal, isOpen: false });
        break;
      case "shipback":
        if (OrderStatus === "QC") {
          let apiEndpoint = null;
          if (modal.OrderID.startsWith("TI")) {
            apiEndpoint = `shop/${shopID}/TradeInTransferToQC/${modal.OrderID}`;
          } else if (modal.OrderID.startsWith("RR")) {
            apiEndpoint = `shop/${shopID}/TransferToQC/${modal.OrderID}`;
          }

          if (apiEndpoint) {
            Axios.put(apiEndpoint, true, {
              headers: {
                "Content-Type": "application/json",
              },
            })
              .then((res) => {
                toast.success("Status Updated");
                refetch();
                return res;
              })
              .catch((err) => {
                toast.error(err.response.data.MESSAGE);
                return err;
              });
          } else {
            // Handle invalid modal.OrderID value
          }
        } else if (OrderStatus === "Dispatch") {
          const data2 = new FormData();
          data2.append("transferred", true);
          imgUploadList.forEach((img) => {
            data2.append("AttachmentList", img.file);
          });

          let apiEndpoint = null;
          if (modal.OrderID.startsWith("TI")) {
            apiEndpoint = `shop/${shopID}/TradeInPackagingTransferParcelToDispatch/${modal.OrderID}`;
          } else if (modal.OrderID.startsWith("RR")) {
            apiEndpoint = `shop/${shopID}/ShipBackTranferToDispatch/${modal.OrderID}`;
          }

          if (apiEndpoint) {
            Axios.put(apiEndpoint, data2)
              .then((res) => {
                toast.success("Status Updated");
                refetch();
                return res;
              })
              .catch((err) => {
                toast.error(err.response.data.MESSAGE);
                return err;
              });
          } else {
            // Handle invalid modal.OrderID value
          }
        }

        setModal({ ...modal, isOpen: false });
        break;

      default:
        setModal({ ...modal, isOpen: false });
        break;
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Modal
        isOpen={modal.isOpen}
        toggle={() => setModal({ ...modal, isOpen: false })}
      >
        <Card className="text-center">
          <CardHeader tag="h3">Current Status: {modal.currentStt}</CardHeader>
          <hr />
          <CardBody>
            {forCategory === "order" ? (
              <div>
                <Button
                  className="btn btn-success text-dark"
                  disabled={tabActive === "1"}
                  onClick={() => {
                    setTabActive("1");
                  }}
                  {...(tabActive === "1" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  ( 1 ) Packaging Received
                </Button>
                <Button
                  className="btn btn-danger text-dark"
                  disabled={tabActive === "2"}
                  onClick={() => {
                    setTabActive("2");
                  }}
                  {...(tabActive === "2" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  ( 1 ) Packaging Rejected
                </Button>
                <Button
                  className="btn btn-primary text-dark"
                  disabled={tabActive === "3"}
                  onClick={() => {
                    setTabActive("3");
                  }}
                  {...(tabActive === "3" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  ( 2 ) Ready To Pick Up
                </Button>

                <Button
                  className="btn btn-warning text-dark"
                  disabled={tabActive === "4"}
                  onClick={() => {
                    setTabActive("4");
                  }}
                  {...(tabActive === "4" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  ( 3 ) Transferred to Dispatch
                </Button>
                <h4>Image to pass</h4>
                <small>Limit up to 3 image</small>
                <ImageUploader onImageUpload={imageCallback} />

                <TabContent activeTab={tabActive}>
                  <TabPane tabId="1">
                    <Button
                      onClick={() => statusUpdate("Packaging Received")}
                      color="danger"
                    >
                      Confirm
                    </Button>
                  </TabPane>
                  <TabPane tabId="2">
                    <Button
                      onClick={() => statusUpdate("Packaging Rejected")}
                      color="danger"
                    >
                      Confirm
                    </Button>
                  </TabPane>
                  <TabPane tabId="3">
                    <Button
                      onClick={() => statusUpdate("Delivered-Logistic")}
                      color="danger"
                    >
                      Confirm
                    </Button>
                  </TabPane>
                  <TabPane tabId="4">
                    <Button
                      onClick={() => statusUpdate("Transferred")}
                      color="danger"
                    >
                      Confirm
                    </Button>
                  </TabPane>
                </TabContent>
              </div>
            ) : (
              <div>
                <Button
                  disabled={tabActive === "1"}
                  onClick={() => {
                    setTabActive("1");
                  }}
                  {...(tabActive === "1" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  Transferred to Dispatch
                </Button>
                <Button
                  disabled={tabActive === "2"}
                  onClick={() => {
                    setTabActive("2");
                  }}
                  {...(tabActive === "2" && {
                    style: {
                      border: "3px solid black",
                    },
                  })}
                >
                  Transferred to QC
                </Button>

                <TabContent activeTab={tabActive}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <h4>Image to pass</h4>
                        <small>Limit up to 3 image</small>
                        <ImageUploader onImageUpload={imageCallback} />
                        <Button
                          onClick={() => statusUpdate("Dispatch")}
                          color="danger"
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Button
                          onClick={() => statusUpdate("QC")}
                          color="danger"
                          className="mt-5"
                        >
                          Confirm
                        </Button>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            )}
          </CardBody>
        </Card>
      </Modal>
      <Modal
        isOpen={modalImg.isOpen}
        toggle={() => setModalImg({ ...modalImg, isOpen: false })}
      >
        <Card className="text-center">
          <CardHeader tag="h3">Image Upload</CardHeader>
          <hr />
          <CardBody>
            <Row>
              {modalImg.imgList.length > 0 ? (
                modalImg.imgList.map((img) => (
                  <Col>
                    <img src={img.ImageUrl} alt=" " />
                  </Col>
                ))
              ) : (
                <Col>
                  <p>No image uploaded</p>
                </Col>
              )}
            </Row>
          </CardBody>
        </Card>
      </Modal>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">All Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Filter:
            </span>
            {filterList.map((element) => (
              <Dropdown
                isOpen={dropOpen === element.field}
                toggle={() => dropdownToggle(element.field)}
              >
                <DropdownToggle caret color="filter" outline>
                  {dropFilter[element.field]}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({
                        ...dropFilter,
                        [element.field]: element.label,
                      });
                    }}
                  >
                    -
                  </DropdownItem>
                  {dropItemList[element.field].map((x) => (
                    <DropdownItem
                      key={x}
                      onClick={() => {
                        setDropFilter({ ...dropFilter, [element.field]: x });
                      }}
                    >
                      {x}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            ))}

            <Button className="ml-1" onClick={() => filterData()}>
              Filter
            </Button>
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>
            <Button
              outline
              color="filter"
              onClick={() =>
                exportPDF(
                  selectedData.length > 0 ? selectedData : filterDataResult
                )
              }
            >
              Export PDF
            </Button>
            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(
                  selectedData.length > 0 ? selectedData : filterDataResult
                );
              }}
            >
              Export CSV
            </Button>
          </div>
          {isSuccess && data && (
            <div id="packaging-report">
              <BasicTable
                pagination
                id="packaging-report"
                selectableRows
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columnsData || []}
                onSelectedRowsChange={handleSelectRows}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

DashBoardDisplay.propTypes = {
  columns: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    selector: PropTypes.node.isRequired,
    sortable: PropTypes.bool.isRequired,
    wrap: PropTypes.bool.isRequired,
  }).isRequired,
  refetch: PropTypes.func.isRequired,
  filterList: PropTypes.node.isRequired,
  data: PropTypes.shape.isRequired,
  columnsForTable: PropTypes.arrayOf.isRequired,
  forCategory: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  trackingNo: PropTypes.string.isRequired,
  rowState: PropTypes.string.isRequired,
  invoice: PropTypes.string.isRequired,
  dateShow: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

export default DashBoardDisplay;
