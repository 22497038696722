/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { MultiSelect } from "react-multi-select-component";
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import Switch from "react-ios-switch";

function CouponForm(props) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;
  const { modal, setModal, type, refetch } = props;

  const [couponType, setCouponType] = useState("Coupon");
  const [prodList, setProdList] = useState([]);
  const [merchList, setMerchList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [collecList, setCollecList] = useState([]);
  const [collabList, setCollabList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [discountFree, setDiscountFree] = useState("Discount");
  const [rank, setRank] = useState([]);

  const [roleLimit, setRoleLimit] = useState(false);
  const [roleStr, setRoleStr] = useState("User");
  const [role, setRole] = useState(false);

  const { data: prodData } = useQuery("ProdList", async () =>
    Axios.get(`/public/Product/GetAllProductWithoutImage`)
  );

  const { data: merchData } = useQuery("MerchList", async () =>
    Axios.get(`/shop/${selectShop}/Merchandise/GetAllMerchandise`)
  );

  const { data: categoryData } = useQuery("CategList", async () =>
    Axios.get(`/public/ProductCategorySetting/ChildCategory`)
  );

  const { data: collectData } = useQuery("CollecList", async () =>
    Axios.get(`/public/${selectShop}/Collection`)
  );

  const { data: collabData } = useQuery("CollabList", async () =>
    Axios.get(`/public/${selectShop}/Collaboration`)
  );

  const { data: AllUser } = useQuery(["UserList", role], async () =>
    Axios.get(`/shop/${selectShop}/Account/AllUser`, {
      params: {
        order: "ASC",
        orderBy: "Email",
        isStaff: role,
        limitPerPage: 10000,
      },
    })
  );

  const { data: LoyaltyRank } = useQuery("LoyaltyRank", async () =>
    Axios.get(`/public/LoyaltySetting`)
  );

  const [status, setStatus] = useState(true);
  const [multiRedeem, setMultiRedeem] = useState(false);
  const [couponOnPromo, setCouponOnPromo] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [thirdParty, setThirdParty] = useState(false);
  const [discountType, setDiscountType] = useState("Subtraction");
  const [discount, setDiscount] = useState("0");
  const [disCapp, setDisCapp] = useState("0");
  const [selectProd, setSelectProd] = useState([]);
  const [selectCat, setSelectCat] = useState([]);
  const [selectCollab, setSelectCollab] = useState([]);
  const [selectCollect, setSelectCollect] = useState([]);
  const [selectEmail, setSelectEmail] = useState([]);
  const [selectFreeGift, setSelectFreeGift] = useState([]);
  const [selectFreeProduct, setSelectFreeProduct] = useState([]);
  const [selectRank, setSelectRank] = useState([]);
  const [loyaltyImg, setLoyaltyImg] = useState("");

  const [originDisType, setOriginDisType] = useState("");
  const [originDis, setOriginDis] = useState("");
  const [originDisCapp, setOriginDisCapp] = useState("");

  const [originFreeGift, setOriginFreeGift] = useState([]);
  const [originFreeProd, setOriginFreeProd] = useState([]);

  const { refetch: refectchCoupon, data: Coupons } = useQuery(
    ["SingleCoupon", type, modal],
    async () => Axios.get(`/shop/${selectShop}/Coupon/${type.ID}`),
    { enabled: type.Type === "Edit" }
  );

  const handleProd = (selectedProd) => {
    setSelectProd(selectedProd);
  };

  const handleEmail = (selectedEmail) => {
    setSelectEmail(selectedEmail);
  };

  const handleCat = (selectedCat) => {
    setSelectCat(selectedCat);
  };

  const handleCollect = (selectedCollect) => {
    setSelectCollect(selectedCollect);
  };

  const handleCollab = (selectedCollab) => {
    setSelectCollab(selectedCollab);
  };

  const handleFreeGift = (selectedFreeGift) => {
    setSelectFreeGift(selectedFreeGift);
  };

  const handleFreeProduct = (selectedFreeProd) => {
    setSelectFreeProduct(selectedFreeProd);
  };

  const handleRank = (selectedRank) => {
    setSelectRank(selectedRank);
  };

  const handleRole = (staffSet) => {
    setRoleStr(staffSet);
    if (staffSet === "User") {
      setRole(false);
    } else {
      setRole(true);
    }
  };

  const toggle = () => {
    setModal(!modal);
    setCouponOnPromo(false);
    setLoyaltyImg("");
    refectchCoupon();
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Please Enter ${couponType} Name`),
  });

  const formik = useFormik({
    initialValues: {
      name: Coupons ? Coupons.data.Name : "",
      desc: Coupons ? Coupons.data.Description : "",
      code: Coupons ? Coupons.data.Code : "",
      minPurchase: Coupons ? Coupons.data.MinPurchaseAmount : 0,
      limit: Coupons ? Coupons.data.LimitCount : 0,
      userLimit: Coupons ? Coupons.data.PerUserLimit : 0,
      points: Coupons ? Coupons.data.LoyaltyPointValue : 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (dataM) => {
      const EmailList = [];
      const ProdList = [];
      const CatList = [];
      const CollabList = [];
      const CollectionList = [];
      const FreeList = [];
      const RankList = [];

      if (selectEmail.length !== 0 && roleLimit) {
        selectEmail.forEach((email) => {
          EmailList.push(email.value);
        });
      }

      if (selectProd.length !== 0) {
        selectProd.forEach((prod) => {
          ProdList.push(prod.value);
        });
      }
      if (selectCat.length !== 0) {
        selectCat.forEach((category) => {
          CatList.push(category.value);
        });
      }

      if (selectCollab.length !== 0) {
        selectCollab.forEach((collab) => {
          CollabList.push(collab.value);
        });
      }

      if (selectCollect.length !== 0) {
        selectCollect.forEach((collection) => {
          CollectionList.push(collection.value);
        });
      }

      if (selectFreeGift.length !== 0) {
        selectFreeGift.forEach((gift) => {
          FreeList.push(gift.value);
        });
      }

      if (selectRank.length !== 0) {
        selectRank.forEach((ranks) => {
          RankList.push(ranks.value);
        });
      }

      const data2 = new FormData();
      data2.append("Name", dataM.name);
      data2.append("Description", dataM.desc);
      if (loyaltyImg !== "") {
        data2.append("CouponAttachmentList", loyaltyImg.File);
      }
      data2.append("Status", status);
      if (couponType === "Loyalty") {
        data2.append("Code", dataM.name);
      } else {
        data2.append("Code", dataM.code);
      }
      data2.append("UseGeneratedCode", true);

      data2.append("AllowStacking", couponType === "Loyalty");
      data2.append("AllowCouponOnPromotion", couponOnPromo);
      data2.append("StartDateTime", startDate);

      if (endDate === "") {
        data2.append("NoEndDate", true);
      } else {
        data2.append("NoEndDate", false);
        data2.append("EndDateTime", endDate);
      }

      data2.append("AllowLimit", dataM.limit !== 0);
      data2.append("LimitCount", dataM.limit);

      if (selectProd.length !== 0) {
        selectProd.forEach((prod) => {
          data2.append("ProductIDList", prod.value);
        });
      }

      if (selectCat.length !== 0) {
        selectCat.forEach((category) => {
          data2.append("CategoryIDList", category.value);
        });
      }

      if (selectCollab.length !== 0) {
        selectCollab.forEach((collab) => {
          data2.append("CollaborationIdlist", collab.value);
        });
      }

      if (selectCollect.length !== 0) {
        selectCollect.forEach((collection) => {
          data2.append("CollectionIdlist", collection.value);
        });
      }

      if (selectRank.length !== 0) {
        data2.append("IsLoyaltyRankLimit", true);
        selectRank.forEach((ranks) => {
          data2.append("AllowedLoyaltyRankList", ranks.value);
        });
      } else {
        data2.append("IsLoyaltyRankLimit", false);
      }

      data2.append("IsRoleLimit", roleLimit);

      if (selectEmail.length !== 0 && roleLimit) {
        data2.append("AllowedRoleList", [roleStr]);
        selectEmail.forEach((email) => {
          data2.append("UserIDList", email.value);
        });
        data2.append("UseUserIDListOnly", true);
      } else {
        data2.append("UseUserIDListOnly", false);
      }

      data2.append("Type", discountFree);

      if (discountFree === "Free") {
        if (selectFreeProduct.length !== 0 || selectFreeGift.length !== 0) {
          selectFreeGift.forEach((gift) => {
            data2.append("Free.FreeGiftIDList", gift.value);
          });
          selectFreeProduct.forEach((product) => {
            data2.append("Free.FreeProductIDList", product.value);
          });
        }
      }

      if (discountFree === "Discount" || discountFree === "Free Shipping") {
        data2.append("Discount.DiscountValue", discount);
        data2.append("Discount.Type", discountType);
        data2.append("Discount.CappedDiscountAmount", disCapp);
        data2.append(
          "Discount.EnableCappedDiscountAmount",
          parseFloat(disCapp) !== 0
        );
      }

      data2.append("EnableMinPurchaseAmount", dataM.minPurchase !== 0);
      data2.append("MinPurchaseAmount", dataM.minPurchase);

      if (couponType === "Loyalty") {
        data2.append("LoyaltyCoupon", true);
        data2.append("LoyaltyPointValue", dataM.points);
      } else {
        data2.append("LoyaltyCoupon", false);
      }

      data2.append("ThirdParty", thirdParty);
      data2.append("IsMultipleRedeem", multiRedeem);

      data2.append("IsPerUserLimit", dataM.userLimit > 0);
      data2.append("PerUserLimit", dataM.userLimit);

      if (
        selectProd.length === 0 &&
        selectCat.length === 0 &&
        selectCollab.length === 0 &&
        selectCollect.length === 0
      ) {
        data2.append("IsOrderCoupon", true);
      } else {
        data2.append("IsOrderCoupon", false);
      }

      if (type.Type === "Create") {
        Axios.post(`/shop/${selectShop}/Coupon`, data2)
          .then(() => {
            toast.success(
              <div>
                <p className="text-white">Successful Created {couponType} </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });
      } else if (type.Type === "Edit") {
        Axios.put(`/shop/${selectShop}/Coupon/${type.ID}`, {
          Name: dataM.name,
          Description: dataM.desc,
          Status: status,
          Code: dataM.code === Coupons.data.Code ? null : dataM.code,
          IsRoleLimit: roleLimit,
          AllowedRoleList: roleLimit ? [roleStr] : null,
          UserIDList: EmailList.length !== 0 && roleLimit ? EmailList : null,
          UseUserIDListOnly: EmailList.length !== 0,
          AllowStacking: couponType === "Loyalty",
          AllowCouponOnPromotion: couponOnPromo,
          StartDateTime: moment(startDate, "YYYY-MM-DD").toDate(),
          NoEndDate: endDate === "",
          EndDateTime: moment(endDate, "YYYY-MM-DD").toDate(),
          LimitCount: dataM.limit,
          AllowLimit: dataM.limit !== 0,
          ProductIDList: ProdList.length !== 0 ? ProdList : null,
          CategoryIDList: CatList.length !== 0 ? CatList : null,
          CollaborationIdlist: CollabList.length !== 0 ? CollabList : null,
          CollectionIdlist: CollectionList.length !== 0 ? CollectionList : null,
          Type: discountFree,
          EnableMinPurchaseAmount: dataM.minPurchase !== 0,
          MinPurchaseAmount: dataM.minPurchase,
          LoyaltyCoupon: couponType === "Loyalty",
          LoyaltyValuePoint: couponType === "Loyalty" ? dataM.points : null,
          ThirdParty: thirdParty,
          IsMultipleRedeem: multiRedeem,
          IsOrderCoupon:
            ProdList.length === 0 &&
            CatList.length === 0 &&
            CollabList.length === 0 &&
            CollectionList.length === 0,
          IsPeruserLimit: dataM.userLimit > 0,
          PeruserLimit: dataM.userLimit,
          IsLoyaltyRankLimit: RankList.length !== 0,
          AllowedLoyaltyRankList: RankList.length !== 0 ? RankList : null,
        })
          .then(() => {
            if (
              discountFree === "Discount" ||
              discountFree === "Free Shipping"
            ) {
              if (
                originDisType !== discountType ||
                originDis !== discount ||
                originDisCapp !== disCapp
              ) {
                Axios.put(`/shop/${selectShop}/Coupon/${type.ID}/discount`, {
                  DiscountValue: discount,
                  Type: discountType,
                  EnableCappedDiscountAmount: parseFloat(disCapp) !== 0,
                  CappedDiscountAmount: disCapp,
                });
              }
            } else if (discountFree === "Free Gift") {
              if (
                JSON.stringify(originFreeGift) !==
                  JSON.stringify(selectFreeGift) ||
                JSON.stringify(originFreeProd) !==
                  JSON.stringify(selectFreeProduct)
              ) {
                const freeGift = [];
                const freeProd = [];

                selectFreeGift.forEach((gifts) => {
                  freeGift.push(gifts.value);
                });

                selectFreeProduct.forEach((prods) => {
                  freeProd.push(prods.value);
                });

                Axios.put(`/shop/${selectShop}/Coupon/${type.ID}/freeItem`, {
                  FreeGiftIDList: freeGift,
                  FreeProductIDList: freeProd,
                });
              }
            }
            toast.success(
              <div>
                <p className="text-white">Successful Updated {couponType} </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });
      }
    },
  });
  const bannerDeskRef = React.createRef();
  const handleBannerDeskClick = () => {
    bannerDeskRef.current.click();
  };

  const handleBannerDesk = (e) => {
    setLoyaltyImg({
      File: e.target.files[0],
      URL: URL.createObjectURL(e.target.files[0]),
    });
  };

  useEffect(() => {
    if (prodData) {
      const temp = [];
      prodData.data.map((item) =>
        temp.push({ label: item.ProductName, value: item.ProductID })
      );
      setProdList(temp);
    }
    if (merchData) {
      const temp = [];
      merchData.data.map((item) =>
        temp.push({ label: item.EngName, value: item.ID })
      );
      setMerchList(temp);
    }
    if (categoryData) {
      const temp = [];
      categoryData.data.ChildCategories.map((item) =>
        temp.push({ label: item.EngCategoryName, value: item.ID })
      );
      setCatList(temp);
    }
    if (collectData) {
      const temp = [];
      collectData.data.map((item) =>
        temp.push({ label: item.Name, value: item.ID })
      );
      setCollecList(temp);
    }
    if (collabData) {
      const temp = [];
      collabData.data.map((item) =>
        temp.push({ label: item.Name, value: item.ID })
      );
      setCollabList(temp);
    }

    if (AllUser) {
      const temp = [];
      AllUser.data.Result.map((item) =>
        temp.push({ label: item.Email, value: item.ID })
      );
      setUserList(temp);
    }

    if (LoyaltyRank) {
      const temp = [];
      LoyaltyRank.data.map((item) =>
        temp.push({ label: item.RankName, value: item.ID })
      );
      setRank(temp);
    }
  }, [prodData, categoryData, collectData, collabData, AllUser]);

  useEffect(() => {
    setCouponOnPromo(false);
    setDiscountType("Subtraction");
    setDiscount("0");
    setDisCapp("0");
    setStartDate("");
    setEndDate("");
    setSelectProd([]);
    setSelectCat([]);
    setSelectCollab([]);
    setSelectCollect([]);
    setSelectEmail([]);
    setSelectFreeGift([]);
    setSelectFreeProduct([]);
    setLoyaltyImg("");
    setRoleLimit(false);
    setRole(false);
    setRoleStr("User");
    formik.resetForm();
    setCouponType(type.Coupon);
  }, [type]);

  useEffect(() => {
    if (Coupons) {
      setCouponType(type.Coupon);
      setStatus(Coupons.data.Status);
      setMultiRedeem(Coupons.data.IsMultipleRedeem);
      if (type.Coupon === "Loyalty") {
        setThirdParty(Coupons.data.ThirdParty);
      }
      setCouponOnPromo(Coupons.data.AllowCouponOnPromotion);
      setStartDate(moment(Coupons.data.StartDateTime).format("YYYY-MM-DD"));
      if (!Coupons.data.NoEndDate) {
        setEndDate(moment(Coupons.data.EndDateTime).format("YYYY-MM-DD"));
      }

      if (Coupons.data.UserIDList.length !== 0) {
        const temp = [];
        Coupons.data.UserIDList.map((item) =>
          temp.push({ label: item.Email, value: item.UserID })
        );
        setSelectEmail(temp);
      }
      if (Coupons.data.ProductIDList.length !== 0) {
        const temp = [];
        Coupons.data.ProductList.map((item) =>
          temp.push({ label: item.Name, value: item.ID })
        );
        setSelectProd(temp);
      }
      if (Coupons.data.CategoryList.length !== 0) {
        const temp = [];
        Coupons.data.CategoryList.map((item) =>
          temp.push({ label: item.Name, value: item.ID })
        );
        setSelectCat(temp);
      }
      if (Coupons.data.CollaborationList.length !== 0) {
        const temp = [];
        Coupons.data.CollaborationList.map((item) =>
          temp.push({ label: item.Name, value: item.ID })
        );
        setSelectCollab(temp);
      }
      if (Coupons.data.CollectionList.length !== 0) {
        const temp = [];
        Coupons.data.CollectionList.map((item) =>
          temp.push({ label: item.Name, value: item.ID })
        );
        setSelectCollect(temp);
      }
      if (
        Coupons.data.AllowedLoyaltyRankList?.length !== 0 ||
        Coupons.data.AllowedLoyaltyRankList !== null
      ) {
        const temp = [];
        Coupons.data.AllowedLoyaltyRankList?.map((item) =>
          temp.push({ label: item.RankName, value: item.RankID })
        );
        setSelectRank(temp);
      }
      setDiscountFree(Coupons.data.Type);
      if (
        Coupons.data.Type === "Discount" ||
        Coupons.data.Type === "Free Shipping"
      ) {
        setDiscountType(Coupons.data.Discount.Type);
        setOriginDisType(Coupons.data.Discount.Type);

        setDiscount(Coupons.data.Discount.DiscountValue);
        setOriginDis(Coupons.data.Discount.DiscountValue);

        setDisCapp(Coupons.data.Discount.CappedDiscountAmount);
        setOriginDisCapp(Coupons.data.Discount.CappedDiscountAmount);
      }

      if (Coupons.data.Type === "Free Gift") {
        if (Coupons.data.Free.FreeGiftIDList.length !== 0) {
          const temp = [];
          Coupons.data.Free.FreeGiftIDList?.map((item) =>
            temp.push({ label: item.Name, value: item.ID })
          );
          setOriginFreeGift(temp);
          setSelectFreeGift(temp);
        }
        if (Coupons.data.Free.FreeProductIDList.length !== 0) {
          const temp = [];
          Coupons.data.Free.FreeProductIDList?.map((item) =>
            temp.push({ label: item.Name, value: item.ID })
          );
          setOriginFreeProd(temp);
          setSelectFreeProduct(temp);
        }
      }
      setRoleLimit(Coupons.data.IsRoleLimit);
    }
  }, [Coupons]);

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalBody className="text-center">
        <Button className="float-right closeBtn" onClick={() => toggle()}>
          <i className="nc-icon nc-simple-remove" />
        </Button>
        <h3 className="mt-3">
          {type.Type} {type.Coupon === "Coupon" ? "" : "Loyalty"} Coupon
        </h3>
        <Form onSubmit={formik.handleSubmit}>
          <Container className="modalContainer">
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Status :
                  </Label>
                </div>
              </Col>
              <Col>
                <Switch
                  className="float-left mt-3"
                  checked={status}
                  onChange={() => setStatus(!status)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Coupon Type :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  type="select"
                  className="modalInput"
                  value={couponType}
                  onChange={(e) => setCouponType(e.target.value)}
                  disabled={type.Type === "Edit"}
                >
                  <option value="Coupon">Coupon</option>
                  <option value="Loyalty">Loyalty Coupon</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} Name :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="modalInput"
                  placeholder={`${couponType} Name..`}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name ? (
                  <p className="errorText float-left">{formik.errors.name}</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} Description :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="desc"
                  name="desc"
                  type="textarea"
                  className="modalInput pt-1 pl-2"
                  placeholder={`${couponType} Description..`}
                  value={formik.values.desc}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            {couponType === "Loyalty" && (
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Loyalty Points Require :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    id="points"
                    name="points"
                    type="text"
                    className="modalInput"
                    placeholder="Loyalty Points Require.."
                    onChange={formik.handleChange}
                    value={formik.values.points}
                  />
                </Col>
              </Row>
            )}

            {couponType === "Coupon" && (
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      {couponType} Code :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    id="code"
                    name="code"
                    type="text"
                    className="modalInput"
                    placeholder={`${couponType} Code..`}
                    value={formik.values.code}
                    onChange={formik.handleChange}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Allowed {couponType} on Promotion :
                  </Label>
                </div>
              </Col>
              <Col md="1">
                <Input
                  className="modalCheck"
                  type="checkbox"
                  checked={couponOnPromo}
                  onChange={() => setCouponOnPromo(!couponOnPromo)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} Date :
                  </Label>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col md="3">
                    <Input
                      type="date"
                      className="modalDate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <p className="ml-1 mt-3 pt-1">To</p>
                  <Col>
                    <Input
                      type="date"
                      className="modalDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Role Limit :
                  </Label>
                </div>
              </Col>
              <Col>
                <Switch
                  className="float-left mt-3"
                  checked={roleLimit}
                  onChange={() => setRoleLimit(!roleLimit)}
                />
              </Col>
            </Row>
            {roleLimit && (
              <>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Allowed Role :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      className="modalInput"
                      value={roleStr}
                      onChange={(e) => handleRole(e.target.value)}
                    >
                      <option value="User">User</option>
                      {/* <option value="Shop">Shop</option> */}
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Allowed Email :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <MultiSelect
                      className="mt-2 pt-1 multiDrop"
                      options={userList}
                      value={selectEmail}
                      onChange={handleEmail}
                      labelledBy="Select User..."
                    />
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} For Product :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={prodList}
                  value={selectProd}
                  onChange={handleProd}
                  labelledBy="Select Products..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} For Category :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={catList}
                  value={selectCat}
                  onChange={handleCat}
                  labelledBy="Select Category..."
                  disabled={
                    selectProd.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} For Collaboration :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={collabList}
                  value={selectCollab}
                  onChange={handleCollab}
                  labelledBy="Select Collaboration..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectProd.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType} For Collection :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={collecList}
                  value={selectCollect}
                  onChange={handleCollect}
                  labelledBy="Select Collection..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectProd.length !== 0
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Miminum Purchase Amount :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="minPurchase"
                  name="minPurchase"
                  type="text"
                  className="modalInput"
                  placeholder="Minimum Purchase Amount.."
                  value={formik.values.minPurchase}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>

            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    {couponType === "Loyalty"
                      ? `${couponType} Coupon`
                      : couponType}{" "}
                    Limit :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="limit"
                  name="limit"
                  className="modalInput"
                  type="text"
                  placeholder={`${couponType} Limit..`}
                  value={formik.values.limit}
                  onChange={formik.handleChange}
                  // checked={onHomepage}
                  // onChange={() => setOnHomepage(!onHomepage)}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Per User Limit :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="userLimit"
                  name="userLimit"
                  type="text"
                  className="modalInput"
                  placeholder="Per User Limit..."
                  value={formik.values.userLimit}
                  onChange={formik.handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Loyalty Rank :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={rank}
                  value={selectRank}
                  onChange={handleRank}
                  labelledBy="Select Rank..."
                />
              </Col>
            </Row>
            {couponType === "Loyalty" && (
              <>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Third Party :
                      </Label>
                    </div>
                  </Col>
                  <Col md="1">
                    <Input
                      className="modalCheck"
                      type="checkbox"
                      checked={thirdParty}
                      onChange={() => setThirdParty(!thirdParty)}
                    />
                  </Col>
                </Row>
                {type.Type !== "Edit" && (
                  <Row>
                    <Col md="4">
                      <div>
                        <Label tag="h5" className="float-right modalLabel">
                          Loyalty Image :
                        </Label>
                      </div>
                    </Col>
                    <Col>
                      {loyaltyImg ? (
                        <div>
                          <button
                            type="button"
                            className="removeImg"
                            onClick={() => setLoyaltyImg("")}
                          >
                            <i className="fa fa-times-circle removeIcon" />
                          </button>
                          <img
                            src={loyaltyImg.URL}
                            className="float-left mt-1 bannerImg"
                          />
                        </div>
                      ) : (
                        <Button
                          className="float-left"
                          onClick={() => handleBannerDeskClick()}
                        >
                          Upload
                        </Button>
                      )}
                      <input
                        className="uploadImg"
                        type="file"
                        accept="image/*"
                        onChange={handleBannerDesk}
                        ref={bannerDeskRef}
                      />
                    </Col>
                  </Row>
                )}
              </>
            )}
            <Row>
              <Col md="4">
                <div>
                  <Label
                    tag="h5"
                    className="float-right modalLabel"
                    style={{ textAlign: "right" }}
                  >
                    Discount / Free Gift
                    <br />/ Free Shipping :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  type="select"
                  className="modalInput"
                  value={discountFree}
                  onChange={(e) => setDiscountFree(e.target.value)}
                >
                  <option value="Discount">Discount</option>
                  <option value="Free Gift">Free Gift</option>
                  <option value="Free Shipping">Free Shipping</option>
                </Input>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Multiple Redeem :
                  </Label>
                </div>
              </Col>
              <Col>
                <Switch
                  className="float-left mt-3"
                  checked={multiRedeem}
                  onChange={() => setMultiRedeem(!multiRedeem)}
                />
              </Col>
            </Row>
            <div className="mt-4">
              <Label tag="h5" className="float-left ml-4">
                {discountFree}
              </Label>
            </div>
            <br />
            <hr />
            {discountFree === "Free Shipping" || discountFree === "Discount" ? (
              <div className="mb-3">
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Discount Type :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      className="modalInput"
                      value={discountType}
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <option value="Subtraction">Subtraction</option>
                      <option value="Percentage">Percentage</option>
                    </Input>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Discount Value :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      className="modalInput"
                      placeholder="Discount Value..."
                      value={discount}
                      onChange={(e) => setDiscount(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Discount Capped :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      className="modalInput"
                      placeholder="Discount Capped..."
                      value={disCapp}
                      onChange={(e) => setDisCapp(e.target.value)}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="mb-3">
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Free Gift :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <MultiSelect
                      className="mt-2 pt-1 multiDrop"
                      options={merchList}
                      value={selectFreeGift}
                      onChange={handleFreeGift}
                      labelledBy="Select Merchandise..."
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        Free Product :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <MultiSelect
                      className="mt-2 pt-1 multiDrop"
                      options={prodList}
                      value={selectFreeGift}
                      onChange={handleFreeProduct}
                      labelledBy="Select Products..."
                    />
                  </Col>
                </Row>
              </div>
            )}
            <Button
              className="submitBtn mb-3"
              onClick={() => formik.handleSubmit()}
            >
              {`${type.Type === "Edit" ? "Update" : "Create"} Coupon`}
            </Button>
          </Container>
        </Form>
      </ModalBody>
    </Modal>
  );
}

CouponForm.defaultProps = {
  modal: false,
};

CouponForm.propTypes = {
  modal: PropTypes.bool,
  type: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default CouponForm;
