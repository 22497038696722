/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  ModalBody,
  Modal,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../sales.css";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import CustomButton from "components/CustomButton/CustomButton";
import ViewERP from "./ViewERP";

const columns = [
  {
    name: "Order&Invoice No",
    selector: (row) => (
      <div className="m-2">
        <p>{`#${row.ID}`}</p>
        <p>{row.Invoice}</p>
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "DateTime",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Source",
    selector: (row) => row.OrderSource,
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        <CurrencyFormat
          decimal={2}
          price={row.ShopOrderCostnShipping.toFixed(2)}
          currency="MYR"
        />
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Status",
    selector: (row) => row.OrderStatus.at(-1).Status,
    sortable: true,
    wrap: true,
  },
  {
    name: "ERP Status",
    selector: (row) => row.LastERPInvoiceStatus,
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [invoiceModal, setInvoiceModal] = useState({
        orderID: "",
        open: false,
      });
      if (!row) return null;

      return (
        <div>
          <CustomButton
            onClick={() =>
              setInvoiceModal({
                orderID: row.ID,
                open: !invoiceModal.open,
              })
            }
            icon="fa fa-database"
            title="ERP"
          />

          <Modal
            size="xl"
            isOpen={invoiceModal.open}
            toggle={() =>
              setInvoiceModal({
                ...invoiceModal,
                open: !invoiceModal.open,
              })
            }
          >
            <div>
              <Button
                className="btn btn-primary float-right mr-5"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setInvoiceModal({
                    ...invoiceModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
            </div>

            <ModalBody>
              <ViewERP orderID={invoiceModal.orderID} />
            </ModalBody>
          </Modal>
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function ERP() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);

  const { isLoading, data, isSuccess, refetch } = useQuery(
    `/shop/${shopID}/Order/QC`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/CustomerService/AllOrder`, {
        params: {
          limitPerPage: "1000000",
        },
      }).then((res) => {
        setFilterDataResult(res.data);
        refetch();
        return res;
      }),
    { refetchOnWindowFocus: true }
  );

  const resetFilter = () => {
    setDateRange([null, null]);
    setFilterDataResult(data.data);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.OrderSource &&
              item.OrderSource.toLowerCase().includes(
                searchQuery.toLowerCase()
              )) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.LastERPInvoiceStatus &&
              item.LastERPInvoiceStatus.toLowerCase().includes(
                searchQuery.toLowerCase()
              )) ||
            (item.OrderStatus.at(-1).Status &&
              item.OrderStatus.at(-1)
                .Status.toLowerCase()
                .includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };
  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const convertToCSV = (data2) => {
    const header = [
      "Order&Invoice No",
      "DateTime",
      "Order Source",
      "Amount",
      "Order Status",
      "ERP Status",
    ];
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (fieldName === "Order&Invoice No") {
              return `#${row.ID} ${row.Invoice}`;
            }

            if (fieldName === "DateTime") {
              return JSON.stringify(
                moment(row.CreatedDate).format("DD/MM/YYYY hh:mm A")
              );
            }
            if (fieldName === "Order Source") {
              return JSON.stringify(row.OrderSource);
            }

            if (fieldName === "Order Status") {
              return JSON.stringify(row.OrderStatus.at(-1).Status);
            }
            if (fieldName === "ERP Status") {
              return JSON.stringify(row.LastERPInvoiceStatus);
            }
            if (fieldName === "Amount") {
              return `"RM ${row.ShopOrderCostnShipping?.toFixed(2).replace(
                /(\d)(?=(\d{3})+\.)/g,
                "$1,"
              )}"`; // add double quotes and format as string
            }
            return JSON.stringify(row[fieldName]);
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">All Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(
                  selectedData.length > 0 ? selectedData : filterDataResult
                );
              }}
            >
              Export CSV
            </Button>
          </div>
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                selectableRows
                onSelectedRowsChange={handleSelectRows}
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ERP;
