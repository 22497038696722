/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customerservice.css";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";

import CustomButton from "components/CustomButton/CustomButton";
import UpdateStatusReceived from "./UpdateStatusReceived";
import AddTradeImage from "./AddTradeImage";
import TradeInImage from "./TradeInImage";

const columns = [
  {
    name: "TradeIn ID",
    selector: (row) => <p>{`#${row.TradeInID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "TradeIn Date",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
  },
  {
    name: "Name",
    selector: (row) => row.FullName,
    sortable: true,
    wrap: true,
  },
  {
    name: "Contact No",
    selector: (row) => row.Contact,
    sortable: true,
    wrap: true,
  },
  {
    name: "Describe Item(s)",
    selector: (row) => (
      <div>
        {row.TradeInProductList.map((item) => (
          <p>{item.Type}</p>
        ))}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Quantity",
    selector: (row) => row.Quantity,
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        {row.TotalApprovedAmount === null ? (
          <CurrencyFormat decimal={2} price={0} currency="MYR" />
        ) : (
          <CurrencyFormat
            decimal={2}
            price={row.TotalApprovedAmount}
            currency="MYR"
          />
        )}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [showModal, setShowModal] = useState({
        tID: "",
        open: false,
      });
      const [statusModal, setStatusModal] = useState({
        tID: "",
        open: false,
      });
      const [imageModal, setImageModal] = useState({
        tID: "",
        open: false,
      });
      if (!row) return null;

      return (
        <div>
          {row.Status === "TradeIn Pending" &&
          row.TradeInProductList[0].InternalStatus ===
            "Trade In Pending Receive" ? (
            <>
              {row.TradeInProductList[0].InternalStatus === null ? (
                <p className="mt-2">{row.Status}</p>
              ) : (
                <p className="mt-2">
                  {row.TradeInProductList[0].InternalStatus}
                </p>
              )}

              <CustomButton
                onClick={() =>
                  setStatusModal({
                    tID: row.TradeInID,
                    open: !statusModal.open,
                  })
                }
                icon=""
                title=""
                text="Update Status"
                color="btn-success"
                size="btn-sm"
              />
              <CustomButton
                to={`/shopowner/customerservice/viewtradeindetails/${row.TradeInID}`}
                icon="fa fa-search-plus"
                title="Order Details"
              />
            </>
          ) : (
            <>
              {row.TradeInProductList[0].InternalStatus === null ? (
                <p className="mt-2">{row.Status}</p>
              ) : (
                <p className="mt-2">
                  {row.TradeInProductList[0].InternalStatus}
                </p>
              )}
              <CustomButton
                to={`/shopowner/customerservice/viewtradeindetails/${row.TradeInID}`}
                icon="fa fa-search-plus"
                title="Order Details"
              />
              {row.Status === "TradeIn Pending" &&
              row.TradeInProductList[0].InternalStatus ===
                "Trade In Parcel Received" ? (
                <p>
                  <CustomButton
                    onClick={() =>
                      setShowModal({
                        tID: row.TradeInID,
                        open: !showModal.open,
                      })
                    }
                    icon=""
                    title=""
                    text="Add Photos"
                    color="btn-primary"
                    size="btn-sm"
                  />
                </p>
              ) : (
                <CustomButton
                  onClick={() =>
                    setImageModal({
                      tID: row.TradeInID,
                      open: !showModal.open,
                    })
                  }
                  icon="fa fa-picture-o"
                  title="Photos"
                  color="btn-link"
                />
              )}
            </>
          )}

          <Modal
            size="lg"
            isOpen={statusModal.open}
            toggle={() =>
              setStatusModal({
                ...statusModal,
                open: !statusModal.open,
              })
            }
          >
            <ModalHeader>
              <Button
                className="btn btn-primary float-right"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setStatusModal({
                    ...statusModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
              {`TradeIn Parcel ${row.TrackingNo} form ${row.Logistic} has Received ?`}
            </ModalHeader>
            <ModalBody>
              <UpdateStatusReceived
                tID={statusModal.tID}
                setStatusModal={setStatusModal}
                statusModal={statusModal}
              />
            </ModalBody>
          </Modal>
          <Modal
            size="lg"
            isOpen={showModal.open}
            toggle={() =>
              setShowModal({
                ...showModal,
                open: !showModal.open,
              })
            }
          >
            <ModalHeader>
              <Button
                className="btn btn-primary float-right"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
              Add Photos
            </ModalHeader>
            <ModalBody>
              <AddTradeImage
                tID={showModal.tID}
                setShowModal={setShowModal}
                showModal={showModal}
              />
            </ModalBody>
          </Modal>
          {/* End of Modal for Add CS image */}
          <Modal
            size="md"
            isOpen={imageModal.open}
            toggle={() =>
              setImageModal({
                ...imageModal,
                open: !imageModal.open,
              })
            }
          >
            <div>
              <Button
                className="btn btn-primary float-right mr-5"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setImageModal({
                    ...imageModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
            </div>

            <ModalBody>
              <TradeInImage tID={imageModal.tID} />
            </ModalBody>
          </Modal>
        </div>
      );
    },
    wrap: true,
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function TradeIn() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/GetAllTradeInRequest`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetAllTradeInRequest`).then((res) => {
        const reversedData = res.data.ShopTradeInRequestGetAllDtoList.reverse();
        setFilterDataResult(reversedData);
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setDateRange([null, null]);

    setFilterDataResult(data.data.ShopTradeInRequestGetAllDtoList);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.ShopTradeInRequestGetAllDtoList.filter(
        (item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
      )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.FullName &&
              item.FullName.toLowerCase().includes(
                searchQuery.toLowerCase()
              )) ||
            (item.TradeInID &&
              item.TradeInID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">All Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default TradeIn;
