import React, { useContext } from "react";

// reactstrap components
import { Spinner } from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import "./packaging.css";
import DashBoardDisplay from "./PackReportDisplay";

const columnsForTable = [
  "Invoice",
  "CreatedDate",
  "Name",
  "OrderSource",
  "Contact",
  "Logistic",
  "TrackingNo",
  "Status",
];

function PackReturnDB() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const columns = [
    {
      name: "Invoice No",
      selector: (row) => row.Invoice,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.CreatedDate).toLocaleDateString("en-GB"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Source",
      selector: (row) => row.OrderSource,
      sortable: true,
      wrap: true,
    },
    {
      name: "Contact No",
      selector: (row) => row.Contact,
      sortable: true,
      wrap: true,
    },
    {
      name: "Logistic",
      selector: (row) => row.Logistic,
      sortable: true,
      wrap: true,
    },
    {
      name: "Tracking Number",
      selector: (row) => row.TrackingNo,
      sortable: true,
      wrap: true,
    },
  ];

  const { isLoading, data, isSuccess, isError, refetch } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`, {
        params: {
          limitPerPage: 9999,
          internalStatus: "Item Transferred to Packaging",
        },
      }),
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <h3>Something goes wrong, try refresh the page</h3>
      </div>
    );
  }

  return (
    <DashBoardDisplay
      refetch={refetch}
      columns={columns}
      data={data.data}
      isSuccess={isSuccess}
      isLoading={isLoading}
      columnsForTable={columnsForTable}
      filterList={[
        {
          field: "OrderSource",
          label: "Order Source",
        },
        {
          field: "Logistic",
          label: "Logistic",
        },
        { field: "Status", label: "Status" },
      ]}
      forCategory="return"
      trackingNo="TrackingNo"
      rowState="Status"
      invoice="Invoice"
      dateShow="CreatedDate"
      customerName="Name"
    />
  );
}

export default PackReturnDB;
