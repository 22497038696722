/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import moment from "moment";
import React, { useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

createTheme(
  "solarized",
  {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#343a40",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "white",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick, onChange }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function CouponTable(props) {
  const {
    setModal,
    setType,
    filterRecord,
    setFilterRecord,
    dataState,
    // setDataState,
    handleDelete,
    refetch,
    dateRange,
    setDateRange,
    totalRow,
    handlePageChange,
    handlePerRowsChange,
  } = props;
  const [dropOpen, setDropOpen] = useState(false);
  const [dropFilter, setDropFilter] = useState("");
  const [searchData, setSearchData] = useState("");
  const Today = moment(new Date()).format("YYYY-MM-DD");

  const column = [
    {
      selector: (row) => {
        // const Date = moment(row.end, "YYYY-MM-DD").toDate();
        const [day, month, year] = row.end.split("/");
        const dateObj = new Date(+year, +month - 1, +day);

        return (
          <>
            {row.noEnd ? (
              <>
                <Button
                  className="addBtn couponTableBtn"
                  onClick={() => {
                    setModal(true);
                    setType({
                      Type: "Edit",
                      ID: row.id,
                      Coupon: row.loyalty === true ? "Loyalty" : "Coupon",
                    });
                  }}
                >
                  Edit
                </Button>
                <Button
                  className="clearBtn couponTableBtn"
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </Button>
              </>
            ) : (
              <>
                {moment(Today).isAfter(moment(dateObj).format("YYYY-MM-DD")) ? (
                  <Button
                    className="clearBtn couponTableBtn"
                    onClick={() => handleDelete(row.id)}
                  >
                    Delete
                  </Button>
                ) : (
                  <>
                    <Button
                      className="addBtn couponTableBtn"
                      onClick={() => {
                        setModal(true);
                        setType({
                          Type: "Edit",
                          ID: row.id,
                          Coupon: row.loyalty === true ? "Loyalty" : "Coupon",
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="clearBtn couponTableBtn"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </>
            )}
            <br />
            <Button
              className="viewBtn couponTableBtn"
              tag={Link}
              to={`/shopOwner/marketing/CouponDetails/${row.id}`}
            >
              More Details
            </Button>
          </>
        );
      },
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
      sortField: "Code",
      width: "200px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      sortField: "Type",
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className="font-weight-bold"
          style={{ color: row.status === "Active" ? "green" : "red" }}
        >
          {row.status}
        </p>
      ),
      sortable: true,
      sortField: "Type",
      width: "190px",
    },
    {
      name: "Description",
      selector: (row) => (
        <Input
          className="pl-3 tableDesc"
          type="textarea"
          value={row.desc}
          disabled
        />
      ),
      sortable: true,
      sortField: "Invoice",
      width: "300px",
    },
    {
      name: "Usage / Limit",
      selector: (row) => row.limit,
      sortable: true,
      sortField: "Usage",
      width: "200px",
    },
    {
      name: "Start Date",
      selector: (row) => row.start,
      sortable: true,
      sortField: "Invoice",
      width: "200px",
    },
    {
      name: "End Date",
      selector: (row) => row.end,
      sortable: true,
      sortField: "Invoice",
      width: "200px",
    },
  ];

  const dropdownToggle = () => {
    setDropOpen(!dropOpen);
  };

  const filterData = () => {
    const newData = dataState
      .filter((row) =>
        dateRange[0] !== null
          ? new Date(moment(row.start, "DD/MM/YYYY").toDate()).setHours(
              0,
              0,
              0,
              0
            ) >= new Date(dateRange[0]).setHours(0, 0, 0, 0)
          : row
      )
      .filter((row) =>
        dateRange[1] !== null
          ? new Date(moment(row.end, "DD/MM/YYYY").toDate()).setHours(
              0,
              0,
              0,
              0
            ) <= new Date(dateRange[1]).setHours(0, 0, 0, 0)
          : row
      )
      .filter((row) => {
        if (dropFilter !== "") {
          return row.status.toLowerCase() === dropFilter.toLowerCase();
        }
        return true;
      })
      .filter(
        (row) =>
          row.code.toLowerCase().includes(searchData.toLowerCase()) ||
          row.type.toLowerCase().includes(searchData.toLowerCase()) ||
          row.desc.toLowerCase().includes(searchData.toLowerCase())
      );
    setFilterRecord(newData);
  };

  const resetFilter = () => {
    setDropFilter("");
    refetch();
  };

  return (
    <>
      <Row xs={1} sm={2} className="my-2">
        <Col>
          <div>Filter Date: </div>
          <div className="d-flex">
            <ReactDatePicker
              calendarClassName="h-100"
              wrapperClassName="w-100 "
              isClearable
              onChange={(update) => {
                setDateRange(update);
              }}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              selectsRange
              dateFormat="dd/MM/yyyy"
              customInput={<ExampleCustomInput />}
            />
          </div>
        </Col>
      </Row>
      <div className="d-flex align-items-center">
        <span className="mr-2" style={{ fontSize: "16px" }}>
          Filter:
        </span>

        <Dropdown isOpen={dropOpen} toggle={() => dropdownToggle(!dropOpen)}>
          <DropdownToggle caret color="filter" outline>
            {dropFilter === "" ? "Status" : dropFilter}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              onClick={() => {
                setDropFilter("");
              }}
            >
              -
            </DropdownItem>

            <DropdownItem onClick={() => setDropFilter("Active")}>
              Active
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDropFilter("Inactive");
              }}
            >
              Inactive
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Button className="ml-1" onClick={() => filterData()}>
          Filter
        </Button>
        <Button
          className="ml-1"
          color="danger"
          onClick={() => {
            resetFilter();
          }}
        >
          Reset All Filter
        </Button>
        <Input
          type="text"
          placeholder="Search..."
          className="searchBarPromo ml-2"
          onChange={(e) => {
            setSearchData(e.target.value);
          }}
        />
      </div>
      <DataTable
        columns={column}
        data={filterRecord}
        customStyles={customStyles}
        theme="solarized"
        responsive
        pagination
        paginationServer
        paginationTotalRows={totalRow}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
}

// CouponTable.defaultProps = {
//   modal: false,
// };

CouponTable.propTypes = {
  setType: PropTypes.string.isRequired,
  setModal: PropTypes.func.isRequired,
  filterRecord: PropTypes.array.isRequired,
  dataState: PropTypes.array.isRequired,
  handleDelete: PropTypes.func.isRequired,
  // setDataState: PropTypes.func.isRequired,
  setFilterRecord: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  dateRange: PropTypes.array.isRequired,
  setDateRange: PropTypes.func.isRequired,
  totalRow: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handlePerRowsChange: PropTypes.func.isRequired,
};

export default CouponTable;
