/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useMutation } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import UserContext from "services/UserContext";
import { useHistory } from "react-router-dom";

function SetPassword() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;
  const { logout, user } = useContext(UserContext);
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState();
  const history = useHistory();
  const validationSchema = Yup.object().shape({
    NewPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters"),
    MatchPassword: Yup.string().oneOf(
      [Yup.ref("NewPassword"), null],
      "Confirm Password does not match"
    ),
  });

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
      // navigate("/");
    }, 3000);
  };

  const { mutate, isLoading } = useMutation(
    (data) =>
      Axios.put(`/shop/${shopID}/Account/SetNewPassword/${user.ID}`, {
        NewPassword: data.NewPassword,
      }),
    {
      onError: async (err) => {
        setMessage({
          data: `${err.response.data.MESSAGE}`,
          type: "alert-danger",
        });
        onShowAlert();
      },

      onSuccess: async (res) => {
        setMessage({
          data: `${res.data.MESSAGE}`,
          type: "alert-success",
        });
        onShowAlert();
        setTimeout(() => {
          logout();
          history.push("/auth/Login");
        }, 3000);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      NewPassword: "",
      MatchPassword: "",
    },
    validationSchema,

    onSubmit: (data) => {
      mutate(data);
    },
  });

  return (
    <div className="mx-auto d-block content mt-5">
      <Container>
        {message !== undefined && message && (
          <Modal isOpen={visible} toggle={() => setVisible(false)}>
            <ModalBody className={`text-center text-white ${message.type}`}>
              {message.data}
            </ModalBody>
            <ModalFooter>
              <Button
                color="link"
                type="button"
                onClick={() => setVisible(false)}
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Card>
          <Row>
            <Col md="6">
              <img
                className="w-100 h-100 img mb-5"
                src={require("assets/img/Page/ForgotPassword/resetpassword.png")}
                alt="emptyCart"
              />
            </Col>
            <Col md="6">
              <h2 className="text-center p-3 pt-5 mt-5 font-weight-bold">
                Set New Password
              </h2>
              <div className="text-center p-3">
                <Form onSubmit={formik.handleSubmit}>
                  <div className="text-left pt-2 font-weight-bold">
                    <Label htmlFor="NewPassword"> NEW PASSWORD </Label>
                    <Input
                      className={
                        formik.errors.NewPassword ? "is-invalid" : "is-valid"
                      }
                      name="NewPassword"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.NewPassword}
                    />
                    <div className="text-danger">
                      {formik.errors.NewPassword
                        ? formik.errors.NewPassword
                        : null}
                    </div>
                  </div>
                  <div className="text-left pt-2 font-weight-bold">
                    <Label htmlFor="MatchPassword">CONFIRM PASSWORD</Label>
                    <Input
                      className={
                        formik.errors.MatchPassword ? "is-invalid" : "is-valid"
                      }
                      name="MatchPassword"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.MatchPassword}
                    />
                    <div className="text-danger">
                      {formik.errors.MatchPassword
                        ? formik.errors.MatchPassword
                        : null}
                    </div>
                  </div>
                  <Button className="text-center w-100 mt-4 p-2 button-white-template">
                    {isLoading ? "Loading..." : "Set New Password"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default SetPassword;
