import React, { useContext } from "react";
// reactstrap components
import { Input, Row, Col, Button } from "reactstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

function Step1(props) {
  const {
    productSelected,
    setProductSelected,
    setBatch,
    setBatchID,
    setBatchName,
  } = props;
  const [scannedCode, setScannedCode] = React.useState(""); // for input
  const [scannedCode2, setScannedCode2] = React.useState(""); // for scan code
  const [typingTimeout, setTypingTimeout] = React.useState(0);

  // for input only
  const addScanItem = () => {
    if (scannedCode.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (!productSelected.includes(scannedCode)) {
      setProductSelected([...productSelected, scannedCode]);
    } else {
      toast.warning(`This item already added: ${scannedCode}`);
    }
    setScannedCode("");
  };

  // for scan only
  const addScanItem2 = () => {
    if (scannedCode2.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (!productSelected.includes(scannedCode2)) {
      setProductSelected([...productSelected, scannedCode2]);
    } else {
      toast.warning(`This item already added: ${scannedCode2}`);
    }
    setScannedCode("");
    setScannedCode2("");
  };

  // Used to handle the barcode scanner
  React.useEffect(() => {
    const handleBarcodeScan = async (event) => {
      clearTimeout(typingTimeout);
      const scannedValue = event.key;
      setScannedCode2((prevCode) => prevCode + scannedValue);
      const timeout = setTimeout(() => {}, 200);

      setTypingTimeout(timeout);

      if (scannedValue === "Enter") {
        await addScanItem2();
      }
    };
    document.addEventListener("keypress", handleBarcodeScan);

    return () => {
      document.removeEventListener("keypress", handleBarcodeScan);
    };
  }, [scannedCode2, typingTimeout]);

  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;

  const handleSubmit = () => {
    Axios.post(`/shop/${shopID}/Live/ScanSNProduct`, productSelected)
      .then((res) => {
        setBatchID(res.data.BatchID);
        setBatchName(res.data.Name);
        setBatch("2");
      })
      .catch((err) => {
        toast.error(err.response.data.MESSAGE);
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <h5 className="info-text">Let&apos;s start with the Adding Products</h5>
        <Button
          disabled={productSelected.length === 0}
          onClick={() => handleSubmit()}
        >
          Create
        </Button>
      </div>
      <Row className="justify-content-center">
        <Col sm="6">
          <Input
            type="text"
            placeholder="Product (SN)  Type/Scan Barcode"
            value={scannedCode}
            onChange={(e) => setScannedCode(e.target.value)}
            className="h-100"
          />
          <Input
            type="hidden"
            id="barcodeScanner"
            value={scannedCode2}
            onChange={(e) => setScannedCode2(e.target.value)}
          />
        </Col>
        <Col sm="6">
          <Button
            disabled={scannedCode.trim() === ""}
            className="d-block"
            onClick={() => addScanItem()}
          >
            Add
          </Button>
        </Col>
      </Row>
      <div className="mt-3">
        {productSelected.length > 0 ? (
          <Row xs="1" sm="2" md="4" xl="5" noGutters>
            {productSelected.map((item, idx) => (
              <Col key={item}>
                <div className="border border-secondary rounded m-2 p-2 d-flex justify-content-between">
                  <div style={{ overflowWrap: "anywhere" }}>{item}</div>
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => {
                      productSelected.splice(idx, 1);
                      setProductSelected([...productSelected]);
                    }}
                  >
                    <span aria-hidden="true" className="text-white">
                      &times;
                    </span>
                  </button>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <h3>No Product Added yet</h3>
        )}
      </div>
    </>
  );
}

Step1.propTypes = {
  productSelected: PropTypes.shape.isRequired,
  setProductSelected: PropTypes.func.isRequired,
  setBatch: PropTypes.func.isRequired,
  setBatchID: PropTypes.func.isRequired,
  setBatchName: PropTypes.func.isRequired,
};

export default Step1;
