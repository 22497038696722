import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  Row,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { useMutation, useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";

function HODResetPass() {
  const Axios = useContext(ApiContext);
  const { department } = useContext(ShopContext);
  const deptID = department.DepartmentID;
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState();
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [userID, setUserID] = useState(false);
  const { data: deptStaff } = useQuery(
    `/admin/Department/${deptID}`,
    async () => Axios.get(`/admin/Department/${deptID}`)
  );
  const validationSchema = Yup.object().shape({
    NewPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(40, "Password must not exceed 40 characters"),
  });

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
      // navigate("/");
    }, 3000);
  };
  const requestBody = JSON.stringify(password);

  const { mutate, isLoading, isSuccess } = useMutation(
    () =>
      Axios.put(`/admin/Account/ResetOneTimePassword/${userID}`, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    {
      onError: async (err) => {
        setMessage({
          data: `${err.response.data.MESSAGE}`,
          type: "alert-danger",
        });
        onShowAlert();
      },

      onSuccess: async (res) => {
        setMessage({
          data: `${res.data.MESSAGE}`,
          type: "alert-success",
        });
        onShowAlert();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      NewPassword: "",
    },
    validationSchema,

    onSubmit: (data) => {
      mutate(data);
    },
  });

  const generatePassword = () => {
    const generate = () => {
      const length = 12;
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&";
      let retVal = "";
      for (let i = 0, n = charset.length; i < length; i += 1) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    };
    setPassword(generate());
  };

  const copyToClipboard = () => {
    const copyText = password;
    const textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
    toast.success("Password Copied");
  };

  return (
    <div className="content w-100">
      <Container>
        {message !== undefined && message && (
          <Modal isOpen={visible} toggle={() => setVisible(false)}>
            <ModalBody className={`text-center text-white ${message.type}`}>
              {message.data}
            </ModalBody>
            <ModalFooter>
              <Button
                color="link"
                type="button"
                onClick={() => setVisible(false)}
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Card
          className="w-75
        "
        >
          <Row>
            <Col md="12">
              <h2 className="text-left p-3 font-weight-bold">
                Reset Staff Password
              </h2>
              <p className="text-left p-3 font-weight-bold">
                Enter your staff&apos;s Email and New password.
              </p>
              <div className="text-center p-3">
                <Form onSubmit={formik.handleSubmit}>
                  <div className="text-left pt-2 font-weight-bold">
                    <Label htmlFor="NewPassword"> STAFF EMAIL </Label>
                    <Input
                      type="select"
                      name="Email"
                      onChange={(e) => {
                        formik.setFieldValue("Email", e.target.value);
                        setUserID(
                          deptStaff?.data.DepartmentMemberList.find(
                            (dept) => dept.Email === e.target.value
                          )?.ID
                        );
                      }}
                      value={formik.values.Email}
                    >
                      <option value="">Select Staff Email</option>
                      {deptStaff?.data.DepartmentMemberList.filter(
                        (dept) => dept.Position !== "DepartmentHead"
                      ).map((dept) => (
                        <option key={dept.Email} value={dept.Email}>
                          {dept.Email}
                        </option>
                      ))}
                    </Input>

                    <div className="text-danger">
                      {formik.errors.Email ? formik.errors.Email : null}
                    </div>
                  </div>
                  <div className="text-left pt-2 font-weight-bold">
                    <Label htmlFor="NewPassword"> NEW PASSWORD </Label>
                    <div className="text-left pt-2 font-weight-bold d-flex">
                      <Input
                        placeholder="Enter Password..."
                        type={show ? "text" : "password"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        readOnly
                        className="mr-2 w-50"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className={show ? "fa fa-eye" : "fa fa-eye-slash"}
                            style={{ cursor: "pointer" }}
                            onClick={() => setShow(!show)}
                            aria-hidden="true"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <div className="mt-1 ml-2">
                        <Button
                          size="sm"
                          color="primary"
                          className="mt-0"
                          onClick={generatePassword}
                        >
                          Gen Password
                        </Button>
                        <Button
                          size="sm"
                          color="success"
                          className="ml-2 text-capitalize mt-0"
                          onClick={copyToClipboard}
                        >
                          <i className="fa fa-clipboard" aria-hidden="true" />
                          Copy
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Button
                    color={isSuccess ? "secondary" : "success"}
                    className={`text-center w-50 mt-4 p-2 button-white-template  ${
                      isSuccess ? "d-block" : "d-none"
                    }`}
                    disabled={isSuccess}
                  >
                    Success Reset Password
                  </Button>
                  <Button
                    color="success"
                    className={`text-center w-50 mt-4 p-2 button-white-template ${
                      isSuccess ? "d-none" : "d-block"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Set New Password"}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  );
}

export default HODResetPass;
