/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Button, Col, Spinner } from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";

function OrderDB() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const columns = [
    {
      name: "Order",
      selector: (row) => row.OrderID,
      sortable: true,
      wrap: true,
    },
    {
      name: "Invoice Number",
      selector: (row) => row.Invoice,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.CreatedDate).toLocaleDateString("en-GB"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Total",
      selector: (row) => row.TotalOrderCost,
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Source",
      selector: (row) => row.OrderSource,
      sortable: true,
      wrap: true,
    },
    {
      name: "Detail",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <div className="d-flex w-100">
          <div className="mt-3 pt-1 mr-3 ml-4">{row.OrderStatus}</div>
          <Link to={`/shopOwner/Live-invoice-detail/order/${row.ID}`}>
            <Button className="btn-icon ml-2" color="primary">
              <img src={require("assets/img/icon/Picture2.png")} alt="" />
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const { isLoading, data, isError } = useQuery(
    `/shop/${shopID}/Live/Order`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Live/Order`, {
        params: {
          limitPerPage: 9999,
          order: "DESC",
          orderBy: "CreatedDate",
        },
      })
  );

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <h3>Something goes wrong, try refresh the page</h3>
      </div>
    );
  }

  return (
    <div className="content">
      <div className="border p-3">
        <BasicTable columns={columns} data={data.data.Result} pagination />
      </div>
    </div>
  );
}

export default OrderDB;
