import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";
import "./stktable.css";
import FilterCard from "./FilterCard";
import FilterCollapse from "./FilterCollapse";

function storePorducts() {
  const match = useParams();
  const currentID = match.id;
  const { selctShopID } = useContext(ShopContext);
  const Axios = useContext(ApiContext);
  const [sortBy, setSortBy] = React.useState("VariationSKU");

  const { isLoading, data, refetch } = useQuery(
    `products/${currentID}`,
    async () =>
      Axios.get(`/shop/${selctShopID.ID}/Order/StockPicker/Category`, {
        params: { CategoryID: currentID },
      })
  );

  const pickItemApi = (pickType, pickedConfirmMsg) => {
    Axios.put(`/shop/${selctShopID.ID}/Order/StockPicker`, {
      variationSKU: pickedConfirmMsg.idx.variationSKU,
      OrderID: pickedConfirmMsg.idx.OrderID,
      PickedType: pickType,
      Status: pickedConfirmMsg.idx.Status,
    })
      .then((res) => {
        toast.success(
          `${`${res.data.MESSAGE}: ${pickedConfirmMsg.idx.variationSKU}`}`
        );
        refetch();
      })
      .catch((err) => {
        toast.error(err.response.data.MESSAGE);
      });
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Link to="/shopOwner/storePicker">
            <Button outline>Back</Button>
          </Link>
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">{data && data.data.Result.Name}</CardTitle>
              <div>
                <span style={{ fontSize: "22px" }}>Filter By: </span>
                <Button onClick={() => setSortBy("VariationSKU")}>
                  Variation
                </Button>
                <Button onClick={() => setSortBy("Weight")}>Weight</Button>
              </div>
            </CardHeader>
            <CardBody>
              {sortBy === "Weight" ? (
                <FilterCollapse {...data} pickItemApi={pickItemApi} />
              ) : (
                <FilterCard
                  {...data}
                  sortBy={sortBy}
                  pickItemApi={pickItemApi}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default storePorducts;
