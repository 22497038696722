/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

function CollaborationView() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const { data: CollabData, refetch } = useQuery("SingleCollab", async () =>
    Axios.get(`/shop/${shopID}/Collaboration/GetSingleCollaboration/${id}`)
  );

  const [editImg, setEditImg] = useState(false);
  const [viewImg, setViewImg] = useState([]);
  const [checkSame, setCheckSame] = useState(true);
  const [imgModal, setImgModal] = useState(false);
  const [addImg, setAddImg] = useState([]);
  const [delId, setDelId] = useState("");
  const [editMsg, setEditMsg] = useState(false);

  const dragImg = useRef(0);
  const dragOverImg = useRef(0);

  const imgListRef = React.createRef();
  const handleImgListClick = () => {
    imgListRef.current.click();
  };

  const removeImg = (imgId) => {
    setDelId([imgId]);
    setEditMsg(true);
  };

  const toggleEditImg = () => {
    setEditImg(!editImg);
    if (CollabData && viewImg.length !== 0) {
      setViewImg(CollabData.data.CollaborationFileUrlList);
    }
  };

  const toggleAddImg = () => {
    setImgModal(!imgModal);
    setAddImg([]);
  };

  function handleSort() {
    if (editImg) {
      const imgClone = [...viewImg];
      const temp = imgClone[dragImg.current];
      imgClone[dragImg.current] = imgClone[dragOverImg.current];
      imgClone[dragOverImg.current] = temp;

      setViewImg(imgClone);
    }
  }

  const imgDelete = () => {
    // const requestBody = JSON.stringify(delId);

    Axios.delete(`/shop/${shopID}/Collaboration/${CollabData.data.ID}/Images`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: delId,
    })
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Image </p>
          </div>
        );
        setEditMsg(false);
        refetch();
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  const handleSave = () => {
    const rearrangeImg = [];
    viewImg.forEach((images) => {
      rearrangeImg.push(images.ID);
    });

    Axios.put(
      `/shop/${shopID}/Collaboration/${CollabData.data.ID}/SortImages`,
      rearrangeImg
    )
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Saved </p>
          </div>
        );
        refetch();
        setEditImg(false);
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  const handleProductImg = (e) => {
    const productFiles = e.target.files;
    const temp = [];
    for (let i = 0; i < productFiles.length; i++) {
      if (i >= 5) {
        break;
      }
      const file = productFiles[i];
      temp.push({ File: file, URL: URL.createObjectURL(file) });
    }
    if (addImg.length === 0) {
      setAddImg(temp);
    } else if (addImg.length <= 4) {
      setAddImg([...addImg, ...temp]);
    }

    e.target.value = null;
  };

  const handleRemoveImg = (idx) => {
    addImg.splice(idx, 1);
    setAddImg([...addImg]);
  };

  const handleAdd = () => {
    if (addImg.length !== 0) {
      const data2 = new FormData();

      const temp = [];
      addImg.forEach((item) => {
        temp.push(item.File);
      });

      temp.forEach((files) => {
        data2.append("CollaborationImageList", files);
      });

      Axios.post(
        `/shop/${shopID}/Collaboration/${CollabData.data.ID}/Images`,
        data2
      )
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">Successful Added Images </p>
            </div>
          );
          refetch();
        })
        .catch(() => {
          toast.error("Something goes wrong, please try later");
        });
    }

    setTimeout(() => {
      toggleAddImg();
    }, 1000);
  };

  useEffect(() => {
    if (CollabData) {
      setViewImg(CollabData.data.CollaborationFileUrlList);
    }
  }, [CollabData]);

  useEffect(() => {
    if (CollabData && viewImg.length !== 0) {
      const imageEqual = CollabData.data.CollaborationFileUrlList.every(
        (e, i) => e === viewImg[i]
      );
      setCheckSame(imageEqual);
    }
  }, [CollabData, viewImg]);

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2"
        tag={Link}
        to="/shopOwner/marketing/CollectCollab"
        size="sm"
      >
        Back
      </Button>
      <Card className="card-stats card-dark">
        <CardBody className="mt-3">
          <p className="bg-info p-2 pl-4">Collaboration Details</p>

          <Row className="pt-3">
            <Col md="6">
              <Card
                className="card-stats card-dark pb-2"
                style={{ border: "1px solid #51BCDA" }}
              >
                <CardBody className="text-white">
                  <p>
                    Status :{" "}
                    <b
                      style={{
                        color: CollabData?.data.Status ? "green" : "red",
                      }}
                    >
                      {CollabData?.data.Status ? "Active" : "Inactive"}
                    </b>
                  </p>
                  <p>
                    Collaboration Name : {CollabData?.data.CollaborationName}
                  </p>
                  {CollabData?.data.DescriptionList.length !== 0 && (
                    <>
                      <p className="mt-4">Description</p>
                      <hr />
                      {CollabData?.data.DescriptionList.map((item, index) => (
                        <p>
                          {index + 1} : {item}
                        </p>
                      ))}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              {CollabData?.data.CollaborationProductList.length !== 0 && (
                <Card
                  className="card-stats card-dark pb-2"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>Collaboration Product </p>
                    <hr />
                    {CollabData?.data.CollaborationProductList.map(
                      (item, index) => (
                        <Row>
                          <Col md="1">
                            <p>{index + 1} :</p>
                          </Col>
                          <Col md="6" className="float-left">
                            <p>Name : {item.ProductName}</p>
                          </Col>
                          <Col>
                            <p>SKU : {item.ProductSKU}</p>
                          </Col>
                        </Row>
                      )
                    )}
                  </CardBody>
                </Card>
              )}
              {CollabData?.data.StarredProductList.length !== 0 && (
                <Card
                  className="card-stats card-dark pb-2"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>Starred Collaboration Product </p>
                    <hr />
                    {CollabData?.data.StarredProductList.map((item, index) => (
                      <Row>
                        <Col md="1">
                          <p>{index + 1} :</p>
                        </Col>
                        <Col md="6" className="float-left">
                          <p>Name : {item.ProductName}</p>
                        </Col>
                        <Col>
                          <p>SKU : {item.ProductSKU}</p>
                        </Col>
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Card
            className="card-stats card-dark"
            style={{ border: "1px solid #51BCDA" }}
          >
            <CardBody className="text-white">
              <h4>
                Template : <b>{CollabData?.data.TemplateNumber}</b>
              </h4>
              {CollabData?.data.DescriptionList.length !== 0 && (
                <>
                  <Row className="mb-0">
                    <Col />
                    <Col>
                      <Button
                        className="float-right editBtn text-center"
                        style={{
                          backgroundColor: `${editImg ? "#eb6532" : ""}`,
                        }}
                        color={editImg ? "danger" : "info"}
                        onClick={toggleEditImg}
                      >
                        Edit
                        <i className="fa fa-edit ml-2" />
                      </Button>
                      {editImg ? (
                        <Button
                          className="float-right editBtn text-center mr-3"
                          style={{ width: "120px" }}
                          color="info"
                          onClick={() => setImgModal(true)}
                        >
                          Add Image
                        </Button>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>

                  <hr />
                  <div className="ml-1 mb-4">
                    <p>Collaboration Images </p>
                  </div>

                  <Row className="text-center mb-4">
                    {viewImg?.map((item, index) => (
                      <Col
                        md="auto"
                        draggable
                        onDragStart={() => (dragImg.current = index)}
                        onDragEnter={() => (dragOverImg.current = index)}
                        onDragEnd={handleSort}
                        onDragOver={(e) => e.preventDefault()}
                      >
                        <p className="ViewImgNo">{index + 1}</p>
                        <button
                          hidden={!editImg}
                          type="button"
                          className="removeBanner"
                          onClick={() => removeImg(item.ID)}
                        >
                          <i className="fa fa-times-circle removeIcon" />
                        </button>
                        <img src={item.Url} className="imgList mb-4 mt-1" />
                      </Col>
                    ))}
                  </Row>
                  {editImg && (
                    <div className="text-center">
                      <p style={{ color: "red" }}>
                        You could Drag and Drop to rearrange the images
                      </p>
                      <Button
                        style={{ width: "200px" }}
                        color="info"
                        disabled={checkSame}
                        onClick={() => handleSave()}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <Modal size="xl" isOpen={imgModal} toggle={() => toggleAddImg()}>
        <ModalBody>
          <Button
            className="float-right closeBtn"
            onClick={() => setImgModal(false)}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Add Image</h3>
            <Button
              onClick={() => handleImgListClick()}
              disabled={viewImg.length >= 4}
            >
              Upload Image
            </Button>
            <input
              type="file"
              className="uploadImg"
              accept="image/*"
              multiple
              ref={imgListRef}
              onChange={handleProductImg}
            />
            {addImg.length !== 0 && (
              <>
                <h4>Add Images</h4>
                <Row className="text-center mb-4">
                  {addImg.map((img, idx) => (
                    <Col md="4">
                      <div>
                        <p className="imgNo">{idx + 1}</p>
                        <button
                          type="button"
                          className="imgListRemove"
                          onClick={() => handleRemoveImg(idx)}
                        >
                          <i className="fa fa-times-circle removeIcon" />
                        </button>
                        <img src={img.URL} className="mt-1 imgList" />
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            )}
            {addImg.length !== 0 && (
              <div>
                <Button className="submitBtn" onClick={() => handleAdd()}>
                  Save
                </Button>
              </div>
            )}
          </Container>
        </ModalBody>
      </Modal>
      {/*  Delete Modal */}
      <Modal size="ml" isOpen={editMsg} toggle={() => setEditMsg(false)}>
        <ModalBody>
          <Container className="text-center">
            <h3 className="mt-3">Delete Image</h3>
            <p>Are You sure you wanted to delete this Image ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    imgDelete();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  className="clearBtn delBtn"
                  onClick={() => setEditMsg(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CollaborationView;
