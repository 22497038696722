/* eslint-disable default-case */
/* eslint-disable no-fallthrough */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import { MultiSelect } from "react-multi-select-component";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { toast } from "react-toastify";
import Switch from "react-ios-switch";

function PromotionForm(props) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;
  const { modal, setModal, type, refetch } = props;
  const [prodList, setProdList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [collecList, setCollecList] = useState([]);
  const [collabList, setCollabList] = useState([]);

  const [selectProd, setSelectProd] = useState([]);
  const [selectCat, setSelectCat] = useState([]);
  const [selectCollab, setSelectCollab] = useState([]);
  const [selectCollect, setSelectCollect] = useState([]);

  const [oriProduct, setOriProduct] = useState([]);
  const [oriCat, setOriCat] = useState([]);
  const [oriCollect, setOriCollect] = useState([]);
  const [oriCollab, setOriCollab] = useState([]);

  const [promotionType, setPromotionType] = useState("Discount");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [status, setStatus] = useState(true);
  const [bannerImg, setBannerImg] = useState("");
  const [promoImg, setPromoImg] = useState("");

  const toggle = () => {
    setModal(!modal);
    setBannerImg("");
    setPromoImg("");
  };

  const { data: prodData } = useQuery("ProdList", async () =>
    Axios.get(`/public/Product/GetAllProductWithoutImage`)
  );

  const { data: categoryData } = useQuery("CategList", async () =>
    Axios.get(`/public/ProductCategorySetting/ChildCategory`)
  );

  const { data: collectData } = useQuery("CollecList", async () =>
    Axios.get(`/public/${selectShop}/Collection`)
  );

  const { data: collabData } = useQuery("CollabList", async () =>
    Axios.get(`/public/${selectShop}/Collaboration`)
  );

  const { data: Promotion } = useQuery(
    ["SinglePromo", type, modal],
    async () => Axios.get(`/shop/${selectShop}/Promotion/${type.ID}`),
    { enabled: type.Type === "Edit" }
  );

  const bannerRef = React.createRef();
  const imgRef = React.createRef();

  const handleBannerClick = () => {
    bannerRef.current.click();
  };

  const handleImgClick = () => {
    imgRef.current.click();
  };

  const handleBanner = (e) => {
    setBannerImg({
      File: e.target.files[0],
      URL: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = null;
  };

  const handlePromoImg = (e) => {
    setPromoImg({
      File: e.target.files[0],
      URL: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = null;
  };

  useEffect(() => {
    if (prodData) {
      const temp = [];
      prodData.data.map((item) =>
        temp.push({ label: item.ProductName, value: item.ProductID })
      );
      setProdList(temp);
    }
    if (categoryData) {
      const temp = [];
      categoryData.data.ChildCategories.map((item) =>
        temp.push({ label: item.EngCategoryName, value: item.ID })
      );
      setCatList(temp);
    }
    if (collectData) {
      const temp = [];
      collectData.data.map((item) =>
        temp.push({ label: item.Name, value: item.ID })
      );
      setCollecList(temp);
    }
    if (collabData) {
      const temp = [];
      collabData.data.map((item) =>
        temp.push({ label: item.Name, value: item.ID })
      );
      setCollabList(temp);
    }
  }, [prodData, categoryData, collectData, collabData]);

  const handleProd = (selectedProd) => {
    setSelectProd(selectedProd);
  };

  const handleCat = (selectedCat) => {
    setSelectCat(selectedCat);
  };

  const handleCollect = (selectedCollect) => {
    setSelectCollect(selectedCollect);
  };

  const handleCollab = (selectedCollab) => {
    setSelectCollab(selectedCollab);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Please Enter Promotion Name`),
  });

  const formik = useFormik({
    initialValues: {
      name: Promotion ? Promotion.data.Name : "",
      description: Promotion ? Promotion.data.Description : "",
      discountValue:
        Promotion?.data.Discount !== null
          ? Promotion?.data.Discount.DiscountValue
          : "",
      cappDiscount:
        Promotion?.data.Discount !== null
          ? Promotion?.data.Discount.CappedDiscountAmount
          : "",
      minPurchase:
        Promotion?.data.Discount !== null
          ? Promotion?.data.Discount.MinPurchaseAmount
          : "",
      minCount: 0,
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (dataM) => {
      const data2 = new FormData();

      const imgCombine = [bannerImg.File, promoImg.File];

      data2.append("Name", dataM.name);
      data2.append("Description", dataM.description);
      data2.append("Status", status);
      data2.append("NoEndDate", endDate === "");
      data2.append("StartDateTime", startDate);
      data2.append("EndDateTime", endDate);

      if (selectProd.length !== 0) {
        selectProd.forEach((prod) => {
          data2.append("ProductIDList", prod.value);
        });
      }

      if (selectCat.length !== 0) {
        selectCat.forEach((category) => {
          data2.append("CategoryIDList", category.value);
        });
      }

      if (selectCollab.length !== 0) {
        selectCollab.forEach((collab) => {
          data2.append("CollaborationIdlist", collab.value);
        });
      }

      if (selectCollect.length !== 0) {
        selectCollect.forEach((collection) => {
          data2.append("CollectionIdlist", collection.value);
        });
      }

      data2.append("Type", promotionType);

      switch (promotionType) {
        case "Discount":
          data2.append("Discount.MinPurchaseAmount", dataM.minPurchase);
          data2.append(
            "Discount.EnableMinPurchaseAmount",
            dataM.minPurchase !== 0
          );
          data2.append("Discount.DiscountValue", dataM.discountValue);
          data2.append("Discount.Type", discountType);
          data2.append("Discount.CappedDiscountAmount", dataM.cappDiscount);
          data2.append(
            "Discount.EnableCappedDiscountAmount",
            dataM.cappDiscount !== 0
          );
          break;

        case "Bulk":
          data2.append("Bulk", [
            {
              MinimumCount: dataM.minCount,
              DiscountValue: dataM.discountValue,
              Type: discountType,
              CappedDiscountAmount: dataM.cappDiscount,
              EnableCappedDiscountAmount: dataM.cappDiscount !== 0,
            },
          ]);
          break;

        default:
          break;
      }

      imgCombine.forEach((files) => {
        data2.append("AttachmentList", files);
      });

      if (type.Type === "Create") {
        Axios.post(`shop/${selectShop}/Promotion`, data2)
          .then(() => {
            toast.success(
              <div>
                <p className="text-white">Successful Created Promotion </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });
      }

      if (type.Type === "Edit") {
        if (selectProd.length !== 0) {
          const isEqual =
            oriProduct.length === selectProd.length &&
            oriProduct.every((value, index) => {
              const obj2 = selectProd[index];
              return value.value === obj2.value && value.label === obj2.label;
            });

          if (!isEqual) {
            const toAdd = selectProd.filter(
              (obj2) => !oriProduct.some((obj1) => obj1.value === obj2.value)
            );
            const toRemove = oriProduct.filter(
              (obj1) => !selectProd.some((obj2) => obj2.value === obj1.value)
            );

            if (toAdd.length !== 0) {
              const tempAdd = [];
              toAdd.forEach((item) => {
                tempAdd.push(item.value);
              });
              Axios.post(
                `/shop/${selectShop}/Promotion/${type.ID}/products`,
                tempAdd
              );
            }

            if (toRemove.length !== 0) {
              const tempRemove = [];
              toRemove.forEach((item) => {
                tempRemove.push(item.value);
              });
              Axios.delete(
                `/shop/${selectShop}/Promotion/${type.ID}/Products`,
                {
                  headers: {
                    "content-type": "application/json",
                  },
                  data: tempRemove,
                }
              );
            }
          }
        }

        if (selectCat.length !== 0) {
          const isEqual =
            oriCat.length === selectCat.length &&
            oriCat.every((value, index) => {
              const obj2 = selectCat[index];
              return value.value === obj2.value && value.label === obj2.label;
            });

          if (!isEqual) {
            const toAdd = selectCat.filter(
              (obj2) => !oriCat.some((obj1) => obj1.value === obj2.value)
            );
            const toRemove = oriCat.filter(
              (obj1) => !selectCat.some((obj2) => obj2.value === obj1.value)
            );

            if (toAdd.length !== 0) {
              const tempAdd = [];
              toAdd.forEach((item) => {
                tempAdd.push(item.value);
              });
              Axios.post(
                `/shop/${selectShop}/Promotion/${type.ID}/categories`,
                tempAdd
              );
            }

            if (toRemove.length !== 0) {
              const tempRemove = [];
              toRemove.forEach((item) => {
                tempRemove.push(item.value);
              });
              Axios.delete(
                `/shop/${selectShop}/Promotion/${type.ID}/categories`,
                {
                  headers: {
                    "content-type": "application/json",
                  },
                  data: tempRemove,
                }
              );
            }
          }
        }

        if (selectCollab.length !== 0) {
          const isEqual =
            oriCollab.length === selectCollab.length &&
            oriCollab.every((value, index) => {
              const obj2 = selectCollab[index];
              return value.value === obj2.value && value.label === obj2.label;
            });

          if (!isEqual) {
            const toAdd = selectCollab.filter(
              (obj2) => !oriCollab.some((obj1) => obj1.value === obj2.value)
            );
            const toRemove = oriCollab.filter(
              (obj1) => !selectCollab.some((obj2) => obj2.value === obj1.value)
            );

            if (toAdd.length !== 0) {
              const tempAdd = [];
              toAdd.forEach((item) => {
                tempAdd.push(item.value);
              });
              Axios.post(
                `/shop/${selectShop}/Promotion/${type.ID}/collaborations`,
                tempAdd
              );
            }

            if (toRemove.length !== 0) {
              const tempRemove = [];
              toRemove.forEach((item) => {
                tempRemove.push(item.value);
              });
              Axios.delete(
                `/shop/${selectShop}/Promotion/${type.ID}/collaborations`,
                {
                  headers: {
                    "content-type": "application/json",
                  },
                  data: tempRemove,
                }
              );
            }
          }
        }

        if (selectCollect.length !== 0) {
          const isEqual =
            oriCollect.length === selectCollect.length &&
            oriCollect.every((value, index) => {
              const obj2 = selectCollect[index];
              return value.value === obj2.value && value.label === obj2.label;
            });

          if (!isEqual) {
            const toAdd = selectCollect.filter(
              (obj2) => !oriCollect.some((obj1) => obj1.value === obj2.value)
            );
            const toRemove = oriCollect.filter(
              (obj1) => !selectCollect.some((obj2) => obj2.value === obj1.value)
            );

            if (toAdd.length !== 0) {
              const tempAdd = [];
              toAdd.forEach((item) => {
                tempAdd.push(item.value);
              });
              Axios.post(
                `/shop/${selectShop}/Promotion/${type.ID}/collection`,
                tempAdd
              );
            }

            if (toRemove.length !== 0) {
              const tempRemove = [];
              toRemove.forEach((item) => {
                tempRemove.push(item.value);
              });
              Axios.delete(
                `/shop/${selectShop}/Promotion/${type.ID}/collection`,
                {
                  headers: {
                    "content-type": "application/json",
                  },
                  data: tempRemove,
                }
              );
            }
          }
        }

        Axios.put(`/shop/${selectShop}/Promotion/${type.ID}`, {
          Name:
            Promotion && Promotion.data.Name === dataM.name ? null : dataM.name,
          Description: dataM.description,
          Status: status,
          NoEndDate: endDate === "",
          StartDateTime:
            Promotion &&
            moment(Promotion.data.StartDateTime).format("YYYY-MM-DD") ===
              startDate
              ? null
              : startDate,
          EndDateTime:
            Promotion &&
            moment(Promotion.data.EndDateTime).format("YYYY-MM-DD") === endDate
              ? null
              : endDate,
        })
          .then(() => {
            switch (promotionType) {
              case "Discount":
                Axios.put(`/shop/${selectShop}/Promotion/${type.ID}/discount`, {
                  MinPurchaseAmount: dataM.minPurchase,
                  EnableMinPurchaseAmount: dataM.minPurchase !== 0,
                  DiscountValue: dataM.discountValue,
                  Type: discountType,
                  CappedDiscountAmount: dataM.cappDiscount,
                  EnableCappedDiscountAmount: dataM.cappDiscount !== 0,
                });
                break;
            }
            toast.success(
              <div>
                <p className="text-white">Successful Updated Promotion </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });

        // switch (promotionType) {
        //   case "Discount":
        //     Axios.put(`/shop/${selectShop}/Promotion/${type.ID}/discount`, {
        //       MinPurchaseAmount: dataM.minPurchase,
        //       EnableMinPurchaseAmount: dataM.minPurchase !== 0,
        //       DiscountValue: dataM.discountValue,
        //       Type: discountType,
        //       CappedDiscountAmount: dataM.cappDiscount,
        //       EnableCappedDiscountAmount: dataM.cappDiscount !== 0,
        //     });
        //     break;
        // }
      }
    },
  });

  useEffect(() => {
    setDiscountType("Subtraction");
    setStartDate("");
    setEndDate("");
    setSelectProd([]);
    setSelectCat([]);
    setSelectCollab([]);
    setSelectCollect([]);
    formik.resetForm();
  }, [type]);

  useEffect(() => {
    if (Promotion) {
      setDiscountType(Promotion.data.Discount.Type);
      setStartDate(moment(Promotion.data.StartDateTime).format("YYYY-MM-DD"));
      if (!Promotion.data.NoEndDate) {
        setEndDate(moment(Promotion.data.EndDateTime).format("YYYY-MM-DD"));
      }

      if (Promotion.data.CollaborationIdlist.length !== 0) {
        const temp = [];
        Promotion.data.CollaborationIdlist.forEach((collab) => {
          temp.push({
            label: collab.Name,
            value: collab.ID,
          });
        });
        setSelectCollab(temp);
        setOriCollab(temp);
      }

      if (Promotion.data.CollectionIdlist.length !== 0) {
        const temp = [];
        Promotion.data.CollectionIdlist.forEach((collection) => {
          temp.push({
            label: collection.Name,
            value: collection.ID,
          });
        });
        setSelectCollect(temp);
        setOriCollect(temp);
      }

      if (Promotion.data.CategoryIdlist.length !== 0) {
        const temp = [];
        Promotion.data.CategoryIdlist.forEach((category) => {
          temp.push({
            label: category.Name,
            value: category.ID,
          });
        });
        setSelectCat(temp);
        setOriCat(temp);
      }

      if (Promotion.data.ProductIdlist.length !== 0) {
        const temp = [];
        Promotion.data.ProductIdlist.forEach((prod) => {
          temp.push({
            label: prod.Name,
            value: prod.ID,
          });
        });
        setSelectProd(temp);
        setOriProduct(temp);
      }
    }
  }, [Promotion]);

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalBody className="text-center">
        <Button className="float-right closeBtn" onClick={() => toggle()}>
          <i className="nc-icon nc-simple-remove" />
        </Button>
        <h3 className="mt-3">{type.Type} Promotion</h3>
        <Form onSubmit={formik.handleSubmit}>
          <Container className="modalContainer">
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Status :
                  </Label>
                </div>
              </Col>
              <Col>
                <Switch
                  className="float-left mt-3"
                  checked={status}
                  onChange={() => setStatus(!status)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion Name :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  className="modalInput"
                  placeholder="Promotion Name..."
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.errors.name ? (
                  <p className="errorText float-left">{formik.errors.name}</p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion Description :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  className="modalInput pt-1 pl-2"
                  placeholder="Promotion Description..."
                  onChange={formik.handleChange}
                  value={formik.values.description}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion Date :
                  </Label>
                </div>
              </Col>
              <Col>
                <Row>
                  <Col md="3">
                    <Input
                      type="date"
                      className="modalDate"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                  <p className="ml-1 mt-3 pt-1">To</p>
                  <Col>
                    <Input
                      type="date"
                      className="modalDate"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion For Product :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={prodList}
                  value={selectProd}
                  onChange={handleProd}
                  labelledBy="Select Products..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion For Category :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={catList}
                  value={selectCat}
                  onChange={handleCat}
                  labelledBy="Select Category..."
                  disabled={
                    selectProd.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion For Collaboration :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={collabList}
                  value={selectCollab}
                  onChange={handleCollab}
                  labelledBy="Select Collaboration..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectProd.length !== 0 ||
                    selectCollect.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion For Collection :
                  </Label>
                </div>
              </Col>
              <Col>
                <MultiSelect
                  className="mt-2 pt-1 multiDrop"
                  options={collecList}
                  value={selectCollect}
                  onChange={handleCollect}
                  labelledBy="Select Collection..."
                  disabled={
                    selectCat.length !== 0 ||
                    selectCollab.length !== 0 ||
                    selectProd.length !== 0
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Promotion Type :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  type="select"
                  className="modalInput"
                  value={promotionType}
                  onChange={(e) => setPromotionType(e.target.value)}
                >
                  <option value="Discount">Discount</option>
                  <option value="Bulk">Bulk</option>
                </Input>
              </Col>
            </Row>
            {type.Type === "Create" ? (
              <>
                <Row className="mb-3">
                  <Col md="4">
                    <div>
                      <Label
                        tag="h5"
                        className="float-right modalLabel"
                        for="exampleFile"
                      >
                        <p className="float-right">Promotion Banner :</p>
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    {bannerImg ? (
                      <div>
                        <button
                          type="button"
                          className="removeImg"
                          onClick={() => setBannerImg("")}
                        >
                          <i className="fa fa-times-circle removeIcon" />
                        </button>
                        <img
                          src={bannerImg.URL}
                          className="float-left mt-1 bannerImg"
                        />
                      </div>
                    ) : (
                      <Button
                        className="float-left"
                        onClick={() => handleBannerClick()}
                      >
                        Upload
                      </Button>
                    )}
                    <input
                      type="file"
                      className="uploadImg"
                      accept="image/*"
                      ref={bannerRef}
                      onChange={handleBanner}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md="4">
                    <div>
                      <Label
                        tag="h5"
                        className="float-right modalLabel"
                        for="exampleFile"
                      >
                        <p className="float-right">Promotion Image :</p>
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    {promoImg ? (
                      <div>
                        <button
                          type="button"
                          className="removeImg"
                          onClick={() => setPromoImg("")}
                        >
                          <i className="fa fa-times-circle removeIcon" />
                        </button>
                        <img
                          src={promoImg.URL}
                          className="float-left mt-1 bannerImg"
                        />
                      </div>
                    ) : (
                      <Button
                        className="float-left"
                        onClick={() => handleImgClick()}
                      >
                        Upload
                      </Button>
                    )}

                    <input
                      type="file"
                      className="uploadImg"
                      accept="image/*"
                      ref={imgRef}
                      onChange={handlePromoImg}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}

            <div className="mt-4">
              <Label tag="h5" className="float-left ml-4">
                {promotionType}
              </Label>
            </div>
            <br />
            <hr />
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Discount Type :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  type="select"
                  className="modalInput"
                  value={discountType}
                  onChange={(e) => setDiscountType(e.target.value)}
                >
                  <option value="Subtraction">Subtraction</option>
                  <option value="Percentage">Percentage</option>
                </Input>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Discount Value :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="discountValue"
                  name="discountValue"
                  type="text"
                  className="modalInput"
                  placeholder="Discount Value..."
                  onChange={formik.handleChange}
                  value={formik.values.discountValue}
                />
              </Col>
            </Row>
            {promotionType === "Discount" ? (
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Minimum Purchase Amount :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    id="minPurchase"
                    name="minPurchase"
                    type="text"
                    className="modalInput"
                    placeholder="Minimum Purchase Amount..."
                    onChange={formik.handleChange}
                    value={formik.values.minPurchase}
                  />
                </Col>
              </Row>
            ) : promotionType === "Bulk" ? (
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Minimun Count :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    id="minCount"
                    name="minCount"
                    type="text"
                    className="modalInput"
                    placeholder="Minimun Count..."
                    onChange={formik.handleChange}
                    value={formik.values.minCount}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <Row>
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Capped Discount Amount :
                  </Label>
                </div>
              </Col>
              <Col>
                <Input
                  id="cappDiscount"
                  name="cappDiscount"
                  type="text"
                  className="modalInput"
                  placeholder="Capped Discount Amount..."
                  onChange={formik.handleChange}
                  value={formik.values.cappDiscount}
                />
              </Col>
            </Row>

            <Button
              className="submitBtn mb-3 mt-3"
              // onClick={() => formik.handleSubmit()}
            >
              {`${type.Type === "Edit" ? "Update" : "Create"} Promotion`}
            </Button>
          </Container>
        </Form>
      </ModalBody>
    </Modal>
  );
}

PromotionForm.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  type: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default PromotionForm;
