/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import PropTypes from "prop-types";
import BasicTable from "views/tables/BasicTable";
import { useQuery } from "react-query";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import moment from "moment";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

const options = {
  responsive: true, // Make the chart responsive
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      ticks: {
        color: "white",
      },
    },
  },
};

function CouponLoyaltyReport(props) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const {
    setTriggerReport,
    couponType,
    setFilterCoupon,
    startDate,
    endDate,
    range,
    isChecked,
  } = props;
  const [rank, setRank] = useState("NORMAL");
  const [searchFilter, setSearchFilter] = useState("");
  const [couponTier, setCouponTier] = useState([]);
  const [datas, setDatas] = useState([]);
  const [graphType, setGraphType] = useState("OrderCount");
  const [period, setPeriod] = useState("Month");

  const columns = [
    {
      name: "Coupon Code",
      selector: (row) => (
        <Link
          to={`/shopOwner/marketing/analytic/couponLoyalty/${couponType}/${row.ID}`}
        >
          {row.Code}
        </Link>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Orders",
      selector: (row) => row.TotalOrderCount,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount Discounted",
      selector: (row) => row.TotalDiscountAmount,
      sortable: true,
      wrap: true,
      width: "210px",
    },
    {
      name: "Created",
      selector: (row) => moment(row.CreatedDate).format("YYYY-MM-DD"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Expires",
      selector: (row) => moment(row.Expire).format("YYYY-MM-DD"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.Type,
      sortable: true,
      wrap: true,
      width: "100px",
    },
  ];

  const { data: Tier } = useQuery(["CouponTier", rank, period], async () =>
    Axios.get(`/shop/${shopID}/Marketing/Dashboard/${couponType}/Tier`, {
      params: {
        loyaltyRank: rank,
      },
    }).then((res) => {
      setCouponTier(res.data.Coupon);

      const temp = [];
      res.data.Coupon.forEach((item) => {
        temp.push(item.Code);
      });
      setFilterCoupon(temp);

      return res;
    })
  );

  const { data: Graph, isSuccess } = useQuery(
    [
      "TierGraph",
      graphType,
      rank,
      period,
      startDate,
      endDate,
      range,
      isChecked,
    ],
    async () =>
      Axios.get(
        `/shop/${shopID}/Marketing/Dashboard/${couponType}/Tier/Graph`,
        {
          params: {
            dateFrom: moment(startDate)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format(),
            dateTo: moment(endDate)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format(),

            dateFrom2:
              (range &&
                moment(range[0])
                  .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                  .format()) ||
              null,
            dateTo2:
              (range &&
                moment(range[1])
                  .set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999,
                  })
                  .format()) ||
              null,
            isCompare: isChecked,
            tablePeriod: period,
            GroupBy: graphType,
            loyaltyRank: rank,
          },
        }
      )
  );

  const lineGraphData = {
    ...datas,
    datasets: [
      {
        label: datas && datas?.datasets && datas?.datasets[0].label,
        data: datas && datas?.datasets && datas?.datasets[0].data,
        borderColor: datas && datas?.datasets && datas?.datasets[0].borderColor,
        yAxisID: "y",
      },
      ...(datas && datas?.datasets && datas?.datasets[1]?.data
        ? [
            {
              label: `Prev date ${
                datas && datas?.datasets && datas?.datasets[1].label
              } `,
              data: datas && datas?.datasets && datas?.datasets[1].data,
              borderColor:
                datas && datas?.datasets && datas?.datasets[1].borderColor,
              yAxisID: "y1",
            },
          ]
        : []),
    ],
  };

  // const handleChange = ({ selectedRows }) => {
  //   console.log(selectedRows);
  // };

  const handleSearch = () => {
    const newData = Tier.data.Coupon.filter(
      (row) =>
        row.Code.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.Type.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.TotalOrderCount.toString().includes(searchFilter.toLowerCase()) ||
        row.TotalDiscountAmount.toString().includes(searchFilter.toLowerCase())
    );
    setCouponTier(newData);
  };

  const resetSearch = () => {
    setSearchFilter("");
    setCouponTier(Tier.data.Coupon);
  };

  useEffect(() => {
    if (Graph) {
      let datasets;
      let datasets2;
      let formattedDates;

      if (Graph.data?.Graph1) {
        // Update the graph with the API response
        datasets = [
          {
            label:
              graphType === "OrderCount" ? "Total Order" : "Total Discounted",
            data: Graph.data.Graph1.map((gdts) => gdts.Value),
            borderColor: "rgba(248, 192, 192,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];
      }
      // get the date ex: 2023-354
      if (Graph.data.Graph1[0]?.ID.Day) {
        // Handle day format
        const dates = Graph.data.Graph1?.map(
          (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
        );
        const sortedDates = dates.sort((a, b) => {
          const [yearA, dayOfYearA] = a.split("-");
          const [yearB, dayOfYearB] = b.split("-");

          const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
          const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

          return dateA - dateB;
        });
        formattedDates = sortedDates.map((dateString) => {
          const [year, dayOfYear] = dateString.split("-");
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return `${date.getDate()} ${date.toLocaleString("default", {
            month: "short",
          })} ${date.getFullYear()}`;
        });
      } else if (Graph.data.Graph1[0]?.ID.Month) {
        formattedDates = Graph.data.Graph1.map((monthData) => {
          const monthDate = new Date(
            monthData.ID.Year,
            monthData.ID.Month - 1,
            1
          );
          return `${monthDate.toLocaleString("default", {
            month: "long",
          })} ${monthData.ID.Year}`;
        });
      } else if (Graph.data.Graph1[0]?.ID.Week) {
        formattedDates = Graph.data.Graph1.map((weekData) => {
          const weekStartDate = new Date(
            weekData.ID.Year,
            0,
            (weekData.ID.Week - 1) * 7
          );
          const weekEndDate = new Date(
            weekData.ID.Year,
            0,
            weekData.ID.Week * 7 - 1
          );
          return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekEndDate.getFullYear()}`;
        });
      }

      if (Graph.data?.Graph2) {
        datasets2 = [
          {
            label:
              graphType === "OrderCount" ? "Total Order" : "Total Discounted",
            data: Graph.data.Graph2?.map((gdts) => gdts.Value),
            borderColor: "rgba(74, 189, 219,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];

        if (Graph.data.Graph2[0]?.ID.Day) {
          // Handle day format
          const dates = Graph.data.Graph1.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (Graph.data.Graph2[0]?.ID.Month) {
          formattedDates = Graph.data.Graph1.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (Graph.data.Graph2[0]?.ID.Week) {
          formattedDates = Graph.data.Graph1.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      const newDatas = {
        labels: formattedDates,
        datasets: [...datasets, ...(datasets2 || [])],
      };
      setDatas(newDatas);
    }
  }, [Graph]);

  return (
    <>
      <Button onClick={() => setTriggerReport(false)}>Back</Button>
      <div className="text-center">
        <Row>
          <Col>
            <Button
              className="float-right rankBtn"
              color={rank === "NORMAL" ? "success" : "info"}
              onClick={() => setRank("NORMAL")}
            >
              Normal
            </Button>
          </Col>
          <Col md="2">
            <Button
              className="rankBtn"
              color={rank === "SILVER" ? "success" : "info"}
              onClick={() => setRank("SILVER")}
            >
              Silver
            </Button>
          </Col>
          <Col>
            <Button
              className="float-left rankBtn"
              color={rank === "GOLD" ? "success" : "info"}
              onClick={() => setRank("GOLD")}
            >
              Gold
            </Button>
          </Col>
        </Row>
      </div>
      <div className="graphContainer">
        <Row>
          <Col
            md="6"
            className={`graphSplit ${
              graphType === "OrderCount" ? "graphSelected" : ""
            }`}
            onClick={() => setGraphType("OrderCount")}
          >
            <div>
              <h4>Total Orders</h4>
              {isSuccess && <p>{Graph.data.TotalOrderCount}</p>}
            </div>
          </Col>
          <Col
            md="6"
            className={`graphSplit ${
              graphType === "OrderDiscount" ? "graphSelected" : ""
            }`}
            onClick={() => setGraphType("OrderDiscount")}
          >
            <div>
              <h4>Total Discounted</h4>
              {isSuccess && <p>{Graph.data.TotalOrderDiscount}</p>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="graphBox">
            <div className="mb-5">
              <Input
                className="float-right"
                type="select"
                placeholder="Coupon..."
                style={{ width: "130px" }}
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
              >
                <option value="Day">By Day</option>
                <option value="Week">By Week</option>
                <option value="Month">By Month</option>
              </Input>
            </div>
            <Line data={lineGraphData} options={options} />
          </Col>
        </Row>
      </div>
      <div className="d-flex mt-4">
        <div className="mb-0">
          <Row>
            <Col>
              <InputGroup
                className="no-border "
                style={{
                  width: "350px",
                  backgroundColor: "white",
                  marginTop: "10px",
                }}
              >
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i
                      className="nc-icon nc-zoom-split"
                      style={{ color: "black" }}
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="textColor"
                  defaultValue=""
                  placeholder="Search..."
                  type="text"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
                {searchFilter !== "" && (
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        className="nc-icon nc-simple-remove"
                        style={{ color: "black", cursor: "pointer" }}
                        onClick={() => setSearchFilter("")}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>
            </Col>
            <Col>
              <div>
                <Button color="secondary" onClick={handleSearch}>
                  Filter
                </Button>
                <Button color="danger" onClick={resetSearch}>
                  Reset Filter
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <BasicTable
        id=""
        className="mt-3"
        responsive
        // selectableRows
        // onSelectedRowsChange={handleChange}
        data={couponTier}
        columns={columns || []}
        pagination
      />
    </>
  );
}

CouponLoyaltyReport.propTypes = {
  setTriggerReport: PropTypes.func.isRequired,
  couponType: PropTypes.string.isRequired,
  setFilterCoupon: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  range: PropTypes.array.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default CouponLoyaltyReport;
