import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";

function TradeInStatusUpdate(props) {
  const { tID, showModal, setShowModal } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const handleSubmit = (event) => {
    event.preventDefault();

    Axios.put(`/shop/${shopID}/TradeInQCReceived/${tID}`)
      .then((response) => {
        toast.success(response.data.MESSAGE);
        setShowModal({
          ...showModal,
          open: !showModal.open,
        });
        // Handle the response from the API
      })
      .catch((error) => {
        // Handle error
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <h5>Confirm Received Parcel</h5>
        <Button className="btn btn-success" type="submit">
          Yes
        </Button>
        <Button
          className="btn btn-danger"
          onClick={() =>
            setShowModal({
              ...showModal,
              open: false,
            })
          }
        >
          No
        </Button>
      </Form>
    </div>
  );
}

TradeInStatusUpdate.propTypes = {
  tID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};

export default TradeInStatusUpdate;
