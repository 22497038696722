/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-alert */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import BasicTable from "views/tables/BasicTable";
import "./department.css";
import moment from "moment";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
// import ApiContext from "services/ApiContext";

function Department() {
  const { selctShopID, department } = useContext(ShopContext);
  const shopID = selctShopID.ID;
  const deptID = department.DepartmentID;
  const deptName = department.DepartmentName;
  const Axios = useContext(ApiContext);

  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [position, setPosition] = useState("");
  const [filterDataResult, setFilterDataResult] = useState([]);
  const [show, setShow] = useState(false);
  const [isAddingStaff, setIsAddingStaff] = useState(false); // State to track whether staff is being added

  const { refetch, data } = useQuery(`/admin/Department/${deptID}`, async () =>
    Axios.get(`/admin/Department/${deptID}`)
  );

  const accStatus = (status, mID) => {
    const requestBody = JSON.stringify(status);

    Axios.put(
      `/shop/${shopID}/Account/UpdateDepartmentMember/${mID}`,
      requestBody,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        toast.success(response.data.MESSAGE);
        refetch();
        // Handle the response from the API
      })
      .catch((error) => {
        // Handle error
        toast.error(error.response.data.MESSAGE);
      });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Position",
      selector: (row) => row.Position,
      sortable: true,
      wrap: true,
    },
    {
      name: "Email",
      selector: (row) => row.Email,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => (row.Status ? "Active" : "Inactive"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) =>
        row.Position !== "DepartmentHead" && (
          <Button
            style={{ backgroundColor: `${row.Status ? "red" : "green"}` }}
            onClick={() => accStatus(!row.Status, row.ID)}
          >
            {row.Status ? "Deactivate" : "Activate"}
          </Button>
        ),
      sortable: true,
      wrap: true,
    },
  ];

  const generatePassword = () => {
    const generate = () => {
      const length = 12;
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%&";
      let retVal = "";
      for (let i = 0, n = charset.length; i < length; i += 1) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    };
    setPassword(generate());
  };

  const copyToClipboard = () => {
    const copyText = password;
    const textArea = document.createElement("textarea");
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
    toast.success("Password Copied");
  };

  const clearAll = () => {
    setEmail("");
    setFullName("");
    setBirthDate("");
    setGender("");
    setPassword("");
  };

  const toggle = () => {
    setModal(!modal);
    clearAll();
  };

  const addStaff = () => {
    if (
      email === "" ||
      fullName === "" ||
      birthDate === "" ||
      gender === "" ||
      password === "" ||
      contactNo === ""
    ) {
      alert("Please Fill In All the Required Fields");
      return;
    }
    setIsAddingStaff(true); // Set the state to true when staff addition begins
    Axios.post(`/shop/${shopID}/Account/RegisterDepartmentMember`, {
      Email: email,
      FullName: fullName,
      Contact: contactNo,
      Password: password,
      DateOfBirth: moment(birthDate).format(),
      Gender: gender,
      Position: position,
    })
      .then(() => {
        toast.success("Staff Added");
        toggle();
        refetch();
      })
      .catch((error) => {
        toast.error(`Adding Staff Failled ${error.response.data[0].MESSAGE}`);
      })
      .finally(() => {
        setIsAddingStaff(false); // Set the state back to false when staff addition completes
      });
  };

  useEffect(() => {
    if (data) {
      setFilterDataResult(data.data.DepartmentMemberList);
    }
  }, [data]);

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="card-dark">
            <CardHeader>
              <CardTitle className="mt-0">
                <Row>
                  <Col>
                    <h4 className="ml-3">{deptName}</h4>
                  </Col>
                  <Col className="mt-3">
                    <Button
                      className="mt-3 orderBtn float-right"
                      onClick={() => setModal(true)}
                    >
                      Add Staff
                    </Button>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody className="mt-0">
              <BasicTable
                pagination
                id=""
                data={filterDataResult}
                columns={columns || []}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <div className="mt-3">
          <Button
            className="btn btn-primary float-right mr-2"
            style={{ fontSize: "12px" }}
            size="sm"
            onClick={() => toggle()}
          >
            x
          </Button>
          <h3 className="mt-2 text-center">Add Staff to Department</h3>
        </div>
        <ModalBody>
          <Row className="text-center">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Full Name :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  placeholder="Enter Name..."
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Date Of Birth :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  type="date"
                  className="deptDate"
                  value={birthDate}
                  placeholder="Enter Date of Birth..."
                  onChange={(e) => setBirthDate(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Gender :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  type="select"
                  className="deptSelect"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Input>
              </div>
            </Col>
          </Row>
          <Row className="text-center  mt-3">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Contact No :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  placeholder="Enter Contact..."
                  type="Contact"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <hr className="mb-4" />
          <Row className="text-center  mt-3">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Email :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  placeholder="Enter Email..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row className="text-center mt-3">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Password :</Label>
              </div>
            </Col>
            <Col md={5}>
              <div className="d-flex">
                <Input
                  placeholder="Enter Password..."
                  type={show ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  readOnly
                  className="mr-2"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i
                      className={show ? "fa fa-eye" : "fa fa-eye-slash"}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(!show)}
                      aria-hidden="true"
                    />
                  </InputGroupText>
                </InputGroupAddon>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <Button
                  size="sm"
                  color="primary"
                  className="mt-0"
                  onClick={generatePassword}
                >
                  Gen Password
                </Button>
                <Button
                  size="sm"
                  color="success"
                  className="ml-2 text-capitalize mt-0"
                  onClick={copyToClipboard}
                >
                  <i className="fa fa-clipboard" aria-hidden="true" />
                  Copy
                </Button>
              </div>
            </Col>
          </Row>
          <hr />
          <Row className="text-center mt-3 mb-5">
            <Col md="3">
              <div className="float-right">
                <Label className="deptLabel">Position :</Label>
              </div>
            </Col>
            <Col>
              <div>
                <Input
                  type="select"
                  className="deptSelect"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="" selected disabled hidden>
                    Select Position
                  </option>
                  <option value="Supervisor">Supervisor</option>
                  <option value="Staff">Staff</option>
                </Input>
              </div>
            </Col>
          </Row>
          <div className="text-center mt-4 ">
            <Button
              className="deptBtn"
              onClick={() => addStaff()}
              disabled={isAddingStaff}
            >
              {isAddingStaff ? "Adding Staff..." : "Add Staff"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Department;
