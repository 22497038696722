/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable consistent-return */
import React, { useContext } from "react";
import { Button, Col, Table } from "reactstrap";
import PropTypes from "prop-types";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import moment from "moment";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";

function ViewERP(props) {
  const { orderID } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const {
    isLoading,
    data: erpInfo,
    isSuccess,
    refetch, // Add the refetch function from react-query
  } = useQuery(
    `/shop/${shopID}/ERPInvoice/GetERPInvoiceDetails/${orderID}`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/ERPInvoice/GetERPInvoiceDetails/${orderID}`)
  );

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }

  const AddERPInvoice = async () => {
    Axios.post(`/shop/${shopID}/ERPInvoice/PostERPInvoice/${orderID}`)
      .then((response) => {
        toast.success("Success:", response);
        // Call the refetch function to refresh the data from the API
        refetch();
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };
  const DeleteERPInvoice = async () => {
    Axios.delete(`/shop/${shopID}/ERPInvoice/DeleteERPInvoice/${orderID}`)
      .then((response) => {
        toast.success("Success:", response.data.MESSAGE);
        refetch();
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <h4>ERP Invoice</h4>
      {erpInfo?.data[0] !==
      "No invoice with reference number 181223-0027 found." ? (
        <Button
          className="float-right"
          outline
          color="primary"
          onClick={() => {
            AddERPInvoice();
          }}
        >
          Add Invoice
        </Button>
      ) : (
        <Button
          className="float-right"
          outline
          color="danger"
          onClick={DeleteERPInvoice}
        >
          Delete Invoice
        </Button>
      )}

      {isSuccess && erpInfo && (
        <div id="" className="mt-5">
          {erpInfo?.data[0] === "No invoice with reference number  found." ? (
            <div>
              <p colSpan="3">{erpInfo.data[0]}</p>
            </div>
          ) : (
            <Table>
              <thead>
                <tr>
                  <th>ERP Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Portal Invoice No</th>
                  <th>SerialNo</th>
                  <th>UnitPrice</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {erpInfo.data.Details?.map((item) => (
                  <tr key={erpInfo.data.ErpInvoiceNo}>
                    <td>{erpInfo.data.ErpInvoiceNo}</td>
                    <td>
                      {moment(erpInfo.data.InvoiceDate).format("DD/MM/YYYY")}
                    </td>
                    <td>{erpInfo.data.PortalInvoiceNo}</td>

                    <td>{item.SerialNo}</td>
                    <td>{item.UnitPrice}</td>
                    <td>
                      <div>
                        <CurrencyFormat
                          decimal={2}
                          price={item.Amount}
                          currency="MYR"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}
    </div>
  );
}
ViewERP.propTypes = {
  orderID: PropTypes.string.isRequired,
};
export default ViewERP;
