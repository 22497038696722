import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";

function pickedOrder() {
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const [selectedOrderID, setSelectedOrderID] = useState(null);
  const [skuNumber, setSkuNumber] = useState("");
  // const [status, setStatus] = useState("");
  const [isInputChecked, setIsInputChecked] = useState(false);
  const { data: pickOrder, refetch } = useQuery(
    [`/shop/${shopID}/Order/Sale/Order`],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/Sale/Order`, {
        params: {
          limitPerPage: 9999,
        },
      })
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleInputChange = (event) => {
    const inputNumber = event.target.value;
    setSkuNumber(inputNumber);
  };

  const handleEnterClick = () => {
    const matchingOrder = pickOrder?.data.Result.find(
      (pOrder) => pOrder.MatchingSerialNo === skuNumber
    );

    if (
      matchingOrder &&
      (matchingOrder.AcceptedBy !== "" || matchingOrder.RejectedBy !== "")
    ) {
      setSelectedOrderID(null);
      toast.error("Input number already has Updated In system");
    } else if (matchingOrder) {
      setSelectedOrderID(matchingOrder.OrderID);
      toast.success(`Input number is match as MatchingSerialNo!`);
      setIsInputChecked(true);
    } else {
      setSelectedOrderID(null);
      toast.error("Serial Number Not Found");
    }
  };
  const handleSubmit = async (status) => {
    if (selectedOrderID) {
      try {
        const response = await Axios.put(`/shop/${shopID}/Order/Sale/Item`, {
          variationSKU: skuNumber,
          OrderID: selectedOrderID,
          ItemStatus: status,
          LatestOrderStatus: "",
        });
        setIsInputChecked(false);
        toast.success("Item received", response.data.MESSAGE);
        refetch();
      } catch (error) {
        toast.error("Error ", error.response.data.MESSAGE);
      }
    }
  };
  const handleReceivedClick = () => {
    handleSubmit("Received");
  };

  const handleRejectedClick = () => {
    handleSubmit("Rejected");
  };

  return (
    <div className="content ">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        tag={Link}
        to="/shopOwner/sales/dashboard"
        size="sm"
      >
        Back
      </Button>
      <Card className="card-stats card-dark">
        <CardHeader>
          <CardTitle tag="h4">Picked Item</CardTitle>
        </CardHeader>
        <CardBody className="m-4">
          <Row>
            <Col md="6">
              <Input onChange={handleInputChange} />
            </Col>
            <Col md="6">
              {isInputChecked === false ? (
                <Button
                  value="Received"
                  onClick={handleEnterClick}
                  style={{ marginTop: "-5px", backgroundColor: "green" }}
                >
                  Check
                </Button>
              ) : (
                <>
                  <Button
                    value="Received"
                    onClick={() => {
                      handleReceivedClick();
                    }}
                    style={{ marginTop: "-5px", backgroundColor: "green" }}
                  >
                    Received
                  </Button>
                  <Button
                    value="Rejected"
                    onClick={() => {
                      handleRejectedClick();
                    }}
                    style={{ marginTop: "-5px", backgroundColor: "red" }}
                  >
                    Rejected
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="card-stats card-dark mt-5">
        <CardHeader>
          <CardTitle tag="h4">Stock details</CardTitle>
        </CardHeader>
        <CardBody>
          <Table bordered>
            <thead
              style={{
                backgroundColor: "#51BCDA",
                color: "white",
              }}
            >
              <tr className="text-center">
                <th className="border-right">Categories</th>
                <th className="border-right">Size</th>
                <th className="border-right">Wight(gm)</th>
                <th className="border-right">Serial Number</th>
                <th className="border-right">Matching SN</th>
                <th className="border-right">Picker</th>
                <th className="border-right">Accept Received</th>
                <th>Rejected Item</th>
              </tr>
            </thead>
            {pickOrder?.data.Result.map((pOrder) => (
              <tbody>
                <tr className="text-center">
                  <td>{pOrder.Category}</td>
                  <td>{pOrder.Size}</td>
                  <td>{pOrder.Weight}</td>
                  <td>{pOrder.SerialNo}</td>
                  <td>{pOrder.MatchingSerialNo}</td>
                  <td>{pOrder.Picker}</td>
                  <td>{pOrder.AcceptedBy}</td>
                  <td>{pOrder.RejectedBy}</td>
                </tr>
              </tbody>
            ))}
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

export default pickedOrder;
