import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Spinner,
  Modal,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import ShopContext from "services/ShopContext";
import moment from "moment";
import { toast } from "react-toastify";
import ImageUpload from "components/CustomUpload/ImageUpload";
import BasicTable from "views/tables/BasicTable";
import { useQuery } from "react-query";

const state = [
  "JOHOR",
  "KEDAH",
  "KELANTAN",
  "MELAKA",
  "NEGERI SEMBILAN",
  "PAHANG",
  "PULAU PINANG",
  "PERAK",
  "PERLIS",
  "SABAH",
  "SARAWAK",
  "SELANGOR",
  "TERENGGANU",
  "KUALA LUMPUR",
  "LABUAN",
  "PUTRAJAYA",
];

function CreateInvoice() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;

  const [modalImg, setModalImg] = React.useState(false);
  const [lockedCode, setLockedCode] = React.useState("");
  // record product added
  const [productSelected, setProductSelected] = React.useState([]);
  const [typingTimeout, setTypingTimeout] = React.useState(0);
  const [scannedCode, setScannedCode] = React.useState("");
  const [scannedCode2, setScannedCode2] = React.useState("");
  const [scanFromScanner, setScanFromScanner] = React.useState(false);

  const [submitting, setSubmitting] = React.useState(false);

  const validationSchema = Yup.object().shape({
    UserDetail: Yup.object().shape({
      Name: Yup.string().required("Name is required"),
      Email: Yup.string().required("Email is required"),
      Contact: Yup.string().required("Contact is required"),
    }),
    UserAddress: Yup.object().shape({
      FirstLine: Yup.string().required("Address is required"),
      City: Yup.string().required("City is required"),
      State: Yup.string().required("State is required"),
      Country: Yup.string().required("Country is required"),
      Postcode: Yup.string().required("Postcode is required"),
    }),
    PaymentMethodID: Yup.string().required("Payment Method is required"),
    DeliveryMethodID: Yup.string().required("Delivery Method is required"),
    OrderSourceID: Yup.string().required("Order Source is required"),
    LiveBatchID: Yup.string().required("Batch is required"),
    ScanProductIDList: Yup.array().min(1, "Product is required"),
    ShippingFee: Yup.number().required("Shipping Fee is required"),
  });

  const formik = useFormik({
    initialValues: {
      UserDetail: {
        Name: "",
        Email: "",
        Contact: "",
      },
      UserAddress: {
        FirstLine: "",
        SecondLine: "",
        City: "",
        State: "",
        Country: "Malaysia",
        Postcode: "",
      },
      PaymentMethodID: "",
      // DeliveryMethodID: "",
      // OrderSourceID: "",
      LiveBatchID: "",
      ShippingFee: 0,
    },
    validationSchema,

    onSubmit: (data) => {
      formik.setSubmitting(true);
      const data2 = new FormData();
      data2.append("UserDetail.Name", data.UserDetail.Name);
      data2.append("UserDetail.Email", data.UserDetail.Email);
      data2.append("UserDetail.Contact", data.UserDetail.Contact);
      data2.append("UserAddress.FirstLine", data.UserAddress.FirstLine);
      data2.append("UserAddress.SecondLine", data.UserAddress.SecondLine);
      data2.append("UserAddress.City", data.UserAddress.City);
      data2.append("UserAddress.State", data.UserAddress.State);
      data2.append("UserAddress.Country", data.UserAddress.Country);
      data2.append("UserAddress.Postcode", data.UserAddress.Postcode);
      data2.append("PaymentMethodID", data.PaymentMethodID);
      data2.append("DeliveryMethodID", data.DeliveryMethodID);
      data2.append("OrderSourceID", data.OrderSourceID);
      data2.append("LiveBatchID", data.LiveBatchID);
      data2.append("ShippingFee", data.ShippingFee);
      data.ScanProductIDList.forEach((item) => {
        data2.append("ScanProductIDList", item.ProductID);
      });
      if (data.PaymentImageList) {
        data2.append("PaymentImageList", data.PaymentImageList);
      }

      Axios.post(`/shop/${shopID}/Live/CreateInvoice`, data2)
        .then(() => {
          toast.success("Invoice created");
          setProductSelected([]);
          setSubmitting(false);
          formik.resetForm();
        })
        .catch((err) => toast.error(err.response.data.MESSAGE));
      formik.setSubmitting(false);
    },
  });

  const { data: orderSouceList, isLoading } = useQuery(
    "AllOrderSource",
    async () =>
      Axios.get("/public/OrderSourceAttribute/AllOrderSource").then((res) => {
        formik.setFieldValue("OrderSourceID", res.data[0].ID);
        return res.data;
      })
  );

  const { data: paymentList } = useQuery("AllLivePaymentGateways", async () =>
    Axios.get("/public/LanguageAttributeSetting/AllLanguagesSetting").then(
      (res) =>
        // default language is english
        Axios.get(
          `/public/PaymentGatewaySetting/AllLivePaymentGateways/${res.data[0].ID}`
        ).then((res2) => res2.data)
    )
  );

  const { data: BatchList } = useQuery(
    `/shop/${shopID}/Live/Dashboard/BatchList`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Live/Dashboard`).then((res) => {
        const temp = res.data.Result.filter(
          (i) => i.ID === moment(new Date()).format("DD/MM/YYYY")
        );
        return temp[0].batchLogList.filter((y) => y.isConfirm === true);
      })
  );

  const { data: deliveryList } = useQuery("AllDeliveryType", async () =>
    Axios.get("/public/DeliveryMethodAttributeSetting/AllDeliveryType").then(
      (res) => {
        if (!formik.values.DeliveryMethodID) {
          // default delivery is english
          formik.setFieldValue("DeliveryMethodID", res.data[0].ID);
        }
        return res.data;
      }
    )
  );

  React.useEffect(() => {
    formik.setFieldValue("ProductList", productSelected);
  }, [productSelected]);

  const { isLoading: isLoadingTableSN, refetch: refetchSN } = useQuery(
    `/shop/${shopID}/Live/GetSelectedSNProduct/${
      formik.values.LiveBatchID
    }/SN/${scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetSelectedSNProduct/${
          formik.values.LiveBatchID
        }/SN/${
          scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode
        }`
      ).then((res) => {
        if (res.data.ID) {
          formik.setFieldValue("ScanProductIDList", [
            ...productSelected,
            res.data,
          ]);
          setProductSelected([...productSelected, res.data]);
          toast.success(`Added product: ${res.data.VariationSKU}`);
        } else {
          toast.warning(res.data.MESSAGE);
        }
        return res;
      }),
    { enabled: false }
  );

  const { isLoading: isLoadingTableNo, refetch: refetchNo } = useQuery(
    `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveBatchID}/No/${lockedCode}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveBatchID}/No/${lockedCode}`
      ).then((res) => {
        if (res.data.ID) {
          formik.setFieldValue("ScanProductIDList", [
            ...productSelected,
            res.data,
          ]);
          setProductSelected([...productSelected, res.data]);
          toast.success(`Added product: ${res.data.VariationSKU}`);
        } else {
          toast.warning(res.data.MESSAGE);
        }
        return res;
      }),
    { enabled: false }
  );

  const addScanItem = (category) => {
    if (category === "locked") {
      if (lockedCode.trim() === "") {
        toast.warning("Cannot be emtpy");
      } else if (
        !productSelected.find((i) => i.ProductNo === lockedCode.trim())
      ) {
        refetchNo();
      } else {
        toast.warning(`This item already added: ${lockedCode}`);
      }
      setLockedCode("");
    }
    if (category === "scanned") {
      if (scannedCode.trim() === "") {
        toast.warning("Cannot be emtpy");
      } else if (
        !productSelected.find((i) => i.VariationSKU === scannedCode.trim())
      ) {
        setScanFromScanner(false);
        refetchSN();
      } else {
        toast.warning(`This item already added: ${scannedCode}`);
      }
      setScannedCode("");
    }
  };

  const addScanItem2 = () => {
    if (scannedCode2.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (
      !productSelected.find((i) => i.VariationSKU === scannedCode2.trim())
    ) {
      setScanFromScanner(true);
      refetchSN();
    } else {
      toast.warning(`This item already added: ${scannedCode2}`);
    }
    setScannedCode("");
    setScannedCode2("");
  };

  // Used to handle the barcode scanner
  React.useEffect(() => {
    const handleBarcodeScan = async (event) => {
      clearTimeout(typingTimeout);
      const scannedValue = event.key;
      setScannedCode2((prevCode) => prevCode + scannedValue);
      const timeout = setTimeout(() => {}, 200);

      setTypingTimeout(timeout);

      if (scannedValue === "Enter") {
        await addScanItem2();
      }
    };
    document.addEventListener("keypress", handleBarcodeScan);

    return () => {
      document.removeEventListener("keypress", handleBarcodeScan);
    };
  }, [scannedCode2, typingTimeout]);

  const imageCallback = (field, file) => {
    formik.setFieldValue(field, file);
  };

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="content">
      <Modal isOpen={modalImg} toggle={() => setModalImg(false)}>
        <Card className="text-center">
          <CardHeader tag="h3">Image Upload</CardHeader>
          <hr />
          <CardBody>
            <ImageUpload
              field="PaymentImageList"
              imageCallback={imageCallback}
              perviouseUpload={formik.values.PaymentImageList}
            />
          </CardBody>
        </Card>
      </Modal>
      <Form>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Date</Label>
              <Input
                type="text"
                disabled
                plaintext
                className="text-white"
                value={moment(new Date()).format("DD/MM/YYYY")}
              />
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            {orderSouceList?.length > 0 && (
              <FormGroup>
                <Label>Order Source</Label>
                <Input
                  name="OrderSourceID"
                  type="select"
                  onChange={formik.handleChange}
                  defaultValue={orderSouceList[0]?.ID}
                >
                  {orderSouceList?.length > 0 &&
                    orderSouceList?.map((item) => (
                      <option value={item.ID}>{item.Attribute}</option>
                    ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.OrderSourceID
                    ? formik.errors.OrderSourceID
                    : null}
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Name</Label>
              <Input
                className={` ${
                  formik.errors.UserDetail && formik.errors.UserDetail.Name
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserDetail.Name"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.UserDetail.Name}
              />
              <div className="text-danger">
                {formik.errors.UserDetail && formik.errors.UserDetail.Name}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            {deliveryList && deliveryList.length > 0 && (
              <FormGroup>
                <Label>Delivery Method</Label>
                <Input
                  name="DeliveryMethodID"
                  type="select"
                  onChange={formik.handleChange}
                >
                  {deliveryList.map((i) => (
                    <option value={i.ID} key={i.ID}>
                      {i.Attribute}
                    </option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.DeliveryMethodID
                    ? formik.errors.DeliveryMethodID
                    : null}
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Email</Label>
              <div className="d-flex">
                <Input
                  className={` ${
                    formik.errors.UserDetail && formik.errors.UserDetail.Email
                      ? "is-invalid"
                      : "is-valid"
                  }`}
                  name="UserDetail.Email"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.UserDetail.Email}
                />
              </div>
              <div className="text-danger">
                {formik.errors.UserDetail && formik.errors.UserDetail.Email
                  ? formik.errors.UserDetail && formik.errors.UserDetail.Email
                  : null}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            {paymentList && paymentList.length > 0 && (
              <FormGroup>
                <Label>Payment Method</Label>
                <div className="d-flex">
                  <Input
                    name="PaymentMethodID"
                    type="select"
                    onChange={formik.handleChange}
                    value={formik.values.PaymentMethodID}
                  >
                    <option value="">-</option>
                    {paymentList.map((method) => (
                      <option value={method.ID}>{method.Name}</option>
                    ))}
                  </Input>
                  <Button
                    className="btn-icon my-0 ml-2"
                    type="button"
                    onClick={() => setModalImg(true)}
                  >
                    <i className="fa fa-cloud-upload" />
                  </Button>
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Contact</Label>
              <Input
                className={` ${
                  formik.errors.UserDetail && formik.errors.UserDetail.Contact
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserDetail.Contact"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.UserDetail.Contact}
              />
              <div className="text-danger">
                {formik.errors.UserDetail && formik.errors.UserDetail.Contact
                  ? formik.errors.UserDetail && formik.errors.UserDetail.Contact
                  : null}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Shipping Fee</Label>
              <Input
                className={` ${
                  formik.errors.ShippingFee ? "is-invalid" : "is-valid"
                }`}
                name="ShippingFee"
                type="number"
                step="0.01"
                onChange={formik.handleChange}
                value={formik.values.ShippingFee}
              />
              <div className="text-danger">
                {formik.errors.ShippingFee ? formik.errors.ShippingFee : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label>Shipping Address</Label>
          <Row>
            <Col md="6" xs="12">
              <Input
                className={` ${
                  formik.errors.UserAddress &&
                  formik.errors.UserAddress.FirstLine
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.FirstLine"
                type="text"
                placeholder="Address 1"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.FirstLine}
              />
              <div className="text-danger">
                {formik.errors.UserAddress &&
                  formik.errors.UserAddress.FirstLine}
              </div>
            </Col>
            <Col md="6" xs="12">
              <Input
                className={` ${
                  formik.errors.UserAddress &&
                  formik.errors.UserAddress.SecondLine
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.SecondLine"
                type="text"
                placeholder="Address 2"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.SecondLine}
              />
              <div className="text-danger">
                {formik.errors.UserAddress &&
                  formik.errors.UserAddress.SecondLine}
              </div>
            </Col>
          </Row>
          <Row className="mt-0 mt-sm-2">
            <Col md="3" xs="12" sm="6">
              <Label>Postcode</Label>
              <Input
                className={` ${
                  formik.errors.UserAddress &&
                  formik.errors.UserAddress.Postcode
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.Postcode"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.Postcode}
                placeholder="Postcode / Zip"
              />
              <div className="text-danger">
                {formik.errors.UserAddress &&
                  formik.errors.UserAddress.Postcode}
              </div>
            </Col>
            <Col md="3" xs="12" sm="6">
              <Label>State</Label>
              <Input
                className={` ${
                  formik.errors.UserAddress && formik.errors.UserAddress.State
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.State"
                type="select"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.State}
                placeholder="State"
              >
                <option value="">-</option>
                {state.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </Input>
              <div className="text-danger">
                {formik.errors.UserAddress && formik.errors.UserAddress.State}
              </div>
            </Col>
            <Col md="3" xs="12" sm="6">
              <Label>City</Label>
              <Input
                className={` ${
                  formik.errors.UserAddress && formik.errors.UserAddress.City
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.City"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.City}
                placeholder="City"
              />
              <div className="text-danger">
                {formik.errors.UserAddress && formik.errors.UserAddress.City}
              </div>
            </Col>
            <Col md="3" xs="12" sm="6">
              <Label>Country</Label>
              <Input
                className={` ${
                  formik.errors.UserAddress && formik.errors.UserAddress.Country
                    ? "is-invalid"
                    : "is-valid"
                }`}
                name="UserAddress.Country"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.UserAddress.Country}
                placeholder="Country"
                disabled
              />
              <div className="text-danger">
                {formik.errors.UserAddress && formik.errors.UserAddress.Country}
              </div>
            </Col>
          </Row>
        </FormGroup>
        <Row>
          <Col md="6" xs="12">
            {BatchList && BatchList.length > 0 ? (
              <FormGroup>
                <Label>Batch</Label>
                <Input
                  type="select"
                  name="LiveBatchID"
                  onChange={formik.handleChange}
                  value={formik.values.LiveBatchID}
                >
                  <option value="">-</option>
                  {BatchList.map((i) => (
                    <option value={i.ID}>{i.batchName}</option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.LiveBatchID ? formik.errors.LiveBatchID : null}
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>Batch</Label>
                <Input type="text" value="No Batch Created" disabled />
              </FormGroup>
            )}
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Locked Item</Label>
              <Row>
                <Col className="d-flex">
                  <Input
                    placeholder="Locked No"
                    type="text"
                    onChange={(e) => {
                      setLockedCode(e.target.value);
                    }}
                    value={lockedCode}
                  />
                  <Button
                    disabled={formik.values.LiveBatchID === ""}
                    onClick={() => addScanItem("locked")}
                    className="my-0"
                  >
                    Add
                  </Button>
                </Col>
                <Col className="d-flex">
                  <Input
                    placeholder="Product SN"
                    type="text"
                    onChange={(e) => {
                      setScannedCode(e.target.value);
                    }}
                    value={scannedCode}
                  />
                  <Button
                    disabled={formik.values.LiveBatchID === ""}
                    onClick={() => addScanItem("scanned")}
                    className="my-0"
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <BasicTable
          progressPending={isLoadingTableSN || isLoadingTableNo}
          progressComponent={
            <div className="py-5">
              <Spinner />
            </div>
          }
          data={productSelected}
          columns={[
            {
              name: "",
              // eslint-disable-next-line react/no-unstable-nested-components
              selector: (_, idx) => (
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => {
                    productSelected.splice(idx, 1);
                    setProductSelected([...productSelected]);
                  }}
                >
                  <span aria-hidden="true" className="text-white">
                    &times;
                  </span>
                </button>
              ),
            },
            {
              name: "No",
              selector: (row) => row.ProductNo,
              sortable: true,
            },
            {
              name: "SN",
              selector: (row) => row.VariationSKU,
              sortable: true,
            },
            {
              name: "Name",
              selector: (row) => row.Name,
              sortable: true,
            },
            {
              name: "SKU",
              selector: (row) => row.ProductSKU,
              sortable: true,
            },
            {
              name: "Weight",
              selector: (row) => row.Weight,
              sortable: true,
            },
            {
              name: "Size",
              selector: (row) => row.Size,
              sortable: true,
            },
            {
              name: "Discounted Price",
              selector: (row) => row.Price,
              sortable: true,
            },
          ]}
        />
        {/* {formik.values.ScanProductIDList && ( */}
        <div className="d-flex justify-content-end mt-3">
          <Button
            className="button-dark-template"
            disabled={
              Object.values(formik.errors).length > 0 ||
              productSelected.length <= 0 ||
              submitting === true
            }
            onClick={() => {
              setSubmitting(true);
              formik.handleSubmit();
            }}
          >
            {formik.isSubmitting ? <Spinner /> : "Process Order"}
          </Button>
        </div>
        {/* )} */}
      </Form>
    </div>
  );
}

export default CreateInvoice;
