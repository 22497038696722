/* eslint-disable */
/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  CardTitle,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "react-query";

function EditProfile(props) {
  const { setMessage, setVisible, shopData, shopID } = props;
  const Axios = useContext(ApiContext);
  const [smallNotice, setSmallNotice] = useState({
    open: false,
    field: "",
  });

  const [profileEdit, setProfileEdit] = useState(false);
  const [addressEdit, setAddressEdit] = useState(false);

  const validationSchema = Yup.object().shape({
    Status: Yup.string().required("Shop Status is required"),
    Name: Yup.string().required("Fullname is required"),
    Company: Yup.string().required("Company is required"),
    Description: Yup.string().required("Description is required"),
    ProductShippingFee: Yup.number().required("ShippingFee is required"),
  });

  const validationAddress = Yup.object().shape({
    Name: Yup.string().required("Fullname is required"),
    ContactNumber: Yup.string()
      .required("Contact is required")
      .matches(/^(\+?6?01)[0-46-9]-?[0-9]{7,8}$/, "Contact is invalid"),
    FirstLine: Yup.string().required("Address is required"),
    SecondLine: Yup.string(),
    Postcode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    State: Yup.string().required("State is required"),
  });

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };
  const { mutate, isLoading } = useMutation(
    (data2) => {
      if (Object.values(data2).every((i) => i !== "")) {
        Axios.put(`/shop/${shopID}`, {
          Status: data.Status,
          Name: data.Name,
          Company: data.Company,
          Description: data.Description,
          ProductShippingFee: data.ProductShippingFee,
        });
      }
    },
    {
      onError: async (err) => {
        let msg = "";
        err.response.data.forEach((element) => {
          msg += `${element.MESSAGE}\n`;
        });
        setMessage({
          data: msg,
          type: "alert-danger",
        });
        onShowAlert();
      },

      onSuccess: async (res) => {
        setMessage({
          data: `${res.data.MESSAGE}`,
          type: "alert-success",
        });
        onShowAlert();
      },
    }
  );
  const formik = useFormik({
    initialValues: {
      Status: shopData.data.Status,
      Name: shopData.data.Name,
      Company: shopData.data.Company,
      Description: shopData.data.Description,
      ProductShippingFee: shopData.data.ProductShippingFee,
    },
    validationSchema,

    onSubmit: (data2) => {
      setSmallNotice({ open: false, field: "" });
      mutate(data2);
    },
  });

  const formik2 = useFormik({
    initialValues: {
      Name: shopData.data.Address.Name,
      ContactNumber: shopData.data.Address.ContactNumber,
      FirstLine: shopData.data.Address.FirstLine,
      SecondLine: shopData.data.Address.SecondLine,
      Postcode: shopData.data.Address.Postcode,
      State: shopData.data.Address.State,
    },
    validationSchema: validationAddress,

    onSubmit: (data) => {
      setSmallNotice({ open: false, field: "" });
      const data3 = {
        Name: data.Name,
        ContactNumber: data.ContactNumber,
        FirstLine: data.FirstLine,
        SecondLine: data.SecondLine,
        Postcode: data.Postcode,
        State: data.State,
      };

      Axios.put(`/shop/${shopID}/address`, data3)
        .then((res) => {
          if (res.status === 200) {
            setMessage({
              data: `${res.data.MESSAGE}`,
              type: "alert-success",
            });
            onShowAlert();
          }
        })
        .catch((err) => {
          let msg = "";
          err.response.data.forEach((element) => {
            msg += `${element.MESSAGE}\n`;
          });
          setMessage({
            data: msg,
            type: "alert-danger",
          });
          onShowAlert();
        });
      onShowAlert();
    },
  });

  return (
    <div className="content">
      <Form>
        <Card className="card-dark">
          <CardHeader>
            <FormGroup className="pb-0">
              {!profileEdit ? (
                <>
                  <a
                    href="#edit"
                    className="float-right font-weight-normal"
                    onClick={() => setProfileEdit(true)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true" />
                    Edit
                  </a>
                  <CardTitle className="font-weight-bold" tag="h5">
                    Profile
                  </CardTitle>
                </>
              ) : (
                <CardTitle className="font-weight-bold" tag="h5">
                  Edit Profile
                </CardTitle>
              )}
            </FormGroup>
          </CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <Label htmlFor="Company">Company</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik.errors.Company ? "is-invalid" : "is-valid"
                      }`}
                      name="Company"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.Company}
                      plaintext={!profileEdit}
                      readOnly={!profileEdit}
                    />
                    <div className="text-danger">
                      {profileEdit && formik.errors.Company
                        ? formik.errors.Company
                        : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="px-1" md="6">
                  <FormGroup>
                    <Label>NAME</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik.errors.Name ? "is-invalid" : "is-valid"
                      }`}
                      name="Name"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.Name}
                      plaintext={!profileEdit}
                      readOnly={!profileEdit}
                    />
                    <div className="text-danger">
                      {profileEdit && formik.errors.Name
                        ? formik.errors.Name
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label>About Shop</Label>
                    <Input
                      className={!profileEdit ? "text-white" : null}
                      name="Description"
                      type="textarea"
                      cols="80"
                      rows="4"
                      onChange={formik.handleChange}
                      value={formik.values.Description}
                      plaintext={!profileEdit}
                      readOnly={!profileEdit}
                    />
                    <div className="text-danger">
                      {profileEdit && formik.errors.Description
                        ? formik.errors.Description
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-1" md="6">
                  <FormGroup>
                    <Label>Shopping Fee</Label>
                    <InputGroup className="b-0">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText
                          className={!profileEdit ? "text-white" : null}
                        >
                          RM&nbsp;
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className={!profileEdit ? "text-white" : null}
                        name="ProductShippingFee"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.ProductShippingFee}
                        plaintext={!profileEdit}
                        readOnly={!profileEdit}
                      />
                    </InputGroup>
                    <div className="text-danger">
                      {profileEdit && formik.errors.ProductShippingFee
                        ? formik.errors.ProductShippingFee
                        : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col className="px-1" md="6">
                  <FormGroup>
                    <Label htmlFor="Status">Status</Label>
                    {profileEdit ? (
                      <Input
                        className={
                          formik.errors.Status ? "is-invalid" : "is-valid"
                        }
                        type="select"
                        name="Status"
                        onChange={formik.handleChange}
                        value={formik.values.Status}
                      >
                        <option defaultValue>Choose Status</option>
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Input>
                    ) : (
                      <Input
                        className={`${!profileEdit ? "text-white" : null} ${
                          formik.errors.Status ? "is-invalid" : "is-valid"
                        }`}
                        type="select"
                        name="Status"
                        onChange={formik.handleChange}
                        value={formik.values.Status}
                        readOnly={!profileEdit}
                        plaintext={!profileEdit}
                      ></Input>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              {profileEdit && (
                <Row className="text-left pt-2 font-weight-bold justify-content-end">
                  <Col sm="9">
                    <div className="text-center">
                      <Button
                        className="mt-3 button-dark-template"
                        disabled={
                          isLoading || Object.values(formik.errors).length > 0
                        }
                        isLoading
                        onClick={() => {
                          setSmallNotice({ open: true, field: "profile" });
                        }}
                      >
                        Save Edit
                      </Button>

                      <Button
                        className=" ml-3 mt-3 button-template"
                        onClick={() => setProfileEdit(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              <hr
                style={{ borderTop: "2px solid grey" }}
                className="pt-2 pb-2"
              />
              <FormGroup className="pb-0">
                {!addressEdit ? (
                  <>
                    <a
                      href="#edit"
                      className="float-right font-weight-normal"
                      onClick={() => setAddressEdit(true)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true" />
                      Edit
                    </a>
                    <CardTitle className="font-weight-bold" tag="h5">
                      Address
                    </CardTitle>
                  </>
                ) : (
                  <CardTitle className="font-weight-bold" tag="h5">
                    Edit Address
                  </CardTitle>
                )}
              </FormGroup>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="Name">Name</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.Name ? "is-invalid" : "is-valid"
                      }`}
                      name="Name"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.Name}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.Name
                        ? formik2.errors.Name
                        : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="ContactNumber">ContactNumber</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.ContactNumber ? "is-invalid" : "is-valid"
                      }`}
                      name="ContactNumber"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.ContactNumber}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.ContactNumber
                        ? formik2.errors.ContactNumber
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="FirstLine">FirstLine</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.FirstLine ? "is-invalid" : "is-valid"
                      }`}
                      name="FirstLine"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.FirstLine}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.FirstLine
                        ? formik2.errors.FirstLine
                        : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="SecondLine">SecondLine</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.SecondLine ? "is-invalid" : "is-valid"
                      }`}
                      name="SecondLine"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.SecondLine}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.SecondLine
                        ? formik2.errors.SecondLine
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="Postcode">Postcode</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.Postcode ? "is-invalid" : "is-valid"
                      }`}
                      name="Postcode"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.Postcode}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.Postcode
                        ? formik2.errors.Postcode
                        : null}
                    </div>
                  </FormGroup>
                </Col>
                <Col md="6">
                  <FormGroup>
                    <Label htmlFor="State">State</Label>
                    <Input
                      className={`${!profileEdit ? "text-white" : null} ${
                        formik2.errors.State ? "is-invalid" : "is-valid"
                      }`}
                      name="State"
                      type="text"
                      onChange={formik2.handleChange}
                      value={formik2.values.State}
                      plaintext={!addressEdit}
                      readOnly={!addressEdit}
                    />
                    <div className="text-danger">
                      {addressEdit && formik2.errors.State
                        ? formik2.errors.State
                        : null}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              {addressEdit && (
                <Row className="text-left pt-2 font-weight-bold justify-content-end">
                  <Col sm="9">
                    <div className="text-center">
                      <Button
                        className="mt-3 button-dark-template"
                        disabled={
                          isLoading || Object.values(formik2.errors).length > 0
                        }
                        isLoading
                        onClick={() => {
                          setSmallNotice({ open: true, field: "password" });
                        }}
                      >
                        Save Edit
                      </Button>

                      <Button
                        className=" ml-3 mt-3 button-template"
                        onClick={() => setAddressEdit(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </Form>
      <Modal
        size="sm"
        isOpen={smallNotice.open}
        toggle={() => setSmallNotice({ open: false, field: "" })}
      >
        <ModalBody className="text-center">
          {smallNotice.field === "profile"
            ? "Confirm to Update Profile?"
            : "Confirm to Update Password?"}
        </ModalBody>
        <ModalFooter>
          <div className="left-side" style={{ width: "48%" }}>
            <Button
              color="primary"
              type="submit"
              onClick={
                smallNotice.field === "profile"
                  ? formik.handleSubmit
                  : formik2.handleSubmit
              }
            >
              Confirm
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side" style={{ width: "48%" }}>
            <Button
              color="secondary"
              onClick={() => setSmallNotice({ open: false, field: "" })}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
EditProfile.propTypes = {
  shopData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
};
export default EditProfile;
