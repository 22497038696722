import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardBody, Form, Input, Label } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

function EditEwallet() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const { data: ewallet } = useQuery(
    `/shop/${shopID}/Ewallet/SingleUserEwalletData/${id}`,
    async () => Axios.get(`/shop/${shopID}/Ewallet/SingleUserEwalletData/${id}`)
  );

  const validationSchema = Yup.object().shape({
    Amount: Yup.string().required("Amount is required"),
    Reference: Yup.string().required("Reference is required"),
    Description: Yup.string().required("Description is required"),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Amount: "",
      Reference: "",
      Description: "",
    },
    validationSchema,
    onSubmit: (data) => {
      const data3 = {
        Amount: data.Amount,
        Reference: data.Reference,
        Description: data.Description,
      };
      Axios.put(`shop/${shopID}/Ewallet/EditUserEwallet/${id}`, data3)
        .then((res) => {
          toast.success(`Updated successfull ${res.data.MESSAGE}`);
          // resetForm();
        })
        .catch((error) => {
          toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
        });
    },
  });

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <Card
        className="card-dark"
        style={{ minWidth: "350px", border: "1px solid #51BCDA" }}
      >
        <CardBody>
          <div>
            <p>{ewallet?.data.Name}</p>
            <p>{ewallet?.data.Email}</p>
            <p className="float-right">
              Current Wallet Balance: &nbsp;
              <CurrencyFormat
                decimal={2}
                price={ewallet?.data.Balance}
                currency="MYR"
              />
            </p>
          </div>
        </CardBody>
      </Card>
      <Card
        className="card-dark"
        style={{ minWidth: "350px", border: "1px solid #51BCDA" }}
      >
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="d-flex justify-content-center mt-3">
              <Label className="mr-1 text-white">*Amount (RM) :</Label>
              <Input
                className="col-6"
                onChange={formik.handleChange}
                placeholder="Amount"
                id="Amount"
                name="Amount"
                value={formik.values.Amount}
                type="number"
                style={{ border: "1px solid gray" }}
              />
            </div>
            <p className="text-danger text-center">
              {formik.errors.Amount ? formik.errors.Amount : null}
            </p>
            <div className="d-flex justify-content-center">
              <Label className="mr-3 text-white">Reference :</Label>
              <Input
                className="col-6"
                onChange={formik.handleChange}
                placeholder="Reference"
                id="Reference"
                name="Reference"
                value={formik.values.Reference}
                type="text"
                style={{ border: "1px solid gray" }}
              />
            </div>
            <p className="text-danger text-center">
              {formik.errors.Reference ? formik.errors.Reference : null}
            </p>
            <div className="d-flex justify-content-center">
              <Label className="mr-1 text-white">Description :</Label>
              <Input
                className="col-6"
                onChange={formik.handleChange}
                placeholder="Description"
                id="Description"
                name="Description"
                value={formik.values.Description}
                type="text"
                style={{ border: "1px solid gray" }}
              />
            </div>
            <p className="text-danger text-center">
              {formik.errors.Description ? formik.errors.Description : null}
            </p>

            <Button className="btn btn-primary float-right" type="submit">
              Update Balance
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}

export default EditEwallet;
