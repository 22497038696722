import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import FinanceOrder from "./FinanceOrder";

function FinanceMain() {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">All Orders</CardTitle>
            </CardHeader>
            <CardBody>
              <Nav justified className="mb-4">
                <NavItem
                  className={
                    activeTab === "1" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Paid
                </NavItem>
                <NavItem
                  className={
                    activeTab === "2" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Unpaid
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <FinanceOrder orderType="Paid" type="FO" />
                </TabPane>
                <TabPane tabId="2">
                  <FinanceOrder orderType="Unpaid" type="FO" />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default FinanceMain;
