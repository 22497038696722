import React, { useContext, useEffect, useState } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import AddPhoto from "components/CustomUpload/AddPhoto";
import { useFormik } from "formik";
import * as Yup from "yup";

function AddRefundImage(props) {
  const { rID, showModal, setShowModal, refetch } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [imgUpload, setImgUpload] = useState(null);
  const [file, setFile] = useState(null);

  const uploadImgCallback = (imgFile) => {
    setImgUpload(imgFile);
  };

  const uploadCombine = (img) => {
    if (img && Array.isArray(img)) {
      setFile([...img]);
    } else if (img === null) {
      setFile([]);
    }
  };

  useEffect(() => {
    uploadCombine(imgUpload);
  }, [imgUpload]);

  const validationSchema = Yup.object().shape({
    OrderQCImageList: Yup.string().required("Upload Image"),
  });
  const formik = useFormik({
    initialValues: {
      OrderQCImageList: "",
    },
    validationSchema,
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const data2 = new FormData();
    data2.append("transferred", true);
    if (file !== null) {
      file.forEach((files) => {
        data2.append("AttachmentList", files);
      });
    } else {
      data2.append("AttachmentList", []);
    }

    if (file !== null && file.length >= 1) {
      Axios.put(`/shop/${shopID}/TransferToDispatch/${rID}`, data2)
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">
                Photos have been uploaded successfully
              </p>
            </div>
          );
          refetch();
          formik.resetForm(); // Reset the form
          setShowModal({
            ...showModal,
            open: false,
          }); // Close the modal
        })
        .catch((error) => {
          toast.error(error.response.data.MESSAGE);
        });
    } else {
      toast.error("Please upload at least 1 photos");
    }
  };

  return (
    <div>
      <Label htmlFor="ImagesPostDto">
        Upload Images <span style={{ color: "red" }}> * </span>
      </Label>
      <Form onSubmit={onSubmit} className="mt-4">
        <AddPhoto uploadImgCallback={uploadImgCallback} />
        {formik.errors.OrderQCImageList ? (
          <p className="errorText text-danger">
            {formik.errors.OrderQCImageList}
          </p>
        ) : (
          ""
        )}
        <Row className="text-left pt-2 font-weight-bold ">
          <Col sm="12">
            <div className="text-center">
              <Button className="btn-sm btn-primary" type="submit">
                Upload
              </Button>

              <Button
                className="ml-3 btn-sm btn-primary"
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    open: false,
                  })
                }
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
AddRefundImage.propTypes = {
  rID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default AddRefundImage;
