/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import "./feedback.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function Feedback() {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );
  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };
  const column = [
    {
      name: "Feedback ID",
      selector: (row) => row.id,
      sortable: true,
      sortField: "_id",
      width: "300px",
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      sortField: "CreatedDate",
      width: "300px",
    },
    {
      name: "Remark",
      selector: (row) =>
        row.remark === null ? <p className="mt-3">&#8212;</p> : row.remark,
      sortable: true,
      sortField: "Name",
      width: "300px",
    },
    {
      name: "Action",
      cell: (row) => (
        <Link to={`feedback/${row.id}`}>
          <Button className="whiteButton">Details</Button>
        </Link>
      ),
    },
  ];
  const Axios = useContext(ApiContext);
  const { isSuccess, data, refetch } = useQuery(["AllFeedback"], async () =>
    Axios.get(`/admin/Feedback/GetAllFeedback`)
  );
  const [dataState, setDataState] = useState([]);
  const [filterRecords, setFilterRecords] = useState([]);
  const [activeTab, setActiveTab] = React.useState("1");
  const [rowSelect, setRowSelect] = React.useState([]);

  useEffect(() => {
    if (data) {
      let filterData = [];
      if (activeTab === "1") {
        filterData = data.data.filter((row) => row.Status === false);
      } else if (activeTab === "2") {
        filterData = data.data.filter((row) => row.Status === true);
      }

      setDataState(
        filterData.map((prop) => ({
          id: prop.ID,
          description: prop.Description,
          remark: prop.Remarks,
          validate: prop.Validated,
        }))
      );
      setFilterRecords(
        filterData.map((prop) => ({
          id: prop.ID,
          description: prop.Description,
          remark: prop.Remarks,
          validate: prop.Validated,
        }))
      );
    }
  }, [isSuccess, data, activeTab]);

  const handleFilter = (event) => {
    let newData = [];
    if (activeTab === "1") {
      newData = filterRecords.filter(
        (row) =>
          row.id.toLowerCase().includes(event.target.value.toLowerCase()) ||
          row.description
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      );
    } else if (activeTab === "2") {
      newData = filterRecords.filter(
        (row) =>
          row.id.toLowerCase().includes(event.target.value.toLowerCase()) ||
          row.description
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          row.remark.toLowerCase().includes(event.target.value.toLowerCase())
      );
    }

    setDataState(newData);
  };

  const handleChange = ({ selectedRows }) => {
    setRowSelect(selectedRows);
  };

  const delFeedback = async () => {
    rowSelect.forEach((row) => {
      Axios.delete(`/admin/Feedback/DeleteFeedback/${row.id}`)
        .then(() => {
          toast.success("Feedback Deleted");
          refetch();
        })
        .catch((error) => {
          toast.error(error.response);
        });
    });
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">Feedback</CardTitle>
              <CardBody>
                <InputGroup className="no-border" style={{ width: "350px" }}>
                  <Input
                    className="searchBar"
                    defaultValue=""
                    placeholder="Search..."
                    type="text"
                    onChange={handleFilter}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        className="nc-icon nc-zoom-split"
                        style={{ color: "white" }}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <Row>
                  <Col md="8" className="mb-0">
                    <Nav tabs style={{ justifyContent: "left" }}>
                      <NavItem className="feedbackTabs">
                        <NavLink
                          className={`feedbackTabs ${
                            activeTab === "1" ? "isActive" : ""
                          }`}
                          onClick={() => setActiveTab("1")}
                        >
                          Incomplete
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={`feedbackTabs ${
                            activeTab === "2" ? "isActive" : ""
                          }`}
                          onClick={() => setActiveTab("2")}
                        >
                          Completed
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                  <Col md="4" className="mb-0 ">
                    <Button
                      className="float-right mb-0 delButton"
                      onClick={() => delFeedback()}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <DataTable
                      columns={column}
                      data={dataState}
                      customStyles={customStyles}
                      theme="solarized"
                      highlightOnHover
                      responsive
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleChange}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <DataTable
                      columns={column}
                      data={dataState}
                      customStyles={customStyles}
                      theme="solarized"
                      highlightOnHover
                      responsive
                      pagination
                      selectableRows
                      onSelectedRowsChange={handleChange}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </CardHeader>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default Feedback;
