/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

import defaultImg from "assets/img/image.png";
import "./AddCheckedPhoto.css";

function AddPhoto(props) {
  const { uploadImgCallback } = props;

  const [imgFile, setImgFile] = React.useState([]);

  const fileInputImg = React.createRef();

  const [imagePreviewUrl, setImagePreviewUrl] = React.useState([]);

  const handleClickImg = () => {
    fileInputImg.current.click();
  };

  const handleImageChange = (e) => {
    e.preventDefault();

    const reader = new FileReader();
    const filePtr = e.target.files[0];

    reader.onloadend = () => {
      setImgFile([...imgFile, filePtr]);
      if (imagePreviewUrl === defaultImg) {
        setImagePreviewUrl([{ url: reader.result }]);
      } else {
        setImagePreviewUrl([...imagePreviewUrl, { url: reader.result }]);
      }
    };

    reader.readAsDataURL(filePtr);
    e.target.value = null;
  };

  const handleRemoveImg = (idx) => {
    imgFile.splice(idx, 1);
    imagePreviewUrl.splice(idx, 1);
    setImgFile([...imgFile]);
    setImagePreviewUrl([...imagePreviewUrl]);
  };

  useEffect(() => {
    uploadImgCallback(imgFile);
  }, [imgFile, uploadImgCallback]);

  return (
    <span className="fileinput">
      <input
        id="img"
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        ref={fileInputImg}
      />

      {[...Array(6).keys()].map((idx) => (
        <span key={idx}>
          {imagePreviewUrl[idx] !== undefined ? (
            <div className="imgPreview mr-2">
              <button
                type="button"
                className="removeImgI"
                onClick={() => handleRemoveImg(idx)}
              >
                <i className="fa fa-times-circle removeIconI" />
              </button>
              <img
                className="VimgCenter"
                alt="..."
                src={
                  imagePreviewUrl[idx] !== undefined
                    ? imagePreviewUrl[idx].url
                    : defaultImg
                }
                width="80px"
                height="80px"
              />
            </div>
          ) : (
            <Button
              id={`img${idx}`}
              className="uploadBtn mr-2 mb-2"
              onClick={() => handleClickImg(idx)}
            >
              <img alt="..." src={defaultImg} width="25px" height="25px" />
            </Button>
          )}
        </span>
      ))}
    </span>
  );
}

AddPhoto.propTypes = {
  uploadImgCallback: PropTypes.func.isRequired,
};

export default AddPhoto;
