import React, { useContext } from "react";
import { Button, Form } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function SelfPickUpdateStatus(props) {
  const { orderID, refetch } = props;
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;
  const updateselfpick = async () => {
    try {
      await Axios.put(`/shop/${shopID}/Order/CS/Order`, {
        OrderID: orderID,
        OrderStatus: "Completed-Pickup",
      });
      // Handle success
      toast.success("Update successfully!");
      refetch();
    } catch (err) {
      // Handle error
      toast.error("Failed to Update Status:", err);
    }
  };
  const formik = useFormik({
    initialValues: {
      OrderID: orderID,
      OrderStatus: "Completed-Pickup",
    },

    onSubmit: (data) => {
      updateselfpick(data);
    },
  });

  return (
    <div>
      <Form onSubmit={formik.handleSubmit}>
        <Button className="text-center w-100 mt-4 p-2 btn btn-primary">
          Picked Up
        </Button>
      </Form>
    </div>
  );
}
SelfPickUpdateStatus.propTypes = {
  orderID: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
};
export default SelfPickUpdateStatus;
