import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "../sales.css";
import Switch from "react-ios-switch/lib/Switch";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";

function UpdateMerchandiseInfo({ merchandiseID, setUpdateInfo }) {
  const [malayName, setMalayName] = useState("");
  const [malayDesp, setMalayDesp] = useState("");
  const [englishName, setMEnglishName] = useState("");
  const [englishDesp, setEnglishDesp] = useState("");
  const [sku, setSKU] = useState("");
  const [price, setPrice] = useState("");
  const [count, setCount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [checked, setChecked] = useState(false);
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const validationSchema = Yup.object().shape({
    MalName: Yup.string().required("MalayName Required"),
    EngName: Yup.string().required("MalayName Required"),
    MalDescription: Yup.string().required("MalDescription Required"),
    EngDescription: Yup.string().required("EngDescription Required"),
    StockCount: Yup.string().required("StockCount Required"),
    Status: Yup.string().required("MalayName Required"),
    SKU: Yup.string().required("SKU Required"),
    Price: Yup.string().required("Price Required"),
  });
  const formik = useFormik({
    initialValues: {
      Status: "",
      StockCount: "",
      Price: "",
      MalName: "",
      EngName: "",
      SKU: "",
      MalDescription: "",
      EngDescription: "",
      AttachmentList: "",
    },
    validationSchema,
  });
  const onSubmit = (e) => {
    e.preventDefault();

    const data = {};

    if (malayName !== "") {
      data.MalName = malayName;
    }
    if (englishName !== "") {
      data.EngName = englishName;
    }
    if (malayDesp !== "") {
      data.MalDescription = malayDesp;
    }
    if (englishDesp !== "") {
      data.EngDescription = englishDesp;
    }
    if (sku !== "") {
      data.SKU = sku;
    }
    if (price !== "") {
      data.Price = price;
    }
    if (count !== "") {
      data.StockCount = count;
    }
    if (remarks !== "") {
      data.Remarks = remarks;
    }
    if (checked !== "") {
      data.Status = checked;
    }

    Axios.put(
      `/shop/${shopID}/Merchandise/UpdateSingleMerchandise/${merchandiseID}`,
      data
    )
      .then((res) => {
        setUpdateInfo(false);
        toast.success(res.data.MESSAGE);
        formik.resetForm(); // Reset the form
      })
      .catch((error) => {
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <div className="content px-3 w-100">
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Update Merchandise</CardTitle>
        </CardHeader>
        <CardBody>
          <p className="bg-info p-2 pl-4">Merchandise Information</p>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col md="6">
                <Row className="ml-1">
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Malay Name:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 l-3 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <Input
                      type="text"
                      value={malayName}
                      onChange={(e) => setMalayName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row className="ml-1">
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Malay Description:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 l-3 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <Input
                      type="text"
                      value={malayDesp}
                      onChange={(e) => setMalayDesp(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row className="ml-1">
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    English Name:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 l-3 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <Input
                      type="text"
                      value={englishName}
                      onChange={(e) => setMEnglishName(e.target.value)}
                    />
                    {formik.errors.MalayName ? (
                      <p className="errorText text-danger">
                        {formik.errors.englishName}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="ml-1">
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    English Description:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 l-3 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <Input
                      type="text"
                      value={englishDesp}
                      onChange={(e) => setEnglishDesp(e.target.value)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Status:
              </Col>
              <Col
                md="8"
                className="p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <div>
                  <Switch
                    checked={checked}
                    onChange={() => {
                      setChecked(!checked);
                    }}
                  />
                  {!checked ? <span>Deactivated</span> : <span>Activated</span>}
                </div>
                {formik.errors.ShopSKU ? (
                  <p className="errorText text-danger">
                    {formik.errors.ShopSKU}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                SKU:
              </Col>
              <Col
                md="8"
                className="p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <Input
                  type="text"
                  value={sku}
                  onChange={(e) => setSKU(e.target.value)}
                />
                {formik.errors.ShopSKU ? (
                  <p className="errorText text-danger">
                    {formik.errors.ShopSKU}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Stock Count:
              </Col>
              <Col
                md="8"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <Input
                  type="number"
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Price(RM):
              </Col>
              <Col
                md="8"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <Input
                  type="text"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Remark:
              </Col>
              <Col
                md="8"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <Input
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Col>
            </Row>
            <Row className="text-left pt-2 font-weight-bold ">
              <Col sm="12">
                <div className="text-center">
                  <Button className="btn-md btn-success" type="submit">
                    Update Merchandise
                  </Button>
                  <Button
                    className="btn-md btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      setUpdateInfo(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
}
UpdateMerchandiseInfo.propTypes = {
  merchandiseID: PropTypes.string.isRequired,
  setUpdateInfo: PropTypes.func.isRequired,
};
export default UpdateMerchandiseInfo;
