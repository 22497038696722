/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Button,
  Card,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import "./order.css";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import CurrencyFormat from "react-currency-format";

createTheme(
  "solarized",
  {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#343a40",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "white",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

function FinanceOrder(props) {
  const { orderType, type } = props;

  const column = [
    {
      name: "Order ID",
      selector: (row) => row.orderID,
      sortable: true,
      sortField: "_id",
      width: "240px",
    },
    {
      name: "Invoice",
      selector: (row) => row.invoice,
      sortable: true,
      sortField: "Invoice",
    },
    {
      name: "Date",
      selector: (row) => row.orderDate,
      sortable: true,
      sortField: "CreatedDate",
      width: "170px",
    },
    {
      name: "Customer",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Customer",
      width: "200px",
    },
    {
      name: "Total",
      sortable: true,
      sortField: "Total",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.total}
          displayType="text"
          thousandSeparator
          prefix="RM "
        />
      ),
      width: "150px",
    },
    {
      name: "Promo Code",
      sortable: true,
      sortField: "Promo",
      selector: (row) => row.promo,
      width: "180px",
      omit: type !== "FO",
    },
    {
      name: "Payment Method",
      sortable: true,
      sortField: "PaymentGateway",
      selector: (row) => row.payment,
      width: "210px",
    },
    {
      name: "Delivery Type",
      sortable: true,
      sortField: "deliveryType",
      selector: (row) => row.delivery,
      width: "210px",
    },
    {
      name: "Order Status",
      sortable: true,
      selector: (row) => row.status,
      width: "200px",
    },
  ];

  const { order } = useParams();

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [pageParam, setPageParam] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [sort, setSort] = useState("DESC");
  const [sortBy, setSortBy] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [source, setSource] = useState("WebSite");

  const { isSuccess, data, refetch } = useQuery(
    [
      "AllOrder",
      pageParam,
      limitPage,
      sort,
      sortBy,
      fromDate,
      toDate,
      source,
      orderType,
    ],
    async () =>
      Axios.get(`/shop/${shopID}/Order/Finance`, {
        params: {
          dateFrom: fromDate,
          dateTo: toDate,
          order: sort,
          orderBy: sortBy,
          orderSource: source,
          page: pageParam,
          limitPerPage: limitPage,
          paymentStatus: orderType,
        },
      })
  );

  const [dataState, setDataState] = useState([]);
  const [filterRecords, setFilterRecords] = useState([]);
  const [totalRow, setTotalRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [defaultDate, setDefaultDate] = useState([]);
  const [windowSize, setWindowSize] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (order === "live") {
      setSource("Live");
      toggle("2");
    } else if (order === "website") {
      setSource("WebSite");
      toggle("1");
    }
  }, [order]);

  useEffect(() => {
    const todayDate = new Date();
    const prevDate = new Date(todayDate);
    prevDate.setDate(todayDate.getDate() - 7);

    setToDate(todayDate);
    setFromDate(prevDate);

    const date1 = todayDate.toISOString().split("T")[0];
    const date2 = prevDate.toISOString().split("T")[0];
    setDefaultDate({ Today: date1, Prev: date2 });
  }, []);

  useEffect(() => {
    if (data) {
      const temp = [];
      if (data.data.Result.OrderList.length !== 0) {
        data.data.Result.OrderList.map((prop) => {
          if (type === "FO" && prop.TIID === "" && prop.RRID === "") {
            temp.push({
              id: prop.ID,
              orderID: `#${prop.ID}`,
              invoice: prop.Invoice,
              orderDate: new Date(prop.OrderDate).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
              name: prop.Name,
              delivery: prop.DeliveryType,
              status: prop.ShopOrderStatus,
              total: `RM ${prop.TotalOrderCost}`,
              payment: prop.PaymentGateway,
              promo: prop.CouponApplied,
            });
          } else if (type === "RR" && prop.RRID !== "" && prop.TIID === "") {
            temp.push({
              id: prop.ID,
              RRID: prop.RRID,
              orderID: `#${prop.ID}`,
              invoice: prop.Invoice,
              orderDate: new Date(prop.OrderDate).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
              name: prop.Name,
              delivery: prop.DeliveryType,
              status: prop.ShopOrderStatus,
              total: `RM ${prop.TotalOrderCost}`,
              payment: prop.PaymentGateway,
            });
          } else if (type === "TI" && prop.RRID === "" && prop.TIID !== "") {
            temp.push({
              id: prop.ID,
              TIID: prop.TIID,
              orderID: `#${prop.ID}`,
              invoice: prop.Invoice,
              orderDate: new Date(prop.OrderDate).toLocaleString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              }),
              name: prop.Name,
              delivery: prop.DeliveryType,
              status: prop.ShopOrderStatus,
              total: `RM ${prop.TotalOrderCost}`,
              payment: prop.PaymentGateway,
            });
          }
          setTotalRow(temp.length);
          setDataState(temp);
          setFilterRecords(temp);
          return prop;
        });
      } else {
        setDataState([]);
        setFilterRecords([]);
      }
    }
  }, [isSuccess, data]);

  const handleFilter = (event) => {
    const newData = filterRecords.filter(
      (row) =>
        row.id.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.invoice.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.orderDate
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        row.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.status.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.total.toLowerCase().includes(event.target.value.toLowerCase()) ||
        row.payment.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataState(newData);
  };

  const handleChange = ({ selectedRows }) => {
    setSelectedRow(selectedRows);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPageParam(page);
    setLoading(false);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setLoading(true);
    setLimitPage(newPerPage);
    setLoading(false);
  };

  const handleOnExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(selectedRow);

    XLSX.utils.book_append_sheet(wb, ws, "AllOrder");

    XLSX.writeFile(wb, "AllOrder.xlsx");
  };

  const handleSort = async (columns, sortDirection) => {
    setLoading(true);
    setSort(sortDirection.toUpperCase());
    setSortBy(columns.sortField);
    setLoading(false);
  };

  const handlePaid = () => {
    if (type === "FO") {
      const temp = [];
      selectedRow.forEach((orders) => temp.push(orders.id));
      Axios.put(`/shop/${shopID}/Order/Finance/Order`, {
        OrderID: temp,
        Status: "Order Paid",
      })
        .then((res) => {
          toast.success(`Updated successfull ${res.data.MESSAGE}`);
          refetch();
        })
        .catch((error) => {
          toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
        });
    }
    if (type === "RR") {
      selectedRow.forEach((orders) =>
        Axios.put(`/shop/${shopID}/RequestApproval/${orders.RRID}`, {
          Status: "Approved",
          ShipBackFeesPayment: true,
        })
          .then((res) => {
            toast.success(`Updated successfull ${res.data.MESSAGE}`);
            refetch();
          })
          .catch((error) => {
            toast.error(`Updated failed! ${error.response.data.MESSAGE}`);
          })
      );
      refetch();
    }

    if (type === "TI") {
      selectedRow.forEach((orders) =>
        Axios.put(
          `/shop/${shopID}/TradeInShipBackPaymentReceived/${orders.TIID}`
        )
          .then((res) => {
            toast.success(`${res.data.MESSAGE}`);
            refetch();
          })
          .catch((error) => {
            toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
          })
      );
      refetch();
    }
  };

  const handleFailed = () => {
    const temp = [];
    selectedRow.forEach((orders) => temp.push(orders.id));

    Axios.put(`/shop/${shopID}/Order/Finance/Order`, {
      OrderID: temp,
      Status: "Payment Failed",
    })
      .then((res) => {
        toast.success(`Updated successfull ${res.data.MESSAGE}`);
        refetch();
        setSelectedRow([]);
      })
      .catch((error) => {
        toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
      });
  };

  React.useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setWindowSize(screenWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Card className="card-dark cardSplit p-3">
      <Row>
        <Col md="4">
          <InputGroup className="no-border" style={{ width: "100%" }}>
            <Input
              className="searchBar"
              defaultValue=""
              placeholder="Search..."
              type="text"
              onChange={handleFilter}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <i
                  className="nc-icon nc-zoom-split"
                  style={{ color: "white" }}
                />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col md="8">
          <Row>
            <Col md="3" xs="5">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  defaultValue={defaultDate.Prev}
                  onChange={(e) => setFromDate(new Date(e.target.value))}
                />
              </InputGroup>
            </Col>
            {windowSize >= 991 ? (
              <p className="mt-2 ml-4">To</p>
            ) : (
              <p className="ml-2" />
            )}

            <Col>
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  defaultValue={defaultDate.Today}
                  onChange={(e) => setToDate(new Date(e.target.value))}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <Nav justified className="mb-4">
            {type === "FO" && (
              <>
                <NavItem
                  className={activeTab === "1" ? "active alt-tab" : "alt-tab"}
                  onClick={() => {
                    setSource("WebSite");
                    toggle("1");
                  }}
                >
                  Website
                </NavItem>
                <NavItem
                  to="/order=live"
                  className={activeTab === "2" ? "active alt-tab" : "alt-tab"}
                  onClick={() => {
                    setSource("Live");
                    toggle("2");
                  }}
                >
                  Live
                </NavItem>
              </>
            )}
          </Nav>
        </Col>
        <Col md="4">
          <Row className="float-right">
            <Col xs="6">
              <CSVLink
                className="float-right orderBtn btn"
                style={{ width: "150px" }}
                data={selectedRow}
                filename="All_Order.csv"
              >
                Export CSV
              </CSVLink>
            </Col>
            <Col xs="6">
              <Button
                className="float-right  orderBtn"
                style={{ width: "150px" }}
                onClick={handleOnExportExcel}
              >
                Export Excel
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <DataTable
        columns={column}
        data={dataState}
        customStyles={customStyles}
        theme="solarized"
        highlightOnHover
        selectableRows
        responsive
        onSelectedRowsChange={handleChange}
        progressPending={loading}
        pagination
        paginationServer
        paginationTotalRows={totalRow}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        onSort={handleSort}
        sortServer
      />
      <div>
        <Button
          className="failed float-right ml-3"
          style={{ width: "170px" }}
          onClick={handleFailed}
          disabled={selectedRow.length === 0}
        >
          Payment Failed
        </Button>
        {orderType === "Paid" ? (
          <Button
            className="success float-right"
            style={{ width: "150px" }}
            onClick={handlePaid}
            disabled={selectedRow.length === 0}
          >
            Paid
          </Button>
        ) : (
          ""
        )}
      </div>
    </Card>
  );
}
FinanceOrder.propTypes = {
  orderType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default FinanceOrder;
