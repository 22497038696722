import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Button, Form, Input, Label } from "reactstrap";
import { useQuery } from "react-query";

function OrderLogistisUpdate(props) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { orderID, showLogisticModal, setShowLogisticModal, tableRefetch } =
    props;
  const { data: logisticDt, refetch } = useQuery(
    `/shop/${shopID}/LogisticList`,
    async () => Axios.get(`/shop/${shopID}/LogisticList`)
  );
  useEffect(() => {
    refetch();
  }, [refetch]);
  const validationSchema = Yup.object().shape({
    Logistic: Yup.string().required("Logistic is required"),
    TrackingNo: Yup.string().required("TrackingNo is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Logistic: "",
      TrackingNo: "",
    },
    validationSchema,
    onSubmit: (data) => {
      const data3 = {
        Logistic: data.Logistic,
        TrackingNo: data.TrackingNo,
      };
      Axios.put(`shop/${shopID}/Order/QC/Order/${orderID}`, data3)
        .then((res) => {
          toast.success(`Updated successfull ${res.data.MESSAGE}`);
          setShowLogisticModal({
            ...showLogisticModal,
            open: !showLogisticModal.open,
          });
          tableRefetch();
        })
        .catch((error) => {
          toast.error(`Updated failed! ${error.response.data[0].MESSAGE}`);
        });
    },
  });

  return (
    <div>
      <h5 className="m-2 text-center mb-4">Update Logistic Information </h5>
      <Form onSubmit={formik.handleSubmit}>
        <div className="d-flex justify-content-center mt-3">
          <Label className="mr-3">Logistic Name</Label>

          <select
            className="col-6"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "5px",
              border: "1px solid black",
            }}
            onChange={formik.handleChange}
            placeholder="Logistic"
            id="Logistic"
            name="Logistic"
            value={formik.values.Logistic}
          >
            <option value="">Select Logistic</option>
            {logisticDt?.data.map((logistic) => (
              <option value={logistic}>{logistic}</option>
            ))}
          </select>
        </div>
        <p className="text-danger text-center">
          {formik.errors.Logistic ? formik.errors.Logistic : null}
        </p>
        <div className="d-flex justify-content-center">
          <Label className="mr-3">Tracking Code</Label>

          <Input
            className="col-6"
            onChange={formik.handleChange}
            placeholder="TrackingNo"
            id="TrackingNo"
            name="TrackingNo"
            value={formik.valuesTrackingNo}
            type="text"
            style={{ border: "1px solid gray" }}
          />
        </div>
        <p className="text-danger text-center">
          {formik.errors.TrackingNo ? formik.errors.TrackingNo : null}
        </p>

        <Button className="btn btn-primary float-right" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
}

OrderLogistisUpdate.propTypes = {
  orderID: PropTypes.string.isRequired,
  setShowLogisticModal: PropTypes.func.isRequired,
  showLogisticModal: PropTypes.func.isRequired,
  tableRefetch: PropTypes.func.isRequired,
};

export default OrderLogistisUpdate;
