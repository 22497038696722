/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import "./CustomCalender.css";
import "react-calendar/dist/Calendar.css";
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from "rsuite";
import { Input } from "reactstrap";
// import moment from "moment";

const today = new Date();
const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
const lastWeekStart = new Date(
  today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
);
const last7Days = new Date(today);
last7Days.setDate(today.getDate() - 7);
// Last 30 days
const last30Days = new Date(today);
last30Days.setDate(today.getDate() - 30);
// Last 6 months to date
const startOfLast6Months = new Date(
  today.getFullYear(),
  today.getMonth() - 6,
  1
);
const endOfLast6Months = new Date(today.getFullYear(), today.getMonth(), 0);
const lastWeekEnd = new Date(
  today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
);
const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
const quarterStart = new Date(today.getFullYear(), today.getMonth() - 3);
const quarterEnd = new Date(today.getFullYear(), today.getMonth());
const yearStart = new Date(today.getFullYear() - 1, 0, 1);
const yearEnd = new Date(today.getFullYear() - 1, 11, 31);
const weekStart = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - today.getDay() + 1
);
const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
const quarterStartToDate = new Date(
  today.getFullYear(),
  Math.floor(today.getMonth() / 3) * 3,
  1
);
const yearToDate = new Date(today.getFullYear(), 0, 1);

const predefinedRanges = [
  {
    label: "Yesterday",
    value: [yesterday, today],
    placement: "left",
  },
  {
    label: "Week to date",
    value: [weekStart, today],
    placement: "left",
  },
  {
    label: "Month to date",
    value: [monthStart, today],
    placement: "left",
  },
  {
    label: "Quarter to date",
    value: [quarterStartToDate, today],
    placement: "left",
  },
  {
    label: "Year to date",
    value: [yearToDate, today],
    placement: "left",
  },
  {
    label: "Last week",
    value: [lastWeekStart, lastWeekEnd],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [last7Days, yesterday],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [last30Days, yesterday],
    placement: "left",
  },
  {
    label: "Last month",
    value: [lastMonthStart, lastMonthEnd],
    placement: "left",
  },
  {
    label: "Last Quarter",
    value: [quarterStart, quarterEnd],
    placement: "left",
  },
  {
    label: "Last Half",
    value: [startOfLast6Months, endOfLast6Months],
    placement: "left",
  },
  {
    label: "Last Year",
    value: [yearStart, yearEnd],
    placement: "left",
  },
];

function CustomCalendar(props) {
  const {
    setDateRange,
    compareCheckbox,
    isChecked,
    setRange,
    // range,
    setIsChecked,
  } = props;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [endDate2, setEndDate2] = useState(null);
  const StartDate = new Date(startDate);
  const EndDate = new Date(endDate);

  const daysInRange =
    Math.floor((EndDate - StartDate) / (1000 * 60 * 60 * 24)) + 1; // Calculate the number of days in the range

  const previousStartDate = new Date(
    StartDate.getFullYear(),
    StartDate.getMonth(),
    StartDate.getDate() - daysInRange
  ); // Subtract the number of days in the range from the start date
  const previousEndDate = new Date(
    StartDate.getFullYear(),
    StartDate.getMonth(),
    StartDate.getDate() - 1
  ); // Subtract 1 day from the start date

  const previousYearStartYear = new Date(startDate).getFullYear() - 1;
  const previousYearEndYear = new Date(endDate).getFullYear() - 1;

  const previousYearStartDate = new Date(
    previousYearStartYear,
    new Date(startDate).getMonth(),
    new Date(startDate).getDate()
  );

  const previousYearEndDate = new Date(
    previousYearEndYear,
    new Date(endDate).getMonth(),
    new Date(endDate).getDate()
  );

  const handleDateRangeSelect = (update) => {
    if (update && update.length >= 2) {
      setStartDate(update[0]);
      setEndDate(update[1]);
      setDateRange([update[0], update[1]]);
    }
  };

  const handleRangeSelect = (update) => {
    if (update && update.length >= 2) {
      setStartDate2(update[0]);
      setEndDate2(update[1]);
      setRange([update[0], update[1]]);
    }
  };
  const predefinedRanges2 = [
    {
      label: "Previous Period",
      value: [previousStartDate, previousEndDate],
      placement: "left",
    },
    {
      label: "Previous Year",
      value: [previousYearStartDate, previousYearEndDate],
      placement: "left",
    },
  ];

  useEffect(() => {
    if (!isChecked) {
      setStartDate(null);
      setEndDate(null);
      setRange(null);
    }
  }, [isChecked]);

  return (
    <div className="d-flex justify-content-between">
      <DateRangePicker
        ranges={predefinedRanges}
        showOneCalendar
        placeholder="Date Range"
        style={{ width: 300 }}
        onChange={handleDateRangeSelect}
      />
      {compareCheckbox && (
        <p className="mr-5">
          <span className="ml-3"> Compare:</span>
          <Input
            className="ml-2"
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
        </p>
      )}
      {isChecked && (
        <div className="d-flex">
          <DateRangePicker
            ranges={predefinedRanges2}
            showOneCalendar
            placeholder="Date Range2"
            style={{ width: 300 }}
            onChange={handleRangeSelect}
            startDate={startDate2}
            endDate={endDate2}
          />
        </div>
      )}
    </div>
  );
}

CustomCalendar.defaultProps = {
  isChecked: false,
  setIsChecked: undefined,
};

CustomCalendar.propTypes = {
  setDateRange: PropTypes.func.isRequired,
  compareCheckbox: PropTypes.bool.isRequired,
  isChecked: PropTypes.bool,
  setRange: PropTypes.func.isRequired,
  // range: PropTypes.string.isRequired,
  setIsChecked: PropTypes.func,
};
export default CustomCalendar;
