/* eslint-disable */
/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
// reactstrap components
import { Button, Row, Col, Modal, ModalBody, ModalFooter } from "reactstrap";
import EditProfile from "./EditProfile";
import { useQuery } from "react-query";
import BankDetails from "./BankDetails";
import ShopAvatar from "./ShopAvatar";
import ShopBanner from "./ShopBanner";
import ShopTag from "./ShopTag";
import ShopMember from "./ShopMember";

function ShopProfile() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState();
  const { isLoading, data: shopData } = useQuery(`shop/${shopID}`, async () =>
    Axios.get(`shop/${shopID}`)
  );
  if (isLoading) {
    return (
      <Col className="text-center" sm="12" md={{ size: 6, offset: 3 }}>
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }

  return (
    <div className="content">
      {message !== undefined && message && (
        <Modal size="sm" isOpen={visible} toggle={() => setVisible(false)}>
          <ModalBody className={`d-flex white-text ${message.type}`}>
            <b>{message.data}</b>
          </ModalBody>
          <ModalFooter>
            <Button
              color="link"
              type="button"
              onClick={() => setVisible(false)}
            >
              Okay
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Row>
        <Col md="4">
          <ShopAvatar
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
          <ShopTag
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
          <BankDetails
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
        </Col>
        <Col md="8">
          <EditProfile
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
          <ShopBanner
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
          <ShopMember
            setMessage={setMessage}
            setVisible={setVisible}
            shopData={shopData}
            shopID={shopID}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ShopProfile;
