/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import CurrencyFormat from "react-currency-format";
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from "reactstrap";
import PaymentContext from "services/PaymentContext";

import DataTable, { createTheme } from "react-data-table-component";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";

function SalesChart() {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { monthly } = useContext(PaymentContext);
  const { isSuccess, data } = useQuery(["FinanceSummary"], async () =>
    Axios.get(`/shop/${shopID}/Order/Finance/Summary`)
  );
  const [yearTotal, setYearTotal] = useState(0);
  const [monthData, setMonthData] = useState([]);
  const [dataState, setDataState] = useState([]);
  const hexToRGB = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  };

  const chartExample4 = {
    data: (canvas) => {
      const ctx = canvas.getContext("2d");

      const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
      gradientStroke.addColorStop(0, "#2CA8FF");
      gradientStroke.addColorStop(1, "#FFFFFF");

      const gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
      gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
      gradientFill.addColorStop(1, hexToRGB("#2CA8FF", 0.4));
      return {
        labels: monthly,
        datasets: [
          {
            label: "Sales",
            backgroundColor: gradientFill,
            borderColor: "#4acccd",
            pointHoverRadius: 2,
            pointRadius: 1,
            fill: false,
            borderWidth: 3,
            barPercentage: 1.6,
            tension: 0.4,
            data: monthData,
          },
        ],
      };
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },

        tooltips: {
          enabled: false,
        },
      },
      scales: {
        y: {
          ticks: {
            color: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#9f9f9f",
          },
        },
      },
    },
  };

  const column = [
    {
      name: "Month",
      selector: (row) => row.month,
      sortable: true,
      sortField: "month",
      width: "140px",
    },
    {
      name: "Customer",
      sortable: true,
      sortField: "customer",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.customer.toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
    },
    {
      name: "Shopee",
      sortable: true,
      sortField: "shopee",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.shopee.toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
    },
    {
      name: "Lazada",
      sortable: true,
      sortField: "lazada",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.lazada.toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
    },
    {
      name: "Live",
      sortable: true,
      sortField: "live",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.live.toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
    },
    {
      name: "Total",
      sortable: true,
      sortField: "total",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={row.total.toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setDataState(
        data.data.Result.map((prop) => ({
          month: prop.month,
          customer: prop.webSiteValue,
          shopee: prop.ShopeeValue,
          lazada: prop.LazadaValue,
          live: prop.LiveValue,
          total: prop.TotalAmount,
        }))
      );

      const temp = [];
      let tempTotal = 0;
      data.data.Result.map((monthTotal) => {
        temp.push(monthTotal.TotalAmount);
        tempTotal = monthTotal.TotalAmount + tempTotal;
        return temp;
      });
      setYearTotal(tempTotal);
      setMonthData(temp);
    }
  }, [isSuccess, data]);

  return (
    <Card className="card-dark cardSplit pl-3 pr-3 pb-3">
      <Card className="card-dark">
        <CardHeader>
          <Row>
            <Col sm="7">
              <CurrencyFormat
                renderText={(value) => (
                  <span className="numbers pull-left">{`${value}`}</span>
                )}
                decimalScale={2}
                value={yearTotal.toFixed(2)}
                displayType="text"
                thousandSeparator
                prefix="RM "
              />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <h6 className="big-title">This Years&apos;s Sales</h6>
          <Line
            data={chartExample4.data}
            options={chartExample4.options}
            height={120}
            width={500}
          />
        </CardBody>
        <CardFooter>
          <DataTable
            customStyles={customStyles}
            theme="solarized"
            columns={column}
            data={dataState}
          />
        </CardFooter>
      </Card>
    </Card>
  );
}
export default SalesChart;
