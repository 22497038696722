/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { toast } from "react-toastify";

function QCimage(props) {
  const { imageList, orderID, tableRefetch, setShowModal, showModal } = props;
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const handleRemoveImg = (id) => {
    // Implement logic to send the orderID, shopID, and imageID to the API DELETE endpoint
    Axios.delete(`/shop/${shopID}/Order/${orderID}/QC/Image/${id}`)
      .then(() => {
        // Display alert message once the request is successful
        toast.success("Image deleted ");
        setShowModal({
          ...showModal,
          open: false,
        }); // Close the modal
        tableRefetch(); // Refetch the table
      })

      .catch((error) => {
        // Handle the error if the request fails
        toast.error("Error ", error);
      });
  };

  return (
    <div>
      {imageList.map((img) => (
        <div className="imgPreview mr-2">
          <button
            type="button "
            className="removeImgI"
            onClick={() => handleRemoveImg(img.ImageID)}
          >
            <i className="fa fa-times-circle removeIconI" />
          </button>
          <img
            className="VimgCenter"
            alt="..."
            src={img.ImageUrl}
            width="80px"
            height="80px"
          />
        </div>
      ))}
    </div>
  );
}
QCimage.propTypes = {
  imageList: PropTypes.string.isRequired,
  orderID: PropTypes.string.isRequired,
  tableRefetch: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};
export default QCimage;
