/* eslint-disable new-cap */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-restricted-globals */
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { useQuery } from "react-query";
// import { Pie } from "react-chartjs-2";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import CouponLoyaltyReport from "./CouponLoyaltyReport";

function LoyaltyAnalytic() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [range, setRange] = useState([null, null]);
  const [isChecked, setIsChecked] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [agePeriod, setAgePeriod] = useState("Month");
  const [statePeriod, setStatePeriod] = useState("Month");
  const [ageChart, setAgeChart] = useState([]);
  const [stateChart, setStateChart] = useState([]);
  const [filterCoupon, setFilterCoupon] = useState([]);
  const [triggerReport, setTriggerReport] = useState(false);

  const columns = [
    {
      name: "Code",
      selector: (row) => row.Code,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Type",
      selector: (row) => row.Type,
      sortable: true,
      wrap: true,
    },
    {
      name: "ProductScope",
      selector: (row) => row.Scope,
      sortable: true,
      wrap: true,
      width: "170px",
    },
    {
      name: "SN",
      selector: (row) => row.SN,
      sortable: true,
      wrap: true,
    },
    {
      name: "Disc. Amount (RM)",
      selector: (row) => row.TotalDiscount,
      sortable: true,
      wrap: true,
      width: "190px",
    },
    {
      name: "Usage/Limit",
      selector: (row) => `${row.UsageCount}/${row.UsageLimit}`,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Redeemed Coupon",
      selector: (row) => row.Redeem,
      sortable: true,
      wrap: true,
      width: "190px",
    },
    {
      name: "Total Sale",
      selector: (row) => row.TotalSales,
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Claim Period",
      selector: (row) => (
        <Input
          className="mt-1 mb-1 pl-1"
          type="textarea"
          style={{ color: "black", width: "212px" }}
          disabled
          rows="2"
          cols="30"
          value={row?.ClaimPeriod?.map(
            (claim) => `${moment(claim).format("DD/MM/YYYY HH:mm")}\r\n`
          )}
          wrap="hard"
        />
      ),

      sortable: true,
      wrap: true,
      width: "200px",
    },
  ];

  const [filterTier, setFilterTier] = useState([]);
  const [filterReport, setFilterReport] = useState([]);

  const { data, refetch } = useQuery(
    "LoyaltyAnalytic",
    async () =>
      Axios.get(`/shop/${shopID}/Marketing/Dashboard/LoyaltyCoupon`).then(
        (res) => {
          setFilterTier(res.data.TierCouponUsageReport.TierUsageCount);
          setFilterReport(res.data.CouponReport.Coupon);

          const temp = [];
          res.data.CouponReport.Coupon.forEach((item) => {
            temp.push(item.Code);
          });
          setFilterCoupon(temp);

          return res;
        }
      ),
    { refetchOnWindowFocus: false }
  );

  const { data: AgeGraph, refetch: ageRefetch } = useQuery(
    ["AgeGraph", startDate, endDate, range, isChecked, agePeriod],
    async () =>
      Axios.get(`/shop/${shopID}/Marketing/Dashboard/LoyaltyCoupon/Graph`, {
        params: {
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),

          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
          isCompare: isChecked,
          tablePeriod: agePeriod,
          GroupBy: "Age",
        },
      })
  );

  const { data: StateGraph, refetch: stateRefetch } = useQuery(
    ["StateGraph", startDate, endDate, range, isChecked, statePeriod],
    async () =>
      Axios.get(`/shop/${shopID}/Marketing/Dashboard/LoyaltyCoupon/Graph`, {
        params: {
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),

          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
          isCompare: isChecked,
          tablePeriod: statePeriod,
          GroupBy: "State",
        },
      })
  );

  const totalNormal = filterTier
    ?.filter((tier) => tier.RankName === "NORMAL")
    .map((item) => item.UsageCount);

  const totalSilver = filterTier
    ?.filter((tier) => tier.RankName === "SILVER")
    .map((item) => item.UsageCount);

  const totalGold = filterTier
    ?.filter((tier) => tier.RankName === "GOLD")
    .map((item) => item.UsageCount);

  const Data = {
    labels: ["Normal", "Silver", "Gold"],
    datasets: [
      {
        data: [totalNormal, totalSilver, totalGold],
        backgroundColor: ["pink", "silver", "gold"],
        borderWidth: 4,
        borderColor: ["#27293D"],
        hoverOffset: 4,
      },
    ],
  };

  const Options = {
    responsive: true,
    maintainAspectRatio: true,
    defaultFontSize: "14px",

    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        position: "top",
        display: true,
        labels: {
          generateLabels: () => {
            if (Data.labels.length && Data.datasets.length) {
              return Data.labels.map((label, i) => {
                const dataset = Data.datasets[0];
                const value = dataset.data[i];
                return {
                  text: `${label}: ${value}`,
                  fillStyle: dataset.backgroundColor[i],
                  fontColor: "white",
                  hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0,
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
    },
  };

  const plugins = [
    {
      beforeDraw(chart) {
        const { width } = chart;
        const { height } = chart;
        const { ctx } = chart;
        ctx.restore();
        const fontSize = (height / 250).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.fillStyle = "#ffffff";
        ctx.textBaseline = "top";
        const text = `${
          totalNormal[0] === 0 && totalSilver[0] === 0 && totalGold[0] === 0
            ? "No Data"
            : "Tier Redemption"
        }`;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  const handleExportToPDF = () => {
    const elementToDownload = document.getElementById("ElementId"); // Replace "ElementId" with the actual ID
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      margin: [50, 50, 50, 50], // Left, Top, Right, Bottom margins
    });
    // Add A4 format header and footer
    doc.setFontSize(12);
    doc.text("Loyalty Report", doc.internal.pageSize.getWidth() / 2, 20);
    doc.text(
      "Loyalty Report",
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 20
    );
    // Capture the specific element content using html2canvas
    html2canvas(elementToDownload, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 600; // A4 width in landscape (595 for portrait)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = (doc.internal.pageSize.getWidth() - imgWidth) / 2; // Center the image horizontally
      const imgY = (doc.internal.pageSize.getHeight() - imgHeight) / 2; // Center the image vertically
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save("Loyalty Report.pdf");
    });
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setRange([null, null]);
    refetch();
    ageRefetch();
    setCouponCode("");
    setFilterTier(data.data.TierCouponUsageReport.TierUsageCount);
    setFilterReport(data.data.CouponReport.Coupon);
  };

  const handleFilter = async () => {
    const res = await Axios.get(
      `/shop/${shopID}/Marketing/Dashboard/LoyaltyCoupon`,
      {
        params: {
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),

          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
          isCompare: isChecked,
        },
      }
    );
    setFilterTier(res.data.TierCouponUsageReport.TierUsageCount);
    if (couponCode !== "") {
      setFilterReport(
        res.data.CouponReport.Coupon.filter(
          (filtered) => filtered.Code === couponCode
        )
      );
    } else {
      setFilterReport(res.data.CouponReport.Coupon);
    }
    stateRefetch();
    ageRefetch();
  };

  const [ageChartType, setAgeChartType] = useState([]);
  const [stateChartType, setStateChartType] = useState([]);

  const AgeColor = [
    "rgba(255, 99, 132, 220)",
    "rgba(53, 162, 235, 220)",
    "green",
    "cyan",
    "magenta",
  ];

  const StateColor = [
    "palegreen",
    "rgb(137, 207, 240)",
    "rgba(255, 99, 132, 220)",
    "rgba(53, 162, 235, 220)",
    "magenta",
  ];

  const Age = {
    labels: ageChartType,
    datasets: ageChart,
  };

  const State = {
    labels: stateChartType,
    datasets: stateChart,
  };

  const BarOption = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          color: "white",
        },
      },
    },
    scales: {
      yAxes: {
        ticks: {
          beginAtZero: true,
          color: "white",
          fontSize: 12,
        },
      },
      xAxes: {
        ticks: {
          beginAtZero: true,
          color: "white",
          fontSize: 12,
        },
      },
    },
  };

  useEffect(() => {
    if (StateGraph) {
      const temp = [];
      let formattedDates;

      if (StateGraph.data.Graph1[0]?.ID.Day || statePeriod === "Day") {
        // Handle day format
        const dates = StateGraph.data.Graph1.map(
          (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
        );
        const sortedDates = dates.sort((a, b) => {
          const [yearA, dayOfYearA] = a.split("-");
          const [yearB, dayOfYearB] = b.split("-");

          const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
          const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

          return dateA - dateB;
        });
        const days = sortedDates.map((dateString) => {
          const [year, dayOfYear] = dateString.split("-");
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return `${date.getDate()} ${date.toLocaleString("default", {
            month: "short",
          })} ${date.getFullYear()}`;
        });
        setStateChartType(days);

        formattedDates = StateGraph.data.Graph1.map((dateString) => {
          const dayTemp = [];
          dateString.UsageCount.forEach((ages) => {
            dayTemp.push(days.map(() => ages?.UsageCount));
          });
          return dayTemp;
        });
      } else if (
        StateGraph.data.Graph1[0]?.ID.Month ||
        statePeriod === "Month"
      ) {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        formattedDates = StateGraph.data.Graph1.map((monthData) => {
          const monthTemp = [];
          monthData.UsageCount.forEach((ages) => {
            monthTemp.push(
              months.map((x, index) =>
                index === monthData.ID.Month - 1 ? ages?.UsageCount : "0"
              )
            );
          });
          setStateChartType(months);
          return monthTemp;
        });
      } else if (StateGraph.data.Graph1[0]?.ID.Week || statePeriod === "Week") {
        const weeks = StateGraph.data.Graph1.map((weekData) => {
          const weekStartDate = new Date(
            weekData.ID.Year,
            0,
            (weekData.ID.Week - 1) * 7 + 1
          );
          const weekEndDate = new Date(
            weekData.ID.Year,
            0,
            weekData.ID.Week * 7 + 1
          );
          return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekEndDate.getFullYear()}`;
        });
        setStateChartType(weeks);

        formattedDates = AgeGraph.data.Graph1.map((weekData) => {
          const weekTemp = [];
          weekData.UsageCount.forEach((ages) => {
            weekTemp.push(weeks.map(() => ages?.UsageCount));
          });
          return weekTemp;
        });
      }

      StateGraph.data.Graph1.map((item) =>
        item.UsageCount.forEach((states, i) => {
          temp.push({
            label: `${states.GroupID}`,
            data: formattedDates[0][i],
            backgroundColor: StateColor[i],
          });
        })
      );
      setStateChart(temp);
    }
  }, [StateGraph]);

  useEffect(() => {
    if (AgeGraph) {
      const temp = [];
      let formattedDates;

      if (AgeGraph.data.Graph1[0]?.ID.Day) {
        // Handle day format
        const dates = AgeGraph.data.Graph1.map(
          (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
        );
        const sortedDates = dates.sort((a, b) => {
          const [yearA, dayOfYearA] = a.split("-");
          const [yearB, dayOfYearB] = b.split("-");

          const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
          const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

          return dateA - dateB;
        });
        const days = sortedDates.map((dateString) => {
          const [year, dayOfYear] = dateString.split("-");
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return `${date.getDate()} ${date.toLocaleString("default", {
            month: "short",
          })} ${date.getFullYear()}`;
        });
        setAgeChartType(days);

        formattedDates = AgeGraph.data.Graph1.map((dateString) => {
          const dayTemp = [];
          dateString.UsageCount.forEach((ages) => {
            dayTemp.push(days.map(() => ages?.UsageCount));
          });
          return dayTemp;
        });
      } else if (AgeGraph.data.Graph1[0]?.ID.Month) {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        formattedDates = AgeGraph.data.Graph1.map((monthData) => {
          const monthTemp = [];
          monthData.UsageCount.forEach((ages) => {
            monthTemp.push(
              months.map((x, index) =>
                index === monthData.ID.Month - 1 ? ages?.UsageCount : "0"
              )
            );
          });
          setAgeChartType(months);
          return monthTemp;
        });
      } else if (AgeGraph.data.Graph1[0]?.ID.Week) {
        const weeks = AgeGraph.data.Graph1.map((weekData) => {
          const weekStartDate = new Date(
            weekData.ID.Year,
            0,
            (weekData.ID.Week - 1) * 7 + 1
          );
          const weekEndDate = new Date(
            weekData.ID.Year,
            0,
            weekData.ID.Week * 7 + 1
          );
          return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekEndDate.getFullYear()}`;
        });
        setAgeChartType(weeks);

        formattedDates = AgeGraph.data.Graph1.map((weekData) => {
          const weekTemp = [];
          weekData.UsageCount.forEach((ages) => {
            weekTemp.push(weeks.map(() => ages?.UsageCount));
          });
          return weekTemp;
        });
      }

      AgeGraph.data.Graph1.map((item) =>
        item.UsageCount.forEach((ages, i) => {
          temp.push({
            label: `Age ${ages.GroupID}`,
            data: formattedDates[0][i],
            backgroundColor: AgeColor[i],
          });
        })
      );
      setAgeChart(temp);
    }
  }, [AgeGraph]);

  return (
    <div className="content" id="ElementId">
      <Card className="card-dark">
        <CardHeader>
          <Row>
            <Col md={isChecked ? 8 : 5}>
              <div className="d-flex mt-2">
                <i className="fa fa-filter mt-2 mr-3" />
                <div className="mb-2">
                  <CustomCalendar
                    setDateRange={setDateRange}
                    setRange={setRange}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    compareCheckbox
                  />
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="d-flex mt-2">
                <p className="mr-3 mt-2">Show :</p>
                <div className="mb-2">
                  <Input
                    type="select"
                    placeholder="Loyalty Code..."
                    style={{ width: "150px" }}
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  >
                    <option value="">Loyalty Code...</option>
                    {filterCoupon.map((codes) => (
                      <option key={codes} value={codes}>
                        {codes}
                      </option>
                    ))}
                  </Input>
                </div>
              </div>
            </Col>
            <Col md="12">
              <Button
                className="ml-4"
                color="info"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
              <Button
                className="mr-4"
                color="danger"
                onClick={() => resetFilter()}
              >
                Reset Filter
              </Button>
              <Button color="success" onClick={handleExportToPDF}>
                Export
              </Button>
            </Col>
          </Row>
          <h2 className="m-4">Loyalty Report</h2>
        </CardHeader>
        <CardBody>
          {!triggerReport ? (
            <>
              <Row>
                <Col md="6">
                  <div className="text-center" style={{ height: "600px" }}>
                    <Doughnut
                      data={Data}
                      options={Options}
                      plugins={plugins}
                      className="mt-3"
                      onClick={(e) => e.preventDefault(setTriggerReport(true))}
                    />
                  </div>
                </Col>
                <Col md="6">
                  <Row>
                    <Col md="12">
                      <div
                        className="float-left mb-5"
                        style={{ height: "300px" }}
                      >
                        <h4>Customer Location Geographic</h4>
                        <Row>
                          <Col>
                            <p>Year {StateGraph?.data.Graph1[0]?.ID.Year}</p>
                          </Col>
                          <Col>
                            <div>
                              <Input
                                className="float-right"
                                type="select"
                                placeholder="Coupon..."
                                style={{ width: "130px" }}
                                value={statePeriod}
                                onChange={(e) => setStatePeriod(e.target.value)}
                              >
                                <option value="Day">By Day</option>
                                <option value="Week">By Week</option>
                                <option value="Month">By Month</option>
                              </Input>
                            </div>
                          </Col>
                        </Row>
                        <Bar
                          options={BarOption}
                          data={State}
                          height="150px"
                          width="500px"
                        />
                      </div>
                    </Col>
                    <Col md="12">
                      <div
                        className="float-left mb-5 mt-5"
                        style={{ height: "300px" }}
                      >
                        <h4>Customer Age</h4>
                        <Row>
                          <Col>
                            <p>Year {AgeGraph?.data.Graph1[0]?.ID.Year}</p>
                          </Col>
                          <Col>
                            <div>
                              <Input
                                className="float-right"
                                type="select"
                                placeholder="Coupon..."
                                style={{ width: "130px" }}
                                value={agePeriod}
                                onChange={(e) => setAgePeriod(e.target.value)}
                              >
                                <option value="Day">By Day</option>
                                <option value="Week">By Week</option>
                                <option value="Month">By Month</option>
                              </Input>
                            </div>
                          </Col>
                        </Row>
                        <Bar
                          options={BarOption}
                          data={Age}
                          height="150px"
                          width="500px"
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <BasicTable
                className="mt-5"
                id=""
                data={filterReport}
                columns={columns || []}
                pagination
              />
            </>
          ) : (
            <CouponLoyaltyReport
              setTriggerReport={setTriggerReport}
              setFilterCoupon={setFilterCoupon}
              couponType="LoyaltyCoupon"
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default LoyaltyAnalytic;
