/* eslint-disable react/no-unstable-nested-components */
// import moment from "moment";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import { Card, Col, Row } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import Filter from "views/components/Filter";

function CreditNote() {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dataState, setDataState] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [userId, setUserId] = useState("");
  const [totalCost, setTotalCost] = useState("");
  const { isSuccess, data } = useQuery(
    ["CreditNote", fromDate, toDate, userId],
    async () =>
      Axios.get(`/shop/${shopID}/Order/Finance/CreditNote`, {
        params: {
          dateFrom: fromDate,
          dateTo: toDate,
          userID: userId,
        },
      })
  );

  const column = [
    {
      name: "No",
      selector: (row) => row.no,
      sortable: true,
      sortField: "no",
      width: "100px",
    },
    {
      name: "Date of CN",
      selector: (row) => row.date,
      sortable: true,
      sortField: "DateCN",
      width: "160px",
    },
    {
      name: "Credit Note No",
      selector: (row) => row.creditNo,
      sortable: true,
      sortField: "CreaditNo",
      width: "250px",
    },
    {
      name: "Total(RM)",
      sortable: true,
      sortField: "Total",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(row.total).toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix=""
        />
      ),
      width: "190px",
    },
    {
      name: "Item SN No.",
      sortable: true,
      sortField: "itemSN",
      selector: (row) => row.SNnum,
      width: "180px",
    },
    {
      name: "Reason",
      sortable: true,
      sortField: "ShopOrderCostnShipping",
      selector: (row) => row.reason,
      width: "250px",
    },
    {
      name: "Date of Invoice",
      sortable: true,
      sortField: "PaymentGateway",
      selector: (row) => row.dateInv,
      width: "200px",
    },
    {
      name: "Order ID No",
      sortable: true,
      selector: (row) => row.orderID,
      width: "250px",
    },
    {
      name: "Invoice No",
      sortable: true,
      selector: (row) => row.invoice,
      width: "250px",
    },
    {
      name: "User ID",
      sortable: true,
      selector: (row) => row.userID,
      width: "300px",
    },
    {
      name: "PIC",
      sortable: true,
      selector: (row) => row.pic,
      width: "200px",
    },
    {
      name: "Approval",
      sortable: true,
      selector: (row) => row.approval,
      width: "150px",
    },
  ];

  useEffect(() => {
    if (isSuccess && data) {
      // setTotalRow(data.data.Count);
      setTotalCost(data.data[0]?.Total);
      setDataState(
        data.data.map((prop, idx) => ({
          no: idx + 1,
          date: moment(prop.CreatedDate).format("DD.MM.YY"),
          creditNo: prop.CreditNoteNum,
          total: prop.Total,
          SNnum: prop.ItemSNNum,
          reason: prop.Reason,
          dateInv: moment(prop.DateOfInvoice).format("DD.MM.YY"),
          orderID: prop.OrderIDNum,
          invoice: prop.Invoice,
          userID: prop.UserId,
          pic: prop.PIC,
          approval: prop.Approval,
        }))
      );
    }
  }, [isSuccess, data]);

  return (
    <div className="content">
      <Card className="card-dark cardSplit pl-3 pr-3 pb-3">
        <Row>
          <Col md="7">
            <div>
              <Row className="mt-2 mb-2">
                <Col md="3" className="mt-2 mb-3">
                  <p>Credit Note (RM) : </p>
                </Col>
                <Col>
                  <CurrencyFormat
                    decimalScale={2}
                    value={parseFloat(totalCost).toFixed(2)}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                  />
                </Col>
              </Row>
              <Filter
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                setUserId={setUserId}
              />
            </div>
          </Col>
        </Row>
        <DataTable
          customStyles={customStyles}
          theme="solarized"
          data={dataState}
          columns={column}
          pagination
        />
      </Card>
    </div>
  );
}

export default CreditNote;
