/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import moment from "moment";
import { toast } from "react-toastify";

function CouponView() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: CouponData, refetch } = useQuery(
    `/shop/${shopID}/Coupon/${id}`,
    async () => Axios.get(`/shop/${shopID}/Coupon/${id}`)
  );

  // const { data: CouponImg } = useQuery(
  //   `/shop/${shopID}/Coupon/${id}/Images`,
  //   async () => Axios.get(`/shop/${shopID}/Coupon/${id}/Images`)
  // );

  const [couponLimitation, setCouponLimitation] = useState([]);
  const [editImg, setEditImg] = useState(false);
  const [imgModal, setImgModal] = useState(false);

  const [addImg, setAddImg] = useState([]);
  const [delId, setDelId] = useState("");
  const [editMsg, setEditMsg] = useState(false);

  const toggleEditImg = () => {
    setEditImg(!editImg);
  };

  const toggleAddImg = () => {
    setImgModal(!imgModal);
  };

  const imgDelete = () => {
    // const requestBody = JSON.stringify(delId);
    Axios.delete(`/shop/${shopID}/Coupon/${id}/Images/${delId}`)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Image </p>
          </div>
        );
        setEditMsg(false);
        refetch();
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  const removeImg = (imgId) => {
    setDelId([imgId]);
    setEditMsg(true);
  };

  const imgListRef = React.createRef();
  const handleImgListClick = () => {
    imgListRef.current.click();
  };

  const handleProductImg = (e) => {
    const imgFile = e.target.files;
    setAddImg({
      File: imgFile[0],
      URL: URL.createObjectURL(imgFile[0]),
    });
    e.target.value = null;
  };

  const handleRemoveImg = () => {
    setAddImg([]);
  };

  const handleAdd = () => {
    if (addImg.length !== 0) {
      const data2 = new FormData();

      data2.append("ImagesPostDto", addImg.File);

      Axios.post(`/shop/${shopID}/Coupon/${id}/Images`, data2)
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">Successful Added Images </p>
            </div>
          );
          refetch();
        })
        .catch(() => {
          toast.error("Something goes wrong, please try later");
        });
    }
    setTimeout(() => {
      toggleAddImg();
    }, 1000);
  };

  useEffect(() => {
    if (CouponData) {
      if (CouponData.data.CategoryList.length !== 0) {
        setCouponLimitation({
          Type: "Category",
          List: CouponData.data.CategoryList,
        });
      } else if (CouponData.data.ProductList.length !== 0) {
        setCouponLimitation({
          Type: "Product",
          List: CouponData.data.ProductList,
        });
      } else if (CouponData.data.CollectionList.length !== 0) {
        setCouponLimitation({
          Type: "Collection",
          List: CouponData.data.CollectionList,
        });
      } else if (CouponData.data.CollaborationList.length !== 0) {
        setCouponLimitation({
          Type: "Collaboration",
          List: CouponData.data.CollaborationList,
        });
      }
    }
  }, [CouponData]);

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2"
        tag={Link}
        to="/shopOwner/marketing/Coupon"
        size="sm"
      >
        Back
      </Button>
      <Card className="card-stats card-dark">
        <CardBody className="mt-3 ">
          <p className="bg-info p-2 pl-4">Coupon Details</p>
          <Card
            className="card-stats card-dark mt-2 "
            style={{ border: "1px solid #51BCDA" }}
          >
            <CardBody className="text-white mb-3">
              <Row>
                <Col md="5">
                  <p>
                    Coupon Type :{" "}
                    {CouponData?.data.LoyaltyCoupon ? "Loyalty" : "Coupon"}
                  </p>
                  {CouponData?.data.LoyaltyCoupon ? (
                    <>
                      <p>
                        Loyalty Points : {CouponData.data.LoyaltyPointValue}
                      </p>
                      <p>
                        Third Party :{" "}
                        {CouponData.data.ThirdParty ? (
                          <i className="pl-2 justify-content-center nc-icon nc-check-2" />
                        ) : (
                          <i className="pl-2 nc-icon nc-simple-remove" />
                        )}
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                  <p>
                    Status :{" "}
                    {CouponData?.data.Status ? (
                      <b style={{ color: "lightgreen" }}>Active</b>
                    ) : (
                      <b style={{ color: "red" }}>Inactive</b>
                    )}
                  </p>
                </Col>
                <Col>
                  {CouponData?.data.LoyaltyCoupon && (
                    <Row>
                      <Col>
                        {CouponData?.data.LoyaltyCoupon &&
                        CouponData?.data.ImageFiles.length !== 0 ? (
                          <div>
                            <button
                              hidden={!editImg}
                              type="button"
                              className="removeCouponImg"
                              style={{ right: "58px" }}
                              onClick={() =>
                                removeImg(CouponData?.data.ImageFiles[0]?.ID)
                              }
                            >
                              <i className="fa fa-times-circle removeIcon" />
                            </button>
                            <img
                              src={CouponData?.data.ImageFiles[1]?.ImageUrl}
                              style={{ width: "300px", height: "300px" }}
                              alt="..."
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col>
                        <div>
                          <Button
                            className="float-right editBtn text-center"
                            style={{
                              backgroundColor: `${editImg ? "#eb6532" : ""}`,
                            }}
                            color={editImg ? "danger" : "info"}
                            onClick={toggleEditImg}
                          >
                            Edit
                            <i className="fa fa-edit ml-2" />
                          </Button>
                        </div>

                        {editImg ? (
                          <div>
                            <Button
                              className="float-right editBtn text-center mr-3"
                              style={{ width: "120px" }}
                              color="info"
                              onClick={() => setImgModal(true)}
                            >
                              Add Image
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col md="6">
              <Card
                className="card-stats card-dark "
                style={{ border: "1px solid #51BCDA" }}
              >
                <CardBody className="text-white mb-3">
                  <p>Coupon Number : {CouponData?.data.ID}</p>
                  <p>Coupon Code : {CouponData?.data.Code}</p>
                  <p>Description : {CouponData?.data.Description}</p>
                  <p>
                    Start Date :{" "}
                    {moment(CouponData?.data.StartDateTime).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p>
                    End Date :{" "}
                    {CouponData?.data.NoEndDate
                      ? "No End Date"
                      : moment(CouponData?.data.EndDateTime).format(
                          "DD/MM/YYYY"
                        )}
                  </p>
                  <p>
                    Miminum Purchase Amount :{" "}
                    {`RM ${CouponData?.data.MinPurchaseAmount}`}
                  </p>
                  <p>Per User Limit : {CouponData?.data.PerUserLimit}</p>
                  <p>Coupon Limit : {CouponData?.data.LimitCount}</p>

                  {CouponData?.data.UserIDList.length !== 0 && (
                    <>
                      <p className="mt-5">Allowed Email </p>
                      <hr />
                      {CouponData?.data.UserIDList.map((item, index) => (
                        <p>
                          {index + 1} : {item.Email}
                        </p>
                      ))}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            {couponLimitation.length !== 0 && (
              <Col md="6">
                <Card
                  className="card-stats card-dark"
                  style={{ border: "1px solid #51BCDA", paddingBottom: "35px" }}
                >
                  <CardBody className="text-white">
                    <p> {couponLimitation?.Type}</p>
                    <hr />
                    {couponLimitation?.List.map((item, index) => (
                      <p>
                        {index + 1} : {item.Name}
                      </p>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col md="6">
              <Card
                className="card-stats card-dark"
                style={{ border: "1px solid #51BCDA", paddingBottom: "35px" }}
              >
                <CardBody className="text-white">
                  <p>{CouponData?.data.Type}</p>
                  <hr />
                  {CouponData?.data.Type === "Discount" ||
                  CouponData?.data.Type === "Free Shipping" ? (
                    <>
                      <p>Discount Type : {CouponData?.data.Discount.Type}</p>
                      <p>
                        Discount Value :{" "}
                        {`${
                          CouponData?.data.Discount.Type !== "Percentage"
                            ? "RM"
                            : ""
                        } ${CouponData?.data.Discount.DiscountValue} ${
                          CouponData?.data.Discount.Type === "Percentage"
                            ? "%"
                            : ""
                        }`}
                      </p>
                      <p>
                        Capped Discount :{" "}
                        {CouponData?.data.Discount.EnableCappedDiscountAmount
                          ? `RM ${CouponData?.data.Discount.CappedDiscountAmount}`
                          : "No Capped Discount"}
                      </p>
                    </>
                  ) : CouponData?.data.Type === "Free Gift" ? (
                    (CouponData?.data.Free.FreeGiftIDList.length !== 0
                      ? CouponData?.data.Free.FreeGiftIDList.map(
                          (item, index) => (
                            <p>
                              {index + 1} : {item}
                            </p>
                          )
                        )
                      : "",
                    CouponData?.data.Free.FreeProductIDList.length !== 0
                      ? CouponData?.data.Free.FreeProductIDList.map(
                          (item, index) => (
                            <p>
                              {index + 1} : {item}
                            </p>
                          )
                        )
                      : "")
                  ) : (
                    ""
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* Delete Message */}
      <Modal size="ml" isOpen={editMsg} toggle={() => setEditMsg(false)}>
        <ModalBody>
          <Container className="text-center">
            <h3 className="mt-3">Delete Image</h3>
            <p>Are You sure you wanted to delete this Image ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    imgDelete();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  className="clearBtn delBtn"
                  onClick={() => setEditMsg(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      {/* Add Images */}
      <Modal size="xl" isOpen={imgModal} toggle={() => toggleAddImg()}>
        <ModalBody>
          <Button
            className="float-right closeBtn"
            onClick={() => setImgModal(false)}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Add Image</h3>
            <Button
              onClick={() => handleImgListClick()}
              disabled={CouponData?.data.ImageFiles.length > 0}
            >
              Upload Image
            </Button>
            <input
              type="file"
              className="uploadImg"
              accept="image/*"
              multiple
              ref={imgListRef}
              onChange={handleProductImg}
            />
            {addImg.length !== 0 && (
              <>
                <h4>Add Images</h4>
                <Row className="text-center mb-4">
                  <Col md="4">
                    <div>
                      <button
                        type="button"
                        className="imgListRemove"
                        onClick={() => handleRemoveImg()}
                      >
                        <i className="fa fa-times-circle removeIcon" />
                      </button>
                      <img src={addImg.URL} className="mt-1 imgList" />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {addImg.length !== 0 && (
              <div>
                <Button className="submitBtn" onClick={() => handleAdd()}>
                  Save
                </Button>
              </div>
            )}
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CouponView;
