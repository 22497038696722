/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import PropTypes from "prop-types";

// reactstrap components
import {
  Row,
  Col,
  Input,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import { toast } from "react-toastify";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

function Step2({ BatchID, BatchName }) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;

  const [discountValue, setDiscountValue] = React.useState(0);
  const [DiscountType, setDiscountType] = React.useState("");
  const [ProductSNList, setProductSNList] = React.useState([]);
  const [selectedRowsData, setSelectedRowsData] = React.useState([]);

  const [PaymentDate, setPaymentDate] = React.useState(new Date());
  const [batchN, setBatchN] = React.useState(BatchName);

  const [exportype, setExportType] = React.useState("pdf");

  const createBatch = () => {
    if (ProductSNList.length > 0) {
      Axios.post(`/shop/${shopID}/Live/CreateSelectedSNBatch`, {
        BatchID,
        BatchName: batchN,
        ProductSNList,
        DiscountType,
        Discount: discountValue,
        LiveDate: PaymentDate,
      }).then(() => {
        toast.success("Batch Created");
      });
    }
  };

  const {
    isLoading: isLoadingTable,
    data: tableData,
    refetch,
  } = useQuery(
    `/shop/${shopID}/Live/GetNPrepareScannedSNProduct/${BatchID}/${DiscountType}/${discountValue}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetNPrepareScannedSNProduct/${BatchID}/${DiscountType}/${discountValue}`
      ).then((res) => {
        setProductSNList(res.data.map((d) => d.VariationSKU));
        return res;
      }),
    { enabled: false }
  );

  const handleSelectRows = ({ selectedRows }) => {
    if (tableData) {
      setSelectedRowsData(selectedRows);
      setProductSNList(selectedRows.map((d) => d.VariationSKU));
    }
  };

  const convertToCSV = (data2) => {
    const header = Object.keys(data2[0]);
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = () => {
    const csv = convertToCSV(selectedRowsData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `${batchN}_${moment(new Date(), "DDMMYYYY")}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportPDF = async () => {
    if (shopID !== "0") {
      const pdfFile = await Axios.post(
        `/shop/${shopID}/Live/GetExportedSNBatch/PDF`,
        {
          batchId: BatchID,
          ProductNoList: selectedRowsData.map((d) => d.No),
        }
      ).catch((err) => toast.error(err.response.data.MESSAGE));

      const alink = document.createElement("a");
      alink.href = pdfFile.data;
      alink.target = "_blank";
      alink.download = `${batchN}_${moment(new Date(), "DDMMYYYY")}.pdf`;
      alink.setAttribute(
        "download",
        `${batchN}_${moment(new Date(), "DDMMYYYY")}.pdf`
      );
      alink.click();
    }
  };

  const getBatchName = (date) => {
    if (date) {
      setPaymentDate(date);
      Axios.post(`/shop/${shopID}/Live/GetBatchName`, {
        batchDate: date,
        batchID: BatchID,
      }).then((res) => setBatchN(res.data.BatchName));
    }
  };

  return (
    <div className="content">
      <Row sm={2} xs={1}>
        <Col>
          <div className="d-flex">
            <div className="mr-2">Date:</div>
            <ReactDatePicker
              selected={PaymentDate}
              onSelect={(update) => {
                getBatchName(moment(update).toDate());
              }}
              onChange={(update) => {
                setPaymentDate(update);
              }}
              isClearable
              value={PaymentDate}
              wrapperClassName="w-100"
              customInput={<Input />}
            />
          </div>
        </Col>
        <Col>
          <div className="d-flex">
            <div className="mr-2">Batch:</div>
            <Input disabled value={batchN} />
            <Button
              disabled={ProductSNList.length === 0}
              className="my-0"
              onClick={() => {
                createBatch();
              }}
            >
              Save
            </Button>
          </div>
        </Col>
        <Col className="mt-sm-2">
          <div className="d-flex align-items-center">
            <div className="mr-2">Discount:</div>
            <Input
              value={discountValue}
              onChange={(e) => setDiscountValue(e.target.value)}
            />
            <UncontrolledDropdown>
              <DropdownToggle caret>{DiscountType || "Select"}</DropdownToggle>
              <DropdownMenu container="body">
                <DropdownItem
                  onClick={() => {
                    setDiscountType("Flat");
                  }}
                >
                  Fix Amount
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setDiscountType("Percentage");
                  }}
                >
                  Percent
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <Button onClick={() => refetch()} disabled={DiscountType === ""}>
              Apply
            </Button>
          </div>
        </Col>
        <Col className="mt-sm-2">
          <div className="d-flex align-items-center">
            <div className="mr-2">Bulk Action:</div>
            <Input
              name="PaymentMethodID"
              type="select"
              onChange={(e) => setExportType(e.target.value)}
            >
              <option value="pdf">PDF</option>
              <option value="excel">EXCEL</option>
            </Input>
            <Button
              onClick={() => {
                if (exportype === "pdf") {
                  exportPDF();
                } else if (exportype === "excel") {
                  downloadCSV();
                }
              }}
              disabled={selectedRowsData.length === 0}
            >
              Export
            </Button>
          </div>
          <small>* Only Allow for Saved Batch</small>
        </Col>
      </Row>
      <div className="pt-3">
        <small>
          Select discount then the data will show, then select the rows that
          need to save / export
        </small>
        <BasicTable
          pagination
          selectableRows
          onSelectedRowsChange={handleSelectRows}
          progressPending={isLoadingTable}
          progressComponent={
            <div className="py-5">
              <Spinner />
            </div>
          }
          data={tableData?.data}
          columns={[
            {
              name: "No",
              selector: (row) => row.No,
              sortable: true,
            },
            {
              name: "SN",
              selector: (row) => row.VariationSKU,
              sortable: true,
            },
            {
              name: "Name",
              selector: (row) => row.Name,
              sortable: true,
            },
            {
              name: "SKU",
              selector: (row) => row.ProductSKU,
              sortable: true,
            },
            {
              name: "Weight",
              selector: (row) => row.Weight,
              sortable: true,
            },
            {
              name: "Size",
              selector: (row) => row.Size,
              sortable: true,
            },
            {
              name: "Discounted Price",
              selector: (row) => row.Price,
              sortable: true,
            },
          ]}
        />
      </div>
    </div>
  );
}
Step2.propTypes = {
  BatchID: PropTypes.string.isRequired,
  BatchName: PropTypes.string.isRequired,
};
export default Step2;
