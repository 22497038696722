/* eslint-disable react/no-array-index-key */
import React, { useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import moment from "moment";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ViewTradeInDetails() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: tradeinDt } = useQuery(
    `/shop/${shopID}/GetSingleTradeInRequest/${id}`,
    async () => Axios.get(`/shop/${shopID}/GetSingleTradeInRequest/${id}`)
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(null);
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [selectedMediaIndex1, setSelectedMediaIndex1] = useState(null);
  const isImage = (media) => !media.match(/.*\.mp4/);

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const text = tradeinDt?.data.MeganShipBackinfo.TrackingNo;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const openModal = (index) => {
    setSelectedMediaIndex(index);
    setModalIsOpen(true);
  };
  const openModal1 = (index) => {
    setSelectedMediaIndex1(index);
    setModalIsOpen1(true);
  };
  const weight = parseFloat(tradeinDt?.data.ShopTradeInInformation.Weight);
  let formattedWeight;
  if (!Number.isNaN(weight)) {
    formattedWeight = weight.toFixed(2);
  }

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <div>
        <Card
          className="card-stats card-dark pl-5 pr-5 pt-3"
          style={{ border: "1px solid #51BCDA" }}
        >
          <div className="d-flex justify-content-around">
            <p>
              {`  Date: 
            ${moment(tradeinDt?.data.CreateDate).format("DD-MMM-YYYY")}`}
            </p>
            <p>{`Trade In Number: ${tradeinDt?.data.TradeInID}`}</p>
            {` Status: ${tradeinDt?.data.ShopTradeInProductGetSingleDtoList[0].InternalStatus}`}
          </div>
        </Card>
      </div>

      <Card className="card-stats card-dark">
        <CardBody className="mt-3">
          <p className="bg-info p-2 pl-4">General Information</p>
          <div className="ml-4">
            <Row>
              <Col md="6">
                <Row>
                  <Col
                    md="2"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Name:
                  </Col>
                  <Col
                    md="9"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {tradeinDt?.data.shopTradeInGeneralInfo.Fullname}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="2"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Contact No:
                  </Col>
                  <Col
                    md="9"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {tradeinDt?.data.shopTradeInGeneralInfo.ContactNumber}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="2"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Address:
                  </Col>
                  <Col
                    md="9"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {`
                    ${tradeinDt?.data.shopTradeInGeneralInfo.Address.Address},${tradeinDt?.data.shopTradeInGeneralInfo.Address.Postcode},${tradeinDt?.data.shopTradeInGeneralInfo.Address.City},${tradeinDt?.data.shopTradeInGeneralInfo.Address.State}`}
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col
                    md="2"
                    className="mr-1 pt-3"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    I/C(Upload):
                  </Col>
                  <Col md="9" style={{ border: "1px solid #51BCDA" }}>
                    <Row className="d-flex justify-content-start p-3">
                      {tradeinDt?.data.shopTradeInGeneralInfo.ICPhotoList?.map(
                        (icimg, index) => (
                          <Col
                            key={icimg + Math.random()}
                            md="4"
                            onClick={(e) => {
                              e.preventDefault();
                              openModal(index);
                            }}
                          >
                            <img
                              height="100"
                              width="150"
                              alt={`Media ${index}`}
                              src={icimg}
                            />
                          </Col>
                        )
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <p className="bg-info p-2 pl-4 mt-3">Trade In Information</p>
          <div className="ml-4">
            <Row>
              <Col md="6">
                <Row>
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Weight (g):
                  </Col>
                  <Col
                    md="8"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {` ${formattedWeight} g`}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Parcel Logisic:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {tradeinDt?.data.ShopTradeInInformation.Logistic}
                  </Col>
                </Row>
              </Col>
              <Col md="6">
                <Row>
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Quantity:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {tradeinDt?.data.ShopTradeInInformation.Qty}
                  </Col>
                </Row>
                <Row>
                  <Col
                    md="3"
                    className="mr-1 p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    Tracking No:
                  </Col>
                  <Col
                    md="8"
                    className="p-2 mb-1"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    {tradeinDt?.data.ShopTradeInInformation.TrackingNumber}
                  </Col>
                </Row>
              </Col>
            </Row>
            {tradeinDt?.data.MeganShipBackinfo === null ? null : (
              <>
                <p className="bg-link border-bottom p-2 pl-4 mt-3">
                  Shipped Back Logistic Information
                </p>
                <Row className="pl-5 pr-5 pt-3">
                  <Col md="6">
                    <Row>
                      <Col
                        md="3"
                        className="mr-1 p-2 mb-1"
                        style={{ border: "1px solid #51BCDA" }}
                      >
                        Parcel Logisic:
                      </Col>
                      <Col
                        md="8"
                        className="p-2 mb-1"
                        style={{ border: "1px solid #51BCDA" }}
                      >
                        {tradeinDt?.data.MeganShipBackinfo.Logistic}
                      </Col>
                    </Row>
                  </Col>
                  <Col md="6">
                    <Row>
                      <Col
                        md="3"
                        className="mr-1 p-2 mb-1"
                        style={{ border: "1px solid #51BCDA" }}
                      >
                        Tracking No:
                      </Col>
                      <Col
                        md="8"
                        className="p-2 mb-1"
                        style={{ border: "1px solid #51BCDA" }}
                      >
                        {tradeinDt?.data.MeganShipBackinfo.TrackingNo}
                        {!isCopied && (
                          <Button
                            className="btn btn-link p-0 float-right text-white text-capitalize"
                            onClick={handleCopy}
                            style={{ marginLeft: "10px" }}
                          >
                            Copy
                          </Button>
                        )}
                        {isCopied && (
                          <span
                            className="btn btn-link p-0 float-right text-white text-capitalize"
                            style={{ marginLeft: "10px" }}
                          >
                            Copied!
                          </span>
                        )}
                      </Col>
                      ;
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </div>
          {/* trade in product */}
          <div>
            {tradeinDt?.data.ShopTradeInProductGetSingleDtoList.map(
              (tradeProduct, idx) => (
                <>
                  <p className="bg-info p-2 pl-4 mt-3">
                    {`   Trade In Product ${idx + 1} Information`}
                  </p>
                  <div className="ml-4">
                    <Row>
                      <Col md="6">
                        <Row>
                          <Col
                            md="3"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            Weight (g):
                          </Col>
                          <Col
                            md="8"
                            className="p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            {` ${tradeProduct.PurposedWeight} g`}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md="3"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            Describe Itme(s):
                          </Col>
                          <Col
                            md="8"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            {tradeProduct.Description}
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md="3"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            Type:
                          </Col>
                          <Col
                            md="8"
                            className="p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            {tradeProduct.Type}
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6">
                        <Row>
                          <Col
                            md="3"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            Trade In (Upload):
                          </Col>
                          <Col
                            md="8"
                            className="mr-1 p-2 mb-1"
                            style={{ border: "1px solid #51BCDA" }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {tradeProduct.TradeInProductFileList.map(
                                (media, index) => (
                                  <div
                                    key={media}
                                    style={{
                                      margin: "10px",
                                      textAlign: "center",
                                    }}
                                    onClick={() => openModal1(index)}
                                    aria-hidden="true"
                                  >
                                    {isImage(media) ? (
                                      <div className="">
                                        <img
                                          alt={`Media ${index}`}
                                          src={media}
                                          width="100px"
                                          height="100px"
                                        />
                                      </div>
                                    ) : (
                                      <ReactPlayer
                                        url={media}
                                        controls
                                        height="100%"
                                        width="250px"
                                        style={{
                                          position: "relative",
                                          paddingRight: "100px",
                                        }}
                                      />
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <p className="p-2 pl-4 ">
                    {` Trade In Amount for Product ${idx + 1}`}
                  </p>
                  <hr
                    className="mr-1"
                    style={{
                      border: "1px dotted #51BCDA",
                      width: "100%",
                    }}
                  />
                  <div>
                    <Row>
                      <Col md="6">
                        <h6 className="bg-success p-2 text-center mr-5">
                          Accept
                        </h6>
                        {tradeProduct.Status === "TradeIn Approved" ? (
                          <>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Actual Weight (g):
                              </Col>
                              <Col
                                md="8"
                                className="p-2 l-3 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.ActualWeight}
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Price(RM):
                              </Col>
                              <Col
                                md="8"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.Price}
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Photos:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {tradeProduct.ShopUploadedFileList.map(
                                    (media, index) => (
                                      <div
                                        key={media + Math.random()}
                                        style={{
                                          margin: "10px",
                                          textAlign: "center",
                                        }}
                                        aria-hidden="true"
                                      >
                                        <div className="">
                                          <img
                                            alt={`Media ${index}`}
                                            src={media}
                                            width="100px"
                                            height="100px"
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Remarks:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.ShopRemarks}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Actual Weight (g):
                              </Col>
                              <Col
                                md="8"
                                className="p-2 l-3 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Price(RM):
                              </Col>
                              <Col
                                md="8"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Photos:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Remarks:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                      <Col md="6">
                        <h6 className="bg-danger p-2 text-center mr-5">
                          Reject
                        </h6>
                        {tradeProduct.Status === "Rejected" ? (
                          <>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Actual Weight (g):
                              </Col>
                              <Col
                                md="8"
                                className="p-2 l-3 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.ActualWeight}
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Price(RM):
                              </Col>
                              <Col
                                md="8"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.Price}
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Photos:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.ShopUploadedFileList}
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Remarks:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                {tradeProduct.ShopRemarks}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Actual Weight (g):
                              </Col>
                              <Col
                                md="8"
                                className="p-2 l-3 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Price(RM):
                              </Col>
                              <Col
                                md="8"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Photos:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                            <Row className="ml-1">
                              <Col
                                md="3"
                                className="mr-1 p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                Remarks:
                              </Col>
                              <Col
                                md="8"
                                className="p-2 mb-1"
                                style={{ border: "1px solid #51BCDA" }}
                              >
                                -
                              </Col>
                            </Row>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              )
            )}
          </div>
        </CardBody>
      </Card>
      <Modal
        size="lg"
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
      >
        <ModalHeader>
          <Button
            className="btn btn-primary float-right"
            style={{ fontSize: "12px" }}
            size="sm"
            onClick={() => setModalIsOpen(false)}
          >
            x
          </Button>
          IC Photo
        </ModalHeader>
        <ModalBody>
          <Carousel
            selectedItem={selectedMediaIndex}
            onChange={(index) => setSelectedMediaIndex(index)}
          >
            {tradeinDt?.data.shopTradeInGeneralInfo.ICPhotoList?.map(
              (icimg, index) => (
                <div key={icimg}>
                  <img
                    height="100%"
                    width="100%"
                    alt={`Media ${index}`}
                    src={icimg}
                  />
                </div>
              )
            )}
          </Carousel>
        </ModalBody>
      </Modal>
      <Modal
        size="lg"
        isOpen={modalIsOpen1}
        onRequestClose={() => setModalIsOpen1(false)}
      >
        <ModalHeader>
          <Button
            className="btn btn-primary float-right"
            style={{ fontSize: "12px" }}
            size="sm"
            onClick={() => setModalIsOpen1(false)}
          >
            x
          </Button>
          Trade In Upload Files
        </ModalHeader>
        <ModalBody>
          <Carousel
            selectedItem={selectedMediaIndex1}
            onChange={(index) => setSelectedMediaIndex1(index)}
          >
            {tradeinDt?.data.ShopTradeInProductGetSingleDtoList.map(
              (productimg) =>
                productimg.TradeInProductFileList.map((media, index) => (
                  <div
                    key={media}
                    style={{ margin: "10px", textAlign: "center" }}
                    onClick={() => openModal1(index)}
                    aria-hidden="true"
                  >
                    {isImage(media) ? (
                      <div className="">
                        <img
                          alt={`Media ${index}`}
                          src={media}
                          width="100%"
                          height="100%"
                        />
                      </div>
                    ) : (
                      <ReactPlayer
                        url={media}
                        controls
                        height="100%"
                        width="100%"
                        style={{
                          position: "relative",
                          paddingRight: "100px",
                        }}
                      />
                    )}
                  </div>
                ))
            )}
          </Carousel>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ViewTradeInDetails;
