/* eslint-disable react/no-unstable-nested-components */
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import { Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DataTable, { createTheme } from "react-data-table-component";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import "./hr.css";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";
import { useQuery } from "react-query";

createTheme(
  "solarized",
  {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#343a40",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "white",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

function HolidaySetting() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: Holidays, refetch } = useQuery(
    `/shop/${shopID}/HolidaySetting`,
    async () => Axios.get(`/shop/${shopID}/HolidaySetting`)
  );

  const [date, setDate] = useState([]);
  const [dateConvert, setDateConvert] = useState([]);
  const [dataState, setDataState] = useState([]);
  const [edit, setEdit] = useState([]);
  // const [selectedDate, setSelectedDate] = useState([]);
  const addRemarks = (remarks, rDate) => {
    const newRemarks = dateConvert.map((obj) => {
      if (obj.Date === rDate) {
        return { ...obj, Remark: remarks };
      }
      return obj;
    });
    setDateConvert(newRemarks);
  };

  useEffect(() => {
    const temp = [];
    date.forEach((dates) => {
      const date1 = dates.toDate();
      const convert = moment(date1).format("DD/MM/YYYY");

      const sameDate = dateConvert.find((obj) => obj.Date === convert);
      if (sameDate && sameDate.length !== 0) {
        temp.push({
          DateTime: date1,
          Date: convert,
          Remark: sameDate.Remark,
        });
      } else {
        temp.push({ DateTime: date1, Date: convert, Remark: "" });
      }
    });
    setDateConvert(temp);
  }, [date]);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const onSubmit = () => {
    const trim = [];
    dateConvert.forEach((obj) => {
      trim.push({ Holiday: obj.DateTime, Remarks: obj.Remark, status: true });
    });
    Axios.post(`/shop/${shopID}/HolidaySetting`, trim)
      .then(() => {
        toast.success("Holiday Added");
        setDate([]);
        setDateConvert([]);
        refetch();
      })
      .catch((error) => {
        toast.error(`Adding Holiday Failled ${error.response.data[0].MESSAGE}`);
      });
  };

  useEffect(() => {
    if (Holidays) {
      setDataState(
        Holidays.data.map((prop, idx) => ({
          id: prop.ID,
          no: idx + 1,
          holiday: prop.Remark,
          date: moment(prop.HolidayDate).format("Do MMMM YYYY"),
        }))
      );
    }
  }, [Holidays]);

  const handleEdit = (rowID) => {
    setEdit({ ID: rowID });
  };

  const handleUpdate = async () => {
    await Axios.put(`/shop/${shopID}/HolidaySetting/${edit.ID}`, {
      HolidayDate: moment(edit.Date).format(),
      Remarks: edit.Remark,
    })
      .then(() => {
        toast.success("Holiday Updated");
        setEdit([]);
        refetch();
      })
      .catch((error) => {
        toast.error(
          `Updating Holiday Failled ${error.response.data[0].MESSAGE}`
        );
      });
  };

  const handleDel = (rowID) => {
    Axios.delete(`/shop/${shopID}/HolidaySetting/${rowID}`)
      .then(() => {
        toast.success("Holiday Deleted");
        refetch();
      })
      .catch((error) => {
        toast.error(
          `Deleting Holiday Failled ${error.response.data[0].MESSAGE}`
        );
      });
  };

  const column = [
    {
      name: "No",
      selector: (row) => row.no,
      sortable: true,
      sortField: "holiday",
      width: "100px",
    },
    {
      name: "Public Holiday ",
      selector: (row) =>
        edit.ID === row.id ? (
          <Input
            type="text"
            placeholder={row.holiday}
            onChange={(e) => setEdit({ ...edit, Remark: e.target.value })}
          />
        ) : (
          row.holiday
        ),
      sortable: true,
      sortField: "holiday",
      width: "450px",
    },
    {
      name: "Date",
      selector: (row) =>
        edit.ID === row.id ? (
          <Input
            type="date"
            onChange={(e) => setEdit({ ...edit, Date: e.target.value })}
          />
        ) : (
          row.date
        ),
      sortable: true,
      sortField: "date",
      width: "330px",
    },
    {
      cell: (row) => (
        <>
          {row.id === edit.ID ? (
            <Button className="edit" onClick={() => handleUpdate()}>
              Update
            </Button>
          ) : (
            <Button className="edit" onClick={() => handleEdit(row.id)}>
              Edit
            </Button>
          )}
          {row.id === edit.ID ? (
            <Button className="del" onClick={() => setEdit([])}>
              Cancel
            </Button>
          ) : (
            <Button className="del" onClick={() => handleDel(row.id)}>
              Delete
            </Button>
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "300px",
    },
  ];

  return (
    <div className="content">
      <Card className="card-dark">
        <CardHeader>
          <CardTitle tag="h4">Holiday Setting</CardTitle>
        </CardHeader>
        <CardBody>
          <Nav justified className="mb-4">
            <NavItem
              className={activeTab === "1" ? "active tab-switch" : "tab-switch"}
              onClick={() => {
                toggle("1");
              }}
            >
              View Holidays
            </NavItem>
            <NavItem
              className={activeTab === "2" ? "active tab-switch" : "tab-switch"}
              onClick={() => {
                toggle("2");
              }}
            >
              Add Holidays
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <DataTable
                customStyles={customStyles}
                theme="solarized"
                data={dataState}
                columns={column}
                responsive
                pagination
              />
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col md="4">
                  <Calendar
                    className="ml-3 mb-3"
                    multiple
                    sort
                    value={date}
                    onChange={(dates) => {
                      setDate(dates);
                    }}
                    plugins={[<DatePanel />]}
                  />
                </Col>
                <Col md="8">
                  <div className="scrollBar">
                    {dateConvert &&
                      dateConvert.map((dateList) => (
                        <div id={dateList} className="ml-4 dateDisplay">
                          <Row>
                            <Col md="4">
                              <p>Date : {dateList.Date}</p>
                            </Col>
                            <Col md="8">
                              <Row>
                                <Col md="4">
                                  <p>Remarks : </p>
                                </Col>
                                <Col>
                                  <Input
                                    type="text"
                                    placeholder="Holiday..."
                                    value={dateList.Remark}
                                    onChange={(e) =>
                                      addRemarks(e.target.value, dateList.Date)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  className="submit"
                  onClick={onSubmit}
                  disabled={dateConvert.length === 0}
                >
                  Submit
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}
export default HolidaySetting;
