import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function CustomButton({ onClick, to, icon, title, text, color, size }) {
  return (
    <Button
      className={`pl-0 pr-1 btn btn-link text-white ${color} ${size}`}
      onClick={onClick}
      tag={to && Link}
      to={to}
    >
      <i className={icon} style={{ fontSize: "18px" }} title={title} />
      {text}
    </Button>
  );
}
CustomButton.propTypes = {
  onClick: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

export default CustomButton;
