import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import Sliders from "views/components/Sliders";

function feedbackDetails(props) {
  const { match } = props;
  const { id } = match.params;
  const Axios = useContext(ApiContext);

  const { isSuccess, data } = useQuery(`/Feedback/${id}`, async () =>
    Axios.get(`/admin/Feedback/GetSingleFeedback/${id}`)
  );
  const [feedback, setFeedback] = useState([]);
  const [remark, setRemark] = useState(null);

  const addPoints = async () => {
    Axios.put(`/admin/Feedback/UpdateFeedback/${id}`, {
      Status: true,
      Remarks: remark,
    });
    Axios.put(`/admin/Feedback/ValidateSingleFeedback/${id}`)
      .then(() => {
        toast.success("Add Points to User");
      })
      .catch((error) => {
        toast.error(error.response);
      });
  };

  useEffect(() => {
    if (isSuccess) {
      setFeedback(data.data);
      setRemark(data.data.Remarks);
    }
  }, [isSuccess, data]);

  return (
    <div className="content">
      <Card className="card-dark pb-3 pt-2">
        <CardHeader>
          <h3 className="d-inline">{`ID : ${feedback.ID} `}</h3>
        </CardHeader>
      </Card>
      <Row>
        <Col xl="5" lg="6" xs="12">
          <Card className="card-dark">
            <CardBody>
              <Row className="mb-3">
                <Col md="3">
                  <p className="mt-2">Description :</p>
                  <p className="mt-2">Remarks :</p>
                </Col>
                <Col md="9">
                  <p className="mt-2 ml-2 pl-1">{feedback.Description}</p>
                  <Input
                    className="mt-3 ml-2 remarksInput p-1"
                    type="textarea"
                    style={{ color: feedback.Status ? "white" : "black" }}
                    onChange={(e) => setRemark(e.target.value)}
                    value={remark}
                    placeholder="Remarks..."
                    readOnly={feedback.Status}
                    plaintext={feedback.Status}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="card-dark" style={{ height: "350px" }}>
            <CardBody className="text-center">
              {feedback.AttachmentList === [] ? (
                <Sliders itemImg={feedback.AttachmentList} />
              ) : (
                <img
                  alt=""
                  src={require("assets/img/image_placeholder.jpg")}
                  style={{ height: "100%" }}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {isSuccess && !feedback.Status ? (
        <Card className="card-dark">
          <CardBody className="text-center">
            <Button
              className="mr-5 whiteButton"
              disabled={remark === null || remark === ""}
            >
              Close Issue
            </Button>
            <Button
              className="ml-5 whiteButton"
              disabled={remark === null || remark === ""}
              onClick={() => addPoints()}
            >
              Add Points
            </Button>
          </CardBody>
        </Card>
      ) : (
        ""
      )}
    </div>
  );
}
export default feedbackDetails;
