/* eslint-disable no-alert */
import axios from "axios";
import React, { createContext, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import moment from "moment";
import UserContext from "services/UserContext";

const ApiContext = createContext(null);

export function ApiProvider({ children }) {
  const { user, updateLocalAccessToken, logout } = useContext(UserContext);
  const history = useHistory();

  async function retrieveNewToken(axiosInstance) {
    const refreshTokenExpiry =
      moment(user.RefreshTokenExpiry).diff(moment()) < 1; // RefreshTokenExpiry
    if (refreshTokenExpiry) {
      alert("Session timeout, please login again");
      logout();
      history.push("/login");

      throw new Error("Session timeout, please login again");
    }

    try {
      const res = await axiosInstance.post("/public/Account/RefreshToken", {
        RefreshToken: user.RefreshToken,
      });
      const {
        AccessToken,
        AccessTokenExpiry,
        RefreshToken,
        RefreshTokenExpiry,
      } = res.data;
      updateLocalAccessToken(
        AccessToken,
        AccessTokenExpiry,
        RefreshToken,
        RefreshTokenExpiry
      );

      return AccessToken;
    } catch (error) {
      alert("Session timeout, please login again");

      logout();
      history.push("/login");

      throw new Error("Session timeout, please login again");
    }
  }

  const Axios = useMemo(() => {
    let headers = {};
    if (user) {
      headers = {
        Authorization: user.AccessToken,
      };
    }

    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers,
    });

    if (user) {
      const responseSuccessHandler = (response) => response;

      const responseErrorHandler = async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest.retry) {
          originalRequest.retry = true;

          const newToken = await retrieveNewToken(axiosInstance);
          axiosInstance.defaults.headers.Authorization = newToken;
          // return originalRequest object with Axios.
          return axiosInstance(originalRequest);
        }

        return Promise.reject(error);
      };

      axiosInstance.interceptors.response.use(
        (response) => responseSuccessHandler(response),
        (error) => responseErrorHandler(error)
      );
    }
    return axiosInstance;
  }, [user]);

  React.useEffect(() => {
    if (user) {
      setTimeout(() => {
        retrieveNewToken(Axios);
      }, moment(user.AccessTokenExpiry).diff(moment()));
    }
  }, [user]);

  return <ApiContext.Provider value={Axios}>{children}</ApiContext.Provider>;
}
ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ApiContext;
