/* eslint-disable */

import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse, Container } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

import avatar from "assets/img/icon/Megan logo.png";
import logo from "assets/img/icon/Megan logo.png";
import UserContext from "services/UserContext";
import ShopContext from "services/ShopContext";
import Select from "react-select";
import { ToastContainer } from "react-toastify";

let ps;
function Sidebar(props) {
  const { logout, user } = useContext(UserContext);
  const { ShopIDs, focusShopID, selctShopID, department } =
    useContext(ShopContext);
  const [openAvatar, setOpenAvatar] = React.useState(false);
  const [collapseStates, setCollapseStates] = React.useState({});
  const [deptSetting, setDeptSetting] = React.useState(false);
  const [passwordSetting, setPasswordSetting] = React.useState(false);
  const sidebar = React.useRef();
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i += 1) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) =>
    props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  React.useEffect(() => {
    if (department && department.Position === "DepartmentHead") {
      setDeptSetting(true);
    } else {
      setDeptSetting(false);
    }
  }, []);

  React.useEffect(() => {
    if (user && user.IsOneTimePassword === true) {
      setPasswordSetting(true);
    } else {
      setPasswordSetting(false);
    }
  }, [user]);
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) =>
    routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop.state] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={prop.name}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={prop.name}>
          <NavLink to={prop.layout + prop.path}>
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <ToastContainer
        bodyStyle={{ whiteSpace: "pre-line" }}
        style={{ zIndex: "500" }}
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="logo">
        <a href="" className="simple-text logo-mini">
          <div className="logo-img">
            <img src={logo} alt="react-logo" />
          </div>
        </a>
        <a href="" className="simple-text logo-normal">
          {selctShopID && selctShopID.Name}
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <div className="user">
          <div className="photo">
            <img src={avatar} alt="Avatar" />
          </div>
          <div className="info">
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={openAvatar}
              onClick={() => setOpenAvatar(!openAvatar)}
            >
              <span>
                {user && user.Fullname?.length > 15
                  ? `${user.Fullname.substring(0, 16)}...`
                  : user.Fullname}
                <b className="caret" />
              </span>
            </a>
            <Collapse isOpen={openAvatar}>
              <ul className="nav">
                {/* <li>
                  <NavLink to="/shopOwner/shop-profile">
                    <span className="sidebar-mini-icon">MS</span>
                    <span className="sidebar-normal">My Shop</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shopOwner/shop-profile">
                    <span className="sidebar-mini-icon">ES</span>
                    <span className="sidebar-normal">Edit Shop</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/shopOwner/shop-profile">
                    <span className="sidebar-mini-icon">S</span>
                    <span className="sidebar-normal">Settings</span>
                  </NavLink>
                </li> */}
                {deptSetting ? (
                  <>
                    <li>
                      <NavLink to="/shopOwner/department">
                        <span className="sidebar-mini-icon">DS</span>
                        <span className="sidebar-normal">
                          Department Settings
                        </span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/shopOwner/changepassword">
                        <span className="sidebar-mini-icon">RSP</span>
                        <span className="sidebar-normal">
                          Reset Staff Password
                        </span>
                      </NavLink>
                    </li>
                  </>
                ) : (
                  ""
                )}
                {passwordSetting ? (
                  <li>
                    <NavLink to="/shopOwner/setPassword">
                      <span className="sidebar-mini-icon">SP</span>
                      <span className="sidebar-normal">Set New Password</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <a onClick={logout} href="">
                    <span className="sidebar-mini-icon">L</span>
                    <span className="sidebar-normal">Logout</span>
                  </a>
                </li>
              </ul>
            </Collapse>
          </div>
        </div>
        <Nav>
          <li>
            {ShopIDs && (
              <Container className="text-white text-center">
                {/* <span className="sidebar-normal text-center">
                  Shop To Manage
                </span> */}
                {/* <Select
                  className="react-select primary pt-2"
                  classNamePrefix="react-select"
                  onChange={(value) => {
                    focusShopID(value.value);
                  }}
                  defaultValue={{
                    value: ShopIDs[0],
                    label: ShopIDs[0].Name,
                  }}
                  options={ShopIDs.map((IDs) => ({
                    value: IDs,
                    label: IDs.Name,
                  }))}
                  isDisabled={true}
                /> */}
              </Container>
            )}
          </li>
          {createLinks(props.routes)}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
