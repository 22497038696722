/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../sales.css";
import moment from "moment";
import { toast } from "react-toastify";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import CustomButton from "components/CustomButton/CustomButton";
import AddQCphoto from "./AddQCphoto";
import QCimage from "./QCimage";
import InvoiceOrder from "./InvoiceOrder";
import PreviewOrder from "./PreviewOrder";
import OrderLogistisUpdate from "./OrderLogistisUpdate";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function CSOrder() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [isFirstSubmit, setIsFirstSubmit] = useState(true);
  // const [isMaxSubmit, setIsMaxSubmit] = useState();
  const [dropItemList, setDropItemList] = React.useState({
    orderSource: [],
    logistic: [],
    status: [],
    // coupon: [],
  });

  const [dropFilter, setDropFilter] = React.useState({
    orderSource: "Order Source",
    logistic: "Logistic",
    status: "Status",
    // coupon: "Coupon",
  });

  const [dropOpen, setDropOpen] = React.useState("");

  const dropDownOptionGet = (data, id) => {
    const set = new Set();
    data.data.Result.forEach((row) => {
      if (row[id] !== "") set.add(row[id]);
    });
    return [...set.values()];
  };
  const { isLoading, data, isSuccess, refetch } = useQuery(
    `/shop/${shopID}/Order/QC`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/QC`, {
        params: {
          limitPerPage: 9999,
        },
      }).then((res) => {
        setDropItemList({
          orderSource: dropDownOptionGet(res, "OrderSource"),
          logistic: dropDownOptionGet(res, "Courier"),
          status: dropDownOptionGet(res, "ShopOrderStatus"),
          // coupon: dropDownOptionGet(res, "CouponApplied"),
        });

        setFilterDataResult(res.data.Result);
        refetch();
        return res;
      }),
    { refetchOnWindowFocus: true }
  );

  const columns = [
    {
      name: "Order&Invoice No",
      selector: (row) => (
        <div className="m-2">
          <p>{`#${row.ID}`}</p>
          <p>{row.Invoice}</p>
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "DateTime",
      selector: (row) => (
        <div>
          <p>{moment(row.OrderDate).format("DD/MM/YYYY")}</p>
          {moment(row.OrderDate).format("hh:mm A")}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Source",
      selector: (row) => row.OrderSource,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Coupon",
      selector: (row) =>
        row.CouponApplied.length === 0 ? row.CouponApplied.Code : null,
      sortable: true,
      wrap: true,
    },
    {
      name: "Amount",
      selector: (row) => (
        <div>
          <CurrencyFormat
            decimal={2}
            price={row.TotalOrderCost.toFixed(2)}
            currency="MYR"
          />
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Payment Method",
      selector: (row) => row.PaymentGateway,
      sortable: true,
      wrap: true,
    },

    {
      name: "Logistic",
      selector: (row) => {
        const [showLogisticModal, setShowLogisticModal] = useState({
          orderID: "",
          open: false,
        });
        if (!row) return null;

        return (
          <>
            <p>
              {row.DeliveryType === "Courier" &&
              row.Courier === "" &&
              row.OrderQCImageList.length !== 0 ? (
                <>
                  <p>{row.DeliveryType}</p>
                  <Button
                    color="link"
                    style={{
                      color: "white",
                    }}
                    px
                    key={row.ID}
                    className="pl-1 pr-1 border"
                    onClick={() =>
                      setShowLogisticModal({
                        orderID: row.ID,
                        open: !showLogisticModal.open,
                      })
                    }
                  >
                    Add Logistic Info
                  </Button>
                </>
              ) : (
                <>
                  <p className="text-white">{row.DeliveryType}</p>
                  <p className="text-white">{row.Courier}</p>
                  <p className="text-white">{row.TrackingCode}</p>
                </>
              )}
            </p>
            <Modal
              size="lg"
              isOpen={showLogisticModal.open}
              toggle={() =>
                setShowLogisticModal({
                  ...showLogisticModal,
                  open: !showLogisticModal.open,
                })
              }
            >
              <div>
                <Button
                  className="btn btn-primary float-right mr-5"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setShowLogisticModal({
                      orderID: row.ID,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
              </div>

              <ModalBody>
                <OrderLogistisUpdate
                  orderID={showLogisticModal.orderID}
                  setShowLogisticModal={setShowLogisticModal}
                  showLogisticModal={showLogisticModal}
                  tableRefetch={refetch}
                />
              </ModalBody>
            </Modal>
          </>
        );
      },
      sortable: false,
      wrap: true,
    },
    {
      name: "PIC",
      selector: (row) => (
        <span>
          {row.PICPhoto && row.PICPhoto.Name && row.PICPhoto.Name.length > 0 ? (
            <>
              <p
                className="font-weight-bold pt-3"
                style={{ lineHeight: "1px" }}
              >
                Photo:
              </p>
              <p
                className="font-weight-bold pl-1 r-3"
                style={{ lineHeight: "16px" }}
              >
                {row.PICPhoto.Name}
              </p>
              <p>
                {`${moment(row.PICPhoto.LastUpdateDate).format(
                  "YYYY-MM-DD HH:MM A"
                )}`}
              </p>
            </>
          ) : (
            ""
          )}

          {row.PICChecked &&
          row.PICChecked.Name &&
          row.PICChecked.Name.length > 0 ? (
            <>
              <p
                className="font-weight-bold mr-3 mt-3 ml-3 mb-2 "
                style={{ lineHeight: "1px" }}
              >
                Checked:
              </p>
              <p
                className="font-weight-bold pl-1 r-3 "
                style={{ lineHeight: "16px" }}
              >
                {row.PICChecked.Name}
              </p>
              <p className="mb-2">
                {`${moment(row.PICChecked.LastUpdateDate).format(
                  "YYYY-MM-DD HH:MM A"
                )}`}
              </p>
            </>
          ) : (
            "-"
          )}
        </span>
      ),
      sortable: false,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.ShopOrderStatus,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      wrap: true,
      selector: (row) => {
        const [previewModal, setPreviewModal] = useState({
          orderID: "",
          open: false,
        });
        const [invoiceModal, setInvoiceModal] = useState({
          orderID: "",
          open: false,
        });
        const [imageModal, setImageModal] = useState({
          imageList: [],
          orderID: "",
          open: false,
        });
        const [showModal, setShowModal] = useState({
          orderID: "",
          open: false,
        });

        if (row.OrderQCImageList.length === 0) {
          setIsFirstSubmit(true);
        } else {
          setIsFirstSubmit(false);
        }

        if (!row) return null;

        return (
          <div>
            <CustomButton
              onClick={() =>
                setPreviewModal({
                  orderID: row.ID,
                  open: !previewModal.open,
                })
              }
              icon="fa fa-eye"
              title="View"
            />

            <CustomButton
              onClick={() =>
                setInvoiceModal({
                  orderID: row.ID,
                  open: !invoiceModal.open,
                })
              }
              icon="fa fa-file-text"
              title="Invoice"
            />
            <CustomButton
              to={`/shopowner/sales/vieworderdetails/${row.ID}`}
              icon="fa fa-search-plus"
              title="Order Details"
            />
            {row.OrderQCImageList.length !== 0 ? (
              <CustomButton
                onClick={() =>
                  setImageModal({
                    imageList: row.OrderQCImageList,
                    orderID: row.ID,
                    open: !imageModal.open,
                  })
                }
                icon="fa fa-picture-o"
                title="Photos"
              />
            ) : null}
            {row.ShopOrderStatus !== "Picked" &&
            row.OrderQCImageList.length < 6 ? (
              <p>
                <CustomButton
                  onClick={() =>
                    setShowModal({
                      orderID: row.ID,
                      open: !showModal.open,
                    })
                  }
                  icon=""
                  title=""
                  text="Add Photos"
                  color="btn-primary"
                  size="btn-sm"
                />
              </p>
            ) : null}
            <Modal
              size="lg"
              isOpen={previewModal.open}
              toggle={() =>
                setPreviewModal({
                  ...previewModal,
                  open: !previewModal.open,
                })
              }
            >
              <div>
                <Button
                  className="btn btn-primary float-right mr-5"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setPreviewModal({
                      ...previewModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
              </div>

              <ModalBody>
                <PreviewOrder previewID={previewModal.orderID} />
              </ModalBody>
            </Modal>
            <Modal
              size="xl"
              isOpen={invoiceModal.open}
              toggle={() =>
                setInvoiceModal({
                  ...invoiceModal,
                  open: !invoiceModal.open,
                })
              }
            >
              <div>
                <Button
                  className="btn btn-primary float-right mr-5"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setInvoiceModal({
                      ...invoiceModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
              </div>

              <ModalBody>
                <InvoiceOrder invoiceID={invoiceModal.orderID} />
              </ModalBody>
            </Modal>
            <Modal
              size="md"
              isOpen={imageModal.open}
              toggle={() =>
                setImageModal({
                  ...imageModal,
                  open: !imageModal.open,
                })
              }
            >
              <div>
                <Button
                  className="btn btn-primary float-right mr-5"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setImageModal({
                      ...imageModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
              </div>

              <ModalBody>
                <QCimage
                  imageList={imageModal.imageList}
                  orderID={imageModal.orderID}
                  setShowModal={setImageModal}
                  showModal={imageModal}
                  tableRefetch={refetch}
                />
              </ModalBody>
            </Modal>
            <Modal
              size="lg"
              isOpen={showModal.open}
              toggle={() =>
                setShowModal({
                  ...showModal,
                  open: !showModal.open,
                })
              }
            >
              <ModalHeader>
                <Button
                  className="btn btn-primary float-right"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setShowModal({
                      ...showModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
                Add Photos
              </ModalHeader>
              <ModalBody>
                <AddQCphoto
                  pID={showModal.orderID}
                  setShowModal={setShowModal}
                  showModal={showModal}
                  tableRefetch={refetch}
                  isFirstSubmit={isFirstSubmit}
                  // isMaxSubmit={isMaxSubmit}
                  OrderQCImageList={row.OrderQCImageList.length}
                />
              </ModalBody>
            </Modal>
          </div>
        );
      },
    },
  ];

  const dropdownToggle = (filter) => {
    if (filter === dropOpen) {
      setDropOpen("");
    } else {
      setDropOpen(filter);
    }
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setDropFilter({
      orderSource: "Order Source",
      logistic: "Logistic",
      status: "Status",
      // coupon: "Coupon",
    });
    setFilterDataResult(data.data.Result);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.Result.filter((item) =>
        startDate !== null
          ? new Date(item.OrderDate).setHours(0, 0, 0, 0) >=
            new Date(
              moment(startDate).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            ).getTime()
          : item
      )
        .filter((item) =>
          endDate !== null
            ? new Date(item.OrderDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          dropFilter.orderSource !== "Order Source"
            ? item.OrderSource === dropFilter.orderSource
            : item
        )
        .filter((item) =>
          dropFilter.logistic !== "Logistic"
            ? item.Courier === dropFilter.logistic
            : item
        )
        .filter((item) =>
          dropFilter.status !== "Status"
            ? item.ShopOrderStatus === dropFilter.status
            : item
        )
        // .filter((item) =>
        //   dropFilter.status !== "Coupon"
        //     ? item.CouponApplied.map((coupons) => coupons.Code) ===
        //       dropFilter.coupon
        //     : item
        // )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  const convertToCSV = (data2) => {
    const header = [
      "Order&Invoice No",
      "DateTime",
      "Order Source",
      "Name",
      "Coupon",
      "Amount",
      "Payment Method",
      "Logistic",
      "PIC",
      "Status",
    ];
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (fieldName === "Order&Invoice No") {
              return `#${row.ID} ${row.Invoice}`;
            }
            if (fieldName === "DateTime") {
              return JSON.stringify(
                moment(row.OrderDate).format("DD/MM/YYYY hh:mm A")
              );
            }

            if (fieldName === "Order Source") {
              return JSON.stringify(row.OrderSource);
            }
            if (fieldName === "Name") {
              return JSON.stringify(row.Name);
            }

            if (fieldName === "Coupon") {
              return JSON.stringify(
                row.CouponApplied.length === 0 ? row.CouponApplied.Code : null
              );
            }

            if (fieldName === "Amount") {
              return `"RM ${row.TotalOrderCost.toFixed(2).replace(
                /(\d)(?=(\d{3})+\.)/g,
                "$1,"
              )}"`; // add double quotes and format as string
            }
            if (fieldName === "Payment Method") {
              return JSON.stringify(row.PaymentGateway);
            }
            if (fieldName === "Logistic") {
              const logisticText = `${row.DeliveryType} ${row.Courier || ""} ${
                row.TrackingCode || ""
              }`;
              return logisticText;
            }
            if (fieldName === "PIC") {
              const picText =
                (row.PICPhoto && row.PICPhoto.Name
                  ? `Photo: ${row.PICPhoto.Name} ${moment(
                      row.PICPhoto.LastUpdateDate
                    ).format("YYYY-MM-DD HH:MM A")} `
                  : "") +
                (row.PICChecked && row.PICChecked.Name
                  ? `Checked: ${row.PICChecked.Name} ${moment(
                      row.PICChecked.LastUpdateDate
                    ).format("YYYY-MM-DD HH:MM A")} `
                  : "-");
              return picText;
            }
            if (fieldName === "Status") {
              return JSON.stringify(row.ShopOrderStatus);
            }
            return JSON.stringify(row[fieldName]);
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadDaily = () => {
    const params = {
      dateFrom: startDate
        ? moment(startDate).format()
        : moment().startOf("day").format(),
      dateTo: endDate
        ? moment(endDate).format()
        : moment().endOf("day").format(),
    };

    Axios.get(`/shop/${shopID}/Order/Sale/DailyBill/Report/Excel`, {
      params,
    })
      .then((response) => {
        const downloadUrl = response.data;
        window.location.href = downloadUrl;
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };
  const downloadWorkPDF = () => {
    Axios.get(`/shop/${shopID}/Order/QC/Report/Work/PDF`)
      .then((response) => {
        const downloadUrl = response.data;
        window.open(downloadUrl, "_blank");
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };

  const downloadWorkEx = () => {
    Axios.get(`/shop/${shopID}/Order/QC/Report/Work/Excel`)
      .then((response) => {
        const downloadUrl = response.data;
        window.location.href = downloadUrl;
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };

  const downloadChecked = () => {
    Axios.get(`/shop/${shopID}/Order/QC/Report/Checked/PDF`, {
      params: { limitPerPage: 9999 },
    })
      .then((response) => {
        const downloadUrl = response.data;
        window.open(downloadUrl, "_blank");
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };

  const downloadGdex = () => {
    Axios.get(`/shop/${shopID}/Order/QC/Report/GDex`)
      .then((response) => {
        const downloadUrl = response.data;
        window.location.href = downloadUrl;
      })
      .catch((error) => {
        toast.error("Error:", error);
      });
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">All Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Filter:
            </span>
            <Dropdown
              isOpen={dropOpen === "OrderSource"}
              toggle={() => dropdownToggle("OrderSource")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.orderSource}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      orderSource: "Order Source",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.orderSource.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, orderSource: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropOpen === "Logistic"}
              toggle={() => dropdownToggle("Logistic")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.logistic}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      logistic: "Logistic",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.logistic.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, logistic: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropOpen === "Status"}
              toggle={() => dropdownToggle("Status")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.status}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      status: "Status",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.status.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, status: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            {/* <Dropdown
              isOpen={dropOpen === "Coupon"}
              toggle={() => dropdownToggle("Coupon")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.coupon}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      logistic: "Coupon",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.coupon?.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, coupon: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown> */}
            <Button className="ml-1" onClick={() => filterData()}>
              Filter
            </Button>
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(filterDataResult);
              }}
            >
              Export CSV
            </Button>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadDaily(filterDataResult);
              }}
            >
              Daily Bill Excel
            </Button>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadWorkPDF();
              }}
            >
              Work PDF
            </Button>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadWorkEx();
              }}
            >
              Work Excel
            </Button>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadChecked();
              }}
            >
              Checked Report PDF
            </Button>

            <Button
              outline
              color="filter"
              onClick={() => {
                downloadGdex();
              }}
            >
              GDex Excel
            </Button>
          </div>
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                // selectableRows
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CSOrder;
