/* eslint-disable react/prop-types */
/* eslint-disable new-cap */
import React, { useContext } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../customerService/customerservice.css";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

const columns = [
  {
    name: "DateTime",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Description",
    selector: (row) => row.Description,
    sortable: true,
    wrap: true,
  },
  {
    name: "Reference",
    selector: (row) => row.Remarks,
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        <CurrencyFormat decimal={2} price={row.EWalletValue} currency="MYR" />
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Type",
    selector: (row) => (
      <div>{row.Action === "Add" ? <p>Debit</p> : <p>Credit</p>}</div>
    ),
    sortable: true,
    wrap: true,
  },
];

const ExampleCustomInput = React.forwardRef(
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function ViewEwallet() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/Ewallet/SingleUserEwalletData/${id}`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Ewallet/SingleUserEwalletData/${id}`, {
        params: {
          limitPerPage: "1000000",
        },
      }).then((res) => {
        const reversedData = res.data.EwalletLogList.reverse();
        setFilterDataResult(reversedData);
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setDateRange([null, null]);

    setFilterDataResult(data.data.EwalletLogList);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.EwalletLogList.filter((item) =>
        startDate !== null
          ? new Date(item.CreatedDate) >=
            new Date(
              moment(startDate).set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
            ).getTime()
          : item
      )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Description &&
              item.Description.toLowerCase().includes(
                searchQuery.toLowerCase()
              )) ||
            (item.Remarks &&
              item.Remarks.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Action === "Add" ? "Debit" : "Credit")
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
        );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Ewallet Dashboard</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <hr
            className="mr-1"
            style={{
              border: "1px solid #51BCDA",
              width: "100%",
            }}
          />
          <Card className="card-dark" style={{ minWidth: "350px" }}>
            <CardBody>
              <div>
                <p>{data.data.Name}</p>
                <p>{data.data.Email}</p>
                <p className="float-right">
                  Current Wallet Balance: &nbsp;
                  <CurrencyFormat
                    decimal={2}
                    price={data.data.Balance}
                    currency="MYR"
                  />
                </p>
              </div>
            </CardBody>
          </Card>
          <hr
            className="mr-1"
            style={{
              border: "1px solid #51BCDA",
              width: "100%",
            }}
          />
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ViewEwallet;
