/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useContext } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../customerService/customerservice.css";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const columns = [
  {
    name: "Order No",
    selector: (row) => <p>{`#${row.OrderID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Date",
    selector: (row) => (
      <div>
        <p>{moment(row.OrderCreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.OrderCreatedDate).format("hh:mm A")}
      </div>
    ),
  },
  {
    name: "Reference No",
    selector: (row) => <p>{`#${row.ID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Return Request Date",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Product",
    selector: (row) => (
      <div>
        <p>{row.Product.ProductEngName}</p>
        {row.Product.VariationList.map((item) => (
          <>
            <p>{item.VariationSKU}</p>
            <p>{`Weight(g): ${item.Weight}`}</p>
          </>
        ))}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Reason(s)",
    selector: (row) => (
      <div>
        {row.ReturnReasonList.map((reason) => (
          <p>{reason}</p>
        ))}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Comments",
    selector: (row) => row.Comment,
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => {
      if (!row) return null;

      return (
        <div>
          <p className="mt-2">{row.Status}</p>

          <Button
            id="itemhover"
            color="link"
            key={row.ID}
            className="pl-0 pr-0"
            tag={Link}
            to={`/shopowner/ReturnRefund/viewRefunddetails/${row.OrderID}/${row.ID}`}
          >
            <i
              className="fa fa-search-plus"
              aria-hidden="true"
              style={{ color: "white", fontSize: "18px" }}
              title="Order Detais"
            />
          </Button>
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function CanceledRefund() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () => {
      if (shopID !== "0") {
        const res = await Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`);
        const filteredData = res.data.filter(
          (item) => item.Status === "Review Rejected"
        );
        setFilterResult(filteredData);
        setFilterDataResult(filteredData);

        return res;
      }
    },
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setDateRange([null, null]);
    setFilterDataResult(filterResult);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Canceled Refund</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CanceledRefund;
