/* eslint-disable react/no-unknown-property */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../sales.css";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const columns = [
  {
    name: "Order No",
    selector: (row) => <p>{`#${row.OrderID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Date",
    selector: (row) => (
      <div>
        <p>{moment(row.OrderCreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.OrderCreatedDate).format("hh:mm A")}
      </div>
    ),
  },
  {
    name: "Reference No",
    selector: (row) => <p>{`#${row.ID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Return Request Date",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Customer Name",
    selector: (row) => row.Name,
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => row.Status,
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [showModal, setShowModal] = useState({
        rID: "",
        open: false,
      });
      if (!row) return null;

      return (
        <div>
          <Button
            id="itemhover"
            color="link"
            key={row.ID}
            className="pl-0 pr-0"
            tag={Link}
            to={`/shopowner/sales/viewrefunddetails/${row.OrderID}/${row.ID}`}
          >
            <i
              className="fa fa-search-plus"
              aria-hidden="true"
              style={{ color: "white", fontSize: "18px" }}
              title="Order Detais"
            />
          </Button>
          {row.Status === "Item Transferred to QC" ? (
            <>
              <Button
                size="sm"
                onClick={() =>
                  setShowModal({
                    rID: row.ID,
                    open: !showModal.open,
                  })
                }
              >
                Update Status
              </Button>
              <Modal
                size="lg"
                isOpen={showModal.open}
                toggle={() =>
                  setShowModal({
                    ...showModal,
                    open: !showModal.open,
                  })
                }
              >
                <ModalHeader>
                  <Button
                    className="btn btn-primary float-right"
                    style={{ fontSize: "12px" }}
                    size="sm"
                    onClick={() =>
                      setShowModal({
                        ...showModal,
                        open: false,
                      })
                    }
                  >
                    x
                  </Button>
                  Update Received Item
                </ModalHeader>
                <ModalBody>
                  <updateReceivedStatus
                    rID={showModal.rID}
                    setShowModal={setShowModal}
                    showModal={showModal}
                  />
                </ModalBody>
              </Modal>
            </>
          ) : null}
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function RefundShipBack() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [selectedData, setSelectedData] = React.useState([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [filterDt, setFilterDt] = React.useState([]);

  const { isLoading, data, isSuccess, refetch } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () => {
      if (shopID !== "0") {
        const res = await Axios.get(
          `/shop/${shopID}/GetFilteredReturnRequest`,
          {
            params: {
              limitPerPage: 9999,
            },
          }
        );
        const filteredData = res.data.filter(
          (item) =>
            item.Status === "Pending Ship Back" ||
            item.Status === "Pending Transfer To Dispatch"
        );
        setFilterDataResult(filteredData);
        setFilterDt(filteredData);
        refetch();
        return res;
      }
    },
    { refetchOnWindowFocus: false }
  );
  const resetFilter = () => {
    setDateRange([null, null]);
    setFilterDataResult(filterDt);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };
  const convertToCSV = (data2) => {
    const header = [
      "Order No",
      "Order Date",
      "Reference",
      "Return Refund Date",
      "Customer Name",
      "Status",
    ];
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (fieldName === "Order No" || fieldName === "Reference") {
              return `#${JSON.stringify(row.OrderID)}`;
            }
            if (fieldName === "Order Date") {
              return JSON.stringify(
                moment(row.OrderCreatedDate).format("DD/MM/YYYY hh:mm A")
              );
            }
            if (fieldName === "Customer Name") {
              return JSON.stringify(row.Name);
            }
            if (fieldName === "Return Refund Date") {
              return JSON.stringify(
                moment(row.CreatedDate).format("DD/MM/YYYY hh:mm A")
              );
            }
            return JSON.stringify(row[fieldName]);
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Refund Ship Back</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>
            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(
                  selectedData.length > 0 ? selectedData : filterDataResult
                );
              }}
            >
              Export CSV
            </Button>
          </div>
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                selectableRows
                onSelectedRowsChange={handleSelectRows}
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default RefundShipBack;
