/* eslint-disable new-cap */
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Card,
  Row,
  Col,
  Input,
  Label,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Spinner,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
// core components
import BasicTable from "views/tables/BasicTable";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";

const columns = [
  {
    name: "No",
    selector: (row) => row.No,
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => row.Status,
    sortable: true,
    wrap: true,
  },
  {
    name: "Loyalty Rank",
    selector: (row) => row.LoyaltyRank,
    sortable: true,
    wrap: true,
  },
  {
    name: "Name",
    selector: (row) => row.FullName,
    sortable: true,
    wrap: true,
  },
  {
    name: "Contact",
    selector: (row) => row.Contact,
    wrap: true,
  },
  {
    name: "Points",
    selector: (row) => row.Points,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => (
      <Link to={`/shopOwner/marketing/rewards/memberDetail/${row.ID}`}>
        <Button>Open</Button>
      </Link>
    ),
    wrap: true,
  },
];

function CustomLoader() {
  return (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div>Fancy Loader...</div>
    </div>
  );
}

function RewardMemberShip() {
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const [totalPageLoad, setTotalPageLoad] = React.useState(100);

  const [searchQuery, setSearchQuery] = React.useState(null);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [lastBuyDateRange, setLastBuyDateRange] = React.useState([null, null]);
  const [lastBuystartDate, lastBuyendDate] = lastBuyDateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [dropdownOpen, setDropdownOpen] = React.useState("");
  const [selectedLoyalty, setSelectedLoyalty] = React.useState(null);
  const [selectedStt, setSelectedStt] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState([0, 0]);
  const [startAmount, setStartAmount] = React.useState(0);
  const [endAmount, setEndAmount] = React.useState(0);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedData, setSelectedData] = React.useState([]);
  const [loadingData, setLoadingData] = useState(false);

  const columnsForTable = [
    "No",
    "Status",
    "LoyaltyRank",
    "FullName",
    "Contact",
    "Points",
  ];

  const searchDebounce = React.useRef(null);
  const {
    isLoading,
    // data: MembershipData,
    refetch,
  } = useQuery(
    [
      "MembershipData",
      selectedState,
      totalPageLoad,
      totalPageLoad,
      dateRange,
      lastBuyDateRange,
      selectedLoyalty,
      selectedStt,
      selectedOrder,
      searchQuery,
    ],
    async () => {
      if (!searchDebounce.current) {
        searchDebounce.current = setTimeout(async () => {
          try {
            const res = await Axios.get(
              `/shop/${shopID}/Marketing/Membership`,
              {
                params: {
                  joinDateFrom: startDate,
                  joinDateTo: endDate,
                  lastPurchaseDateFrom: lastBuystartDate,
                  lastPurchaseDateTo: lastBuyendDate,
                  loyaltyTier: selectedLoyalty,
                  status: selectedStt,
                  totalOrderFrom: startAmount || null,
                  totalOrderTo: endAmount || null,
                  state: selectedState,
                  limitPerPage: totalPageLoad,
                  search: searchQuery,
                },
              }
            );
            setFilterDataResult(res?.data);
          } finally {
            searchDebounce.current = null;
          }
        }, 100); // debounce of 500ms
      }
    }
  );

  // function handleSearchInputChange(event) {
  //   setSearchQuery(event.target.value);
  // }

  // const filteredData = MembershipData?.data.Result.filter((Oreport) => {
  //   const searchTerms = Object.values(Oreport).join(" ").toLowerCase();
  //   return searchTerms.includes(searchQuery.toLowerCase());
  // });
  // React.useEffect(() => {
  //   setFilterDataResult(filteredData);
  // }, [searchQuery, filteredData, MembershipData]);

  const toggleDropdown = (x) => {
    if (dropdownOpen === x) {
      setDropdownOpen("");
    } else {
      setDropdownOpen(x);
    }
  };

  const resetFilter = () => {
    // setFilterDataResult(MembershipData);
    setDateRange([null, null]);
    setLastBuyDateRange([null, null]);
    setEndAmount(0);
    setStartAmount(0);
    setSelectedLoyalty(null);
    setSelectedStt(null);
    setSelectedState(null);
    setSelectedOrder([0, 0]);
    setSearchQuery(null);

    setTimeout(() => {
      refetch();
    }, 500);
  };

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const exportPDF = (jsondata) => {
    const doc = new jsPDF({ orientation: "landscape", size: "A4" });
    doc.setFontSize(12);

    let bodydata = jsondata.map((d) => columnsForTable.map((e) => d[e]));

    bodydata = bodydata.map((item) => {
      const updatedItem = Object.keys(item).reduce((acc, key) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          acc[key] = item[key];
        }
        return acc;
      }, {});

      return updatedItem;
    });

    autoTable(doc, {
      head: [columns.map((c) => c.name)],
      body: bodydata,
    });
    doc.save("MembershipReport.pdf");
  };

  useEffect(() => {
    if (isLoading) {
      setLoadingData(true);
    } else {
      setLoadingData(false);
    }
  }, [isLoading]);

  // if (isLoading) {
  //   return (
  //     <div className="content d-flex align-items-center justify-content-center">
  //       <Spinner />
  //     </div>
  //   );
  // }

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <div className="d-flex justify-content-between">
        <h3>Membership</h3>
      </div>

      <Card className="card-dark">
        <div className="p-3">
          <Row className="mb-3">
            <Col xs="6" sm="4" md="3">
              <Label>Total Data Display</Label>
              <Input
                type="select"
                defaultValue="100"
                onChange={(e) => setTotalPageLoad(Number(e.target.value))}
              >
                <option value="50">Show 50 entries</option>
                <option value="100">Show 100 entries</option>
                <option value="1000">Show 1000 entries</option>
              </Input>
            </Col>
            <Col xs="12" sm="6" md="6">
              <Label>Search</Label>
              <div className="d-flex align-items-center">
                <Input
                  type="text"
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </Col>

            <Button
              className="mt-4"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                resetFilter();
              }}
            >
              Reset All Filter
            </Button>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label>Join Date</Label>
              <CustomCalendar
                setDateRange={setDateRange}
                compareCheckbox={false}
                setRange={() => {}}
                // style={{ width: "250px" }}
              />
            </Col>

            <Col>
              <Label>Last Purchase</Label>
              <CustomCalendar
                setDateRange={setLastBuyDateRange}
                compareCheckbox={false}
                setRange={() => {}}
                // style={{ width: "250px" }}
              />
            </Col>

            <Col>
              <Label>Loyalty Tier</Label>
              <Dropdown
                isOpen={dropdownOpen === "loyalty"}
                toggle={() => toggleDropdown("loyalty")}
              >
                <DropdownToggle className="mt-0 w-100" caret>
                  Loyalty Tier
                </DropdownToggle>
                <DropdownMenu right>
                  {["NORMAL", "SILVER", "GOLD"].map((item) => (
                    <DropdownItem key={item} toggle={false}>
                      <Container onClick={() => setSelectedLoyalty(item)}>
                        <Input
                          type="radio"
                          checked={selectedLoyalty === item}
                          name="loyalty-tier"
                        />
                        {item}
                      </Container>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col>
              <Label>Status</Label>
              <Dropdown
                isOpen={dropdownOpen === "status"}
                toggle={() => toggleDropdown("status")}
              >
                <DropdownToggle className="mt-0 w-100" caret>
                  Status
                </DropdownToggle>
                <DropdownMenu right>
                  {["Active", "Non-Active"].map((item) => (
                    <DropdownItem key={item} toggle={false}>
                      <Container onClick={() => setSelectedStt(item)}>
                        <Input type="radio" checked={selectedStt === item} />
                        {item}
                      </Container>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col>
              <Label>Total Order</Label>
              <Dropdown
                isOpen={dropdownOpen === "order"}
                toggle={() => toggleDropdown("order")}
              >
                <DropdownToggle className="mt-0 w-100" caret>
                  Total Order
                </DropdownToggle>
                <DropdownMenu right className="dropOrder">
                  <DropdownItem toggle={false}>
                    <Container>
                      <Label>Total Order Range</Label>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="pr-2">RM</span>
                        <Input
                          style={{ width: "100px" }}
                          onChange={(e) => setStartAmount(e.target.value)}
                          value={startAmount}
                        />
                        <span className="px-2"> - RM</span>

                        <Input
                          style={{ width: "100px" }}
                          onChange={(e) => setEndAmount(e.target.value)}
                          value={endAmount}
                        />

                        <Button
                          color="primary"
                          onClick={() =>
                            setSelectedOrder([startAmount, endAmount])
                          }
                          className="ml-2"
                        >
                          OK
                        </Button>
                      </div>
                    </Container>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>

            <Col>
              <Label>State</Label>
              <Dropdown
                isOpen={dropdownOpen === "state"}
                toggle={() => toggleDropdown("state")}
              >
                <DropdownToggle className="mt-0 w-100" caret>
                  State
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem disabled toggle={false}>
                    <h6>West Malaysia</h6>
                  </DropdownItem>
                  {[
                    "JOHOR",
                    "KEDAH",
                    "KELANTAN",
                    "KUALA LUMPUR",
                    "MELAKA",
                    "NEGERI SEMBILAN",
                    "PERLIS",
                    "PERAK",
                    "PULAU PINANG",
                    "PAHANG",
                    "SELANGOR",
                    "TERENGGANU",
                    "PUTRAJAYA",
                  ].map((item) => (
                    <DropdownItem
                      key={item}
                      toggle={false}
                      className="py-0"
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedState(item);
                      }}
                    >
                      <Container>
                        <Input type="radio" checked={selectedState === item} />
                        {item}
                      </Container>
                    </DropdownItem>
                  ))}
                  <DropdownItem disabled toggle={false}>
                    <h6>East Malaysia</h6>
                  </DropdownItem>
                  {["SABAH", "SARAWAK", "LABUAN"].map((item) => (
                    <DropdownItem key={item} toggle={false} className="py-0">
                      <Container>
                        <Input
                          type="radio"
                          checked={selectedState === item}
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedState(item);
                          }}
                        />
                        {item}
                      </Container>
                    </DropdownItem>
                  ))}
                  <DropdownItem disabled />
                </DropdownMenu>
              </Dropdown>
            </Col>
            <span className="mr-2 ml-3 mt-3" style={{ fontSize: "16px" }}>
              Bulk Action :
            </span>
            <Button
              outline
              color="filter"
              onClick={() =>
                exportPDF(
                  selectedData.length > 0 ? selectedData : filterDataResult
                )
              }
            >
              Export PDF
            </Button>
          </Row>
          <div id="ElementId">
            <BasicTable
              pagination
              subHeaderWrap
              selectableRows
              onSelectedRowsChange={handleSelectRows}
              data={filterDataResult}
              columns={columns}
              progressPending={loadingData}
              progressComponents={<CustomLoader />}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}
export default RewardMemberShip;
