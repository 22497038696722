import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Spinner,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import ShopContext from "services/ShopContext";
import moment from "moment";
import { toast } from "react-toastify";
import ImageUpload from "components/CustomUpload/ImageUpload";
import BasicTable from "views/tables/BasicTable";
import { useQuery } from "react-query";

function CreateMarketPlace() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;

  const [scannedCode, setScannedCode] = React.useState("");
  const [scannedCode2, setScannedCode2] = React.useState("");
  const [scanFromScanner, setScanFromScanner] = React.useState(false);
  const [typingTimeout, setTypingTimeout] = React.useState(0);

  const [reset, setReset] = React.useState(false);
  // record product added
  const [productSelected, setProductSelected] = React.useState([]);
  // used to pass to api
  const [finalData, setFinalData] = React.useState([]);

  const validationSchema = Yup.object().shape({
    // ProductList: Yup.string().required("Product SN is required"),
    CustomerName: Yup.string().required("Name is required"),
    OrderID: Yup.string().required("Order ID is required"),
    OrderSourceID: Yup.string().required("Order Source is required"),
    Price: Yup.number().required("Price is required"),
  });

  const formik = useFormik({
    initialValues: {
      ProductList: "",
      CustomerName: "",
      OrderID: "",
      OrderSourceID: "",
      Price: "",
    },
    validationSchema,

    onSubmit: (data) => {
      const data2 = new FormData();
      data2.append("CustomerName", data.CustomerName);
      data2.append("OrderID", data.OrderID);
      data2.append("OrderSourceID", data.OrderSourceID);
      data2.append("MarketPlaceOrderID", data.OrderID);
      data2.append("Price", data.Price);
      data.ProductList.forEach((item) => {
        data2.append("ProductList", JSON.stringify(item));
      });

      if (data.ImageFile) {
        data2.append("ImageFile", data.ImageFile);
      }

      Axios.post(
        `/shop/${shopID}/MarketPlace/MarketPlaceProcessOrder`,
        data2
      ).then(() => {
        toast.success("Order created");
        setReset(true);
        setFinalData([]);
        formik.resetForm();
      });
      formik.setSubmitting(false);
      // Reset the image field before making the API call
      formik.setFieldValue("ImageFile", null);
    },
  });

  const { data: orderSouceList, isLoading } = useQuery(
    "AllOrderSource",
    async () =>
      Axios.get(
        "/public/MarketPlaceOrderSourceAtributes/AllMarketPlaceOrderSource"
      ).then((res) => res.data)
  );

  const imageCallback = (field, file) => {
    formik.setFieldValue(field, file);
  };

  React.useEffect(() => {
    formik.setFieldValue("ProductList", productSelected);
  }, [productSelected]);

  const { isLoading: isLoadingTable, refetch } = useQuery(
    `/shop/${shopID}/MarketPlace/SNProductScan/${
      scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode
    }/${formik.values.Price}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/MarketPlace/SNProductScan/${
          scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode
        }/${formik.values.Price}`
      )
        .then((res) => {
          if (res.data.ID) {
            formik.setFieldValue("ProductList", [...productSelected, res.data]);
            setProductSelected([...productSelected, res.data]);
            const temp = [...productSelected, res.data];
            temp.push({
              Price: [...productSelected, res.data].reduce(
                (acc, item) => acc + item.Price,
                0
              ),
            });
            setFinalData(temp);
            toast.success(`Added product: ${res.data.VariationSKU}`);
          }
          return res;
        })
        .catch((err) => toast.warn(err.response.data.MESSAGE)),
    { enabled: false }
  );

  const addScanItem = () => {
    if (scannedCode.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (
      !productSelected.find((i) => i.VariationSKU === scannedCode.trim())
    ) {
      setScanFromScanner(false);
      refetch();
    } else {
      toast.warning(`This item already added: ${scannedCode}`);
    }
    setScannedCode("");
  };

  const addScanItem2 = () => {
    if (scannedCode2.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (
      !productSelected.find((i) => i.VariationSKU === scannedCode2.trim())
    ) {
      setScanFromScanner(true);
      refetch();
    } else {
      toast.warning(`This item already added: ${scannedCode2}`);
    }
    setScannedCode("");
    setScannedCode2("");
  };

  // Used to handle the barcode scanner
  React.useEffect(() => {
    const handleBarcodeScan = async (event) => {
      clearTimeout(typingTimeout);
      const scannedValue = event.key;
      setScannedCode2((prevCode) => prevCode + scannedValue);
      const timeout = setTimeout(() => {}, 200);

      setTypingTimeout(timeout);

      if (scannedValue === "Enter") {
        await addScanItem2();
      }
    };
    document.addEventListener("keypress", handleBarcodeScan);

    return () => {
      document.removeEventListener("keypress", handleBarcodeScan);
    };
  }, [scannedCode2, typingTimeout]);

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="content">
      <Form>
        <FormGroup className="d-flex align-items-center">
          <h6 className="m-0">Date: </h6>
          <Input
            type="text"
            value={moment(new Date()).format("DD/MM/YYYY")}
            plaintext
            readOnly
            style={{ color: "white" }}
            className="ml-2"
          />
        </FormGroup>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>NAME</Label>
              <Input
                className={` ${
                  formik.errors.CustomerName ? "is-invalid" : "is-valid"
                }`}
                name="CustomerName"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.CustomerName}
              />
              <div className="text-danger">
                {formik.errors.CustomerName ? formik.errors.CustomerName : null}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Order ID</Label>
              <Input
                className={` ${
                  formik.errors.OrderID ? "is-invalid" : "is-valid"
                }`}
                name="OrderID"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.OrderID}
              />
              <div className="text-danger">
                {formik.errors.OrderID ? formik.errors.OrderID : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label htmlFor="Price">Price +/- (for each product)</Label>
              <Input
                className={`${formik.errors.Price ? "is-invalid" : "is-valid"}`}
                type="number"
                name="Price"
                onChange={formik.handleChange}
                value={formik.values.Price}
              />
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Product SN: </Label>
              <div className="d-flex">
                <Input
                  type="text"
                  name="ProductList"
                  placeholder="Product (SN)  Type/Scan Barcode"
                  value={scannedCode}
                  onChange={(e) => setScannedCode(e.target.value)}
                  className="h-100"
                />
                <Button
                  onClick={() => addScanItem()}
                  disabled={formik.values.Price === "" || scannedCode === ""}
                  className=" my-0"
                >
                  Add
                </Button>
              </div>
              <div className="text-danger">
                {formik.errors.Description ? formik.errors.Description : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            {orderSouceList?.length > 0 && (
              <FormGroup>
                <Label>Order Source</Label>
                <Input
                  name="OrderSourceID"
                  type="select"
                  onChange={formik.handleChange}
                  // defaultValue={}
                >
                  <option value="">Select Order Source</option>
                  {orderSouceList?.map((ordersource) => (
                    <option value={ordersource.ID}>
                      {ordersource.Attribute}
                    </option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.OrderSourceID
                    ? formik.errors.OrderSourceID
                    : null}
                </div>
              </FormGroup>
            )}
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label htmlFor="Price">Upload</Label>
              {/* <Input
                className={`${formik.errors.Price ? "is-invalid" : "is-valid"}`}
                type="number"
                name="Price"
                onChange={formik.handleChange}
                value={formik.values.Price}
              /> */}
              <div>
                <ImageUpload
                  reset={reset}
                  setReset={setReset}
                  imageCallback={imageCallback}
                  field="ImageFile"
                  perviouseUpload={formik.values.ImageFile}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <BasicTable
          progressPending={isLoadingTable}
          progressComponent={
            <div className="py-5">
              <Spinner />
            </div>
          }
          data={finalData}
          columns={[
            {
              name: "No",
              selector: (row, idx) => row.VariationSKU && idx + 1,
              sortable: true,
            },
            {
              name: "SN",
              selector: (row) => row.VariationSKU,
              sortable: true,
            },
            {
              name: "Name",
              selector: (row) => row.Name,
              sortable: true,
            },
            {
              name: "SKU",
              selector: (row) => row.ProductSKU,
              sortable: true,
            },
            {
              name: "Weight",
              selector: (row) => row.Weight,
              sortable: true,
            },
            {
              name: "Size",
              selector: (row) => row.Size,
              sortable: true,
            },
            {
              name: "Discounted Price",
              selector: (row) => row.Price,
              sortable: true,
            },
          ]}
        />
        <div className="text-center">
          <Button
            className="mt-3 button-dark-template"
            disabled={
              Object.values(formik.errors).length > 0 ||
              productSelected.length <= 0
            }
            onClick={formik.handleSubmit}
          >
            {formik.isSubmitting ? <Spinner /> : "Process Order"}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateMarketPlace;
