import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { toast } from "react-toastify";

function FilterCard({ data, sortBy, pickItemApi }) {
  const [dataScan, setDataScan] = React.useState([]);
  const [waitingList, setWaitingList] = React.useState([]);

  const [pickedConfirmMsg, setPickedConfirmMsg] = React.useState({
    msg: "",
    isOpen: false,
    idx: { variationSKU: "", OrderID: "" },
    Status: "",
  });

  const pickedConfirm = (idx, toPick) => {
    setPickedConfirmMsg({
      msg: `Product ${toPick ? "picked" : "unpicked"}: ${idx.variationSKU}?`,
      isOpen: true,
      idx,
      Status: toPick ? "Picked" : "Unpicked",
    });
  };

  const [scannedCode, setScannedCode] = React.useState("");
  const [typingTimeout, setTypingTimeout] = React.useState(0);

  // Used to handle the barcode scanner
  React.useEffect(() => {
    const handleBarcodeScan = (event) => {
      clearTimeout(typingTimeout);
      const scannedValue = event.key;
      setScannedCode((prevCode) => prevCode + scannedValue);

      const selectedItem = data.Result.ProductList.filter(
        (x) => x.Variation.VariationSKU === scannedCode
      );
      const timeout = setTimeout(() => {
        setScannedCode("");
        if (!dataScan.includes(scannedCode))
          setDataScan([...dataScan, scannedCode]);
      }, 200);

      setTypingTimeout(timeout);

      if (scannedValue === "Enter") {
        if (selectedItem.length > 0) {
          pickItemApi("Scan", {
            idx: {
              variationSKU: selectedItem[0].Variation.VariationSKU,
              OrderID: selectedItem[0].Variation.OrderID,
              Status: "Picked",
            },
          });
        } else {
          toast.warning(`This item not found: ${scannedCode}`);
        }
      }
    };

    document.addEventListener("keypress", handleBarcodeScan);

    return () => {
      document.removeEventListener("keypress", handleBarcodeScan);
    };
  }, [scannedCode, typingTimeout]);

  // Used to move the "picked" item to back

  const combineVariations = (array) => {
    const groupedVariations = array.reduce((result, item) => {
      const existingItem = result.find(
        (i) => i.Name === item.Name && i.ProductID === item.ProductID
      );

      if (existingItem) {
        existingItem.Variation.push({
          ...item.Variation,
          OrderID: item.OrderID,
          IsPickedByClick: item.IsPickedByClick,
          IsPickedByScan: item.IsPickedByScan,
          Status: item.Status,
        });
      } else {
        result.push({
          ...item,
          Variation: [
            {
              ...item.Variation,
              OrderID: item.OrderID,
              IsPickedByClick: item.IsPickedByClick,
              IsPickedByScan: item.IsPickedByScan,
              Status: item.Status,
            },
          ],
          // Add other necessary fields from the original object here
        });
      }

      const sortedProducts = result.sort((a, b) => {
        const allPickedA = a.Variation.every(
          (variation) => variation.Status === "Picked"
        );
        const allPickedB = b.Variation.every(
          (variation) => variation.Status === "Picked"
        );

        if (allPickedA && !allPickedB) {
          return 1;
        }
        if (!allPickedA && allPickedB) {
          return -1;
        }
        return 0;
      });

      return sortedProducts;
    }, []);
    return Object.values(groupedVariations);
  };

  const filterArrayByFilter = (array) => {
    const temp = array;
    temp.sort((a, b) => {
      if (a.Status === "Picked" && b.Status !== "Picked") {
        return 1;
      }
      if (a.Status !== "Picked" && b.Status === "Picked") {
        return -1;
      }
      return 0;
    });
    return temp;
  };

  // Used sorting and rearrange the product display
  React.useEffect(() => {
    const originArray = data.Result.ProductList.sort((a, b) => {
      if (sortBy === "VariationSKU") {
        const filterA = a.Variation ? a.Variation.VariationSKU : null;
        const filterB = b.Variation ? b.Variation.VariationSKU : null;
        return filterA - filterB; // Change to b - a for descending order
      }
      const filterA = a[sortBy];
      const filterB = b[sortBy];
      return filterA - filterB; // Change to b - a for descending order
    });

    let hadInsert = 0;
    const hookOptiond = [];

    while (hadInsert < originArray.length) {
      const temp = filterArrayByFilter(originArray);

      if (temp.length > 0) {
        hookOptiond.push({
          EachWeight: sortBy,
          dataArray: combineVariations(temp),
        });
        hadInsert += temp.length;
      }
    }
    setWaitingList(hookOptiond);
  }, [data]);

  return (
    <div className="stock-detail">
      <Modal isOpen={pickedConfirmMsg.isOpen} role="dialog">
        <ModalHeader tag="h3">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <CardTitle tag="p">{pickedConfirmMsg.msg}</CardTitle>
        </ModalHeader>
        <ModalFooter className="justify-content-center">
          <Button
            style={{ fontSize: "16px" }}
            onClick={() => {
              pickItemApi("Click", pickedConfirmMsg);
              setPickedConfirmMsg({
                msg: "",
                isOpen: false,
                idx: { variationSKU: "", OrderID: "" },
                Status: "",
              });
            }}
          >
            Yes
          </Button>
          <Button
            style={{ fontSize: "16px" }}
            color="primary"
            onClick={() =>
              setPickedConfirmMsg({
                msg: "",
                isOpen: false,
                idx: { variationSKU: "", OrderID: "" },
                Status: "",
              })
            }
          >
            No
          </Button>
        </ModalFooter>
      </Modal>

      <Input
        type="text"
        placeholder="Scan barcode - auto detect"
        value={scannedCode}
        readOnly
        className="py-2"
      />
      <div
        aria-multiselectable
        className="card-collapse"
        id="accordion"
        role="tablist"
        style={{
          background: "linear-gradient(#1e1e2f, #1e1e24)",
        }}
      >
        {waitingList &&
          waitingList.map((item) => (
            <Card className="card-plain text-white border mt-2">
              <CardHeader tag="h5" className="text-center">
                <span className="nowrap">{sortBy}</span>
              </CardHeader>
              <CardBody>
                <Row xs={1} sm="2" lg="3">
                  {item.dataArray.map((prop, idx2) => (
                    <Col>
                      <Card
                        className={`card-dark mt-sm-0 ${idx2 > 0 && `mt-3`}`}
                        style={{
                          background: "linear-gradient(#1e1e2f, #1e1e24)",
                        }}
                      >
                        <img
                          width="100"
                          height="100"
                          className="align-self-center"
                          alt={prop.ProductID}
                          src={prop.ProductImageList[0]?.ImageS3Url}
                        />
                        <CardBody className="p-0 p-sm-2">
                          <CardTitle tag="h5" className="text-center">
                            {prop.Name}
                          </CardTitle>
                          {prop.Variation.map((vrt) => (
                            <div className=" position-relative">
                              {vrt.Status === "Picked" && (
                                <div className="disabled-tr">
                                  <span>PICKED</span>
                                  {vrt.IsPickedByClick && (
                                    <Button
                                      className="mt-1"
                                      disabled={
                                        vrt.Status !== "Picked" &&
                                        !vrt.IsPickedByClick
                                      }
                                      onClick={() => {
                                        pickedConfirm(
                                          {
                                            variationSKU: vrt.VariationSKU,
                                            OrderID: vrt.OrderID,
                                            Status: "Unpicked",
                                          },
                                          false
                                        );
                                      }}
                                    >
                                      Unpicked
                                    </Button>
                                  )}
                                </div>
                              )}
                              <div className="p-3 p-sm-2 p-md-3">
                                <CardSubtitle className="text-muted" tag="h5">
                                  {vrt.VariationSKU}
                                </CardSubtitle>
                                <CardText style={{ fontSize: "large" }}>
                                  Weight:{vrt.Weight}
                                </CardText>

                                <div className="d-flex justify-content-center align-items-center flex-column">
                                  {vrt.Status !== "Picked" && (
                                    <Button
                                      className="mt-1 w-100"
                                      disabled={vrt.Status === "Picked"}
                                      {...(vrt.Status !== "Picked" && {
                                        color: "success",
                                      })}
                                      onClick={() =>
                                        pickedConfirm(
                                          {
                                            variationSKU: vrt.VariationSKU,
                                            OrderID: vrt.OrderID,
                                            Status: "Picked",
                                          },
                                          true
                                        )
                                      }
                                    >
                                      Picked
                                    </Button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          ))}
      </div>

      {/* <ul>{dataScan && dataScan.map((res) => <li>{res}</li>)}</ul> */}
    </div>
  );
}

FilterCard.propTypes = {
  data: PropTypes.shape.isRequired,
  sortBy: PropTypes.string.isRequired,
  pickItemApi: PropTypes.func.isRequired,
};

export default FilterCard;
