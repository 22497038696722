import AddCheckedPhoto from "components/CustomUpload/AddCheckedPhoto";
import React from "react";

import PropTypes from "prop-types";
import { Col, Input, Row } from "reactstrap";

function ApprovedTradeIn(props) {
  const {
    setRemarks,
    remarks,
    uploadImgCallback,
    setActualWeight,
    actualWeight,
  } = props;

  return (
    <>
      <h6 className="bg-success p-2 text-center mr-4 ml-0">Aprrove</h6>
      <Row className="ml-1">
        <Col
          md="3"
          className="mr-1 p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          Actual Weight (g):
        </Col>
        <Col
          md="8"
          className="p-2 l-3 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          <Input
            type="text"
            value={actualWeight}
            onChange={(e) => setActualWeight(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="ml-1">
        <Col
          md="3"
          className="mr-1 p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          Price(RM):
        </Col>
        <Col
          md="8"
          className="mr-1 p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          <Input
            type="text"
            //   value={price}
            //   onChange={(e) => setPrice(e.target.value)}
            disabled
          />
        </Col>
      </Row>
      <Row className="ml-1">
        <Col
          md="3"
          className="mr-1 p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          Photos:
        </Col>
        <Col
          md="8"
          className="p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          <AddCheckedPhoto uploadImgCallback={uploadImgCallback} />
        </Col>
      </Row>
      <Row className="ml-1">
        <Col
          md="3"
          className="mr-1 p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          Remarks:
        </Col>
        <Col
          md="8"
          className="p-2 mb-1"
          style={{ border: "1px solid #51BCDA" }}
        >
          <Input
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
          />
        </Col>
      </Row>
    </>
  );
}
ApprovedTradeIn.propTypes = {
  setRemarks: PropTypes.string.isRequired,
  remarks: PropTypes.string.isRequired,
  uploadImgCallback: PropTypes.string.isRequired,
  setActualWeight: PropTypes.string.isRequired,
  actualWeight: PropTypes.string.isRequired,
};
export default ApprovedTradeIn;
