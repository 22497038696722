/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useContext } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../customerService/customerservice.css";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const columns = [
  {
    name: "Order No",
    selector: (row) => <p>{`#${row.OrderID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Date",
    selector: (row) => (
      <div>
        <p>{moment(row.OrderCreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.OrderCreatedDate).format("hh:mm A")}
      </div>
    ),
  },
  {
    name: "Reference No",
    selector: (row) => <p>{`#${row.ID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Return Request Date",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Customer Name",
    selector: (row) => row.Name,
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => (
      <div>
        {row.Status === "Pending Payment" ? (
          <p>{`Refund Rejected && ${row.Status}`}</p>
        ) : (
          row.Status
        )}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      if (!row) return null;

      return (
        <div>
          <Button
            id="itemhover"
            color="link"
            key={row.ID}
            className="pl-0 pr-0"
            tag={Link}
            to={`/shopowner/ReturnRefund/viewRefunddetails/${row.OrderID}/${row.ID}`}
          >
            <i
              className="fa fa-search-plus"
              aria-hidden="true"
              style={{ color: "white", fontSize: "18px" }}
              title="Order Detais"
            />
          </Button>
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function RefundApproval() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () => {
      if (shopID !== "0") {
        const res = await Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`);
        const filteredData = res.data.filter(
          (item) =>
            item.Status === "Refund Approved" ||
            item.Status === "Pending Payment" ||
            item.Status === "Refund Rejected"
        );
        setFilterDataResult(filteredData);
        setFilterResult(filteredData);
        return res;
      }
    },
    { refetchOnWindowFocus: false }
  );
  const resetFilter = () => {
    setDateRange([null, null]);
    setFilterDataResult(filterResult);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.OrderID &&
              item.OrderID.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };
  const convertToCSV = (data2) => {
    const header = [
      "Order No",
      "Order Date",
      "Reference No",
      "Return Request Date",
      "Customer Name",
      "Status",
    ];
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (fieldName === "Order No") {
              return `#${row.OrderID}`;
            }
            if (fieldName === "Order Date") {
              return JSON.stringify(
                moment(row.OrderCreatedDate).format("DD/MM/YYYY hh:mm A")
              );
            }

            if (fieldName === "Reference No") {
              return JSON.stringify(row.ID);
            }
            if (fieldName === "Return Request Date") {
              return JSON.stringify(row.CreatedDat);
            }

            if (fieldName === "Customer Name") {
              return JSON.stringify(row.Name);
            }

            if (fieldName === "Status") {
              return JSON.stringify(row.Status);
            }
            return JSON.stringify(row[fieldName]);
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Refund Approval</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>
            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(
                  selectedData.length > 0 ? selectedData : filterDataResult
                );
              }}
            >
              Export CSV
            </Button>
          </div>
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                selectableRows
                onSelectedRowsChange={handleSelectRows}
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default RefundApproval;
