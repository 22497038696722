import React, { useContext } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";

function CSImage(props) {
  const { orderID } = props;
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const { data: orderData } = useQuery(
    `/shop/${shopID}/Order/${orderID}/Delivery/Image`,
    async () => Axios.get(`/shop/${shopID}/Order/${orderID}/Delivery/Image`)
  );
  return (
    <div>
      {orderData?.data.map((img) => (
        <div className="imgPreview mr-2" key={img.ImgaeID}>
          <img
            className="VimgCenter"
            alt="..."
            src={img.ImageUrl}
            width="80px"
            height="80px"
          />
        </div>
      ))}
    </div>
  );
}
CSImage.propTypes = {
  orderID: PropTypes.string.isRequired,
};
export default CSImage;
