/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  Container,
  Col,
  Label,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";

function BannerUpload(props) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const { modal, setModal, refetch } = props;
  const [banner, setBanner] = useState([]);

  const toggle = () => {
    setModal(!modal);
    setBanner([]);
  };

  const bannerRef = React.createRef();
  const handleUploadClick = () => {
    bannerRef.current.click();
  };

  const handleBannerUpload = (e) => {
    const productFiles = e.target.files;
    const temp = [];
    for (let i = 0; i < productFiles.length; i++) {
      if (i >= 5) {
        break;
      }
      const file = productFiles[i];
      temp.push({ File: file, URL: URL.createObjectURL(file) });
    }
    if (banner.length === 0) {
      setBanner(temp);
    } else if (banner.length < 5) {
      setBanner([...banner, ...temp]);
    }
    e.target.value = null;
  };

  const handleRemoveBanner = (idx) => {
    banner.splice(idx, 1);
    setBanner([...banner]);
  };

  const uploadBanner = () => {
    const data2 = new FormData();

    if (banner.length !== 0) {
      banner.forEach((item) => {
        data2.append("newBanners", item.File);
      });

      Axios.post(`/shop/${selectShop}/banners`, data2)
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">Successful Uploaded Banner </p>
            </div>
          );
          toggle();
          refetch();
        })
        .catch(() => {
          toast.error("Something goes wrong, please try later");
        });
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalBody className="text-center">
        <Button className="float-right closeBtn" onClick={() => toggle()}>
          <i className="nc-icon nc-simple-remove" />
        </Button>
        <h3 className="mt-3">Upload Banner</h3>
        <Container className="modalContainer">
          <div style={{ minHeight: "450px" }}>
            <Row className="mt-3">
              <Col md="4">
                <div>
                  <Label tag="h5" className="float-right modalLabel">
                    Upload Banner :
                  </Label>
                </div>
              </Col>
              <Col>
                <Button
                  className="float-left"
                  onClick={() => handleUploadClick()}
                >
                  Upload
                </Button>
                <input
                  className="uploadImg"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleBannerUpload}
                  ref={bannerRef}
                />
                {banner.length !== 0 && (
                  <Button
                    className="float-left clearBtn"
                    onClick={() => setBanner([])}
                  >
                    Clear All
                  </Button>
                )}
              </Col>
            </Row>
            <Row className="text-center mb-4">
              {banner.map((img, idx) => (
                <Col md="12" className="d-flex justify-content-center">
                  <div
                    className="position-relative px-3"
                    style={{ height: "100%", width: "90%" }}
                  >
                    <p className="ViewBannerNo">{idx + 1}</p>
                    <button
                      type="button"
                      className="removeBanner "
                      onClick={() => handleRemoveBanner(idx)}
                    >
                      <i className="fa fa-times-circle removeIcon" />
                    </button>
                    <img
                      src={img.URL}
                      className="mt-1 bannerImg mb-3"
                      style={{ height: "100%", width: "100%" }}
                      alt="banner"
                    />
                  </div>
                </Col>
              ))}
            </Row>
          </div>
          <Button className="submitBtn mb-3" onClick={() => uploadBanner()}>
            Upload Banner
          </Button>
        </Container>
      </ModalBody>
    </Modal>
  );
}

BannerUpload.propTypes = {
  modal: PropTypes.bool.isRequired,
  setModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default BannerUpload;
