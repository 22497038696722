import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import ApiContext from "services/ApiContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "../sales.css";
import ApprovedTradeIn from "./ApprovedTradeIn";

function TradeInAprrovalUpdate(props) {
  const { shopID, Tid, productID } = props;
  // const [tradeproductID, setTradeproductID] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [actualWeight, setActualWeight] = useState("");
  const [remarks, setRemarks] = useState("");
  const [action, setAction] = useState(null);
  const Axios = useContext(ApiContext);

  const [imgUpload, setImgUpload] = useState(null);
  const [file, setFile] = useState(null);

  const uploadImgCallback = (imgFile) => {
    setImgUpload(imgFile);
  };
  const uploadCombine = (img) => {
    if (img && Array.isArray(img)) {
      setFile([...img]);
    } else if (img === null) {
      setFile([]);
    }
  };

  useEffect(() => {
    uploadCombine(imgUpload);
  }, [imgUpload]);

  const validationSchema = Yup.object().shape({
    ActualWeight: Yup.string().required("ActualWeight Required"),
    ShopProductFileList: Yup.string().required("Image  Required"),
    ShopRemarks: Yup.string().required("ShopRemarks Required"),
    Action: Yup.string().required("Action Required"),
  });
  const formik = useFormik({
    initialValues: {
      ActualWeight: "",
      ShopProductFileList: "",
      ShopRemarks: "",
      Action: "",
    },
    validationSchema,
  });
  const onSubmit = (e) => {
    e.preventDefault();
    const data2 = new FormData();
    data2.append("ActualWeight", actualWeight);
    data2.append("ShopRemarks", remarks);
    data2.append("Action", action);

    if (file !== null) {
      file.forEach((files) => {
        data2.append("ShopProductFileList", files);
      });
    } else {
      data2.append("ShopProductFileList", []);
    }

    if (file !== null && file.length >= 0) {
      Axios.put(
        `/shop/${shopID}/SingleProductApproval/${Tid}/${productID}`,
        data2
      )
        .then((res) => {
          toast.success(res.data.MESSAGE);
          formik.resetForm(); // Reset the form
        })
        .catch((error) => {
          toast.error(error.response.data.MESSAGE);
        });
    } else {
      toast.error("No files selected");
    }
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Row>
          <Col
            md="6"
            className={`p-3 text-center ${
              selectedColumn === "reject" ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedColumn("reject");
              setAction("Rejected");
              // setTradeproductID();
            }}
          >
            <h6 className="bg-danger p-2 text-center mr-4 ml-0">Reject</h6>

            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Actual Weight (g):
              </Col>
              <Col
                md="8"
                className="p-2 l-3 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                -
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Price(RM):
              </Col>
              <Col
                md="8"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                -
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Photos:
              </Col>
              <Col
                md="8"
                className="p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                -
              </Col>
            </Row>
            <Row className="ml-1">
              <Col
                md="3"
                className="mr-1 p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                Remarks:
              </Col>
              <Col
                md="8"
                className="p-2 mb-1"
                style={{ border: "1px solid #51BCDA" }}
              >
                <Input
                  type="text"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
                {formik.errors.ShopRemarks ? (
                  <p className="errorText text-danger">
                    {formik.errors.ShopRemarks}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Col>
          <Col
            md="6"
            className={`p-3 text-center ${
              selectedColumn === "approve" ? "selected" : ""
            }`}
            onClick={() => {
              setSelectedColumn("approve");
              setAction("Approved");
            }}
          >
            <ApprovedTradeIn
              actualWeight={actualWeight}
              setActualWeight={setActualWeight}
              formik={formik.error}
              uploadImgCallback={uploadImgCallback}
              remarks={remarks}
              setRemarks={setRemarks}
            />
          </Col>

          {/* The same modifications can be made for the "Reject" section */}
        </Row>
        <Row className="text-left pt-2 font-weight-bold ">
          <Col sm="12">
            <div className="text-center">
              <Button className="btn-md btn-success" type="submit">
                Update Trade In Approval
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
TradeInAprrovalUpdate.propTypes = {
  Tid: PropTypes.string.isRequired,
  shopID: PropTypes.func.isRequired,
  productID: PropTypes.string.isRequired,
};
export default TradeInAprrovalUpdate;
