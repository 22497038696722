/* eslint-disable */

import React, { useContext, useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import PaymentContext from "services/PaymentContext";
import CurrencyFormat from "react-currency-format";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  InputGroup,
  CardText,
} from "reactstrap";

import {
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "variables/charts";
import { Link } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import moment from "moment";
import ModalTitle from "rsuite/esm/Modal/ModalTitle";
import { toast } from "react-toastify";

const mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function financeDash() {
  const { Currentday, Previousday, todaydata, todayOtime } =
    useContext(PaymentContext);

  const [Time, setTime] = useState([]);

  const chartExample10 = {
    data: {
      labels: Time,
      datasets: [
        {
          label: "Sales",
          borderColor: "#6bd098",
          pointRadius: 2,
          pointHoverRadius: 2,
          fill: false,
          borderWidth: 3,
          barPercentage: 1.6,
          tension: 0.4,
          data: todaydata,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },

        tooltips: {
          enabled: false,
        },
      },

      scales: {
        y: {
          ticks: {
            color: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
            // padding: 20
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#9f9f9f",
          },
        },
      },
    },
  };

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  // const [source, setSource] = useState("WebSite");
  const [webCount, setWebCouunt] = useState(0);
  const [liveCount, setLiveCount] = useState(0);
  const [TICount, setTICount] = useState(0);
  const [RRCount, setRRCount] = useState(0);
  const [reportDownload, setReportDownload] = useState(false);
  const [reportStart, setReportStart] = useState("");
  const [reportEnd, setReportEnd] = useState("");
  const orderType = "Paid";

  const { data } = useQuery(["Orders", orderType], async () =>
    Axios.get(`/shop/${shopID}/Order/Finance`, {
      params: {
        order: "DESC",
        paymentStatus: orderType,
      },
    })
  );

  const downloadReport = () => {
    const reportFrom = reportStart;
    let reportTo = reportEnd;

    if (reportStart !== "" || reportEnd !== "") {
      if (reportFrom === reportTo) {
        reportTo = moment(reportTo)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format();
      }

      Axios.get(`/shop/${shopID}/Order/Finance/Report`, {
        params: {
          dateFrom: reportFrom,
          dateTo: reportTo,
        },
      })
        .then((res) => {
          const downloadUrl = res.data;
          window.open(downloadUrl, "_blank");
          setReportDownload(false);
          setReportStart("");
          setReportEnd("");
        })
        .catch((error) => {
          toast.error("Error:", error);
        });
    } else {
      toast.error("Please select start and end date");
    }
  };

  useEffect(() => {
    if (data) {
      setWebCouunt(
        data.data.Result.OrderList.filter(
          (item) =>
            item.OrderSource === "WebSite" &&
            item.RRID === "" &&
            item.TIID === ""
        ).length
      );
      setLiveCount(
        data.data.Result.OrderList.filter(
          (item) =>
            item.OrderSource !== "WebSite" &&
            item.RRID === "" &&
            item.TIID === ""
        ).length
      );
      setTICount(
        data.data.Result.OrderList.filter(
          (item) =>
            item.OrderSource === "WebSite" &&
            item.RRID === "" &&
            item.TIID !== ""
        ).length
      );
      setRRCount(
        data.data.Result.OrderList.filter(
          (item) =>
            item.OrderSource === "WebSite" &&
            item.RRID !== "" &&
            item.TIID === ""
        ).length
      );
    }
  }, [data]);

  useEffect(() => {
    const temp = [];
    todayOtime.forEach((item) => {
      temp.push(item + ":00");
    });
    setTime(temp);
  }, []);

  return (
    <div className="content">
      <Row>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark card-dash">
            <CardBody>
              <Row>
                <Col md="8" xs="5">
                  <Row>
                    <Col md="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="9">
                      <h3 className="mt-2">Websites</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="7">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{webCount}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats float-right">
                <Button
                  className="orderBtn"
                  tag={Link}
                  to={`/shopOwner/finance/ordersType/website`}
                >
                  Show More
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark card-dash">
            <CardBody>
              <Row>
                <Col md="8" xs="5">
                  <Row>
                    <Col md="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="9">
                      <h3 className="mt-2">Live</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="7">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{liveCount}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats float-right">
                <Button
                  className="orderBtn"
                  tag={Link}
                  to={`/shopOwner/finance/ordersType/live`}
                >
                  Show More
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark card-dash">
            <CardBody>
              <Row>
                <Col md="8" xs="5">
                  <Row>
                    <Col md="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="9">
                      <h3 className="mt-2">Trade In</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="7">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{TICount}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats float-right">
                <Link to="tradeIn">
                  <Button className="orderBtn">Show More</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark card-dash">
            <CardBody>
              <Row>
                <Col md="8" xs="5">
                  <Row>
                    <Col md="3">
                      <div className="icon-big text-center icon-warning">
                        <i className="nc-icon nc-globe text-warning" />
                      </div>
                    </Col>
                    <Col md="9">
                      <h3 className="mt-2">Return & Refund</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="7">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{RRCount}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats float-right">
                <Link to="ReturnNrefund">
                  <Button className="orderBtn">Show More</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="12" md="12">
          <Card className="card-dark">
            <CardHeader>
              <Row>
                <Col sm="7">
                  <CurrencyFormat
                    renderText={(value) => (
                      <span className="numbers pull-left">{`${value}`}</span>
                    )}
                    decimalScale={2}
                    value={Currentday.toFixed(2)}
                    displayType="text"
                    thousandSeparator
                    prefix="RM "
                  />
                </Col>
                <Col>
                  <Button
                    className="orderBtn float-right"
                    // onClick={() => downloadReport()}
                    onClick={() => setReportDownload(!reportDownload)}
                  >
                    Download Report
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h6 className="big-title">today's Sales</h6>
              <Line
                data={chartExample10.data}
                options={chartExample10.options}
                height={150}
                width={826}
              />
            </CardBody>
            <CardFooter>
              <hr />
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        isOpen={reportDownload}
        toggle={() => setReportDownload(!reportDownload)}
      >
        <ModalBody
          className="white-text text-center modal-color"
          style={{ backgroundColor: "#1e1e2f" }} // linear-gradient(#1e1e2f, #1e1e24)
        >
          <div className="mb-4">
            <h5 style={{ color: "white" }}>
              Please Select Date Range to Download Report
            </h5>
          </div>
          <Row style={{ marginLeft: "15%" }}>
            <Col md="5" className="mr-2">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(reportStart).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setReportStart(moment(e.target.value).format())
                  }
                />
              </InputGroup>
            </Col>
            <p className="mt-2 mr-4" style={{ color: "white" }}>
              To
            </p>
            <Col md="5">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(reportEnd).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setReportEnd(moment(e.target.value).format())
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="info"
            onClick={() => downloadReport()}
            disabled={reportStart === "" || reportEnd === ""}
          >
            Generate Report
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setReportDownload(false);
              setReportStart("");
              setReportEnd("");
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default financeDash;
