import { React, useContext, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { toast } from "react-toastify";
import moment from "moment";
import FinanceSalesReport from "./Reports";
import Ewallet from "./Ewallet";
import CreditNote from "./CreditNote";

function FinanceReport() {
  const [activeTab, setActiveTab] = useState("1");
  const [reportDownload, setReportDownload] = useState(false);
  const [reportStart, setReportStart] = useState("");
  const [reportEnd, setReportEnd] = useState("");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const downloadReport = () => {
    const reportFrom = reportStart;
    let reportTo = reportEnd;

    if (reportStart !== "" || reportEnd !== "") {
      if (reportFrom === reportTo) {
        reportTo = moment(reportTo)
          .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
          .format();
      }

      Axios.get(`/shop/${shopID}/Order/Finance/Report`, {
        params: {
          dateFrom: reportFrom,
          dateTo: reportTo,
        },
      })
        .then((res) => {
          const downloadUrl = res.data;
          window.open(downloadUrl, "_blank");
          setReportDownload(false);
          setReportStart("");
          setReportEnd("");
        })
        .catch((error) => {
          toast.error("Error:", error);
        });
    } else {
      toast.error("Please select start and end date");
    }
  };

  return (
    <div className="content">
      <Row>
        <Col>
          <Card className="card-dark">
            <CardHeader>
              <CardTitle className="mt-0">
                <Row>
                  <Col>
                    <h4>Finance Report</h4>
                  </Col>
                  <Col className="mt-3">
                    <Button
                      className="mt-3 orderBtn float-right"
                      onClick={() => setReportDownload(true)}
                    >
                      Download Report
                    </Button>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Nav justified className="mb-4">
                <NavItem
                  className={
                    activeTab === "1" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Sales Report
                </NavItem>
                <NavItem
                  className={
                    activeTab === "2" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  E-Wallet
                </NavItem>
                <NavItem
                  className={
                    activeTab === "3" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Credit Notes
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <FinanceSalesReport />
                </TabPane>
                <TabPane tabId="2">
                  <Ewallet />
                </TabPane>
                <TabPane tabId="3">
                  <CreditNote />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal
        size="lg"
        isOpen={reportDownload}
        toggle={() => setReportDownload(!reportDownload)}
      >
        <ModalBody
          className="white-text text-center modal-color"
          style={{ backgroundColor: "#1e1e2f" }} // linear-gradient(#1e1e2f, #1e1e24)
        >
          <div className="mb-4">
            <h5 style={{ color: "white" }}>
              Please Select Date Range to Download Report
            </h5>
          </div>
          <Row style={{ marginLeft: "15%" }}>
            <Col md="5" className="mr-2">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(reportStart).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setReportStart(moment(e.target.value).format())
                  }
                />
              </InputGroup>
            </Col>
            <p className="mt-2 mr-4" style={{ color: "white" }}>
              To
            </p>
            <Col md="5">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(reportEnd).format("YYYY-MM-DD")}
                  onChange={(e) =>
                    setReportEnd(moment(e.target.value).format())
                  }
                />
              </InputGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button
            color="info"
            onClick={() => downloadReport()}
            disabled={reportStart === "" || reportEnd === ""}
          >
            Generate Report
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              setReportDownload(false);
              setReportStart("");
              setReportEnd("");
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FinanceReport;
