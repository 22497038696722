import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useMutation } from "react-query";

function ShopBanner(props) {
  const { setMessage, setVisible, shopData, shopID } = props;
  const Axios = useContext(ApiContext);
  const [smallNotice, setSmallNotice] = useState({
    open: false,
    field: "",
  });

  const [bannerEdit, setBannerEdit] = useState(false);

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const { mutate, isLoading } = useMutation((data) => {
    // reformat data into correct strcuture
    const data2 = new FormData();
    data2.append("newBanners", data.newBanners);
    Axios.post(`/shop/${shopID}/banners`, data2)
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        let msg = "";
        err.response.data.forEach((element) => {
          msg += `${element.MESSAGE}\n`;
        });
        setMessage({
          data: msg,
          type: "alert-danger",
        });
        onShowAlert();
      });

    onShowAlert();
  });
  const formik = useFormik({
    initialValues: {
      newBanners: "",
    },
    onSubmit: (data) => {
      setSmallNotice({ open: false });
      mutate(data);
    },
  });

  const DeleteBanner = async (banner) => {
    Axios.delete(`/shop/${shopID}/banners`, {
      headers: {
        "content-type": "application/json",
      },
      data: [banner],
    })
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        setMessage({
          data: err.response.data.MESSAGE,
          type: "alert-danger",
        });
        onShowAlert();
      });
  };

  const imageCallback = (field, file) => {
    formik.values[field] = file;
  };

  return (
    <div>
      <Form>
        <Card className="card-dark">
          <CardHeader>
            <FormGroup className="pb-0">
              {!bannerEdit && (
                <a
                  href="#edit"
                  className="float-right font-weight-normal"
                  onClick={() => setBannerEdit(true)}
                >
                  <i className="fa fa-pencil" aria-hidden="true" />
                  Add
                </a>
              )}
              <CardTitle className="font-weight-bold" tag="h5">
                Add Shop Banner
              </CardTitle>
            </FormGroup>
          </CardHeader>
          <CardBody>
            {!bannerEdit && (
              <div className="p-3">
                <Row>
                  {shopData.data.ShopBannerList?.map((banners) => (
                    <Col
                      lg="6"
                      md="6"
                      sm="6"
                      xs="6"
                      className="p-3"
                      key={`img-${banners}`}
                    >
                      <img
                        className="border border-white w-75 h-75"
                        key={`img-${banners}`}
                        src={`/api/v2/public/Shop/${shopID}/banner/${encodeURIComponent(
                          banners
                        )}`}
                        style={{ padding: "5px" }}
                        alt="banner"
                      />
                      <Button
                        className="btn-link text-white"
                        value={banners}
                        onClick={(e) => {
                          DeleteBanner(e.target.value);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
            {bannerEdit && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="newBanners">
                      Shop banners <span style={{ color: "red" }}> * </span>
                    </Label>
                    <p>
                      <small>
                        <span id="textarea-limited-message">limit in 1mb</span>
                      </small>
                    </p>
                    <ImageUpload
                      imageCallback={imageCallback}
                      field="newBanners"
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {bannerEdit && (
              <Row className="text-left pt-2 font-weight-bold justify-content-end">
                <Col sm="9">
                  <div className="text-center">
                    <Button
                      className="mt-3 button-dark-template"
                      disabled={
                        isLoading || Object.values(formik.errors).length > 0
                      }
                      loading={isLoading}
                      onClick={() => {
                        setSmallNotice({ open: true, field: "banners" });
                      }}
                    >
                      Upload
                    </Button>

                    <Button
                      className=" ml-3 mt-3 button-template"
                      onClick={() => setBannerEdit(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Form>
      <Modal
        size="sm"
        isOpen={smallNotice.open}
        toggle={() => setSmallNotice({ open: false, field: "" })}
      >
        <ModalBody className="text-center">
          {smallNotice.field === "banners"
            ? "Confirm to Update Shop banners?"
            : "Confirm to Delete Shop banners?"}
        </ModalBody>
        <ModalFooter>
          <div className="left-side" style={{ width: "48%" }}>
            <Button color="primary" type="submit" onClick={formik.handleSubmit}>
              Confirm
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side" style={{ width: "48%" }}>
            <Button
              color="secondary"
              onClick={() => setSmallNotice({ open: false, field: "" })}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
ShopBanner.propTypes = {
  shopData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  setMessage: PropTypes.string.isRequired,
  setVisible: PropTypes.string.isRequired,
  shopID: PropTypes.string.isRequired,
};
export default ShopBanner;
