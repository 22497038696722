/* eslint-disable no-unused-vars */
import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useParams, useLocation } from "react-router-dom";

// reactstrap components
import Step1 from "./BatchCreateStep/Step1";
import Step2 from "./BatchCreateStep/Step2";

import "./live.css";

function BatchCreateSteps() {
  const { step } = useParams();

  const location = useLocation();

  const [productSelected, setProductSelected] = React.useState([]);
  const [batch, setBatch] = React.useState(step);
  const [batchID, setBatchID] = React.useState(
    location.state ? location.state.BID : ""
  );
  const [batchName, setBatchName] = React.useState(
    location.state ? location.state.BName : ""
  );

  return (
    <div className="content">
      <Card className="card-dark">
        <CardHeader>
          <h2 className="title">Create Batch</h2>
        </CardHeader>
        <CardBody>
          {batch === "1" && (
            <Step1
              setProductSelected={setProductSelected}
              productSelected={productSelected}
              setBatch={setBatch}
              setBatchID={setBatchID}
              setBatchName={setBatchName}
            />
          )}
          {batch === "2" && batchID && (
            <Step2 BatchID={batchID} BatchName={batchName} />
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default BatchCreateSteps;
