import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Collection from "./Collection";
import "./marketing.css";
import Collaboration from "./Collaboration";

function CollectionCollaboration() {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">Collection & Collaboration</CardTitle>
              <span style={{ color: "red" }}>
                ** To load will take a few seconds. Please wait till it is ready
              </span>
            </CardHeader>
            <CardBody>
              <Nav justified className="mb-4">
                <NavItem
                  className={
                    activeTab === "1" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Collection
                </NavItem>
                <NavItem
                  className={
                    activeTab === "2" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Collaboration
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Collection />
                </TabPane>
                <TabPane tabId="2">
                  <Collaboration />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CollectionCollaboration;
