import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form, Label } from "reactstrap";

function UpdateStatusUsertoCS(props) {
  const { rID, showModal, setShowModal, refetch } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [selectedOption, setSelectedOption] = useState(false);

  // Handle option selection
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value === "true");
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Create the request body
    const requestBody = JSON.stringify(selectedOption);

    // Send the selected option value to the API endpoint using axios
    Axios.put(`/shop/${shopID}/ReceiveFromCustomer/${rID}`, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        setShowModal({
          ...showModal,
          open: !showModal.open,
        });
        // Handle the response from the API
        toast.success("Update Item Received", response.data.MESSAGE);
        refetch();
      })
      .catch((error) => {
        // Handle error
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <Label>Select Status:</Label>
          <select
            value={selectedOption.toString()}
            onChange={handleOptionChange}
            className="ml-2 mb-5"
            style={{ width: "50%", height: "100%" }}
          >
            <option value="">Status</option>
            <option value="true">Received</option>
          </select>
          <Button type="submit" className="ml-5 btn btn-primary">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

UpdateStatusUsertoCS.propTypes = {
  rID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default UpdateStatusUsertoCS;
