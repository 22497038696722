/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { Button, Card, CardBody } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";

function BirthdayCoupon() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [labelName, setLabelName] = React.useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("Day");
  const [selectedTier, setSelectedTier] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [range, setRange] = useState([null, null]);

  const handleExportToPDF = () => {
    const elementToDownload = document.getElementById("ElementId"); // Replace "ElementId" with the actual ID
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      margin: [50, 50, 50, 50], // Left, Top, Right, Bottom margins
    });
    // Add A4 format header and footer
    doc.setFontSize(12);
    doc.text(
      "Birthday Coupon Report",
      doc.internal.pageSize.getWidth() / 2,
      20
    );
    doc.text(
      "Birthday Coupon Report",
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 20
    );
    // Capture the specific element content using html2canvas
    html2canvas(elementToDownload, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 600; // A4 width in landscape (595 for portrait)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = (doc.internal.pageSize.getWidth() - imgWidth) / 2; // Center the image horizontally
      const imgY = (doc.internal.pageSize.getHeight() - imgHeight) / 2; // Center the image vertically
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save("Birthday Coupon Report.pdf");
    });
  };
  const { data: bdayCoupon, refetch } = useQuery(
    ["bdayCoupon", startDate, endDate],
    async () => {
      const res = await Axios.get(
        `/shop/${shopID}/Marketing/Dashboard/BirthdayCoupon/Tier`,
        {
          params: {
            dateFrom: startDate,
            dateTo: endDate,
          },
        }
      );
      const dataMap = new Map();
      res.data.BirthdayCoupon.forEach((item) => {
        const key = `${item.ID.Year}_${item.ID.Month}`;
        if (dataMap.has(key)) {
          const existingData = dataMap.get(key);
          const existingRank = existingData.ranks.find(
            (rank) =>
              rank.LoyaltyRankName === item.ID.LoyaltyRankName &&
              rank.count === item.Count
          );
          if (existingRank) {
            existingRank.count += 1;
          } else {
            existingData.ranks.push({
              LoyaltyRankName: item.ID.LoyaltyRankName,
              count: item.Count,
            });
          }
        } else {
          dataMap.set(key, {
            ID: {
              Year: item.ID.Year,
              Month: item.ID.Month,
            },
            ranks: [
              {
                LoyaltyRankName: item.ID.LoyaltyRankName,
                count: item.Count,
              },
            ],
          });
        }
      });
      const data = Array.from(dataMap.values());
      setFilterDataResult(data);
      return res;
    },
    { refetchOnWindowFocus: true }
  );

  const resetFilter = () => {
    setDateRange([null, null]);
    setRange([null, null]);
    refetch();
    setFilterDataResult(bdayCoupon.data.BirthdayCoupon);
  };

  const { data: graphdata, isSuccess } = useQuery(
    [
      "graphdata",
      startDate,
      endDate,
      range,
      isChecked,
      selectedTier,
      selectedPeriod,
    ],
    () =>
      Axios.get(
        `/shop/${shopID}/Marketing/Dashboard/BirthdayCoupon/Tier/Graph`,
        {
          params: {
            dateFrom: moment(startDate)
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format(),
            dateTo: moment(endDate)
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format(),
            dateFrom2:
              (range &&
                moment(range[0])
                  .set({
                    hour: 0,
                    minute: 0,
                    second: 0,
                    millisecond: 0,
                  })
                  .format()) ||
              null,
            dateTo2:
              (range &&
                moment(range[1])
                  .set({
                    hour: 23,
                    minute: 59,
                    second: 59,
                    millisecond: 999,
                  })
                  .format()) ||
              null,
            isCompare: isChecked,
            loyaltyRank: selectedTier,
            tablePeriod: selectedPeriod,
          },
        }
      )
  );

  useEffect(() => {
    if (graphdata) {
      let datasets;
      let datasets2;
      let formattedDates;

      if (graphdata.data.Graph1) {
        // Update the graph with the API graphdataponse

        datasets = [
          {
            data: graphdata.data.Graph1.map((gdts) => gdts.Value),
            borderColor: "rgba(74, 189, 219,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];
        // get the date ex: 2023-354
        if (graphdata.data.Graph1[0]?.ID.Day) {
          // Handle day format
          const dates = graphdata.data.Graph1.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (graphdata.data.Graph1[0]?.ID.Month) {
          formattedDates = graphdata.data.Graph1.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (graphdata.data.Graph1[0]?.ID.Week) {
          formattedDates = graphdata.data.Graph1.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      if (graphdata.data.Graph2) {
        datasets2 = [
          {
            data: graphdata.data.Graph2.map((gdts) => gdts.Value),
            borderColor: "rgba(107, 191, 160,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];

        // get the date ex: 2023-354
        if (graphdata.data.Graph2[0]?.ID.Day) {
          // Handle day format
          const dates = graphdata.data.Graph2.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (graphdata.data.Graph2[0]?.ID.Month) {
          formattedDates = graphdata.data.Graph2.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (graphdata.data.Graph2[0]?.ID.Week) {
          formattedDates = graphdata.data.Graph2.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      const newDatas = {
        labels: formattedDates,
        datasets: [...datasets, ...(datasets2 || [])],
      };
      setDatas(newDatas);
      setLabelName(graphdata.data.LoyaltyRankName);
    }
  });

  const columns = [
    {
      name: "Month",
      selector: (row) => {
        const monthDate = new Date(row.ID.Year, row.ID.Month - 1, 1);
        return `${monthDate.toLocaleString("default", {
          month: "long",
        })} ${row.ID.Year}`;
      },
    },
    {
      name: (
        <Button
          className="text-white"
          color="link"
          onClick={() => {
            setSelectedTier("SILVER");
          }}
        >
          Silver Redemption
        </Button>
      ),
      selector: (row) =>
        row.ranks?.map((lname) =>
          lname.LoyaltyRankName === "SILVER" ? lname.count : null
        ),
    },
    {
      name: (
        <Button
          color="link"
          className="text-white"
          onClick={() => {
            setSelectedTier("GOLD");
          }}
        >
          GOLD Redemption
        </Button>
      ),
      selector: (row) =>
        row.ranks?.map((lname) =>
          lname.LoyaltyRankName === "GOLD" ? lname.count : null
        ),
    },
  ];

  const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false, // Prevent the chart from maintaining aspect ratio
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        offset: true,
        ticks: {
          color: "white",
        },
      },
      x: {
        ticks: {
          color: "white",
        },
      },
    },
  };

  const lineGraphData = {
    ...datas,
    datasets: [
      {
        label: datas && datas?.datasets && datas?.datasets[0].label,
        data: datas && datas?.datasets && datas?.datasets[0].data,
        borderColor: datas && datas?.datasets && datas?.datasets[0].borderColor,
        yAxisID: "y",
      },
      ...(datas && datas?.datasets && datas?.datasets[1]?.data
        ? [
            {
              label: `Prev date ${
                datas && datas?.datasets && datas?.datasets[1].label
              } `,
              data: datas && datas?.datasets && datas?.datasets[1].data,
              borderColor:
                datas && datas?.datasets && datas?.datasets[1].borderColor,
              yAxisID: "y",
            },
          ]
        : []),
    ],
  };

  return (
    <div
      className="content px-3 w-100"
      style={{ overflowX: "auto" }}
      id="ElementId"
    >
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardBody>
          <div className="d-flex">
            <p className="mr-4 mt-2">Date: </p>
            <div className="mb-2">
              <CustomCalendar
                setDateRange={setDateRange}
                compareCheckbox
                setRange={setRange}
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
            </div>
            <div className="d-flex flex-wrap mb-5">
              <Button color="danger" onClick={() => resetFilter()}>
                Reset All Filter
              </Button>
              <Button onClick={handleExportToPDF} color="secondary">
                Export to PDF
              </Button>
            </div>
          </div>
          <div>
            <h2 className="m-4">Birthday Coupon Report</h2>
            <hr />
            <div className="mt-5 mb-5" style={{ height: "500px" }}>
              <div className="d-flex justify-content-between">
                <h6>{labelName}</h6>

                <h6 className="d-flex align-items-center justify-content-between">
                  {!dateRange ||
                  (dateRange[0] === null && dateRange[1] === null) ? (
                    ""
                  ) : (
                    <div
                      className="square-box m-2"
                      style={{
                        backgroundColor: "rgba(74, 189, 219, 0.5)",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    />
                  )}

                  {!dateRange ||
                  (dateRange[0] === null && dateRange[1] === null)
                    ? ""
                    : `${moment(dateRange[0]).format("YYYY-MM-DD")}-${moment(
                        dateRange[1]
                      ).format("YYYY-MM-DD")}`}
                  {graphdata?.data.Graph1TotalCount === 0 ? null : (
                    <p className="m-2">
                      {graphdata?.data.Graph1TotalCount.toFixed(2)}
                    </p>
                  )}
                </h6>

                {range ? (
                  <>
                    <h6>vs</h6>
                    <h6 className="d-flex align-items-center justify-content-between">
                      <span
                        className="square-box m-2"
                        style={{
                          backgroundColor: "rgba(75, 192, 192, 1)",
                          opacity: 50,
                          width: "20px",
                          height: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                        }}
                      />
                      {range
                        ? `${moment(range[0]).format("YYYY-MM-DD")}-${moment(
                            range[1]
                          ).format("YYYY-MM-DD")}`
                        : ""}
                      {graphdata?.data.Graph2TotalCount === 0 ? null : (
                        <p>{graphdata?.data.Graph2TotalCount}</p>
                      )}
                    </h6>
                  </>
                ) : null}

                <select
                  className="text-dark"
                  onChange={(e) => {
                    setSelectedPeriod(e.target.value);
                  }}
                  style={{
                    marginBottom: "10px",
                    width: "10%",
                    borderRadius: "5px",
                  }}
                >
                  <option value="Day">By Day</option>
                  <option value="Week">By Week</option>
                  <option value="Month">By Month</option>
                </select>
              </div>
              <hr />
              {isSuccess && datas && (
                <Line data={lineGraphData} options={options} />
              )}
            </div>

            <div className="react-table-container m-5 pt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default BirthdayCoupon;
