import React, { useContext } from "react";
import { useQuery } from "react-query";
import {
  CardBody,
  Card,
  Spinner,
  Row,
  Col,
  Button,
  TabContent,
  TabPane,
  Input,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { Link } from "react-router-dom";
import ShopContext from "services/ShopContext";
import DataTable, { createTheme } from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input ref={ref} value={value} onClick={onClick}>
      {children}
    </Input>
  )
);

function StoreBoard() {
  const [current, setCurrent] = React.useState("Dashboard");
  const [dataState, setDataState] = React.useState([]);
  const [dateFocus, setDateFocus] = React.useState();
  const Axios = useContext(ApiContext);
  const { selctShopID } = useContext(ShopContext);

  const convertISOtime = (currentDate) => {
    const temp = new Date(currentDate);
    temp.setDate(temp.getDate() + 1);
    temp.setMilliseconds(temp.getMilliseconds() - 1);
    const formattedDate = temp.toISOString();
    return formattedDate;
  };

  const {
    isLoading,
    isError,
    data: reportList,
  } = useQuery(["/StockPicker/report", dateFocus], async () =>
    Axios.get(`/shop/${selctShopID.ID}/Order/StockPicker/Report`, {
      params: {
        dateFrom: dateFocus || "",
        dateTo: dateFocus ? convertISOtime(dateFocus) : "",
      },
    })
      .then((res) => {
        setDataState(
          res.data.Result.Category.map((prop) => ({
            Category: prop.Name,
            total: prop.Total,
            pending: prop.TotalPending,
            picked: prop.TotalPicked,
            pickpic: prop.PickPIC,
            receviedQTY: prop.TotalReceived,
            receviedPIC: prop.ReceivedPIC || "",
          }))
        );
        return res.data.Result;
      })
      .catch((err) => {
        setDateFocus(new Date());
        toast.error(err.response.data.MESSAGE);
      })
  );

  const columns = [
    {
      name: "Category",
      selector: (row) => row.Category,
    },
    {
      name: "total",
      selector: (row) => row.total,
    },
    {
      name: "pending",
      selector: (row) => row.pending,
    },
    {
      name: "pickpic",
      selector: (row) => row.pickpic.map((pic) => <div>{pic}</div>),
    },
    {
      name: "receviedQTY",
      selector: (row) => row.receviedQTY,
    },
    {
      name: "receviedPIC",
      selector: (row) => row.receviedPIC.map((pic) => <div>{pic}</div>),
    },
  ];

  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
        padding: "2rem",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const subHeaderComponentMemo = React.useMemo(
    () => (
      <div className="d-flex justify-content-between w-100 my-2 flex-sm-row flex-column">
        <div className="align-self-center">
          <h3 className="m-0 text-center">
            Stock Report:
            {dateFocus ? dateFocus.toLocaleDateString("en-GB") : "All Time"}
          </h3>
        </div>
        <div className="position-relative mt-2 mt-sm-0">
          <div className="text-left">Filter by date: </div>
          <DatePicker
            isClearable
            selected={dateFocus}
            onChange={(date) => setDateFocus(date)}
            dateFormat="dd/MM/yyyy"
            customInput={<ExampleCustomInput />}
          />
        </div>
      </div>
    ),
    [dateFocus]
  );

  if (isLoading) {
    return (
      <div className="content">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div>
        <h1 className="title text-center">
          <p className="error-msg text-center">
            We&apos;re sorry, There is an error encounter.
            <br />
            Please retry again after few minutes.
          </p>
        </h1>
      </div>
    );
  }

  return (
    <div className="content">
      <Card
        className="bg-transparent card-dark border ml-auto mr-auto mr-lg-0"
        style={{ width: "max-content" }}
      >
        <CardBody>
          {reportList &&
            `Pending: ${reportList.TotalPending} Picked: ${reportList.TotalPicked}`}
        </CardBody>
      </Card>
      <div
        className="p-3 border rounded"
        style={{ overflowX: "auto", minHeight: "80vh" }}
      >
        <div className="d-inline">
          <Button onClick={() => setCurrent("Dashboard")}>Dashboard</Button>
          <Button onClick={() => setCurrent("Report")}>Report</Button>
          {subHeaderComponentMemo}
        </div>
        <TabContent activeTab={current}>
          <TabPane tabId="Dashboard">
            {reportList && (
              <Row xl={4} sm={2} xs={1} data-test-id="navbar-category">
                {reportList.Category.map((item) => (
                  <Col className="mb-3" key={item.CategoryID}>
                    <Card className="card-dark h-100">
                      <CardBody className="text-center d-flex flex-column justify-content-between">
                        <h3 className="mb-1">{item.Name}</h3>
                        <div>
                          <p className="mb-0">Total: {`${item.Total}`}</p>
                          <p className="mb-0">
                            Pending: {`${item.TotalPending}`}
                          </p>
                          <p className="mb-0">
                            Picked: {`${item.TotalPicked}`}
                          </p>
                          <Button
                            className="mt-3"
                            to={`/shopOwner/storePickerDetail/${item.CategoryID}`}
                            tag={Link}
                          >
                            Details
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
          <TabPane tabId="Report">
            {dataState.length > 0 && (
              <DataTable
                columns={columns}
                data={dataState}
                customStyles={customStyles}
                theme="solarized"
              />
            )}
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
}

export default StoreBoard;
