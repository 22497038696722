import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";

function DeleteMerchandise(props) {
  const { mID, setShowModal, showModal } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const handleRemoveMerchandise = (event) => {
    event.preventDefault();

    Axios.delete(`/shop/${shopID}/Merchandise/RemoveMerchandise/${mID}`)
      .then((response) => {
        setShowModal({
          ...showModal,
          open: !showModal.open,
        });
        toast.success("Merchandise Deleted", response.data.MESSAGE);
      })
      .catch((error) => {
        toast.error("Error Deleting Merchandise", error.response.data.MESSAGE);
      });
  };
  return (
    <div>
      <Form onSubmit={handleRemoveMerchandise}>
        <h5 className="text-center">Confirm Delete this Merchandise</h5>
        <div className="text-center">
          <Button className="btn btn-success" type="submit">
            Yes
          </Button>
          <Button
            className="btn btn-danger"
            onClick={() =>
              setShowModal({
                ...showModal,
                open: false,
              })
            }
          >
            No
          </Button>
        </div>
      </Form>
    </div>
  );
}
DeleteMerchandise.propTypes = {
  mID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
};
export default DeleteMerchandise;
