import React, { useContext } from "react";
import classnames from "classnames";
import { useLocation } from "react-router-dom";
import {
  Button,
  Collapse,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
  NavbarBrand,
  Navbar,
  // NavItem,
  // NavLink,
  Nav,
  Container,
} from "reactstrap";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";

function ShopNavbar(props) {
  const { selctShopID } = useContext(ShopContext);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [color, setColor] = React.useState("navbar-dark");
  const location = useLocation();

  React.useEffect(() => {
    if (
      window.outerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && collapseOpen) {
      setColor("bg-dark");
    } else {
      setColor("navbar-dark");
    }
  };
  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setSidebarOpen(!sidebarOpen);
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-dark class to the navbar when closed
  // ad bg-dark when opened
  const toggleCollapse = () => {
    if (!collapseOpen) {
      setColor("bg-dark");
    } else {
      setColor("navbar-dark");
    }
    setCollapseOpen(!collapseOpen);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateColor);
  });

  const { handleMiniClick } = props;

  return (
    <Navbar
      className={classnames("navbar-absolute fixed-top", color)}
      expand="lg"
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-minimize">
            <Button
              className="btn-icon btn-round"
              color="default"
              id="minimizeSidebar"
              onClick={handleMiniClick}
            >
              <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
              <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
            </Button>
          </div>
          <div
            className={classnames("navbar-toggle", {
              toggled: sidebarOpen,
            })}
          >
            <button
              aria-label="navbar-toggler"
              className="navbar-toggler"
              type="button"
              onClick={toggleSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
            <span className="d-none d-md-block">
              {selctShopID && selctShopID.Name}
            </span>
            <span className="d-block d-md-none">MEGAN</span>
          </NavbarBrand>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={collapseOpen}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          // data-target="#navigation"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse className="justify-content-end" navbar isOpen={collapseOpen}>
          {/* <Form>
            <InputGroup className="no-border">
              <Input defaultValue="" placeholder="Search..." type="text" />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="nc-icon nc-zoom-split" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form> */}
          <Nav navbar>
            {/* <NavItem>
              <NavLink
                className="btn-magnify"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-layout-11" />
                <p>
                  <span className="d-lg-none d-md-block">Stats</span>
                </p>
              </NavLink>
            </NavItem> */}
            {/* <UncontrolledDropdown className="btn-rotate" nav>
              <DropdownToggle
                aria-haspopup
                caret
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                nav
              >
                <i className="nc-icon nc-bell-55" />
                <p>
                  <span className="d-lg-none d-md-block">Some Actions</span>
                </p>
              </DropdownToggle>
              <DropdownMenu
                persist
                aria-labelledby="navbarDropdownMenuLink"
                right
              >
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  Action
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  Another action
                </DropdownItem>
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  Something else here
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
            {/* <NavItem>
              <NavLink
                className="btn-rotate"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                <i className="nc-icon nc-settings-gear-65" />
                <p>
                  <span className="d-lg-none d-md-block">Account</span>
                </p>
              </NavLink>
            </NavItem> */}
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

ShopNavbar.propTypes = {
  handleMiniClick: PropTypes.func.isRequired,
};

export default ShopNavbar;
