import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";

function UpdateStatusReceived(props) {
  const { tID, statusModal, setStatusModal } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  // Handle option selection

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    // Send the selected option value to the API endpoint using axios
    Axios.put(`/shop/${shopID}/TradeInParcelReceived/${tID}`)
      .then((response) => {
        setStatusModal({
          ...statusModal,
          open: !statusModal.open,
        });
        // Handle the response from the API
        toast.success(response.data.MESSAGE);
      })
      .catch((error) => {
        // Handle error
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <div className="text-center">
          <Button type="submit" className=" ml-5 btn btn-primary">
            Parcel Received
          </Button>
        </div>
      </Form>
    </div>
  );
}
UpdateStatusReceived.propTypes = {
  tID: PropTypes.string.isRequired,
  setStatusModal: PropTypes.func.isRequired,
  statusModal: PropTypes.func.isRequired,
};
export default UpdateStatusReceived;
