/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ViewOrderDetail() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: orderData } = useQuery(
    `/shop/${shopID}/Order/${id}`,
    async () => Axios.get(`/shop/${shopID}/Order/${id}`)
  );

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <Card className="card-stats card-dark">
        <CardBody className="mt-3">
          <p className="bg-info p-2 pl-4">User Information</p>
          <Row>
            <Col md="6">
              <Card
                className="card-stats card-dark"
                style={{ border: "1px solid #51BCDA" }}
              >
                <CardBody className="text-white">
                  <p>Order Number: {orderData?.data.ID}</p>
                  <p>Invoice Number: {orderData?.data.Invoice}</p>
                  <p>Status: {orderData?.data.ShopOrderStatus}</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card
                className="card-stats card-dark"
                style={{ border: "1px solid #51BCDA", paddingBottom: "35px" }}
              >
                <CardBody className="text-white">
                  <p>Name: {orderData?.data.Name}</p>
                  <p>Email: {orderData?.data.Email}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card
            className="card-stats card-dark "
            style={{ border: "1px solid #51BCDA" }}
          >
            <CardBody>
              <Row>
                <Col md="6">
                  <div className="text-white">
                    <h6
                      className="font-weight-bold text-left text-capitalize text-white"
                      style={{ fontSize: "14px" }}
                    >
                      Billing Information
                    </h6>
                    <p
                      className="text-capitalize font-weight-bold "
                      style={{ fontSize: "15px" }}
                    >
                      {orderData?.data.BillingName}
                    </p>
                    <p style={{ fontSize: "15px" }}>
                      {`H/P: ${orderData?.data.BillingContact}`}
                      <br />
                      {orderData?.data.Email}
                      <br />
                      {orderData?.data.BillingAddress}
                      <br />
                    </p>
                  </div>
                </Col>
                <Col md="6">
                  <div>
                    <h6
                      className="font-weight-bold text-capitalize"
                      style={{ fontSize: "14px" }}
                    >
                      Shipping Information
                    </h6>
                    <p
                      className="text-capitalize font-weight-bold"
                      style={{ fontSize: "15px" }}
                    >
                      {orderData?.data.ShippingName}
                    </p>
                    <p style={{ fontSize: "15px" }}>
                      {`H/P: ${orderData?.data.ShippingContact}`}
                      <br />
                      {orderData?.data.Email}
                      <br />
                      {orderData?.data.ShippingAddress}
                      <br />
                    </p>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Row>
            <Col md="6">
              <p className="m-2">
                Source:
                <span
                  className="m-3 p-2 pl-5 pr-5"
                  style={{ border: "1px solid #51BCDA", borderRadius: "10px" }}
                >
                  {orderData?.data.OrderSource}
                </span>
              </p>
            </Col>
            <Col md="6">
              {orderData?.data.DeliveryType === "Self PickUp" ? (
                <div>
                  <p className="m-2">
                    Logistic:
                    <span
                      className="m-3 p-2 pl-5 pr-5"
                      style={{
                        border: "1px solid #51BCDA",
                        borderRadius: "10px",
                      }}
                    >
                      {orderData?.data.DeliveryType} &nbsp;
                    </span>
                  </p>
                </div>
              ) : (
                <div>
                  <p className="m-2">
                    Logistic:
                    <span
                      className="m-3 p-2 pl-5 pr-5"
                      style={{
                        border: "1px solid #51BCDA",
                        borderRadius: "10px",
                      }}
                    >
                      {orderData?.data.DeliveryType} &nbsp;{" :"}
                      {orderData?.data.Courier} &nbsp;{" "}
                      {orderData?.data.TrackingCode} &nbsp;
                    </span>
                  </p>
                </div>
              )}
            </Col>
          </Row>
          <div style={{ overflowX: "auto" }}>
            <table className="mt-5 w-100" style={{ minWidth: "600px" }}>
              <thead>
                <tr>
                  <td>ID</td>
                  <td>Item</td>
                  <td>Before Discount</td>
                  <td>Discount</td>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                {orderData?.data.Product.map((order) => (
                  <tr>
                    <td>
                      <img
                        src={order.ImageS3Url}
                        alt=""
                        width="100px"
                        height="100px"
                      />
                    </td>
                    <td className="py-3">
                      {order.Name}
                      <br />
                      {order.VariationSKU}
                      <br />
                      {order.Attribute.map((attr) => (
                        <p
                          className="mt-2"
                          style={{ lineHeight: "10px" }}
                        >{`${attr.Attribute} = ${attr.Value}`}</p>
                      ))}
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={order.TotalOrderCost.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={order.Discount.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={
                          order.TotalOrderCost.toFixed(2) -
                          order.Discount.toFixed(2)
                        }
                        currency="MYR"
                      />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td />
                  <td>
                    <p>Shipping Fee - {orderData?.data.Region}</p>
                  </td>
                  <td />
                  <td />
                  <td>
                    <CurrencyFormat
                      decimal={2}
                      price={orderData?.data.ShippingFee.toFixed(2)}
                      currency="MYR"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>
                    <hr
                      style={{
                        border: "1px solid #51BCDA",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} />
                  <td>
                    <p>Total : </p>
                  </td>
                  <td>
                    <CurrencyFormat
                      decimal={2}
                      price={orderData?.data.TotalCost.toFixed(2)}
                      currency="MYR"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} />
                  <td colSpan={2}>
                    <hr
                      style={{
                        border: "1px solid #51BCDA",
                      }}
                    />
                  </td>
                </tr>

                <tr>
                  <td colSpan={3} />
                  <td>
                    <p style={{ marginRight: "20%" }}>Ewallet : </p>
                  </td>
                  <td>
                    <CurrencyFormat
                      decimal={2}
                      price={orderData?.data.Ewallet.toFixed(2)}
                      currency="MYR"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} />
                  <td>
                    <p style={{ marginRight: "20%" }}>Bank Transfer : </p>
                  </td>
                  <td>
                    <CurrencyFormat
                      decimal={2}
                      price={orderData?.data.TotalOrderCost.toFixed(2)}
                      currency="MYR"
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} />
                  <td colSpan={2}>
                    <hr
                      className="d-flex justify-content-end mr-1"
                      style={{
                        border: "1px solid #51BCDA",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default ViewOrderDetail;
