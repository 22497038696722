/* eslint-disable new-cap */
import React, { useContext } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import "react-datepicker/dist/react-datepicker.css";
import "../../customerService/customerservice.css";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import CustomButton from "components/CustomButton/CustomButton";

const columns = [
  {
    name: "Name",
    selector: (row) => (
      <div className="m-2 text-left">
        <p>{`${row.Name}`}</p>
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Email",
    selector: (row) => row.Email,
    sortable: true,
    wrap: true,
  },
  {
    name: "Current Wallet Balance",
    selector: (row) => (
      <div>
        <CurrencyFormat decimal={2} price={row.Balance} currency="MYR" />
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      if (!row) return null;

      return (
        <div>
          <CustomButton
            to={`/shopowner/ewallet/viewewalletdetails/${row.userID}`}
            icon="fa fa-search-plus"
            title="User Details"
          />
          <CustomButton
            to={`/shopowner/ewallet/editewalletdetails/${row.userID}`}
            icon="fa fa-pencil"
            title="User Edit"
          />
        </div>
      );
    },
  },
];

function Ewallet() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [filterDataResult, setFilterDataResult] = React.useState([]);

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/Ewallet/AllUserEwalletData`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Ewallet/AllUserEwalletData`, {
        params: {
          limitPerPage: "1000000",
        },
      }).then((res) => {
        const reversedData = res.data.reverse();
        setFilterDataResult(reversedData);
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setFilterDataResult(data.data);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.filter(
        (item) =>
          (item.Name &&
            item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.Email &&
            item.Email.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Ewallet Dashboard</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default Ewallet;
