/* eslint-disable react/prop-types */
import React from "react";
import { useTable, useFilters, useSortBy, usePagination } from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import { matchSorter } from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import { Container, Row, Col, FormGroup, Input } from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({ column: { preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;
  return (
    <FormGroup>
      <Input
        placeholder={`Search ${count} records...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

export function SelectorColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const set = new Set();
    preFilteredRows.forEach((row) => {
      set.add(row.values[id]);
    });
    return [...set.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Input
      type="select"
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </Input>
  );
}

const priceReformat = (string) =>
  string.replaceAll("RM", "").replace("-", "").trim().split(` `);

export function PriceRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    const price = preFilteredRows.length
      ? priceReformat(preFilteredRows[0].values[id])
      : [0, 0];
    let [min1, max1] = [price[0], price[price.length - 1]];
    preFilteredRows.forEach((row) => {
      min1 = Math.min(priceReformat(row.values[id])[0], min1);
      max1 = Math.max(priceReformat(row.values[id])[price.length - 1], max1);
    });

    return [min1, max1];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        min={min}
        max={max}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        min={min}
        max={max}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min1 = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max1 = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min1 = Math.min(row.values[id], min1);
      max1 = Math.max(row.values[id], max1);
    });
    return [min1, max1];
  }, [id, preFilteredRows]);

  return (
    <input
      type="range"
      max={max}
      min={min}
      value={filterValue || min}
      onChange={(e) => {
        setFilter(parseInt(e.target.value, 10));
      }}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: 10,
    label: "10 rows",
  });
  const [pageSelect, handlePageSelect] = React.useState({
    value: 0,
    label: "Page 1",
  });
  const filterTypes = React.useMemo(
    () => ({
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) =>
        rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        }),
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      FilterColumn: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    pageOptions,
    previousPage,
    canPreviousPage,
    canNextPage,
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize: 10, pageIndex: 0 },
    },
    useFilters, // useFilters!
    useSortBy,
    usePagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  const pageSelectData = [...Array(pageOptions.length)].map((i) => i);
  const numberOfRowsData = [5, 10, 20, 25, 50, 70];
  return (
    <div className="ReactTable -striped -highlight info-pagination">
      <div className="pagination-top">
        <div className="-pagination">
          <div className="-previous">
            <button
              type="button"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="-btn"
            >
              Previous
            </button>
          </div>
          <div className="-center">
            <Container>
              <Row className="justify-content-center">
                <Col md="4" sm="6" xs="12">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pageSelect"
                    value={pageSelect}
                    onChange={(value) => {
                      gotoPage(value.value);
                      handlePageSelect(value);
                    }}
                    options={pageSelectData.map((prop, key) => ({
                      value: key,
                      label: `Page ${key + 1}`,
                    }))}
                    placeholder="Choose Page"
                  />
                </Col>
                <Col md="4" sm="6" xs="12">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="numberOfRows"
                    value={numberOfRows}
                    onChange={(value) => {
                      setPageSize(value.value);
                      setNumberOfRows(value);
                    }}
                    options={numberOfRowsData.map((prop) => ({
                      value: prop,
                      label: `${prop} rows`,
                    }))}
                    placeholder="Choose Rows"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="-next">
            <button
              type="button"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="-btn"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <table
        {...getTableProps()}
        className="rt-table table-dark table table-striped"
      >
        <thead className="rt-thead -header">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="rt-tr">
              {headerGroup.headers.map((column) => (
                <th
                  className={classnames("rt-th rt-resizable-header", {
                    "-cursor-pointer": column.sortable,
                    "-sort-asc": column.isSorted && !column.isSortedDesc,
                    "-sort-desc": column.isSorted && column.isSortedDesc,
                  })}
                >
                  <div
                    className="rt-resizable-header-content"
                    {...(column.sortable && {
                      ...column.getHeaderProps(column.getSortByToggleProps()),
                    })}
                  >
                    {column.render("Header")}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>
                    {column.canFilter ? column.render("FilterColumn") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="rt-tbody">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classnames(
                  "rt-tr",
                  { " -odd": i % 2 === 0 },
                  { " -even": i % 2 === 1 }
                )}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="rt-td"
                    style={{ whiteSpace: "break-spaces" }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <button
              type="button"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="-btn"
            >
              Previous
            </button>
          </div>
          <div className="-center">
            <Container>
              <Row className="justify-content-center">
                <Col md="4" sm="6" xs="12">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pageSelect"
                    value={pageSelect}
                    onChange={(value) => {
                      gotoPage(value.value);
                      handlePageSelect(value);
                    }}
                    options={pageSelectData.map((prop, key) => ({
                      value: key,
                      label: `Page ${key + 1}`,
                    }))}
                    placeholder="Choose Page"
                  />
                </Col>
                <Col md="4" sm="6" xs="12">
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="numberOfRows"
                    value={numberOfRows}
                    onChange={(value) => {
                      setPageSize(value.value);
                      setNumberOfRows(value);
                    }}
                    options={numberOfRowsData.map((prop) => ({
                      value: prop,
                      label: `${prop} rows`,
                    }))}
                    placeholder="Choose Rows"
                  />
                </Col>
              </Row>
            </Container>
          </div>
          <div className="-next">
            <button
              type="button"
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="-btn"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

// Define custom filter function! (example:  )
export function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export function minMaxRange(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = priceReformat(row.values[id]);
    if (filterValue[0] === undefined) {
      return rowValue[rowValue.length - 1] < filterValue[1];
    }
    if (filterValue[1] === undefined) {
      return rowValue[0] >= filterValue[0];
    }
    return (
      rowValue[0] >= filterValue[0] &&
      rowValue[rowValue.length - 1] <= filterValue[1]
    );
  });
}
minMaxRange.autoRemove = (val) =>
  JSON.stringify(val) === JSON.stringify([undefined, undefined]);

export function linkFilter(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id].props.children;
    return rowValue.toLowerCase().includes(filterValue.toLowerCase());
  });
}

export default Table;
