import React, { useContext } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";

function ReturnRefundImage(props) {
  const { rID } = props;
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const { data: rData } = useQuery(`/shop/${shopID}/${rID}`, async () =>
    Axios.get(`/shop/${shopID}/${rID}`)
  );

  return (
    <div>
      {rData?.data.CSTransferredDispatchFileUrlList.length === 0 ? (
        <div>
          <p className="text-dark">No Image</p>
        </div>
      ) : (
        <div>
          {rData?.data.CSTransferredDispatchFileUrlList.map((img) => (
            <div className="imgPreview mr-2">
              <img
                className="VimgCenter"
                alt="..."
                src={img}
                width="80px"
                height="80px"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
ReturnRefundImage.propTypes = {
  rID: PropTypes.string.isRequired,
};
export default ReturnRefundImage;
