import React, { useContext, useEffect } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import UserContext from "services/UserContext";
import ShopOwner from "layouts/ShopOwner";

import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";
import { ShopProvider } from "services/ShopContext";
import { PaymentProvider } from "services/PaymentContext";
import SetPassword from "views/pages/SetPassword/SetPassword";

export default function App() {
  const { user } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (user?.IsOneTimePassword === true) {
      history.replace(
        user.IsOneTimePassword === true
          ? "/setPassword"
          : "/shopOwner/dashboard"
      );
    }
  }, [user, history]);

  return (
    <ShopProvider>
      <PaymentProvider>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
          {user && (
            <Route
              path="/shopOwner"
              render={(props) => <ShopOwner {...props} />}
            />
          )}
          <Route path="/setPassword" component={SetPassword} />
          {user && <Redirect to="/shopOwner/dashboard" />}
          {!user && <Redirect to="/auth/Login" />}
        </Switch>
      </PaymentProvider>
    </ShopProvider>
  );
}
