import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import classnames from "classnames";
import ShopContext from "services/ShopContext";
import UserProfile from "./UserProfile";
import UserAddrress from "./UserAddrress";

function UserDetails() {
  const { userID } = useParams();
  const history = useHistory();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const { data: userDetails } = useQuery(
    `/shop/${shopID}/CustomerService/GetSingleUser/${userID}`,
    async () =>
      Axios.get(`/shop/${shopID}/CustomerService/GetSingleUser/${userID}`)
  );

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <Card
        className="card-stats card-dark"
        style={{ border: "1px solid #51BCDA" }}
      >
        <CardBody className="text-white">
          <Row>
            <Col md="12">
              <div className="m-3 text-center">
                <Nav tabs style={{ display: "contents" }}>
                  <Row className="justify-content-between">
                    <Col md="5" style={{ backgroundColor: "#51BCDA" }}>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "1",
                          })} text-white font-weight-bold `}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          Profile
                        </NavLink>
                      </NavItem>
                    </Col>
                    <Col md="5" style={{ backgroundColor: "#51BCDA" }}>
                      <NavItem>
                        <NavLink
                          className={`${classnames({
                            active: activeTab === "2",
                          })} text-white font-weight-bold`}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          Address
                        </NavLink>
                      </NavItem>
                    </Col>
                  </Row>
                </Nav>
              </div>
              <div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <UserProfile userDetails={userDetails} />
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="2">
                    <UserAddrress userID={userDetails?.data.ID} />
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

export default UserDetails;
