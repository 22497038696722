/* eslint-disable no-unused-expressions */

import LockScreen from "views/pages/LockScreen";
import ShopProfile from "views/shop/Profile";
import Login from "views/pages/Login";
import Register from "views/pages/Register";
import Department from "views/shop/Admin/Department";
import HODResetPass from "views/pages/HODResetPass/HODResetPass";
import SetPassword from "views/pages/SetPassword/SetPassword";
import ForgotPassword from "views/pages/Forgotpassword/ForgotPassword";
import ResetPassword from "views/pages/Forgotpassword/ResetPassword";
// feedback
import Feedback from "views/shop/Feedback";
import feedbackDetails from "views/shop/Feedback/feedbackDetails";
// Finance Dept
import financeDash from "views/dashboard/financeDash";
import ReturnNrefund from "views/departments/finance/ReturnNrefund";
import FinanceMain from "views/departments/finance";
import FinanceReport from "views/departments/finance/FinanceReport";
import FinanceTradeIn from "views/departments/finance/TradeIn";
// Marketing Part 1
import marketingDash from "views/dashboard/marketingDash";
import CollectionCollaboration from "views/departments/marketing/CollectionCollaboration";
import CouponLoyalty from "views/departments/marketing/CouponLoyalty";
import Promotion from "views/departments/marketing/Promotion";
import CouponView from "views/departments/marketing/MarketingView/CouponView";
import ShopBanner from "views/departments/marketing/ShopBanner";
import PromotionView from "views/departments/marketing/MarketingView/PromotionView";
import CollectionView from "views/departments/marketing/MarketingView/CollectionView";
import CollaborationView from "views/departments/marketing/MarketingView/CollaborationView";
// Marketing Part 2
import RewardOverview from "views/departments/marketing/Rewards/Overview";
import CouponAnalytic from "views/departments/marketing/Analytics/CouponAnalytic";
import LoyaltyAnalytic from "views/departments/marketing/Analytics/LoyaltyAnalytic";
import RewardMemberShip from "views/departments/marketing/Rewards/Membership";
import BirthdayCoupon from "views/departments/marketing/Analytics/BirthdayCoupon";
import MembershipTier from "views/departments/marketing/Analytics/MembershipTier";
import RewardMemberDetail from "views/departments/marketing/Rewards/MemberDetail";
import CouponLoyaltyDetails from "views/departments/marketing/Analytics/CouponLoyaltyDetails";
// HR Dept
import HolidaySetting from "views/departments/hr/HolidaySetting";
// Packaging Dept
import PackOrderDB from "views/departments/packaging/ViewOrderAll";
import PackReturnDB from "views/departments/packaging/ViewReturnAll";
import PackTradeDB from "views/departments/packaging/ViewTradeInAll";
import PackShipDB from "views/departments/packaging/ViewShipBackAll";
import PackSummaryDB from "views/departments/packaging/ViewSummary";
import PackTradeDetail from "views/departments/packaging/ViewTradeInDetail";
// Store Picker Dept
import StoreBoard from "views/departments/storePicker/Dashboard";
import StoreDetail from "views/departments/storePicker/Detail";
// Dispatch Dept
import DispatchPage from "views/departments/dispatch";
import DispatchDash from "views/dashboard/dispatchDash";
// Customer Service Dept
import CSOrder from "views/departments/customerService";
import ReturnRefund from "views/departments/customerService/ReturnRefund";
import ViewOrderDetail from "views/departments/customerService/ViewOrderDetail";
import PickupOrder from "views/departments/customerService/PickupOrder";
import TradeIn from "views/departments/customerService/TradeIn";
import ShipBack from "views/departments/customerService/ShipBack";
import ViewTradeInDetails from "views/departments/customerService/ViewTradeInDetails";
// User Dashboard
import UserDashboard from "views/departments/userDash";
import UserStatement from "views/departments/userDash/UserStatement";
import UserDetails from "views/departments/userDash/UserDetails";
import LoyaltyInfo from "views/departments/userDash/LoyaltyInfo";
// Sales Dept
import salesDashboard from "views/departments/sales/salesDashboard";
import pickedOrder from "views/departments/sales/orders/pickedOrder";
import orderReport from "views/departments/sales/orders/orderReport";
import orderDetails from "views/departments/sales/orders/orderDetails";
import refundValidation from "views/departments/sales/refund/refundValidation";
import ViewRefundDetails from "views/departments/sales/refund/ViewRefundDetails";
import TradeInDetails from "views/departments/sales/tradein/TradeInDetails";
import RefundShipBack from "views/departments/sales/refund/RefundShipBack";
import TradeInShipBack from "views/departments/sales/tradein/TradeInShipBack";
import Merchandise from "views/departments/sales/merchandise";
import AddNewMerchandise from "views/departments/sales/merchandise/AddNewMerchandise";
import ViewMerchandiseDetails from "views/departments/sales/merchandise/ViewMerchandiseDetails";
import ViewSalesOrderDetail from "views/departments/sales/orders/ViewOrderDetail";
import TradeInsales from "views/departments/sales/tradein/TradeIn";
import ERP from "views/departments/sales/erp";
// Return Refund
import ReturnRefundOrders from "views/departments/returnRefund";
import ReturnBack from "views/departments/returnRefund/ReturnBack";
import RefundApproval from "views/departments/returnRefund/RefundApproval";
import ViewRefundDetail from "views/departments/returnRefund/ViewRefundDetail";
import CanceledRefund from "views/departments/returnRefund/CanceledRefund";
// E-Wallet
import Ewallet from "views/departments/ewallet/ewalletDash";
import ViewEwallet from "views/departments/ewallet/ewalletDash/ViewEwallet";
import EditEwallet from "views/departments/ewallet/ewalletDash/EditEwallet";
import RefundEwallet from "views/departments/ewallet/refundEwallet";
import TradeList from "views/departments/ewallet/tradeEwallet/TradeList";
import ViewRefund from "views/departments/ewallet/refundEwallet/ViewRefund";
import ViewTradeIn from "views/departments/ewallet/tradeEwallet/ViewTradeIn";
// Live
import LiveDB from "views/departments/live/LiveDB";
import ViewBatchDetail from "views/departments/live/TableDetailIDisplay";
import MarketplaceDB from "views/departments/live/MarketplaceDB";
import OrderDB from "views/departments/live/OrderDB";
import BatchCreateSteps from "views/departments/live/CreateBatch";
import InvoiceDetail from "views/departments/live/InvoiceDetailIDisplay";
import CreateQuotation from "views/departments/live/CreateQuotation";
import CreateInvoice from "views/departments/live/CreateInvoice";

const dept = [];

if (JSON.parse(localStorage.getItem("deptProfile")) !== null) {
  dept.push(JSON.parse(localStorage.getItem("deptProfile")));
}

// import SalesReport from "views/shop/viewPayment/SalesReport";
// import OrdersList from "views/shop/orders/ViewAll";
// import OrderDetail from "views/shop/orders/ViewSingle";
// import ProductsList from "views/shop/Products/ViewAll";
// import ProductDetail from "views/shop/Products/ViewSingle";
// set redirect: true to hide on sidebar
const routesShop = [
  {
    name: "Department",
  },
  {
    collapse: true,
    layout: "/shopOwner",
    redirect: true,
    name: "Pages",
    icon: "nc-icon nc-book-bookmark",
    state: "pagesCollapse",
    views: [
      {
        path: "/login",
        name: "Login",
        mini: "L",
        component: Login,
        layout: "/auth",
      },
      {
        path: "/forgot-password",
        name: "ForgotPassword",
        mini: "FP",
        component: ForgotPassword,
        layout: "/auth",
      },
      {
        path: "/reset-password",
        name: "ResetPassword",
        mini: "RP",
        component: ResetPassword,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        mini: "R",
        component: Register,
        layout: "/auth",
      },
      {
        path: "/lock-screen",
        name: "LockScreen",
        mini: "LS",
        component: LockScreen,
        layout: "/auth",
      },
      {
        path: "/shop-profile",
        name: "ShopProfile",
        mini: "SP",
        component: ShopProfile,
        layout: "/shopOwner",
      },
      {
        path: "/changepassword",
        name: "Reset Staff Password",
        mini: "RST",
        component: HODResetPass,
        layout: "/shopOwner",
      },
      {
        path: "/department",
        name: "DeparmentSettings",
        mini: "DS",
        component: Department,
        layout: "/shopOwner",
      },
      {
        path: "/setPassword",
        name: "SetNewPassword",
        mini: "SP",
        component: SetPassword,
        layout: "/shopOwner",
      },
    ],
  },
];

if (dept.length !== 0) {
  switch (dept[0].DepartmentName) {
    case "Human Resource": {
      routesShop.push(
        // HR
        {
          layout: "/shopOwner",
          name: "HR",
          icon: "nc-icon nc-badge",
          state: "hrCollapse",
          collapse: true,
          views: [
            {
              path: "/hr/holidaySetting",
              name: "Holiday Setting",
              mini: "HS",
              component: HolidaySetting,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "Sales": {
      routesShop.push(
        // Sales
        {
          layout: "/shopOwner",
          name: "Sales Dept",
          icon: "nc-icon nc-book-bookmark",
          state: "pagesCollapse0",
          collapse: true,
          views: [
            {
              path: "/sales/dashboard",
              name: "Dashboard",
              mini: "SD",
              component: salesDashboard,
              layout: "/shopOwner",
            },
            {
              path: "/sales/stock-details",
              name: "Picked Item",
              mini: "PI",
              redirect: true,
              component: pickedOrder,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-report",
              name: "Order Report",
              mini: "OR",
              collapse: false,
              component: orderReport,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-details",
              name: "Order Details",
              mini: "OD",
              component: orderDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-details",
              name: "Order Details",
              mini: "OD",
              redirect: true,
              component: orderDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/vieworderdetails/:id",
              redirect: true,
              component: ViewSalesOrderDetail,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-details",
              name: "Return Refund",
              mini: "RR",
              component: refundValidation,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-details",
              redirect: true,
              component: refundValidation,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-shipback",
              name: "Refund Ship Back",
              mini: "RSB",
              component: RefundShipBack,
              layout: "/shopOwner",
            },
            {
              path: "/sales/trade-In",
              name: "Trade In",
              mini: "TI",
              component: TradeInsales,
              layout: "/shopOwner",
            },
            {
              path: "/sales/tradein-shipback",
              name: "TradeIn Ship Back",
              mini: "TISB",
              component: TradeInShipBack,
              layout: "/shopOwner",
            },
            {
              path: "/sales/viewrefunddetails/:orderId/:id",
              redirect: true,
              component: ViewRefundDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/viewtradeindetails/:id",
              redirect: true,
              component: TradeInDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/ERP",
              name: "ERP Invoice",
              mini: "ERP",
              component: ERP,
              layout: "/shopOwner",
            },
          ],
        },
        // Merchandise
        {
          layout: "/shopOwner",
          name: "Merchandise",
          path: "/sales/Merchandise/dashboard",
          icon: "nc-icon nc-book-bookmark",
          state: "MerchandiseCollapse",
          collapse: true,
          views: [
            {
              path: "/sales/Merchandise/dashboard",
              name: "Merchandise",
              mini: "M",
              component: Merchandise,
              layout: "/shopOwner",
            },
            {
              path: "/salesMerchandise/NewMerchandise",
              redirect: true,
              component: AddNewMerchandise,
              layout: "/shopOwner",
            },
            {
              path: "/sales/Merchandise/:id",
              redirect: true,
              component: ViewMerchandiseDetails,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "Dispatch": {
      routesShop.push(
        // Dispatch
        {
          layout: "/shopOwner",
          name: "Dispatch",
          icon: "nc-icon nc-box-2",
          state: "pagesCollapseDispatch",
          collapse: true,
          views: [
            {
              path: "/dispatch/dashboard",
              name: "Dashboard",
              mini: "DD",
              component: DispatchDash,
              layout: "/shopOwner",
            },
            {
              path: "/dispatch/main/:id",
              name: "Dispatch",
              mini: "D",
              component: DispatchPage,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "Customer Service": {
      routesShop.push(
        // CS
        {
          layout: "/shopOwner",
          name: "Customer Service Dept",
          icon: "nc-icon nc-book-bookmark",
          state: "CustomerServiceCollapse",
          collapse: true,
          views: [
            {
              path: "/customerservice/Order",
              name: "Order",
              mini: "CS",
              component: CSOrder,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/vieworderdetails/:id",
              redirect: true,
              component: ViewOrderDetail,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/pickup-order",
              name: "Pick Up",
              mini: "PU",
              component: PickupOrder,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/viewtradeindetails/:id",
              redirect: true,
              component: ViewTradeInDetails,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/TradeIn",
              name: "Trade In",
              mini: "TI",
              component: TradeIn,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/ShipBack",
              name: "Trade Ship Back",
              mini: "SB",
              component: ShipBack,
              layout: "/shopOwner",
            },
          ],
        },
        // Return Refund
        {
          layout: "/shopOwner",
          name: "Refund Dashboard",
          icon: "nc-icon nc-paper",
          state: "pagesCollapse2",
          collapse: true,
          views: [
            {
              path: "/ReturnRefund/RequestApproval",
              name: "Request Approval",
              mini: "RA",
              component: ReturnRefundOrders,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/ReturnBack",
              name: "Return Back",
              mini: "RB",
              component: ReturnBack,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/ReturnRefund",
              name: "Return Refund",
              mini: "RR",
              component: ReturnRefund,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/RefundApproval",
              name: "Refund Approval",
              mini: "RA",
              component: RefundApproval,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/viewRefunddetails/:id",
              redirect: true,
              component: ViewRefundDetail,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/CanceledRefund",
              name: "Canceled Refund",
              mini: "CR",
              component: CanceledRefund,
              layout: "/shopOwner",
            },
          ],
        },
        // User
        {
          layout: "/shopOwner",
          name: "User Dashboard",
          icon: "nc-icon nc-paper",
          state: "pagesCollapse4",
          collapse: true,
          views: [
            {
              path: "/UserDashboard",
              name: "User Dashboard",
              mini: "UD",
              component: UserDashboard,
              layout: "/shopOwner",
            },
            {
              path: "/userOrderHistory/view/:userID",
              redirect: true,
              component: UserStatement,
              layout: "/shopOwner",
            },
            {
              path: "/viewUserHistory/view/:userID",
              redirect: true,
              component: UserDetails,
              layout: "/shopOwner",
            },
            {
              path: "/loyaltyInfoUser/view/:userID",
              redirect: true,
              component: LoyaltyInfo,
              layout: "/shopOwner",
            },
          ],
        }
      );

      if (
        dept[0].Position === "DepartmentHead" ||
        dept[0].Position === "Supervisor"
      ) {
        routesShop.push(
          // Ewallet
          {
            layout: "/shopOwner",
            name: "Ewallet Dashboard",
            icon: "nc-icon nc-paper",
            state: "pagesCollapse3",
            collapse: true,
            views: [
              {
                path: "/EwalletDashboard",
                name: "Ewallet List",
                mini: "RA",
                component: Ewallet,
                layout: "/shopOwner",
              },
              {
                path: "/ewallet/viewewalletdetails/:id",
                redirect: true,
                component: ViewEwallet,
                layout: "/shopOwner",
              },
              {
                path: "/ewallet/editewalletdetails/:id",
                redirect: true,
                component: EditEwallet,
                layout: "/shopOwner",
              },
              {
                path: "/EwalletRefundReturn",
                name: "Return Refund Ewallet",
                mini: "RR",
                component: RefundEwallet,
                layout: "/shopOwner",
              },
              {
                path: "/tradeEwalletList",
                name: "Trade In Ewallet",
                mini: "TE",
                component: TradeList,
                layout: "/shopOwner",
              },
              {
                path: "/refundewallet/view/:orderId/:id",
                redirect: true,
                component: ViewRefund,
                layout: "/shopOwner",
              },
              {
                path: "/tradeewallet/view/:id",
                redirect: true,
                component: ViewTradeIn,
                layout: "/shopOwner",
              },
            ],
          }
        );
      }
      break;
    }
    case "Stock Picker": {
      routesShop.push(
        // Stock picker
        {
          path: "/storePicker",
          layout: "/shopOwner",
          name: "Store Picker",
          icon: "nc-icon nc-book-bookmark",
          component: StoreBoard,
          collapse: false,
        },
        {
          path: "/storePickerDetail/:id",
          layout: "/shopOwner",
          name: "Store Picker",
          icon: "nc-icon nc-book-bookmark",
          component: StoreDetail,
          redirect: true,
        }
      );
      break;
    }
    case "Packaging": {
      routesShop.push(
        // Packaging
        {
          collapse: true,
          layout: "/shopOwner",
          name: "Packaging",
          icon: "nc-icon nc-book-bookmark",
          state: "pagesCollapse",
          views: [
            {
              path: "/packaging-summary-dashboard",
              name: "Dashboard",
              mini: "D",
              component: PackSummaryDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-order-dashboard",
              name: "Order",
              mini: "O",
              component: PackOrderDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-return-dashboard",
              name: "Return",
              mini: "R",
              component: PackReturnDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-shipback-dashboard",
              name: "Ship Back",
              mini: "S",
              component: PackShipDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-trade-dashboard",
              name: "Trade In",
              mini: "T",
              component: PackTradeDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-trade-detail/:id",
              name: "Trade In",
              mini: "T",
              redirect: true,
              component: PackTradeDetail,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "Finance": {
      routesShop.push(
        // Finance
        {
          layout: "/shopOwner",
          name: "Finance",
          icon: "nc-icon nc-bank",
          state: "pagesCollapse1",
          collapse: true,
          views: [
            {
              path: "/finance/dashboard",
              name: "Dashboard",
              mini: "FD",
              component: financeDash,
              layout: "/shopOwner",
            },
            {
              path: "/finance/orders",
              name: "All Order",
              mini: "O",
              component: FinanceMain,
              layout: "/shopOwner",
            },
            // {
            //   path: "/finance/order/:id",
            //   name: "Orders",
            //   mini: "O",
            //   redirect: true,
            //   component: OrderDetail,
            //   layout: "/shopOwner",
            // },
            {
              path: "/finance/ordersType/:order",
              name: "Orders",
              mini: "O",
              redirect: true,
              component: FinanceMain,
              layout: "/shopOwner",
            },
            {
              path: "/finance/returnNrefund",
              name: "Return And Refund",
              mini: "RR",
              component: ReturnNrefund,
              layout: "/shopOwner",
            },
            {
              path: "/finance/tradeIn",
              name: "Trade In",
              mini: "TI",
              component: FinanceTradeIn,
              layout: "/shopOwner",
            },
            {
              path: "/finance/report",
              name: "Report",
              mini: "FR",
              component: FinanceReport,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "Marketing": {
      routesShop.push({
        layout: "/shopOwner",
        name: "Digital Marketing",
        icon: "nc-icon nc-book-bookmark",
        state: "marketingCollapse",
        collapse: true,
        views: [
          {
            path: "/marketing/dashboard",
            name: "Dashboard",
            mini: "MD",
            component: marketingDash,
            layout: "/shopOwner",
          },
          {
            layout: "/shopOwner",
            name: "Shop Décor",
            icon: "nc-icon nc-shop",
            state: "marketingCollapse",
            collapse: true,
            views: [
              {
                path: "/marketing/ShopBanner",
                name: "Shop Banner",
                mini: "SB",
                component: ShopBanner,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/CollectCollab",
                name: "Collection / Collaboration",
                mini: "CC",
                component: CollectionCollaboration,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/CollectionDetails/:id",
                name: "View Collection Details",
                mini: "VP",
                component: CollectionView,
                redirect: true,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/CollaborationDetails/:id",
                name: "View Collaboration Details",
                mini: "VP",
                component: CollaborationView,
                redirect: true,
                layout: "/shopOwner",
              },
            ],
          },
          {
            layout: "/shopOwner",
            name: "Promotions",
            icon: "nc-icon nc-tag-content",
            state: "marketingCollapse",
            collapse: true,
            views: [
              {
                path: "/marketing/Promotion",
                name: "Promotions",
                mini: "P",
                component: Promotion,
                layout: "/shopOwner",
              },

              {
                path: "/marketing/Coupon",
                name: "Coupons / Loyalty",
                mini: "CL",
                component: CouponLoyalty,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/CouponDetails/:id",
                name: "View Coupon Details",
                mini: "VC",
                redirect: true,
                component: CouponView,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/PromotionDetails/:id",
                name: "View Promotion Details",
                mini: "VP",
                component: PromotionView,
                redirect: true,
                layout: "/shopOwner",
              },
            ],
          },
          {
            layout: "/shopOwner",
            name: "Rewards",
            icon: "nc-icon nc-book-bookmark",
            state: "marketingCollapse",
            collapse: true,
            views: [
              {
                path: "/marketing/rewards/overview",
                name: "Overview",
                mini: "o",
                component: RewardOverview,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/rewards/membership",
                name: "Mermbership",
                mini: "m",
                component: RewardMemberShip,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/rewards/memberDetail/:id",
                name: "Member Detail",
                mini: "m",
                redirect: true,
                component: RewardMemberDetail,
                layout: "/shopOwner",
              },
            ],
          },
          {
            layout: "/shopOwner",
            name: "Analytic",
            icon: "nc-icon nc-chart-bar-32",
            state: "marketingCollapse",
            collapse: true,
            views: [
              {
                path: "/marketing/analytic/coupon",
                name: "Coupon",
                mini: "C",
                component: CouponAnalytic,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/analytic/loyalty",
                name: "Loyalty",
                mini: "L",
                component: LoyaltyAnalytic,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/analytic/couponLoyalty/:type/:id",
                redirect: true,
                component: CouponLoyaltyDetails,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/analytic/birthday",
                name: "Birthday Coupon",
                mini: "BC",
                component: BirthdayCoupon,
                layout: "/shopOwner",
              },
              {
                path: "/marketing/analytic/membershiptier",
                name: "Membership Tier",
                mini: "MT",
                component: MembershipTier,
                layout: "/shopOwner",
              },
            ],
          },
        ],
      });
      break;
    }
    case "Live": {
      routesShop.push(
        // Stock picker
        {
          collapse: true,
          layout: "/shopOwner",
          name: "Live",
          icon: "nc-icon nc-book-bookmark",
          state: "liveCollapse",
          views: [
            {
              path: "/Live-main-dashboard",
              name: "Dashboard",
              mini: "D",
              component: LiveDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-dashboard/:type/:id",
              name: "detail",
              mini: "O",
              redirect: true,
              component: ViewBatchDetail,
              layout: "/shopOwner",
            },
            {
              path: "/Live-marketplaceDB-dashboard",
              name: "MarketPlace",
              mini: "MP",
              component: MarketplaceDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-order-dashboard",
              name: "Order",
              mini: "O",
              component: OrderDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-create-quotation",
              name: "Create Quotation",
              mini: "Q",
              component: CreateQuotation,
              layout: "/shopOwner",
            },
            {
              path: "/Live-create-invoice",
              name: "Create Invoice",
              mini: "Q",
              component: CreateInvoice,
              layout: "/shopOwner",
            },
            {
              path: "/Live-invoice-detail/:type/:id",
              name: "invoice detail",
              mini: "O",
              redirect: true,
              component: InvoiceDetail,
              layout: "/shopOwner",
            },
            {
              path: "/Live-batch-generate/:step",
              name: "Create Batch",
              mini: "B",
              redirect: true,
              component: BatchCreateSteps,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    case "IT Team": {
      routesShop.push(
        // Reviews
        {
          path: "/feedbacks",
          name: "Feedback",
          icon: "nc-icon nc-paper",
          component: Feedback,
          layout: "/shopOwner",
          collapse: false,
        },
        {
          path: "/feedback/:id",
          name: "Feedback",
          mini: "F",
          redirect: true,
          component: feedbackDetails,
          layout: "/shopOwner",
        },
        // HR
        {
          layout: "/shopOwner",
          name: "HR",
          icon: "nc-icon nc-badge",
          state: "hrCollapse",
          collapse: true,
          views: [
            {
              path: "/hr/holidaySetting",
              name: "Holiday Setting",
              mini: "HS",
              component: HolidaySetting,
              layout: "/shopOwner",
            },
          ],
        },
        // Marketing Dept
        {
          layout: "/shopOwner",
          name: "Digital Marketing",
          icon: "nc-icon nc-book-bookmark",
          state: "marketingCollapse",
          collapse: true,
          views: [
            {
              path: "/marketing/dashboard",
              name: "Dashboard",
              mini: "MD",
              component: marketingDash,
              layout: "/shopOwner",
            },
            {
              layout: "/shopOwner",
              name: "Shop Décor",
              icon: "nc-icon nc-shop",
              state: "marketingCollapse",
              collapse: true,
              views: [
                {
                  path: "/marketing/ShopBanner",
                  name: "Shop Banner",
                  mini: "SB",
                  component: ShopBanner,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/CollectCollab",
                  name: "Collection / Collaboration",
                  mini: "CC",
                  component: CollectionCollaboration,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/CollectionDetails/:id",
                  name: "View Collection Details",
                  mini: "VP",
                  component: CollectionView,
                  redirect: true,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/CollaborationDetails/:id",
                  name: "View Collaboration Details",
                  mini: "VP",
                  component: CollaborationView,
                  redirect: true,
                  layout: "/shopOwner",
                },
              ],
            },
            {
              layout: "/shopOwner",
              name: "Promotions",
              icon: "nc-icon nc-tag-content",
              state: "marketingCollapse",
              collapse: true,
              views: [
                {
                  path: "/marketing/Promotion",
                  name: "Promotions",
                  mini: "P",
                  component: Promotion,
                  layout: "/shopOwner",
                },

                {
                  path: "/marketing/Coupon",
                  name: "Coupons / Loyalty",
                  mini: "CL",
                  component: CouponLoyalty,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/CouponDetails/:id",
                  name: "View Coupon Details",
                  mini: "VC",
                  redirect: true,
                  component: CouponView,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/PromotionDetails/:id",
                  name: "View Promotion Details",
                  mini: "VP",
                  component: PromotionView,
                  redirect: true,
                  layout: "/shopOwner",
                },
              ],
            },
            {
              layout: "/shopOwner",
              name: "Rewards",
              icon: "nc-icon nc-book-bookmark",
              state: "marketingCollapse",
              collapse: true,
              views: [
                {
                  path: "/marketing/rewards/overview",
                  name: "Overview",
                  mini: "o",
                  component: RewardOverview,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/rewards/membership",
                  name: "Mermbership",
                  mini: "m",
                  component: RewardMemberShip,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/rewards/memberDetail/:id",
                  name: "Member Detail",
                  mini: "m",
                  redirect: true,
                  component: RewardMemberDetail,
                  layout: "/shopOwner",
                },
              ],
            },
            {
              layout: "/shopOwner",
              name: "Analytic",
              icon: "nc-icon nc-chart-bar-32",
              state: "marketingCollapse",
              collapse: true,
              views: [
                {
                  path: "/marketing/analytic/coupon",
                  name: "Coupon",
                  mini: "C",
                  component: CouponAnalytic,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/analytic/loyalty",
                  name: "Loyalty",
                  mini: "L",
                  component: LoyaltyAnalytic,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/analytic/couponLoyalty/:type/:id",
                  redirect: true,
                  component: CouponLoyaltyDetails,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/analytic/birthday",
                  name: "Birthday Coupon",
                  mini: "BC",
                  component: BirthdayCoupon,
                  layout: "/shopOwner",
                },
                {
                  path: "/marketing/analytic/membershiptier",
                  name: "Membership Tier",
                  mini: "MT",
                  component: MembershipTier,
                  layout: "/shopOwner",
                },
              ],
            },
          ],
        },
        // Sales Dept
        {
          layout: "/shopOwner",
          name: "Sales Dept",
          icon: "nc-icon nc-book-bookmark",
          state: "pagesCollapse0",
          collapse: true,
          views: [
            {
              path: "/sales/dashboard",
              name: "Dashboard",
              mini: "SD",
              component: salesDashboard,
              layout: "/shopOwner",
            },
            {
              path: "/sales/stock-details",
              name: "Picked Item",
              mini: "PI",
              redirect: true,
              component: pickedOrder,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-report",
              name: "Order Report",
              mini: "OR",
              collapse: false,
              component: orderReport,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-details",
              name: "Order Details",
              mini: "OD",
              component: orderDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/order-details",
              name: "Order Details",
              mini: "OD",
              redirect: true,
              component: orderDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/vieworderdetails/:id",
              redirect: true,
              component: ViewSalesOrderDetail,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-details",
              name: "Return Refund",
              mini: "RR",
              component: refundValidation,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-details",
              redirect: true,
              component: refundValidation,
              layout: "/shopOwner",
            },
            {
              path: "/sales/refund-shipback",
              name: "Refund Ship Back",
              mini: "RSB",
              component: RefundShipBack,
              layout: "/shopOwner",
            },
            {
              path: "/sales/trade-In",
              name: "Trade In",
              mini: "TI",
              component: TradeInsales,
              layout: "/shopOwner",
            },
            {
              path: "/sales/tradein-shipback",
              name: "TradeIn Ship Back",
              mini: "TISB",
              component: TradeInShipBack,
              layout: "/shopOwner",
            },
            {
              path: "/sales/viewrefunddetails/:orderId/:id",
              redirect: true,
              component: ViewRefundDetails,
              layout: "/shopOwner",
            },
            {
              path: "/sales/viewtradeindetails/:id",
              redirect: true,
              component: TradeInDetails,
              layout: "/shopOwner",
            },
            // ERP
            {
              path: "/sales/ERP",
              name: "ERP Invoice",
              mini: "ERP",
              component: ERP,
              layout: "/shopOwner",
            },
          ],
        },
        // Dispatch Dept
        {
          layout: "/shopOwner",
          name: "Dispatch",
          icon: "nc-icon nc-box-2",
          state: "pagesCollapseDispatch",
          collapse: true,
          views: [
            {
              path: "/dispatch/dashboard",
              name: "Dashboard",
              mini: "DD",
              component: DispatchDash,
              layout: "/shopOwner",
            },
            {
              path: "/dispatch/main/:id",
              name: "Dispatch",
              mini: "D",
              component: DispatchPage,
              layout: "/shopOwner",
            },
          ],
        },
        // CS Dept
        {
          layout: "/shopOwner",
          name: "Customer Service Dept",
          icon: "nc-icon nc-book-bookmark",
          state: "CustomerServiceCollapse",
          collapse: true,
          views: [
            {
              path: "/customerservice/Order",
              name: "Order",
              mini: "CS",
              component: CSOrder,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/vieworderdetails/:id",
              redirect: true,
              component: ViewOrderDetail,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/pickup-order",
              name: "Pick Up",
              mini: "PU",
              component: PickupOrder,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/viewtradeindetails/:id",
              redirect: true,
              component: ViewTradeInDetails,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/TradeIn",
              name: "Trade In",
              mini: "TI",
              component: TradeIn,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/ShipBack",
              name: "Trade Ship Back",
              mini: "SB",
              component: ShipBack,
              layout: "/shopOwner",
            },
          ],
        },
        // Return Refund
        {
          layout: "/shopOwner",
          name: "Refund Dashboard",
          icon: "nc-icon nc-paper",
          state: "pagesCollapse2",
          collapse: true,
          views: [
            {
              path: "/ReturnRefund/RequestApproval",
              name: "Request Approval",
              mini: "RA",
              component: ReturnRefundOrders,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/ReturnBack",
              name: "Return Back",
              mini: "RB",
              component: ReturnBack,
              layout: "/shopOwner",
            },
            {
              path: "/customerservice/ReturnRefund",
              name: "Return Refund",
              mini: "RR",
              component: ReturnRefund,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/RefundApproval",
              name: "Refund Approval",
              mini: "RA",
              component: RefundApproval,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/viewRefunddetails/:orderId/:id",
              redirect: true,
              component: ViewRefundDetail,
              layout: "/shopOwner",
            },
            {
              path: "/ReturnRefund/CanceledRefund",
              name: "Canceled Refund",
              mini: "CR",
              component: CanceledRefund,
              layout: "/shopOwner",
            },
          ],
        },
        // Ewallet
        {
          layout: "/shopOwner",
          name: "Ewallet Dashboard",
          icon: "nc-icon nc-paper",
          state: "pagesCollapse3",
          collapse: true,
          views: [
            {
              path: "/EwalletDashboard",
              name: "Ewallet List",
              mini: "RA",
              component: Ewallet,
              layout: "/shopOwner",
            },
            {
              path: "/ewallet/viewewalletdetails/:id",
              redirect: true,
              component: ViewEwallet,
              layout: "/shopOwner",
            },
            {
              path: "/ewallet/editewalletdetails/:id",
              redirect: true,
              component: EditEwallet,
              layout: "/shopOwner",
            },
            {
              path: "/EwalletRefundReturn",
              name: "Return Refund Ewallet",
              mini: "RR",
              component: RefundEwallet,
              layout: "/shopOwner",
            },
            {
              path: "/tradeEwalletList",
              name: "Trade In Ewallet",
              mini: "TE",
              component: TradeList,
              layout: "/shopOwner",
            },
            {
              path: "/refundewallet/view/:orderId/:id",
              redirect: true,
              component: ViewRefund,
              layout: "/shopOwner",
            },
            {
              path: "/tradeewallet/view/:id",
              redirect: true,
              component: ViewTradeIn,
              layout: "/shopOwner",
            },
          ],
        },
        // Merchandise
        {
          layout: "/shopOwner",
          name: "Merchandise",
          path: "/sales/Merchandise/dashboard",
          icon: "nc-icon nc-book-bookmark",
          state: "MerchandiseCollapse",
          collapse: true,
          views: [
            {
              path: "/sales/Merchandise/dashboard",
              name: "Merchandise",
              mini: "M",
              component: Merchandise,
              layout: "/shopOwner",
            },
            {
              path: "/salesMerchandise/NewMerchandise",
              redirect: true,
              component: AddNewMerchandise,
              layout: "/shopOwner",
            },
            {
              path: "/sales/Merchandise/:id",
              redirect: true,
              component: ViewMerchandiseDetails,
              layout: "/shopOwner",
            },
          ],
        },
        // User Dash
        {
          layout: "/shopOwner",
          name: "User Dashboard",
          icon: "nc-icon nc-paper",
          state: "pagesCollapse4",
          collapse: true,
          views: [
            {
              path: "/UserDashboard",
              name: "User Dashboard",
              mini: "UD",
              component: UserDashboard,
              layout: "/shopOwner",
            },
            {
              path: "/userOrderHistory/view/:userID",
              redirect: true,
              component: UserStatement,
              layout: "/shopOwner",
            },
            {
              path: "/viewUserHistory/view/:userID",
              redirect: true,
              component: UserDetails,
              layout: "/shopOwner",
            },
            {
              path: "/loyaltyInfoUser/view/:userID",
              redirect: true,
              component: LoyaltyInfo,
              layout: "/shopOwner",
            },
          ],
        },
        // Stock picker
        {
          path: "/storePicker",
          layout: "/shopOwner",
          name: "Store Picker",
          icon: "nc-icon nc-book-bookmark",
          component: StoreBoard,
          collapse: false,
        },
        {
          path: "/storePickerDetail/:id",
          layout: "/shopOwner",
          name: "Store Picker",
          icon: "nc-icon nc-book-bookmark",
          component: StoreDetail,
          redirect: true,
        },
        // Packaging
        {
          collapse: true,
          layout: "/shopOwner",
          name: "Packaging",
          icon: "nc-icon nc-book-bookmark",
          state: "packCollapse",
          views: [
            {
              path: "/packaging-summary-dashboard",
              name: "Dashboard",
              mini: "D",
              component: PackSummaryDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-order-dashboard",
              name: "Order",
              mini: "O",
              component: PackOrderDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-return-dashboard",
              name: "Return",
              mini: "R",
              component: PackReturnDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-shipback-dashboard",
              name: "Ship Back",
              mini: "S",
              component: PackShipDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-trade-dashboard",
              name: "Trade In",
              mini: "T",
              component: PackTradeDB,
              layout: "/shopOwner",
            },
            {
              path: "/packaging-trade-detail/:id",
              name: "Trade In",
              mini: "T",
              redirect: true,
              component: PackTradeDetail,
              layout: "/shopOwner",
            },
          ],
        },
        // Finance
        {
          layout: "/shopOwner",
          name: "Finance",
          icon: "nc-icon nc-bank",
          state: "pagesCollapse1",
          collapse: true,
          views: [
            {
              path: "/finance/dashboard",
              name: "Dashboard",
              mini: "FD",
              component: financeDash,
              layout: "/shopOwner",
            },
            {
              path: "/finance/orders",
              name: "All Order",
              mini: "O",
              component: FinanceMain,
              layout: "/shopOwner",
            },
            // {
            //   path: "/finance/orders/:id",
            //   name: "Orders",
            //   mini: "O",
            //   redirect: true,
            //   component: OrderDetail,
            //   layout: "/shopOwner",
            // },
            {
              path: "/finance/ordersType/:order",
              name: "Orders",
              mini: "O",
              redirect: true,
              component: FinanceMain,
              layout: "/shopOwner",
            },
            {
              path: "/finance/returnNrefund",
              name: "Return And Refund",
              mini: "RR",
              component: ReturnNrefund,
              layout: "/shopOwner",
            },
            {
              path: "/finance/tradeIn",
              name: "Trade In",
              mini: "TI",
              component: FinanceTradeIn,
              layout: "/shopOwner",
            },
            {
              path: "/finance/report",
              name: "Report",
              mini: "FR",
              component: FinanceReport,
              layout: "/shopOwner",
            },
          ],
        },
        // Live
        {
          collapse: true,
          layout: "/shopOwner",
          name: "Live",
          icon: "nc-icon nc-book-bookmark",
          state: "liveCollapse",
          views: [
            {
              path: "/Live-main-dashboard",
              name: "Dashboard",
              mini: "D",
              component: LiveDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-dashboard/:type/:id",
              name: "detail",
              mini: "O",
              redirect: true,
              component: ViewBatchDetail,
              layout: "/shopOwner",
            },
            {
              path: "/Live-marketplaceDB-dashboard",
              name: "MarketPlace",
              mini: "MP",
              component: MarketplaceDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-order-dashboard",
              name: "Order",
              mini: "O",
              component: OrderDB,
              layout: "/shopOwner",
            },
            {
              path: "/Live-create-quotation",
              name: "Create Quotation",
              mini: "Q",
              component: CreateQuotation,
              layout: "/shopOwner",
            },
            {
              path: "/Live-create-invoice",
              name: "Create Invoice",
              mini: "Q",
              component: CreateInvoice,
              layout: "/shopOwner",
            },
            {
              path: "/Live-invoice-detail/:type/:id",
              name: "invoice detail",
              mini: "O",
              redirect: true,
              component: InvoiceDetail,
              layout: "/shopOwner",
            },
            {
              path: "/Live-batch-generate/:step",
              name: "Create Batch",
              mini: "B",
              redirect: true,
              component: BatchCreateSteps,
              layout: "/shopOwner",
            },
          ],
        }
      );
      break;
    }
    default:
      null;
  }
}

export default routesShop;
