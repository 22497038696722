import React, { useMemo } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const UserContext = React.createContext(null);

export function UserProvider({ children }) {
  const [user, setUser] = React.useState(
    JSON.parse(localStorage.getItem("user")) || null
  );

  const login = async (email, password) => {
    const res = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/public/Account/Login`,
      {
        email,
        password,
      }
    );
    setUser(res.data);
    localStorage.setItem("user", JSON.stringify(res.data));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const updateLocalAccessToken = (
    AccessToken,
    AccessTokenExpiry,
    RefreshToken,
    RefreshTokenExpiry
  ) => {
    // 2) Change Authorization token with the new AccessToken & RefreshToken
    const temp = {
      ...user,
      AccessToken,
      AccessTokenExpiry,
      RefreshToken,
      RefreshTokenExpiry,
    };
    localStorage.setItem("user", JSON.stringify(temp));
    setUser(temp);
    // to refresh the currect page after the accesstoken has update to new AccessToken
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
  };

  const contextValue = useMemo(
    () => ({
      user,
      setUser,
      login,
      updateLocalAccessToken,
      logout,
    }),
    [user]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
