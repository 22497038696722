/* eslint-disable new-cap */
import React, { useContext } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import "react-datepicker/dist/react-datepicker.css";
import "../customerService/customerservice.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const columns = [
  {
    name: "User ID",
    selector: (row) => <p>{`${row.ID}`}</p>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Full Name",
    selector: (row) => row.FullName,
  },
  {
    name: "Contact No",
    selector: (row) => row.Contact,
    sortable: true,
    wrap: true,
  },
  {
    name: "Email",
    selector: (row) => row.Email,
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => row.Status,
    sortable: true,
    wrap: true,
  },
  {
    name: "Order History",
    selector: (row) => {
      if (!row) return null;
      return (
        <Button
          id="itemhover"
          key={row.TradeInID}
          className="pl-2 pr-2 btn btn-default"
          tag={Link}
          to={`/shopowner/userOrderHistory/view/${row.ID}`}
        >
          Statement
        </Button>
      );
    },
    sortable: true,
    wrap: true,
  },
  {
    name: "View",
    selector: (row) => {
      if (!row) return null;

      return (
        <div>
          <Button
            id="itemhover"
            key={row.ID}
            className="pl-2 pr-2 btn btn-default"
            tag={Link}
            to={`/shopowner/viewUserHistory/view/${row.ID}`}
          >
            Details
          </Button>
        </div>
      );
    },
  },
];

function UserDashboard() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");

  const [filterDataResult, setFilterDataResult] = React.useState([]);

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/CustomerService/AllUser`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/CustomerService/AllUser`).then((res) => {
        const filteredData = res.data.filter((item) => item.Role === "User");
        setFilterDataResult(filteredData);

        return filteredData;
      }),
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setFilterDataResult(data);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.filter(
        (item) =>
          (item.FullName &&
            item.FullName.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.Email &&
            item.Email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (item.Contact &&
            item.Contact.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">User Dashboard</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div>
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="" onClick={() => filterData()}>
                  Search
                </Button>
                <Button
                  className="ml-1"
                  color="danger"
                  onClick={() => resetFilter()}
                >
                  Reset All Filter
                </Button>
              </div>
            </Col>
          </Row>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default UserDashboard;
