/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../customerService/customerservice.css";
import moment from "moment";
import UpdateStatus from "./UpdateStatus";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function ReturnRefundOrders() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [filterResult, setFilterResult] = React.useState([]);

  const { isLoading, data, isSuccess, refetch } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () => {
      if (shopID !== "0") {
        const res = await Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`);
        const filteredData = res.data.filter(
          (item) => item.Status === "Pending Review"
        );
        setFilterDataResult(filteredData);
        setFilterResult(filteredData);

        return res;
      }
    },
    { refetchOnWindowFocus: false }
  );

  const columns = [
    {
      name: "Order No",
      selector: (row) => <p>{`#${row.OrderID}`}</p>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Date",
      selector: (row) => (
        <div>
          <p>{moment(row.OrderCreatedDate).format("DD/MM/YYYY")}</p>
          {moment(row.OrderCreatedDate).format("hh:mm A")}
        </div>
      ),
    },
    {
      name: "Reference No",
      selector: (row) => <p>{`#${row.ID}`}</p>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Return Request Date",
      selector: (row) => (
        <div>
          <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
          {moment(row.CreatedDate).format("hh:mm A")}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Product",
      selector: (row) => (
        <div>
          <p>{row.Product.ProductEngName}</p>
          {row.Product.VariationList.map((item) => (
            <>
              <p>{item.VariationSKU}</p>
              <p>{`Weight(g): ${item.Weight}`}</p>
            </>
          ))}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Reason(s)",
      selector: (row) => (
        <div>
          {row.ReturnReasonList.map((reason) => (
            <p>{reason}</p>
          ))}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Comments",
      selector: (row) => row.Comment,
      sortable: true,
      wrap: true,
    },
    {
      name: "Files",
      selector: (row) => (
        <div>
          {row.ReturnRequestImageList.map((img) => (
            <p>
              <a href={img.ImageUrl} target="_blank" rel="noopener noreferrer">
                {img.ImageUrl.length > 25
                  ? `${img.ImageUrl.substring(0, 25)}...`
                  : img.ImageUrl}
              </a>
            </p>
          ))}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => {
        const [showModal, setShowModal] = useState({
          rID: "",
          open: false,
        });
        if (!row) return null;

        return (
          <div>
            <p className="mt-2">{row.Status}</p>

            <Button
              size="sm"
              onClick={() =>
                setShowModal({
                  rID: row.ID,
                  open: !showModal.open,
                })
              }
            >
              Update Status
            </Button>
            {/* Modal for Add CS image */}
            <Modal
              size="lg"
              isOpen={showModal.open}
              toggle={() =>
                setShowModal({
                  ...showModal,
                  open: !showModal.open,
                })
              }
            >
              <ModalHeader>
                <Button
                  className="btn btn-primary float-right"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setShowModal({
                      ...showModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
                Update Request Status
              </ModalHeader>
              <ModalBody>
                <UpdateStatus
                  refetch={refetch}
                  rID={showModal.rID}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              </ModalBody>
            </Modal>
            {/* End of Modal for Add CS image */}
          </div>
        );
      },
    },
  ];

  const resetFilter = () => {
    setDateRange([null, null]);
    setFilterDataResult(filterResult);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.OrderID &&
              item.OrderID.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Request Approval</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ReturnRefundOrders;
