/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import moment from "moment";
import { Card, CardBody, Table } from "reactstrap";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";

function PreviewOrder(props) {
  const { previewID } = props;
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: previewData } = useQuery(
    `/shop/${shopID}/Order/${previewID}`,
    async () => Axios.get(`/shop/${shopID}/Order/${previewID}`)
  );

  return (
    <div>
      <p className="font-weight-bold">
        {`Invoice Number : ${previewData?.data.Invoice}`}
      </p>
      <p className="font-weight-bold">
        {`Order No : #${previewData?.data.ID}`}
      </p>
      <small>{`Paid on ${moment(previewData?.data.OrderDate).format(
        "YYYY-MM-DD hh:mm A"
      )}`}</small>
      <p className="font-weight-bold mt-3">Shipping</p>
      <p className="" style={{ lineHeight: "10px" }}>
        {previewData?.data.ShippingName}
      </p>
      <p className="" style={{ lineHeight: "10px" }}>
        {previewData?.data.ShippingAddress}
      </p>
      <p className="font-weight-bold" style={{ lineHeight: "10px" }}>
        Email address: &nbsp;
        <span className="font-weight-normal">{previewData?.data.Email}</span>
      </p>
      <p className="font-weight-bold" style={{ lineHeight: "10px" }}>
        Phone: &nbsp;
        <span className="font-weight-normal">
          {previewData?.data.ShippingContact}
        </span>
      </p>
      <p className="font-weight-bold mt-3 mb-3">
        Logistic:
        <span>
          {previewData?.data.Courier} &nbsp;&nbsp;
          {previewData?.data.TrackingCode}
        </span>
      </p>
      <Card style={{ border: "1px solid #51BCDA" }}>
        <CardBody className="mt-2">
          <Table>
            {previewData?.data.Product.map((products) => (
              <>
                <thead>
                  <tr>
                    <th />
                    <th style={{ fontSize: "12px" }}>Qty</th>
                    <th style={{ fontSize: "12px" }}>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <p className="" style={{ lineHeight: "10px" }}>
                        {products.Name}
                      </p>
                      <p className="" style={{ lineHeight: "10px" }}>
                        {products.VariationSKU}
                      </p>
                      {products.Attribute.map((variant) => (
                        <p className="" style={{ lineHeight: "10px" }}>
                          {`${variant.Attribute} : ${variant.Value}`}
                        </p>
                      ))}
                    </td>
                    <td>{products.ProductOrderCount}</td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={products.TotalOrderCost.toFixed(2)}
                        currency="MYR"
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            ))}
            <tbody>
              <tr>
                <td className="text-right font-weight-bold">Total</td>
                <td>{previewData?.data.TotalProductQuantity}</td>
                <td>
                  <CurrencyFormat
                    decimal={2}
                    price={previewData?.data.SubTotal}
                    currency="MYR"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
PreviewOrder.propTypes = {
  previewID: PropTypes.string.isRequired,
};
export default PreviewOrder;
