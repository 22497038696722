/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  // CardFooter,
  // CardTitle,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";
import "./sales.css";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
// import CurrencyFormat from "react-currency-format";

function salesDashboard() {
  // const { Previousday } = useContext(PaymentContext);
  const [dateRanges, setDateRanges] = useState([null, null]);
  const [filterDate, setFilterDate] = useState([null, null]);
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;

  const {
    isSuccess,
    isError,
    data: filterData,
  } = useQuery([`/shop/${shopID}/Order/Sale/Dashboard`, filterDate], async () =>
    Axios.get(`/shop/${shopID}/Order/Sale/Dashboard`, {
      params: {
        dateFrom: filterDate[0] ? moment(filterDate[0]).format() : "",
        dateTo: filterDate[1] ? moment(filterDate[1]).format() : "",
      },
    })
      .then((res) => res.data)
      .catch((err) => {
        toast.error(err.response.data.message);
      })
  );

  const { data: refundCountDt } = useQuery(
    `/shop/${shopID}/ReturnRefund/GetStatusCount`,
    async () => {
      const res = await Axios.get(
        `/shop/${shopID}/ReturnRefund/GetStatusCount`
      );
      return res.data;
    }
  );

  const total =
    refundCountDt &&
    refundCountDt.PendingApprovalCount + refundCountDt &&
    refundCountDt.PendingShipBackCount;

  const handleFilterButtonClick = useCallback(() => {
    if (dateRanges[0] && dateRanges[1]) {
      const startDateParam = dateRanges[0].toISOString();
      let endDateParam = dateRanges[1].toISOString();

      const temp = new Date(dateRanges[1]);
      temp.setDate(temp.getDate() + 1);
      temp.setMilliseconds(temp.getMilliseconds() - 1);
      endDateParam = temp.toISOString();

      setFilterDate([startDateParam, endDateParam]);
    }
  }, [dateRanges, isSuccess]);

  const clearFilter = () => {
    setDateRanges([null, null]);
    setFilterDate([null, null]);
  };

  if (isError) {
    return (
      <div>
        <h1 className="title text-center">
          404
          <br />
          <p className="error-msg text-center">
            We are sorry, but something went wrong. We are working an fixing
            this.
            <br />
            Please refresh the page in a couple of seconds.
          </p>
        </h1>
      </div>
    );
  }

  return (
    <div className="content">
      <Row>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark">
            <CardBody>
              <Row className="d-flex  align-items-center">
                <Col xs="auto">
                  <Row>
                    <Col xs="auto">Select Date:</Col>
                    <Col>
                      <DatePicker
                        className="text-dark input-form"
                        selectsRange
                        startDate={dateRanges[0]}
                        endDate={dateRanges[1]}
                        dateFormat="dd/MM/yyyy"
                        onChange={(update) => {
                          setDateRanges(update);
                        }}
                        isClearable
                        wrapperClassName="w-100"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button
                    onClick={handleFilterButtonClick}
                    className="mt-2 p-2"
                    style={{ backgroundColor: "#51BCDA" }}
                  >
                    Filter
                  </Button>
                  <Button
                    style={{ backgroundColor: "#51BCDA" }}
                    onClick={clearFilter}
                    className="mt-2 p-2"
                  >
                    Clear Filter
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">Stock</div>
                  <Button
                    style={{ backgroundColor: "#51BCDA" }}
                    className="mt-2 p-2 ml-5"
                    tag={Link}
                    to="/shopOwner/sales/stock-details"
                  >
                    Details
                  </Button>
                </Col>
                <Col md="8" xs="7">
                  <Table bordered>
                    <thead
                      style={{
                        backgroundColor: "#51BCDA",
                        color: "white",
                      }}
                    >
                      <tr
                        style={{
                          color: "white",
                        }}
                      >
                        <th className="border-right">Picked</th>
                        <th className="border-right">Item Received</th>
                        <th>Pending Received</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td>{filterData?.Result.TotalPicked}</td>
                        <td className="text-success">
                          {filterData?.Result.TotalReceived}
                        </td>
                        <td className="text-danger">
                          {filterData?.Result.TotalPending}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">Order</div>

                  <Link to="/shopOwner/sales/order-details">
                    <Button
                      style={{ backgroundColor: "#51BCDA" }}
                      className="mt-2 p-2 ml-5"
                    >
                      Details
                    </Button>
                  </Link>
                </Col>
                <Col md="8" xs="7">
                  <Table bordered>
                    <thead
                      style={{
                        backgroundColor: "#51BCDA",
                        color: "white",
                      }}
                    >
                      <tr
                        style={{
                          color: "white",
                        }}
                      >
                        <th className="border-right" />
                        <th className="border-right">Invoice</th>
                        <th className="border-right">Jewellery</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td className="text-success">Checked</td>
                        <td className="text-success">
                          {filterData?.Result.TotalOrderChecked}
                        </td>
                        <td className="text-success">
                          {filterData?.Result.TotalReceived}
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td>Total</td>
                        <td>{filterData?.Result.TotalOrder}</td>
                        <td>{filterData?.Result.TotalReceived}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="card-stats card-dark">
            <CardBody>
              <Row className="d-flex  align-items-center">
                <Col xs="auto">
                  <Row>
                    <Col xs="auto">Select Date:</Col>
                    <Col>
                      <DatePicker
                        className="text-dark input-form"
                        selectsRange
                        startDate={dateRanges[0]}
                        endDate={dateRanges[1]}
                        dateFormat="dd/MM/yyyy"
                        onChange={(update) => {
                          setDateRanges(update);
                        }}
                        isClearable
                        wrapperClassName="w-100"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button
                    onClick={handleFilterButtonClick}
                    className="mt-2 p-2"
                    style={{ backgroundColor: "#51BCDA" }}
                  >
                    Filter
                  </Button>
                  <Button
                    style={{ backgroundColor: "#51BCDA" }}
                    onClick={clearFilter}
                    className="mt-2 p-2"
                  >
                    Clear Filter
                  </Button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="4" xs="5">
                  <div className="icon-big text-center icon-warning">
                    Refund
                  </div>

                  <Button
                    style={{ backgroundColor: "#51BCDA" }}
                    className="mt-2 p-2 ml-5"
                    tag={Link}
                    to="/shopOwner/sales/refund-details"
                  >
                    Details
                  </Button>
                </Col>
                <Col md="8" xs="7">
                  <Table bordered>
                    <thead
                      style={{
                        backgroundColor: "#51BCDA",
                        color: "white",
                      }}
                    >
                      <tr>
                        <th className="border-right">
                          Process To Ship & Validating Return
                        </th>
                        <th colSpan="2" className="border-right">
                          Refund Approval
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="text-center">
                        <td />
                        <td>
                          Approved:{" "}
                          {refundCountDt &&
                            refundCountDt.ApprovedValidationCount}
                        </td>
                        <td>
                          Rejected:{" "}
                          {refundCountDt &&
                            refundCountDt.RejectedValidationCount}
                        </td>
                      </tr>
                      <tr className="text-center">
                        <td>{total}</td>
                        <td>
                          Refund Paid:
                          {refundCountDt && refundCountDt.RefundPaidCount}
                        </td>
                        <td>
                          Shipped Back:
                          {refundCountDt && refundCountDt.ShippedBackCount}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col lg="6" md="6" sm="6" className="">
          <Card className="card-stats card-dark">
            <div className="icon-big text-center icon-warning font-weight-bold mt-5 ml-5 mr-5">
              Marketing Campaign
            </div>
            <CardBody className="mt-3">
              <div
                className="mScrollbar"
                style={{
                  top: "10px",
                  bottom: "10px",
                  left: "10px",
                  right: "0px",
                }}
              >
                {filterData?.Result.CouponList?.length === 0 ? (
                  <div className="text-center">No Campaign</div>
                ) : (
                  filterData &&
                  filterData?.Result.CouponList?.map((couponList) => (
                    <div key={couponList.ID} className="p-3">
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <img
                              src={require("assets/img/banner.png")}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p
                              className="card-category text-left text-white font-weight-bold"
                              style={{ fontSize: "12px" }}
                            >
                              Details of Campaign:
                            </p>
                            <p
                              className="text-left text-white"
                              style={{ fontSize: "12px" }}
                            >
                              {couponList.Description}
                            </p>

                            <p />
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-2 pb-2">
                        <p className="card-category text-left text-white">
                          Discounts/ Gifts:
                        </p>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="card-category text-center bg-info">
                              <p className="text-white pl-3 pr-3">
                                {couponList.Code}
                              </p>
                            </div>
                            {/* <div className="d-flex flex-wrap">
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; website
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Shopee
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Retails
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Facebook
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Lazada
                              </p>
                            </div> */}
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p
                                className="card-category text-left text-white font-weight-bold"
                                style={{ fontSize: "12px" }}
                              >
                                {couponList.Name}
                              </p>
                              <p
                                className="text-left text-white"
                                style={{ fontSize: "12px" }}
                              >
                                <ul className="pl-3">
                                  <li>
                                    {couponList.EnableMinPurchaseAmount ===
                                    false
                                      ? "With no minimum order"
                                      : `With RM${couponList.MinPurchaseAmount} minimum order`}
                                  </li>
                                  <li>
                                    {`Duration ${moment(
                                      couponList.StartDateTime
                                    ).format("DD MMM YYYY")} - ${moment(
                                      couponList.EndDateTime
                                    ).format("DD MMM YYYY")}`}
                                  </li>
                                </ul>
                              </p>

                              <p />
                            </div>
                          </Col>
                        </Row>
                        <hr
                          style={{
                            backgroudColor: "#51BCDA",
                            border: "1px solid #51BCDA",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  ))
                )}
                {filterData?.Result.PromotionList.length === 0
                  ? null
                  : filterData?.Result.PromotionList.map((promoList) => (
                      <div key={promoList.ID} className="p-3">
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <img
                                src={require("assets/img/banner.png")}
                                alt=""
                              />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p
                                className="card-category text-left text-white font-weight-bold"
                                style={{ fontSize: "12px" }}
                              >
                                Details of Campaign:
                              </p>
                              <p
                                className="text-left text-white"
                                style={{ fontSize: "12px" }}
                              >
                                {promoList.Description}
                              </p>

                              <p />
                            </div>
                          </Col>
                        </Row>
                        <div className="pt-2 pb-2">
                          <p className="card-category text-left text-white">
                            Discounts/ Gifts:
                          </p>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="card-category text-center bg-info">
                                <p className="text-white pl-3 pr-3">
                                  {promoList.Name}
                                </p>
                              </div>
                              <div className="d-flex flex-wrap">
                                <p
                                  className="card-category text-left text-white mr-2"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    style={{ color: "#51BCDA" }}
                                  />
                                  &nbsp; website
                                </p>
                                <p
                                  className="card-category text-left text-white mr-2"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    style={{ color: "#51BCDA" }}
                                  />
                                  &nbsp; Shopee
                                </p>
                                <p
                                  className="card-category text-left text-white mr-2"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    style={{ color: "#51BCDA" }}
                                  />
                                  &nbsp; Retails
                                </p>
                                <p
                                  className="card-category text-left text-white mr-2"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    style={{ color: "#51BCDA" }}
                                  />
                                  &nbsp; Facebook
                                </p>
                                <p
                                  className="card-category text-left text-white mr-2"
                                  style={{ lineHeight: "1px" }}
                                >
                                  <i
                                    className="fa fa-bookmark"
                                    aria-hidden="true"
                                    style={{ color: "#51BCDA" }}
                                  />
                                  &nbsp; Lazada
                                </p>
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p
                                  className="card-category text-left text-white font-weight-bold"
                                  style={{ fontSize: "12px" }}
                                >
                                  {promoList.Name}
                                </p>
                                <p
                                  className="text-left text-white"
                                  style={{ fontSize: "12px" }}
                                >
                                  <ul className="pl-3">
                                    <li>
                                      {promoList.Discount
                                        .EnableMinPurchaseAmount === false
                                        ? "With no minimum order"
                                        : `With RM${promoList.Discount.MinPurchaseAmount} minimum order`}
                                    </li>
                                    <li>
                                      {`Duration ${moment(
                                        promoList.StartDateTime
                                      ).format("DD MMM YYYY")} - ${moment(
                                        promoList.EndDateTime
                                      ).format("DD MMM YYYY")}`}
                                    </li>
                                  </ul>
                                </p>

                                <p />
                              </div>
                            </Col>
                          </Row>
                          <hr
                            style={{
                              backgroudColor: "#51BCDA",
                              border: "1px solid #51BCDA",
                              width: "100%",
                            }}
                          />
                        </div>
                      </div>
                    ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default salesDashboard;
