/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import PropTypes from "prop-types";

// reactstrap components
import { Col, Button, Spinner } from "reactstrap";

// core components
import BasicTable from "views/tables/BasicTable";
import "./live.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

function ViewBatchDetail({ type, batchID, batchName }) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const columnTable = [
    "No",
    "CreatedDate",
    "batchName",
    "VariationSKU",
    "Name",
    "ProductSKU",
    "Weight",
    "Size",
  ];

  const columns = [
    {
      name: "NO",
      selector: (row) => row.No,
      sortable: true,
      wrap: true,
    },
    {
      name: "Date",
      selector: (row) => row.CreatedDate,
      sortable: true,
      wrap: true,
    },
    {
      name: "Batch",
      selector: () => batchName,
      sortable: true,
      wrap: true,
    },
    {
      name: "SN",
      selector: (row) => row.VariationSKU,
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "SKU",
      selector: (row) => row.ProductSKU,
      sortable: true,
      wrap: true,
    },
    {
      name: "Weight",
      selector: (row) => row.Weight,
      sortable: true,
      wrap: true,
    },
    {
      name: "Size",
      selector: (row) => row.Size,
      sortable: true,
      wrap: true,
    },
    {
      ...(type === "Batch" && {
        name: "Discounted Price (RM)",
        selector: (row) => row.Price,
        sortable: true,
        wrap: true,
      }),
    },
  ];

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/${type}/${batchID}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        type === "Scanned"
          ? `/shop/${shopID}/Live/GetScannedSNBatch/${batchID}`
          : `/shop/${shopID}/Live/GetSelectedSNBatch/${batchID}`
      ).then((res) => {
        if (!res.data.MESSAGE) {
          // setColumnTable(Object.keys(res.data.Result[0]));
        }

        return res.data;
      }),
    { enabled: type !== "" }
  );

  const exportPDF = () => {
    const doc = new jsPDF({ orientation: "landscape", size: "A4" });
    doc.setFontSize(12);

    if (type === "Batch") {
      columnTable.push("Price");
    }

    let bodydata = data.map((d) =>
      columnTable.map((e) => (e === "batchName" ? batchName : d[e]))
    );

    function isISODate(dateString) {
      const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      return isoDatePattern.test(dateString);
    }

    bodydata = bodydata.map((item) => {
      const updatedItem = Object.keys(item).reduce((acc, key) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          if (isISODate(item[key])) {
            acc[key] = new Date(item[key]).toLocaleDateString("en-GB");
          } else if (Array.isArray(item[key]) && item[key].length <= 0) {
            acc[key] = "";
          } else {
            acc[key] = item[key];
          }
        }
        return acc;
      }, {});

      return updatedItem;
    });

    autoTable(doc, {
      head: [columns.map((c) => c.name)],
      body: bodydata,
    });
    doc.save(`${type}_Table_${moment(new Date()).format("DD-MM-YYYY")}.pdf`); // Generated PDF
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <Spinner />
      </Col>
    );
  }

  if (
    isSuccess &&
    !Array.isArray(data) &&
    data.MESSAGE === "this batch is not created"
  ) {
    return (
      <div className="content w-100 text-center" style={{ overflowX: "auto" }}>
        <h3>{data.MESSAGE}</h3>
        <div className="mt-5">
          Want to continue?
          <Link
            to={{
              pathname: "/shopowner/Live-batch-generate/2",
              state: {
                BID: batchID,
                BName: batchName,
              },
            }}
          >
            <Button>Continue</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="content w-100" style={{ overflowX: "auto" }}>
      {isSuccess && data && (
        <div id="packaging-report">
          <BasicTable
            pagination
            id="packaging-report"
            progressPending={isLoading}
            data={data}
            columns={columns}
          />
        </div>
      )}
      <div className="d-flex justify-content-end">
        <Button onClick={() => exportPDF(data)}>Export PDF</Button>

        <Link
          to={{
            pathname: "/shopowner/Live-batch-generate/2",
            state: {
              BID: batchID,
              BName: batchName,
            },
          }}
        >
          <Button>Edit</Button>
        </Link>
      </div>
    </div>
  );
}

ViewBatchDetail.propTypes = {
  type: PropTypes.string.isRequired,
  batchID: PropTypes.string.isRequired,
  batchName: PropTypes.string.isRequired,
};

export default ViewBatchDetail;
