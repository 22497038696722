/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useContext, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import moment from "moment";
import { toast } from "react-toastify";
import ShopContext from "services/ShopContext";
// import ReactDatePicker from "react-datepicker";
import CouponForm from "./MarketingForm/CouponForm";
import "react-datepicker/dist/react-datepicker.css";
import CouponTable from "./CouponTable";

function CouponLoyalty() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;
  const [activeTab, setActiveTab] = useState("1");
  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false);

  const [delID, setDelID] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const toggleDel = () => {
    setDelModal(!delModal);
  };

  const [loyalty, setLoyalty] = useState(false);
  const [dataState, setDataState] = useState([]);
  const [type, setType] = useState({
    Type: "Create",
    ID: "",
    Coupon: "Coupon",
  });
  const [filterRecord, setFilterRecord] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [pageParam, setPageParam] = useState(1);
  const [limitPage, setLimitPage] = useState(10);
  const [isFirst, setIsFirst] = useState(true);
  const { data, refetch } = useQuery(
    ["Coupons", { loyalty, pageParam, limitPage, dateRange }],
    async () =>
      Axios.get(
        `/public/Coupon`,
        {
          params: {
            dateFrom: dateRange[0],
            dateTo: dateRange[1],
            loyaltyCoupon: loyalty,
            page: pageParam,
            limitPerPage: limitPage,
            order: "DESC",
          },
        },
        {
          refetchOnWindowFocus: false,
        }
      )
  );

  const handleDelete = (id) => {
    setDelID(id);
    toggleDel();
  };

  const handlePageChange = (page) => {
    setPageParam(page);
  };

  const handlePerRowsChange = async (newPerPage) => {
    setLimitPage(newPerPage);
  };

  const deleteCoupon = () => {
    Axios.delete(`/shop/${selectShop}/Coupon/${delID}`)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Coupon </p>
          </div>
        );
        toggleDel();
        refetch();
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  useEffect(() => {
    if (data) {
      if (isFirst) {
        setTotalRow(data.data.TotalCount);
        setIsFirst(false);
      }

      setDataState(
        data.data.Result.map((prop, idx) => ({
          no: idx,
          id: prop.ID,
          name: prop.Name,
          desc: prop.Description,
          code: prop.Code,
          type: prop.Type,
          status: prop.Status ? "Active" : "Inactive",
          limit: `${prop.UsageCount} / ${prop.LimitCount}`,
          start: moment(prop.StartDateTime).format("DD/MM/YYYY"),
          end: moment(prop.EndDateTime).format("DD/MM/YYYY"),
          noEnd: prop.NoEndDate,
          loyalty: prop.LoyaltyCoupon,
        }))
      );
      setFilterRecord(
        data.data.Result.map((prop, idx) => ({
          no: idx,
          id: prop.ID,
          name: prop.Name,
          desc: prop.Description,
          code: prop.Code,
          type: prop.Type,
          status: prop.Status ? "Active" : "Inactive",
          limit: `${prop.UsageCount} / ${prop.LimitCount}`,
          start: moment(prop.StartDateTime).format("DD/MM/YYYY"),
          end: moment(prop.EndDateTime).format("DD/MM/YYYY"),
          noEnd: prop.NoEndDate,
          loyalty: prop.LoyaltyCoupon,
        }))
      );
    }
  }, [data]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">Coupons & Loyalty</CardTitle>
              <Button
                className="float-right addBtn"
                onClick={() => {
                  setModal(true);
                  setType({
                    Type: "Create",
                    ID: "",
                    Coupon: loyalty ? "Loyalty" : "Coupon",
                  });
                }}
              >
                Create Coupon
              </Button>
            </CardHeader>
            <CardBody>
              <Nav justified className="mb-4">
                <NavItem
                  className={
                    activeTab === "1" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("1");
                    setLoyalty(false);
                    setType({
                      Type: "Create",
                      ID: "",
                      Coupon: "Coupon",
                    });
                  }}
                >
                  Coupon
                </NavItem>
                <NavItem
                  className={
                    activeTab === "2" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("2");
                    setLoyalty(true);
                    setType({
                      Type: "Create",
                      ID: "",
                      Coupon: "Loyalty",
                    });
                  }}
                >
                  Loyalty Coupon
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <CouponTable
                    setModal={setModal}
                    setType={setType}
                    filterRecord={filterRecord}
                    setFilterRecord={setFilterRecord}
                    dataState={dataState}
                    setDataState={setDataState}
                    handleDelete={handleDelete}
                    refetch={refetch}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    totalRow={totalRow}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <CouponTable
                    setModal={setModal}
                    setType={setType}
                    filterRecord={filterRecord}
                    setFilterRecord={setFilterRecord}
                    dataState={dataState}
                    setDataState={setDataState}
                    handleDelete={handleDelete}
                    refetch={refetch}
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    totalRow={totalRow}
                    handlePageChange={handlePageChange}
                    handlePerRowsChange={handlePerRowsChange}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <CouponForm
        modal={modal}
        setModal={setModal}
        type={type}
        refetch={refetch}
      />
      <Modal isOpen={delModal} toggle={toggleDel} size="ml">
        <ModalBody>
          <Button className="float-right closeBtn" onClick={() => toggleDel()}>
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Delete Coupon</h3>
            <p>Are You sure you wanted to delete this Coupon ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    deleteCoupon();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button className="clearBtn delBtn" onClick={() => toggleDel()}>
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CouponLoyalty;
