/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CollectNcollabForm from "./MarketingForm/CollectNcollabForm";

createTheme(
  "solarized",
  {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#343a40",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "white",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

function Collection() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;
  const { refetch, data } = useQuery("Collections", async () =>
    Axios.get(`/shop/${selectShop}/Collection/GetAllCollection`)
  );

  const [modal, setModal] = useState(false);
  const [dataState, setDataState] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [editType, setEditType] = useState([]);
  const [delID, setDelID] = useState("");
  const [delModal, setDelModal] = useState(false);

  const [dropOpen, setDropOpen] = useState(false);
  const [dropFilter, setDropFilter] = useState("");
  const [searchData, setSearchData] = useState("");

  const dropdownToggle = () => {
    setDropOpen(!dropOpen);
  };

  const toggleDel = () => {
    setDelModal(!delModal);
  };

  const handleDelete = (id) => {
    setDelID(id);
    toggleDel();
  };

  const deleteCollection = () => {
    Axios.delete(`/shop/${selectShop}/Collection/${delID}`)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Collection </p>
          </div>
        );
        toggleDel();
        refetch();
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  const column = [
    {
      selector: (row) => (
        <>
          <Button
            className="addBtn couponTableBtn"
            onClick={() => {
              setModal(true);
              setEditType({ Type: "Edit", ID: row.id });
            }}
          >
            Edit
          </Button>
          <Button
            className="clearBtn couponTableBtn"
            onClick={() => handleDelete(row.id)}
          >
            Delete
          </Button>
          <br />
          <Button
            className="viewBtn couponTableBtn"
            tag={Link}
            to={`/shopOwner/marketing/CollectionDetails/${row.id}`}
          >
            More Details
          </Button>
        </>
      ),
      width: "240px",
    },
    // {
    //   name: "No.",
    //   selector: (row) => row.no,
    //   sortable: true,
    //   sortField: "_id",
    //   width: "100px",
    // },
    // {
    //   name: "Collection ID",
    //   selector: (row) => row.id,
    //   sortable: true,
    //   sortField: "_id",
    //   // width: "250px",
    // },
    {
      name: "Collection Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "CollectionName",
      // width: "250px",
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className="mt-2 font-weight-bold"
          style={{ color: row.status === "Active" ? "green" : "red" }}
        >
          {row.status}
        </p>
      ),
      sortable: true,
      sortField: "Status",
      // width: "250px",
    },
  ];

  // const handleSearch = (event) => {
  //   if (filterData) {
  //     const newData = filterData.filter((item) =>
  //       item.name.toLowerCase().includes(event.toLowerCase())
  //     );
  //     setDataState(newData);
  //   }
  // };

  const filterCollection = () => {
    const newData = dataState
      .filter((row) => {
        if (dropFilter !== "") {
          return row.status.toLowerCase() === dropFilter.toLowerCase();
        }
        return true;
      })
      .filter((row) =>
        row.name.toLowerCase().includes(searchData.toLowerCase())
      );
    setFilterData(newData);
  };

  const resetFilter = () => {
    setDropFilter("");
    refetch();
  };

  useEffect(() => {
    if (data) {
      setDataState(
        data.data.map((prop, idx) => ({
          no: idx + 1,
          id: prop.ID,
          name: prop.CollectionName,
          status: prop.Status ? "Active" : "Inactive",
        }))
      );
      setFilterData(
        data.data.map((prop, idx) => ({
          no: idx + 1,
          id: prop.ID,
          name: prop.CollectionName,
          status: prop.Status ? "Active" : "Inactive",
        }))
      );
    }
  }, [data]);

  return (
    <div>
      <Button
        className="float-right addBtn mb-2"
        onClick={() => {
          setModal(true);
          setEditType({ Type: "Add" });
        }}
      >
        Add Collection
      </Button>
      <div className="d-flex align-items-center">
        <span className="mr-2" style={{ fontSize: "16px" }}>
          Filter:
        </span>

        <Dropdown isOpen={dropOpen} toggle={() => dropdownToggle(!dropOpen)}>
          <DropdownToggle caret color="filter" outline>
            {dropFilter === "" ? "Status" : dropFilter}
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              onClick={() => {
                setDropFilter("");
              }}
            >
              -
            </DropdownItem>

            <DropdownItem onClick={() => setDropFilter("Active")}>
              Active
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                setDropFilter("Inactive");
              }}
            >
              Inactive
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Button className="ml-1" onClick={() => filterCollection()}>
          Filter
        </Button>
        <Button
          className="ml-1"
          color="danger"
          onClick={() => {
            resetFilter();
          }}
        >
          Reset All Filter
        </Button>
        <Input
          type="text"
          placeholder="Search..."
          className="searchBarPromo ml-2"
          onChange={(e) => {
            setSearchData(e.target.value);
          }}
        />
      </div>
      <DataTable
        columns={column}
        data={filterData}
        customStyles={customStyles}
        theme="solarized"
        responsive
        pagination
      />
      <CollectNcollabForm
        type="Collection"
        modal={modal}
        setModal={setModal}
        refetch={refetch}
        editType={editType}
      />
      <Modal isOpen={delModal} toggle={toggleDel} size="ml">
        <ModalBody>
          <Button className="float-right closeBtn" onClick={() => toggleDel()}>
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Delete Collection</h3>
            <p>Are You sure you wanted to delete this Collection ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    deleteCollection();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button className="clearBtn delBtn" onClick={() => toggleDel()}>
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Collection;
