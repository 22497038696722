/* eslint-disable no-unused-vars */
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useMutation } from "react-query";
import ApiContext from "services/ApiContext";

function ForgotPassword() {
  const Axios = useContext(ApiContext);
  const [message, setMessage] = useState();
  const [visible, setVisible] = useState();
  const validationSchema = Yup.object().shape({
    Email: Yup.string().required("Email is required").email("Email is invalid"),
  });
  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const { mutate, isLoading } = useMutation(
    (data) =>
      Axios.post("/public/Account/StaffForgotPassword", { Email: data.Email }),
    {
      onError: async (err) => {
        setMessage({
          data: `${err.response.data.MESSAGE}`,
          type: "alert-danger",
        });
        onShowAlert();
      },

      onSuccess: async (res) => {
        setMessage({
          data: `${res.data.MESSAGE}`,
          type: "alert-success",
        });
        onShowAlert();
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      Email: "",
    },
    validationSchema,

    onSubmit: (data) => {
      mutate(data);
    },
  });

  return (
    <div className="mx-auto d-block">
      <Container>
        {message !== undefined && message && (
          <Modal isOpen={visible} toggle={() => setVisible(false)}>
            <ModalBody className={`text-center text-white ${message.type}`}>
              {message.data}
            </ModalBody>
            <ModalFooter>
              <Button
                color="link"
                type="button"
                onClick={() => setVisible(false)}
              >
                Okay
              </Button>
            </ModalFooter>
          </Modal>
        )}
        <Card>
          <Row>
            <Col md="6">
              <img
                className="w-100 h-100 img mb-5"
                src={require("assets/img/Page/ForgotPassword/forgotpassword.png")}
                alt="emptyCart"
              />
            </Col>
            <Col md="6">
              <h2 className="text-center p-3 font-weight-bold">
                Forgot Your Password
              </h2>
              <p className="text-center p-3">
                Enter your email addrress to request a password reset.
              </p>
              <div className="text-center p-3">
                <Form onSubmit={formik.handleSubmit}>
                  <div className="text-left pt-2 font-weight-bold">
                    <Label htmlFor="ResetToken"> Email</Label>
                    <Input
                      className={
                        formik.errors.Email ? "is-invalid" : "is-valid"
                      }
                      name="Email"
                      type="Email"
                      onChange={formik.handleChange}
                      value={formik.values.Email}
                    />
                    <div className="text-danger">
                      {formik.errors.Email ? formik.errors.Email : null}
                    </div>
                  </div>
                  <Button
                    color="success"
                    className="text-center w-100 mt-4 p-2 button-white-template"
                    disabled={isLoading}
                  >
                    {isLoading ? "Sending Link..." : "Send"}
                  </Button>
                </Form>

                <Button
                  className="text-center mt-5 button-white-template"
                  tag={Link}
                  to="/auth/login"
                  color="warning"
                >
                  Back to Login
                </Button>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
      <div
        className="full-page-background"
        style={{
          background: `url(${require("assets/img/bg/download.jpg")})`,
        }}
      />
    </div>
  );
}

export default ForgotPassword;
