/* eslint-disable new-cap */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import React, { useContext, useEffect, useState } from "react";
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import BasicTable from "views/tables/BasicTable";
import moment from "moment";
import { Line } from "react-chartjs-2";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const options = {
  responsive: true, // Make the chart responsive
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },
    y1: {
      type: "linear",
      display: true,
      position: "right",
      grid: {
        drawOnChartArea: false,
      },
    },
    x: {
      ticks: {
        color: "white",
      },
    },
  },
};

function CouponLoyaltyDetails() {
  const { type, id } = useParams();

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [range, setRange] = useState([null, null]);
  const [isChecked, setIsChecked] = useState(false);
  const [rank, setRank] = useState("NORMAL");
  const [details, setDetails] = useState([]);
  const [period, setPeriod] = useState("Month");
  const [graphType, setGraphType] = useState("OrderCount");
  const [datas, setDatas] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [graph, setGraph] = useState([]);

  const columns = [
    {
      name: "Date",
      selector: (row) => moment(row.CreatedDate).format("YYYY-MM-DD"),
      sortable: true,
      wrap: true,
    },
    {
      name: "Orders",
      selector: (row) => row.OrderID,
      sortable: true,
      wrap: true,
      width: "240px",
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
      wrap: true,
      width: "210px",
    },
    {
      name: "Customer",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Item Sold",
      selector: (row) => row.ItemSold,
      sortable: true,
      wrap: true,
    },
    {
      name: "Coupon",
      selector: (row) => row.Code,
      sortable: true,
      wrap: true,
    },
    {
      name: "Net Sales",
      selector: (row) => row.NetSale,
      sortable: true,
      wrap: true,
      width: "150px",
    },
  ];

  const { data: DetailsData } = useQuery(["DetailsData", rank, id], async () =>
    Axios.get(`/shop/${shopID}/Marketing/Dashboard/${type}/Tier/Details`, {
      params: {
        loyaltyRank: rank,
        couponID: id,
      },
    }).then((res) => {
      setDetails(res.data.Order);
      return res;
    })
  );

  const { data: DetailsGraph, isSuccess: graphSuccess } = useQuery(
    ["DetailsGraph", rank, id, period, graphType],
    async () =>
      Axios.get(
        `/shop/${shopID}/Marketing/Dashboard/${type}/Tier/Details/Graph`,
        {
          params: {
            tablePeriod: period,
            GroupBy: graphType,
            loyaltyRank: rank,
            couponID: id,
          },
        }
      ).then((res) => {
        setGraph(res);
        return res;
      })
  );

  const lineGraphData = {
    ...datas,
    datasets: [
      {
        label: datas && datas?.datasets && datas?.datasets[0].label,
        data: datas && datas?.datasets && datas?.datasets[0].data,
        borderColor: datas && datas?.datasets && datas?.datasets[0].borderColor,
        yAxisID: "y",
      },
      ...(datas && datas?.datasets && datas?.datasets[1]?.data
        ? [
            {
              label: `Prev date ${
                datas && datas?.datasets && datas?.datasets[1].label
              } `,
              data: datas && datas?.datasets && datas?.datasets[1].data,
              borderColor:
                datas && datas?.datasets && datas?.datasets[1].borderColor,
              yAxisID: "y1",
            },
          ]
        : []),
    ],
  };

  const handleFilter = async () => {
    const res = await Axios.get(
      `/shop/${shopID}/Marketing/Dashboard/${type}/Tier/Details`,
      {
        params: {
          loyaltyRank: rank,
          couponID: id,
          dateFrom: startDate,
          dateTo: endDate,
        },
      }
    );
    setDetails(res.data.Order);

    const res2 = await Axios.get(
      `/shop/${shopID}/Marketing/Dashboard/${type}/Tier/Details/Graph`,
      {
        params: {
          tablePeriod: period,
          GroupBy: graphType,
          loyaltyRank: rank,
          couponID: id,
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),

          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
          isCompare: isChecked,
        },
      }
    );
    setGraph(res2);
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setRange([null, null]);
    setDetails(DetailsData.data.Order);
    setGraph(DetailsGraph);
  };

  const handleSearch = () => {
    const newData = DetailsData.data.Order.filter(
      (row) =>
        row.OrderID.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.Status.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.Name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.Code.toLowerCase().includes(searchFilter.toLowerCase()) ||
        row.ItemSold.toString().includes(searchFilter.toLowerCase()) ||
        row.NetSale.toString().includes(searchFilter.toLowerCase())
    );

    setDetails(newData);
  };

  const resetSearch = () => {
    setSearchFilter("");
    setDetails(DetailsData.data.Order);
  };

  const handleExportToPDF = () => {
    const elementToDownload = document.getElementById("ElementId"); // Replace "ElementId" with the actual ID
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      margin: [50, 50, 50, 50], // Left, Top, Right, Bottom margins
    });
    // Add A4 format header and footer
    doc.setFontSize(12);
    doc.text(
      `${type === "Coupon" ? "Coupon" : "Loyalty"} Report Details`,
      doc.internal.pageSize.getWidth() / 2,
      20
    );
    doc.text(
      `${type === "Coupon" ? "Coupon" : "Loyalty"} Report Details`,
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 20
    );
    // Capture the specific element content using html2canvas
    html2canvas(elementToDownload, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 600; // A4 width in landscape (595 for portrait)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = (doc.internal.pageSize.getWidth() - imgWidth) / 2; // Center the image horizontally
      const imgY = (doc.internal.pageSize.getHeight() - imgHeight) / 2; // Center the image vertically
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save(
        `${type === "Coupon" ? "Coupon" : "Loyalty"} Report Details.pdf`
      );
    });
  };

  useEffect(() => {
    if (graph.length !== 0) {
      let datasets;
      let datasets2;
      let formattedDates;

      if (graph.data?.Graph1) {
        // Update the graph with the API response
        datasets = [
          {
            label:
              graphType === "OrderCount"
                ? "Orders"
                : graphType === "NetSales"
                ? "Net Sales"
                : graphType === "OrderAverage"
                ? "Order Average"
                : graphType === "OrderItemAverage"
                ? "Order Item Average"
                : "",
            data: graph.data.Graph1.map((gdts) => gdts.Value),
            borderColor: "rgba(248, 192, 192,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];
      }
      // get the date ex: 2023-354
      if (graph.data.Graph1[0]?.ID.Day) {
        // Handle day format
        const dates = graph.data.Graph1?.map(
          (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
        );
        const sortedDates = dates.sort((a, b) => {
          const [yearA, dayOfYearA] = a.split("-");
          const [yearB, dayOfYearB] = b.split("-");

          const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
          const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

          return dateA - dateB;
        });
        formattedDates = sortedDates.map((dateString) => {
          const [year, dayOfYear] = dateString.split("-");
          const date = new Date(year, 0);
          date.setDate(dayOfYear);
          return `${date.getDate()} ${date.toLocaleString("default", {
            month: "short",
          })} ${date.getFullYear()}`;
        });
      } else if (graph.data.Graph1[0]?.ID.Month) {
        formattedDates = graph.data.Graph1.map((monthData) => {
          const monthDate = new Date(
            monthData.ID.Year,
            monthData.ID.Month - 1,
            1
          );
          return `${monthDate.toLocaleString("default", {
            month: "long",
          })} ${monthData.ID.Year}`;
        });
      } else if (graph.data.Graph1[0]?.ID.Week) {
        formattedDates = graph.data.Graph1.map((weekData) => {
          const weekStartDate = new Date(
            weekData.ID.Year,
            0,
            (weekData.ID.Week - 1) * 7
          );
          const weekEndDate = new Date(
            weekData.ID.Year,
            0,
            weekData.ID.Week * 7 - 1
          );
          return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
            "default",
            { month: "short" }
          )} ${weekEndDate.getFullYear()}`;
        });
      }

      if (graph.data?.Graph2) {
        datasets2 = [
          {
            label:
              graphType === "OrderCount" ? "Total Order" : "Total Discounted",
            data: graph.data.Graph2?.map((gdts) => gdts.Value),
            borderColor: "rgba(74, 189, 219,1)",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
          },
        ];

        if (graph.data.Graph2[0]?.ID.Day) {
          // Handle day format
          const dates = graph.data.Graph1.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (graph.data.Graph2[0]?.ID.Month) {
          formattedDates = graph.data.Graph1.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (graph.data.Graph2[0]?.ID.Week) {
          formattedDates = graph.data.Graph1.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      const newDatas = {
        labels: formattedDates,
        datasets: [...datasets, ...(datasets2 || [])],
      };
      setDatas(newDatas);
    }
  }, [graph]);

  return (
    <div className="content" id="ElementId">
      <Card className="card-dark">
        <CardHeader>
          <Row>
            <Col md={isChecked ? 8 : 5}>
              <div className="d-flex mt-2">
                <i className="fa fa-filter mt-2 mr-3" />
                <div className="mb-2">
                  <CustomCalendar
                    setDateRange={setDateRange}
                    setRange={setRange}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    compareCheckbox
                  />
                </div>
              </div>
            </Col>
            <Col md="12">
              <Button
                className="ml-4"
                color="info"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
              <Button
                className="mr-4"
                color="danger"
                onClick={() => resetFilter()}
              >
                Reset Filter
              </Button>
              <Button color="success" onClick={handleExportToPDF}>
                Export
              </Button>
            </Col>
          </Row>
          <h2 className="m-4">
            {type === "Coupon" ? "Coupon" : "Loyalty"} Report Details
          </h2>
        </CardHeader>
        <CardBody>
          <Link
            to={`/shopOwner/marketing/analytic/${
              type === "Coupon" ? "coupon" : "loyalty"
            }`}
          >
            <Button>Back</Button>
          </Link>

          <div className="text-center">
            <Row>
              <Col>
                <Button
                  className="float-right rankBtn"
                  color={rank === "NORMAL" ? "success" : "info"}
                  onClick={() => setRank("NORMAL")}
                >
                  Normal
                </Button>
              </Col>
              <Col md="2">
                <Button
                  className="rankBtn"
                  color={rank === "SILVER" ? "success" : "info"}
                  onClick={() => setRank("SILVER")}
                >
                  Silver
                </Button>
              </Col>
              <Col>
                <Button
                  className="float-left rankBtn"
                  color={rank === "GOLD" ? "success" : "info"}
                  onClick={() => setRank("GOLD")}
                >
                  Gold
                </Button>
              </Col>
            </Row>
          </div>
          <div className="graphContainer">
            <Row>
              <Col
                md="3"
                className={`graphSplit ${
                  graphType === "OrderCount" ? "graphSelected" : ""
                }`}
                onClick={() => setGraphType("OrderCount")}
              >
                <div>
                  <h4>Orders</h4>
                  {graphSuccess && <p>{DetailsGraph.data.TotalOrderCount}</p>}
                </div>
              </Col>
              <Col
                md="3"
                className={`graphSplit ${
                  graphType === "NetSales" ? "graphSelected" : ""
                }`}
                onClick={() => setGraphType("NetSales")}
              >
                <div>
                  <h4>Net Sales</h4>
                  {graphSuccess && <p>RM {DetailsGraph.data.TotalNetSales}</p>}
                </div>
              </Col>
              <Col
                md="3"
                className={`graphSplit ${
                  graphType === "OrderAverage" ? "graphSelected" : ""
                }`}
                onClick={() => setGraphType("OrderAverage")}
              >
                <div>
                  <h4>Average Order Value</h4>
                  {graphSuccess && (
                    <p>RM {DetailsGraph.data.TotalOrderAverage}</p>
                  )}
                </div>
              </Col>
              <Col
                md="3"
                className={`graphSplit ${
                  graphType === "OrderItemAverage" ? "graphSelected" : ""
                }`}
                onClick={() => setGraphType("OrderItemAverage")}
              >
                <div>
                  <h4>Average Items Per Order </h4>
                  {graphSuccess && (
                    <p>{DetailsGraph.data.TotalOrderItemAverage}</p>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="graphBox">
                <div className="mb-5">
                  <Input
                    className="float-right"
                    type="select"
                    placeholder="Coupon..."
                    style={{ width: "130px" }}
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  >
                    <option value="Day">By Day</option>
                    <option value="Week">By Week</option>
                    <option value="Month">By Month</option>
                  </Input>
                </div>
                <Line data={lineGraphData} options={options} />
              </Col>
            </Row>
          </div>
          <div className="d-flex mt-4">
            <div className="mb-0">
              <Row>
                <Col>
                  <InputGroup
                    className="no-border "
                    style={{
                      width: "350px",
                      backgroundColor: "white",
                      marginTop: "10px",
                    }}
                  >
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i
                          className="nc-icon nc-zoom-split"
                          style={{ color: "black" }}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      className="textColor"
                      defaultValue=""
                      placeholder="Search..."
                      type="text"
                      value={searchFilter}
                      onChange={(e) => setSearchFilter(e.target.value)}
                    />
                    {searchFilter !== "" && (
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i
                            className="nc-icon nc-simple-remove"
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() => setSearchFilter("")}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    )}
                  </InputGroup>
                </Col>
                <Col>
                  <div>
                    <Button color="secondary" onClick={handleSearch}>
                      Filter
                    </Button>
                    <Button color="danger" onClick={resetSearch}>
                      Reset Filter
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <BasicTable
            id=""
            className="mt-3"
            responsive
            selectableRows
            // onSelectedRowsChange={handleChange}
            data={details}
            columns={columns || []}
            pagination
          />
        </CardBody>
      </Card>
    </div>
  );
}

export default CouponLoyaltyDetails;
