/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardHeader,
  CardTitle,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";
import BannerUpload from "./MarketingForm/BannerUpload";

function ShopBanner() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const { data, refetch } = useQuery("Shop", async () =>
    Axios.get(`/public/Shop/GetAllShopBanner/${selectShop}`)
  );

  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const removeBanner = (id) => {
    Axios.delete(`/shop/${selectShop}/banners/${id}`)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Banner </p>
          </div>
        );
        refetch();
        setEdit(false);
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  return (
    <div className="content">
      <Card className="card-stats card-dark">
        <CardHeader>
          <CardTitle tag="h4">Shop Banner</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            <Button
              className="float-right addBtn "
              onClick={() => setEdit(!edit)}
            >
              Edit
            </Button>
            <Button
              className="float-right addBtn mr-3"
              onClick={() => setModal(true)}
            >
              Add Shop Banner
            </Button>
          </div>
          <br />
          <br />
          <br />
          <Card
            className="card-stats card-dark  mt-2"
            style={{ border: "1px solid #51BCDA" }}
          >
            <CardBody
              className="text-white text-center "
              style={{ minHeight: "400px" }}
            >
              <Row className="bannerScroll">
                {data && data.data.length !== 0 ? (
                  data.data.map((item, index) => (
                    <Col md="12">
                      <div>
                        <p className="viewBannerListNo">{index + 1}</p>
                        <button
                          hidden={!edit}
                          type="button"
                          className="removeBanner"
                          style={{ right: "10px" }}
                          onClick={() => removeBanner(item.ID)}
                        >
                          <i className="fa fa-times-circle removeIcon" />
                        </button>
                        <img
                          alt="Banner"
                          src={item.BannerUrl}
                          className="viewBannerList mb-5"
                        />
                      </div>
                    </Col>
                  ))
                ) : (
                  <Col className="text-center" style={{ marginTop: "12%" }}>
                    <h3>There Are Currently No Banners</h3>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <BannerUpload modal={modal} setModal={setModal} refetch={refetch} />
    </div>
  );
}

export default ShopBanner;
