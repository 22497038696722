import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useParams } from "react-router-dom";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";
import DispatchList from "./DispatchList";

function DispatchPage() {
  const [activeTab, setActiveTab] = useState("1");
  const match = useParams();
  const dispatchType = match.id;

  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const { data: DispatchOrder, refetch: OrderRefetch } = useQuery(
    "DispatchOrder",
    async () =>
      Axios.get(`/shop/${selectShop}/Order/Dispatch`, {
        params: {
          limitPerPage: "1000000",
        },
      })
  );

  const { data: ReturnRefund, refetch: RRrefetch } = useQuery(
    "ReturnRefund",
    async () => Axios.get(`/shop/${selectShop}/GetFilteredReturnRequest`)
  );

  const { data: TradeIn, refetch: TIRefetch } = useQuery("TradeIn", async () =>
    Axios.get(`/shop/${selectShop}/GetAllTradeInRequest`)
  );

  const [rrTotal, setRRtotal] = useState(0);
  const [courierTotal, setCourierTotal] = useState(0);
  const [selfPickupTotal, setSelfPickupTotal] = useState(0);
  const [TITotal, setTITotal] = useState(0);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  useEffect(() => {
    if (dispatchType === "courier" || dispatchType === ":id") {
      setActiveTab("1");
    } else if (dispatchType === "self-pickup") {
      setActiveTab("2");
    } else if (dispatchType === "returnRefund") {
      setActiveTab("3");
    } else if (dispatchType === "tradeIn") {
      setActiveTab("4");
    }
  }, []);

  useEffect(() => {
    if (ReturnRefund) {
      const calTotal = ReturnRefund.data.filter(
        (row) =>
          row.Status === "Item Transferred to Dispatch" ||
          row.Status === "Dispatch Received" ||
          row.Status === "Pending Transfer To Dispatch" ||
          row.Status === "Item Received from Customer"
      ).length;
      setRRtotal(calTotal);
    }

    if (DispatchOrder) {
      const calCourierTotal = DispatchOrder.data.Result.filter(
        (row) =>
          (row.DeliveryType === "Courier" &&
            row.LatestOrderStatus === "Order Shipped Out") ||
          (row.DeliveryType === "Courier" &&
            row.LatestOrderStatus === "Delivered-Logistic")
      ).length;
      setCourierTotal(calCourierTotal);

      const calSelfPickupTotal = DispatchOrder.data.Result.filter(
        (row) =>
          (row.DeliveryType === "Self-Pickup" &&
            row.LatestOrderStatus === "Order Shipped Out") ||
          (row.DeliveryType === "Self-Pickup" &&
            row.LatestOrderStatus === "Delivered-Logistic")
      ).length;
      setSelfPickupTotal(calSelfPickupTotal);
    }

    if (TradeIn) {
      const calTITotal = TradeIn.data.ShopTradeInRequestGetAllDtoList.filter(
        (item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
                "Trade In Parcel Transferred to Dispatch From CS" ||
              items.InternalStatus ===
                "Trade In Parcel Pending Transferred to Dispatch"
          )
      ).length;
      setTITotal(calTITotal);
    }
  }, [ReturnRefund, DispatchOrder, TradeIn]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4" className="text-center">
                Dispatch Packages
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Nav justified className="mb-2">
                <NavItem
                  className={
                    activeTab === "1" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Courier ({courierTotal})
                </NavItem>
                <NavItem
                  className={
                    activeTab === "2" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Self-Pickup ({selfPickupTotal})
                </NavItem>
                <NavItem
                  className={
                    activeTab === "3" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Return & Refund ({rrTotal})
                </NavItem>
                <NavItem
                  className={
                    activeTab === "4" ? "active tab-switch" : "tab-switch"
                  }
                  onClick={() => {
                    toggle("4");
                  }}
                >
                  Trade In ({TITotal})
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <DispatchList
                    tab="courier"
                    data={DispatchOrder?.data.Result.filter(
                      (row) =>
                        (row.DeliveryType === "Courier" &&
                          row.LatestOrderStatus === "Order Shipped Out") ||
                        (row.DeliveryType === "Courier" &&
                          row.LatestOrderStatus === "Delivered-Logistic")
                    )}
                    refetch={OrderRefetch}
                  />
                </TabPane>
                <TabPane tabId="2">
                  <DispatchList
                    tab="selfPickup"
                    data={DispatchOrder?.data.Result.filter(
                      (row) =>
                        (row.DeliveryType === "Self-Pickup" &&
                          row.LatestOrderStatus === "Order Shipped Out") ||
                        (row.DeliveryType === "Self-Pickup" &&
                          row.LatestOrderStatus === "Delivered-Logistic")
                    )}
                    refetch={OrderRefetch}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <DispatchList
                    tab="returnRefund"
                    data={ReturnRefund?.data}
                    refetch={RRrefetch}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <DispatchList
                    tab="tradeIn"
                    data={TradeIn?.data.ShopTradeInRequestGetAllDtoList.filter(
                      (item) =>
                        item.TradeInProductList.some(
                          (items) =>
                            items.InternalStatus ===
                              "Trade In Parcel Transferred to Dispatch From CS" ||
                            items.InternalStatus ===
                              "Trade In Parcel Pending Transferred to Dispatch"
                        )
                    )}
                    refetch={TIRefetch}
                  />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default DispatchPage;
