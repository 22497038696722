/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

function ViewRefundDetails() {
  const { orderId, id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const { data: orderDt } = useQuery(
    `/shop/${shopID}/Order/${orderId}`,
    async () => Axios.get(`/shop/${shopID}/Order/${orderId}`)
  );
  const { data: returnData } = useQuery(`/shop/${shopID}/${id}`, async () =>
    Axios.get(`/shop/${shopID}/${id}`)
  );

  const dept = [];

  if (JSON.parse(localStorage.getItem("deptProfile")) !== null) {
    dept.push(JSON.parse(localStorage.getItem("deptProfile")));
  }

  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const text = returnData?.data.ReturnRefundMeganShipBackInfo.TrackingNumber;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  // get the products that request to return from order
  const productRelated = React.useMemo(() => {
    if (orderDt && returnData) {
      return orderDt.data.Product.find(
        (x) => x.ID === returnData.data.Product.ID
      );
    }
    return null;
  }, [orderDt, returnData]);

  // use to calculate latest price (display only)
  const totalPrice = React.useMemo(() => {
    if (productRelated) {
      let price = productRelated.TotalOrderCost;

      price += orderDt?.data.Region.includes("West") ? 15 : 18;

      return price;
    }
    return 0;
  }, [productRelated]);

  if (productRelated) {
    return (
      <div className="content">
        <Button
          style={{ backgroundColor: "#51BCDA" }}
          className="mt-2 p-2 ml-5"
          onClick={handleBackClick}
          size="sm"
        >
          Back
        </Button>
        <Card className="card-stats card-dark">
          <CardBody className="mt-3">
            <p className="bg-info p-2 pl-4">User Information</p>
            <Row>
              <Col md="6">
                <Card
                  className="card-stats card-dark"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>Order Number: {orderDt?.data.ID}</p>
                    <p>Invoice Number: {orderDt?.data.Invoice}</p>
                    <p>Status: {`${returnData?.data.status}`}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                {returnData?.data.shopReturnInformationGetDto !== null &&
                returnData?.data.shopReturnInformationGetDto?.DropOffInfo !==
                  null ? (
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>Refund Number: {returnData?.data.ID}</p>
                      <p>
                        {`Name:
                      ${returnData?.data.shopReturnInformationGetDto?.DropOffInfo?.DropOffPersonDetails.DropOffPersonName}`}
                      </p>
                      <p>
                        {` ContactNo:
                      ${returnData?.data.shopReturnInformationGetDto?.DropOffInfo?.DropOffPersonDetails.ContactNo}`}
                      </p>
                    </CardBody>
                  </Card>
                ) : (
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>Refund Number: {returnData?.data.ID}</p>
                      {returnData?.data.shopReturnInformationGetDto !== null ? (
                        <p>
                          {`Name:
                  ${returnData?.data.shopReturnInformationGetDto?.CourierServiceInfo.FullName}`}
                        </p>
                      ) : (
                        <p>Name :</p>
                      )}
                      {returnData?.data.shopReturnInformationGetDto !== null ? (
                        <p>
                          {` ContactNo:
                      ${returnData?.data.shopReturnInformationGetDto?.CourierServiceInfo.ContactNo}`}
                        </p>
                      ) : (
                        <p>ContactNo :</p>
                      )}
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
            <Card
              className="card-stats card-dark "
              style={{ border: "1px solid #51BCDA" }}
            >
              <CardBody>
                <Row>
                  <Col md="6">
                    <div className="text-white">
                      <h6
                        className="font-weight-bold text-left text-capitalize text-white"
                        style={{ fontSize: "14px" }}
                      >
                        Billing Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold "
                        style={{ fontSize: "15px" }}
                      >
                        {orderDt?.data.Name}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        {`H/P: ${orderDt?.data.Contact}`}
                        <br />
                        {orderDt?.data.Email}
                        <br />
                        {orderDt?.data.BillingAddress}
                        <br />
                      </p>
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <h6
                        className="font-weight-bold text-capitalize"
                        style={{ fontSize: "14px" }}
                      >
                        Shipping Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold"
                        style={{ fontSize: "15px" }}
                      >
                        {orderDt?.data.Name}
                      </p>
                      <p style={{ fontSize: "15px" }}>
                        {`H/P: ${orderDt?.data.Contact}`}
                        <br />
                        {orderDt?.data.Email}
                        <br />
                        {orderDt?.data.ShippingAddress}
                        <br />
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card
              className="card-stats card-dark "
              style={{ border: "1px solid #51BCDA" }}
            >
              <CardBody>
                <Row>
                  <Col md="6">
                    <div className="text-white">
                      <h6
                        className="font-weight-bold text-left text-capitalize text-white"
                        style={{ fontSize: "14px" }}
                      >
                        Refund Information
                      </h6>
                      <p
                        className="text-capitalize font-weight-bold "
                        style={{ fontSize: "15px" }}
                      >
                        Reasons
                        {returnData?.data.returnReasonList?.map((reason) => (
                          <div>
                            <ul>
                              <li>{reason}</li>
                            </ul>
                          </div>
                        ))}
                      </p>
                      <p>{`Comment : ${returnData?.data.comment}`}</p>
                      {returnData?.data.shopReturnInformationGetDto !== null ? (
                        <>
                          <h6
                            className="font-weight-bold text-capitalize"
                            style={{ fontSize: "14px" }}
                          >
                            Drop Off Person Details
                          </h6>
                          <p>
                            Name :&nbsp;
                            {
                              returnData?.data.shopReturnInformationGetDto
                                ?.DropOffInfo?.DropOffPersonDetails
                                .DropOffPersonName
                            }
                          </p>
                          <p>
                            ContactNO :&nbsp;
                            {
                              returnData?.data.shopReturnInformationGetDto
                                ?.DropOffInfo?.DropOffPersonDetails.ContactNo
                            }
                          </p>
                          <p>
                            Remarks:&nbsp;
                            {
                              returnData?.data.shopReturnInformationGetDto
                                ?.DropOffInfo?.DropOffPersonDetails.Remarks
                            }
                          </p>
                        </>
                      ) : null}
                      {returnData?.data.ReturnRefundMeganShipBackInfo !==
                      null ? (
                        <>
                          <p className="font-weight-bold">
                            Shipped Back Logistic Info
                          </p>
                          <p>
                            Courier Name:&nbsp;
                            {
                              returnData?.data.ReturnRefundMeganShipBackInfo
                                .Logistic
                            }
                          </p>
                          <p>
                            Tracking NO:&nbsp;
                            {
                              returnData?.data.ReturnRefundMeganShipBackInfo
                                .TrackingNumber
                            }
                            {!isCopied && (
                              <Button
                                className="btn btn-link p-0 text-left text-white text-capitalize"
                                onClick={handleCopy}
                                style={{ marginLeft: "10px" }}
                              >
                                Copy
                              </Button>
                            )}
                            {isCopied && (
                              <span
                                className="btn btn-link p-0 text-left text-white text-capitalize"
                                style={{ marginLeft: "10px" }}
                              >
                                Copied!
                              </span>
                            )}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </Col>
                  <Col md="6">
                    <div>
                      <h6
                        className="font-weight-bold text-capitalize"
                        style={{ fontSize: "14px" }}
                      >
                        Customer Attachment Details
                      </h6>
                      <div>
                        {returnData?.data.FileUrlList.map((img) => (
                          <p>
                            <a
                              href={img}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {img.length > 25
                                ? `${img.substring(0, 25)}...`
                                : img}
                            </a>
                          </p>
                        ))}
                      </div>

                      {returnData?.data.shopReturnInformationGetDto !== null &&
                      returnData?.data.shopReturnInformationGetDto
                        ?.ReturnMethod !== null ? (
                        <p>
                          Return Method:
                          {
                            returnData?.data.shopReturnInformationGetDto
                              ?.ReturnMethod
                          }
                        </p>
                      ) : null}

                      {returnData?.data.shopReturnInformationGetDto !== null &&
                      returnData?.data.shopReturnInformationGetDto
                        ?.DropOffInfo !== null ? (
                        <>
                          <p>
                            {`DropOff Date :
                      ${moment(
                        returnData?.data.shopReturnInformationGetDto
                          ?.DropOffInfo.DropOffDate
                      ).format("DD-MMM-YYYY")}`}
                          </p>
                          <h6
                            className="font-weight-bold text-capitalize"
                            style={{ fontSize: "14px" }}
                          >
                            Branch
                          </h6>
                          <p>
                            {
                              returnData?.data.shopReturnInformationGetDto
                                ?.DropOffInfo.BranchDetails.BranchName
                            }
                          </p>
                          <p>
                            {
                              returnData?.data.shopReturnInformationGetDto
                                ?.DropOffInfo.BranchDetails.BranchAddress
                            }
                          </p>
                        </>
                      ) : null}
                      {returnData?.data.shopReturnInformationGetDto !== null &&
                      returnData?.data.shopReturnInformationGetDto
                        ?.CourierServiceInfo !== null ? (
                        <>
                          <p>
                            {`Courier Name:
                  ${returnData?.data.shopReturnInformationGetDto?.CourierServiceInfo?.CourierName}`}
                          </p>
                          <p>
                            {`Tracking No:
                  ${returnData?.data.shopReturnInformationGetDto?.CourierServiceInfo?.TrackingNo}`}
                          </p>
                        </>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h6
              className="mt-5 ml-3 font-weight-bold text-left text-capitalize text-white"
              style={{ fontSize: "14px" }}
            >
              Refund Product Information
            </h6>

            <div style={{ overflowX: "auto" }}>
              <table className="mt-5 w-100" style={{ minWidth: "600px" }}>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Item</td>
                    <td>Before Discount</td>
                    <td>Discount</td>
                    <td>Total</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img
                        src={
                          returnData?.data.Product.ProductImageList[0].ImageUrl
                        }
                        alt=""
                        width="100px"
                        height="100px"
                      />
                    </td>
                    <td className="py-3">
                      <p style={{ wordWrap: "break-word", maxWidth: "30ch" }}>
                        {returnData?.data.Product.Name}
                      </p>
                      <br />
                      <p>
                        {returnData?.data.Product.VariationList[0].VariationSKU}
                        <br />
                        <p>{`Weight = ${returnData?.data.Product.VariationList[0].Weight}`}</p>
                      </p>
                    </td>

                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={productRelated?.TotalOrderCost}
                        currency="MYR"
                      />
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={productRelated?.Discount}
                        currency="MYR"
                      />
                    </td>

                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={
                          productRelated.TotalOrderCost -
                          productRelated.Discount
                        }
                        currency="MYR"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td>
                      <p>Shipping Fee - {orderDt?.data.Region}</p>
                    </td>
                    <td />
                    <td />
                    <td style={{ whiteSpace: "nowrap" }}>
                      {returnData?.data.status === "Refund Approved" ||
                      returnData?.data.status === "Refund Rejected" ||
                      returnData?.data.status === "Shipped Back" ||
                      (returnData?.data.status === "Ship Back" &&
                        dept[0].Position === "DepartmentHead") ||
                      dept[0].Position === "Supervisor" ? (
                        <p className="position-relative">
                          <span
                            className="position-absolute"
                            style={{ left: "-12px", top: 0 }}
                          >
                            +
                          </span>
                          <CurrencyFormat
                            decimal={2}
                            price={
                              orderDt?.data.Region.includes("West")
                                ? "15"
                                : "18"
                            }
                            currency="MYR"
                          />
                        </p>
                      ) : (
                        <p>
                          <CurrencyFormat
                            decimal={2}
                            price={0}
                            currency="MYR"
                          />
                        </p>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={5}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Total : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={totalPrice}
                        currency="MYR"
                      />
                    </td>
                  </tr>

                  {/* <tr>
                    <td colSpan={3} />
                    <td>Shipping Fee : </td>
                    <td>
                      {checked ||
                      returnData?.data.status === "Refund Approved" ||
                      returnData?.data.status === "Refund Rejected" ||
                      returnData?.data.status === "Shipped Back" ||
                      returnData?.data.status === "Ship Back" ? (
                        <CurrencyFormat
                          decimal={2}
                          price={15}
                          currency="+ MYR"
                        />
                      ) : (
                        <CurrencyFormat decimal={2} price={0} currency="MYR" />
                      )}
                    </td>
                  </tr> */}
                  <tr>
                    <td colSpan={3} />
                    <td colSpan={2}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Ewallet : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={orderDt?.data.Ewallet}
                        currency="MYR"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} />
                    <td>
                      <p>Bank Transfer : </p>
                    </td>
                    <td>
                      <CurrencyFormat
                        decimal={2}
                        price={totalPrice}
                        currency="MYR"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td colSpan={3} />
                    <td colSpan={2}>
                      <hr
                        style={{
                          border: "1px solid #51BCDA",
                        }}
                      />
                    </td>
                  </tr>
                  {returnData?.data.status === "Review Rejected" ||
                  returnData?.data.status === "Review Approved" ? null : (
                    <tr>
                      <td>
                        <h5 className="text-primary">Approved Amount</h5>
                        <h4 className="font-weight-bold mb-5">
                          <CurrencyFormat
                            decimal={2}
                            price={returnData?.data.RefundAmount}
                            currency="MYR"
                          />
                        </h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default ViewRefundDetails;
