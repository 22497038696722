/* eslint-disable no-unused-vars */
/*!

=========================================================
* Paper Dashboard PRO React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react";
// react plugin used to create charts
import { Line, Bar, Doughnut } from "react-chartjs-2";
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";
import PaymentContext from "services/PaymentContext";
import CurrencyFormat from "react-currency-format";
import "../departments/dispatch/dispatch.scss";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "variables/charts";
import { Link } from "react-router-dom";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";

const mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

function dispatchDash() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const { data: DispatchOrder } = useQuery("DispatchOrder", async () =>
    Axios.get(`/shop/${selectShop}/Order/Dispatch`, {
      params: {
        limitPerPage: "1000000",
      },
    })
  );

  const { data: ReturnRefund } = useQuery("ReturnRefund", async () =>
    Axios.get(`/shop/${selectShop}/GetFilteredReturnRequest`)
  );

  const { data: TradeIn } = useQuery("TradeIn", async () =>
    Axios.get(`/shop/${selectShop}/GetAllTradeInRequest`)
  );

  const [RRTotal, setRRtotal] = useState(0);
  const [courierTotal, setCourierTotal] = useState(0);
  const [selfPickupTotal, setSelfPickupTotal] = useState(0);
  const [TITotal, setTITotal] = useState(0);

  const { Currentday, Previousday, todaydata, todayOtime } =
    useContext(PaymentContext);

  const chartExample10 = {
    data: {
      labels: todayOtime,
      datasets: [
        {
          label: "Sales",
          borderColor: "#6bd098",
          pointRadius: 2,
          pointHoverRadius: 2,
          fill: false,
          borderWidth: 3,
          barPercentage: 1.6,
          tension: 0.4,
          data: todaydata,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },

        tooltips: {
          enabled: false,
        },
      },

      scales: {
        y: {
          ticks: {
            color: "#9f9f9f",
            beginAtZero: false,
            maxTicksLimit: 5,
            // padding: 20
          },
          grid: {
            drawBorder: false,
            display: false,
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
          },
          ticks: {
            padding: 20,
            color: "#9f9f9f",
          },
        },
      },
    },
  };

  useEffect(() => {
    if (ReturnRefund) {
      const calTotal = ReturnRefund.data.filter(
        (row) =>
          row.Status === "Item Transferred to Dispatch" ||
          row.Status === "Dispatch Received" ||
          row.Status === "Pending Transfer To Dispatch" ||
          row.Status === "Item Received from Customer"
      ).length;
      setRRtotal(calTotal);
    }

    if (DispatchOrder) {
      const calCourierTotal = DispatchOrder.data.Result.filter(
        (row) =>
          (row.DeliveryType === "Courier" &&
            row.LatestOrderStatus === "Order Shipped Out") ||
          (row.DeliveryType === "Courier" &&
            row.LatestOrderStatus === "Delivered-Logistic")
      ).length;
      setCourierTotal(calCourierTotal);

      const calSelfPickupTotal = DispatchOrder.data.Result.filter(
        (row) =>
          (row.DeliveryType === "Self-Pickup" &&
            row.LatestOrderStatus === "Order Shipped Out") ||
          (row.DeliveryType === "Self-Pickup" &&
            row.LatestOrderStatus === "Delivered-Logistic")
      ).length;
      setSelfPickupTotal(calSelfPickupTotal);
    }

    if (TradeIn) {
      const calTITotal = TradeIn.data.ShopTradeInRequestGetAllDtoList.filter(
        (item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
                "Trade In Parcel Transferred to Dispatch From CS" ||
              items.InternalStatus ===
                "Trade In Parcel Pending Transferred to Dispatch"
          )
      ).length;
      setTITotal(calTITotal);
    }
  }, [ReturnRefund, DispatchOrder]);

  return (
    <div className="content">
      <Row>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark">
            <CardBody>
              <Row>
                <Col md="8" xs="8">
                  <Row>
                    <Col md="12" xs="12">
                      <h3 className="ml-2 mt-2 text-center">Courier</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="4">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{courierTotal}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="text-center">
                <Link to="main/courier">
                  <Button className="dashBtn">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark">
            <CardBody>
              <Row>
                <Col md="8" xs="8">
                  <Row>
                    <Col md="12" xs="12">
                      <h3 className="ml-2 mt-2 text-center">Self-Pickup</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="4">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{selfPickupTotal}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="text-center">
                <Link to="main/self-pickup">
                  <Button className="dashBtn">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark">
            <CardBody>
              <Row>
                <Col md="8" xs="8">
                  <Row>
                    <Col md="12" xs="12">
                      <h3 className="ml-2 mt-2 text-center">Return & Refund</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="4">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{RRTotal}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="text-center">
                <Link to="main/returnRefund">
                  <Button className="dashBtn">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col lg="6" md="6" sm="6">
          <Card className="card-stats card-dark">
            <CardBody>
              <Row>
                <Col md="8" xs="8">
                  <Row>
                    <Col md="12" xs="12">
                      <h3 className="ml-2 mt-2 text-center">Trade In</h3>
                    </Col>
                  </Row>
                </Col>
                <Col md="4" xs="4">
                  <div className="numbers">
                    <p className="card-category">Orders</p>
                    <CardTitle tag="p">{TITotal}</CardTitle>
                    <p />
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="text-center">
                <Link to="main/tradeIn">
                  <Button className="dashBtn">View</Button>
                </Link>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default dispatchDash;
