/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/alt-text */
import moment from "moment";
import React, { useContext, useState } from "react"; // useEffect, useState
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

function PromotionView() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const { data: PromotionData, refetch } = useQuery(
    `/shop/${shopID}/Promotion/${id}`,
    async () => Axios.get(`/shop/${shopID}/Promotion/${id}`)
  );

  const [imgModal, setImgModal] = useState(false);

  const [edit, setEdit] = useState(false);
  const [delMsg, setDelMsg] = useState(false);
  const [delID, setDelID] = useState("");

  const [addBanner, setAddBanner] = useState([]);
  const [addImg, setAddImg] = useState([]);

  // const [promoLimitation, setPromoLimitation] = useState([]);

  // useEffect(() => {
  //   if (PromotionData) {
  //     if (PromotionData.data.CategoryList.length !== 0) {
  //       setPromoLimitation({
  //         Type: "Category",
  //         List: PromotionData.data.CategoryList,
  //       });
  //     } else if (PromotionData.data.ProductList.length !== 0) {
  //       setPromoLimitation({
  //         Type: "Product",
  //         List: PromotionData.data.ProductList,
  //       });
  //     } else if (PromotionData.data.CollectionList.length !== 0) {
  //       setPromoLimitation({
  //         Type: "Collection",
  //         List: PromotionData.data.CollectionList,
  //       });
  //     } else if (PromotionData.data.CollaborationList.length !== 0) {
  //       setPromoLimitation({
  //         Type: "Collaboration",
  //         List: PromotionData.data.CollaborationList,
  //       });
  //     }
  //   }
  // }, [PromotionData]);

  const imgListRef = React.createRef();
  const handleImgListClick = () => {
    imgListRef.current.click();
  };

  const bannerListRef = React.createRef();
  const handleBannerListClick = () => {
    bannerListRef.current.click();
  };

  const handleRemoveBanner = () => {
    setAddBanner([]);
  };

  const handleRemoveImg = () => {
    setAddImg([]);
  };

  const toggleAddImg = () => {
    setImgModal(!imgModal);
    setAddBanner([]);
    setAddImg([]);
  };

  const handleBannerImg = (e) => {
    const bannerFiles = e.target.files;
    setAddBanner({
      File: bannerFiles[0],
      URL: URL.createObjectURL(bannerFiles[0]),
    });
    e.target.value = null;
  };

  const handleImg = (e) => {
    const imgs = e.target.files;
    setAddImg({
      File: imgs[0],
      URL: URL.createObjectURL(imgs[0]),
    });
    e.target.value = null;
  };

  const handleAdd = () => {
    const insertFile = [addBanner.File, addImg.File];

    const dataImg = new FormData();
    insertFile.forEach((files) => {
      dataImg.append("AttachmentList", files);
    });

    if (insertFile.length === 2) {
      if (PromotionData.data.PromotionFilesList.length !== 0) {
        PromotionData.data.PromotionFilesList.forEach((item) => {
          Axios.delete(
            `/shop/${shopID}/Promotion/DeleteAttachment/${id}/${item.FileID}`
          );
        });
      }

      Axios.put(`/shop/${shopID}/Promotion/UploadAttachment/${id}`, dataImg)
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">Successful Updated Images </p>
            </div>
          );
          toggleAddImg();
          refetch();
        })
        .catch(() => {
          toast.error("Something goes wrong, please try later");
        });
    }
  };

  const removeImg = () => {
    const temp = [];
    PromotionData?.data.PromotionFilesUrlList.forEach((img) => {
      temp.push(img.ImageID);
    });
    setDelID(temp);
    setDelMsg(true);
  };

  const imgDelete = () => {
    delID.forEach((ids) => {
      Axios.delete(`/shop/${shopID}/Promotion/DeleteAttachment/${id}/${ids}`)
        .then(() => {
          toast.success(
            <div>
              <p className="text-white">Successful Deleted Image </p>
            </div>
          );
          setDelMsg(false);
          refetch();
        })
        .catch(() => {
          toast.error("Something goes wrong, please try later");
        });
    });
  };

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2"
        tag={Link}
        to="/shopOwner/marketing/Promotion"
        size="sm"
      >
        Back
      </Button>
      <Card className="card-stats card-dark">
        <CardBody className="mt-3">
          <p className="bg-info p-2 pl-4">Promotion Details</p>
          <Row className="pt-3">
            <Col md="6">
              <Card
                className="card-stats card-dark pb-2"
                style={{ border: "1px solid #51BCDA" }}
              >
                <CardBody className="text-white">
                  <p>
                    Status :{" "}
                    {PromotionData?.data.Status ? (
                      <b style={{ color: "lightgreen" }}>Active</b>
                    ) : (
                      <b style={{ color: "red" }}>Inactive</b>
                    )}
                  </p>
                  <p>Promotion ID : {PromotionData?.data.ID}</p>
                  <p>Promotion Name : {PromotionData?.data.Name}</p>
                  <p>Description : {PromotionData?.data.Description}</p>
                  <p>
                    Start Date :{" "}
                    {moment(PromotionData?.data.StartDateTime).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p>
                    End Date :{" "}
                    {PromotionData?.data.EndDateTime === null
                      ? "No End Date"
                      : moment(PromotionData?.data.EndDateTime).format(
                          "DD/MM/YYYY"
                        )}
                  </p>
                </CardBody>
              </Card>
            </Col>
            {PromotionData?.data.CategoryIdlist.length !== 0 ||
            PromotionData?.data.CollaborationIdlist.length !== 0 ||
            PromotionData?.data.CollectionIdlist.length !== 0 ||
            PromotionData?.data.ProductIdlist.length !== 0 ? (
              <Col md="6">
                <Card
                  className="card-stats card-dark pb-2"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <>
                      {PromotionData?.data.CategoryIdlist.length !== 0 && (
                        <p>Category</p>
                      )}
                      {PromotionData?.data.CategoryIdlist.length !== 0 &&
                        PromotionData?.data.CategoryIdlist.map((category) => (
                          <p>Name : {category.Name} </p>
                        ))}
                    </>
                    <>
                      {PromotionData?.data.CollaborationIdlist.length !== 0 && (
                        <p>Collaboration</p>
                      )}
                      {PromotionData?.data.CollaborationIdlist.length !== 0 &&
                        PromotionData?.data.CollaborationIdlist.map(
                          (collab) => <p>Name : {collab.Name} </p>
                        )}
                    </>
                    <>
                      {PromotionData?.data.CollectionIdlist.length !== 0 && (
                        <p>Collection</p>
                      )}
                      {PromotionData?.data.CollectionIdlist.length !== 0 &&
                        PromotionData?.data.CollectionIdlist.map(
                          (collection) => <p>Name : {collection.Name} </p>
                        )}
                    </>
                    <>
                      {PromotionData?.data.ProductIdlist.length !== 0 && (
                        <p>Products</p>
                      )}
                      {PromotionData?.data.ProductIdlist.length !== 0 &&
                        PromotionData?.data.ProductIdlist.map((product) => (
                          <p>Name : {product.Name} </p>
                        ))}
                    </>
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Card
            className="card-stats card-dark mt-3 pb-3"
            style={{ border: "1px solid #51BCDA" }}
          >
            <CardBody className="text-white">
              <Row>
                <Col>
                  <h4 className="pt-3">{PromotionData?.data.Type}</h4>
                </Col>
                <Col>
                  <Button
                    className="float-right addBtn ml-2"
                    onClick={() => setEdit(!edit)}
                    color="info"
                  >
                    Edit
                  </Button>
                  {edit ? (
                    <Button
                      className="float-right addBtn "
                      onClick={() => toggleAddImg()}
                      color="info"
                    >
                      Add Image
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <hr />
              <Row>
                {PromotionData?.data.Discount !== null && (
                  <Col md="4">
                    <p>Discount Type : {PromotionData?.data.Discount?.Type}</p>
                    <p>
                      Discount Value :{" "}
                      {`RM ${PromotionData?.data.Discount?.DiscountValue}`}
                    </p>
                    <p>
                      Capped Discount Value :{" "}
                      {`RM ${PromotionData?.data.Discount?.CappedDiscountAmount}`}
                    </p>
                    <p>
                      Minimum Purchase Value :{" "}
                      {`RM ${PromotionData?.data.Discount?.MinPurchaseAmount}`}
                    </p>
                  </Col>
                )}
                {PromotionData?.data.Bundle !== null && (
                  <Col md="4">
                    <p>Bundle Type : {PromotionData?.data.Bundle?.Type}</p>
                    <p>
                      Discount Value :{" "}
                      {`RM ${PromotionData?.data.Bundle?.DiscountValue}`}
                    </p>
                    <p>Bundle Product :</p>
                    {PromotionData?.data.Bundle?.BundleProductList.map(
                      (product, index) => (
                        <p>
                          {index + 1} : {product.Name}
                        </p>
                      )
                    )}
                  </Col>
                )}
                <Col md="8">
                  <Row>
                    {PromotionData?.data.PromotionFilesUrlList.map(
                      (imgs, index) => (
                        <Col md={index === 0 ? "12" : "6"}>
                          <div
                            className="mb-3 position-relative"
                            {...(index !== 0 && {
                              style: {
                                width: "fit-content",
                              },
                            })}
                          >
                            <button
                              hidden={!edit}
                              type="button"
                              className="removeBanner"
                              style={{ right: "-10px" }}
                              onClick={() => removeImg()}
                            >
                              <i className="fa fa-times-circle removeIcon" />
                            </button>
                            <img
                              src={imgs.ImageUrl}
                              className={`mt-1 ${
                                index === 0 ? "imgBanner" : "imgList"
                              }`}
                            />
                          </div>
                        </Col>
                      )
                    )}
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      {/* Delete Message  */}
      <Modal size="ml" isOpen={delMsg} toggle={() => setDelMsg(false)}>
        <ModalBody>
          <Container className="text-center">
            <h3 className="mt-3">Delete Image</h3>
            <p className="mt-2" style={{ color: "red", fontSize: "12px" }}>
              This Will Delete all the Images
            </p>
            <p>Are You sure you wanted to delete all Image ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    imgDelete();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button
                  className="clearBtn delBtn"
                  onClick={() => setDelMsg(false)}
                >
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      {/* Add Modal  */}
      <Modal size="xl" isOpen={imgModal} toggle={() => toggleAddImg()}>
        <ModalBody>
          <Button
            className="float-right closeBtn"
            onClick={() => setImgModal(false)}
          >
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Add Image</h3>
            <p style={{ color: "red", fontSize: "13px" }}>
              Note : Saving any image will delete all existing images
            </p>
            <p style={{ color: "red", fontSize: "13px" }}>
              Please Uplod a Banner and a Square Image
            </p>
            <div className="mr-5">
              <Button
                className="mr-3"
                onClick={() => handleBannerListClick()}
                // disabled={viewBanner.length >= 1}
              >
                Upload Banner
              </Button>
              <input
                type="file"
                className="uploadImg"
                accept="image/*"
                ref={bannerListRef}
                onChange={handleBannerImg}
              />
              <Button onClick={() => handleImgListClick()}>Upload Image</Button>
              <input
                type="file"
                className="uploadImg"
                accept="image/*"
                ref={imgListRef}
                onChange={handleImg}
              />
            </div>

            {addBanner.length !== 0 && (
              <>
                <h4>Add Banner</h4>
                <Row className="text-center mb-4">
                  <Col md="12">
                    <div>
                      <button
                        type="button"
                        className="removeBanner"
                        onClick={() => handleRemoveBanner()}
                      >
                        <i className="fa fa-times-circle removeIcon" />
                      </button>
                      <img src={addBanner.URL} className="mt-1 imgBanner" />
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {addImg.length !== 0 && (
              <>
                <h4>Add Images</h4>
                <Row className="text-center mb-4">
                  <Col md="4">
                    <div>
                      <button
                        type="button"
                        className="imgListRemove"
                        onClick={() => handleRemoveImg()}
                      >
                        <i className="fa fa-times-circle removeIcon" />
                      </button>
                      <img src={addImg.URL} className="mt-1 imgList" />
                    </div>
                  </Col>
                </Row>
              </>
            )}

            <div>
              <Button
                className="submitBtn"
                onClick={() => handleAdd()}
                disabled={!(addBanner.length !== 0 && addImg.length !== 0)}
              >
                Save
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PromotionView;
