import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";

import ShopNavbar from "components/Navbars/ShopNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin";
import PropTypes from "prop-types";
// import routesShop from "routes/routesShop";
import routesShop from "routes/routesShop";

let ps;

function ShopOwner(props) {
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = React.useState("black");
  const [activeColor, setActiveColor] = React.useState("info");
  const [sidebarMini, setSidebarMini] = React.useState(false);
  const mainPanel = React.useRef();

  const { location: propsLocation } = props;

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(mainPanel.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  });
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
  }, [location]);
  const getRoutes = (_routes) =>
    _routes.map((prop) => {
      if (prop.collapse && prop.layout === "/shopOwner") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/shopOwner") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={prop.layout + prop.path}
          />
        );
      }
      return null;
    });
  const handleActiveClick = (color) => {
    setActiveColor(color);
  };
  const handleBgClick = (color) => {
    setBackgroundColor(color);
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <div className="wrapper">
      <Sidebar
        {...props}
        routes={routesShop}
        bgColor={backgroundColor}
        activeColor={activeColor}
      />
      <div className="main-panel bg-dark" ref={mainPanel}>
        <ShopNavbar {...props} handleMiniClick={handleMiniClick} />
        <Switch>{getRoutes(routesShop)}</Switch>
        {
          // we don't want the Footer to be rendered on full screen maps page
          propsLocation.pathname.indexOf("full-screen-map") !== -1 ? null : (
            <Footer fluid />
          )
        }
      </div>
      <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
        handleMiniClick={handleMiniClick}
      />
    </div>
  );
}

ShopOwner.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};
ShopOwner.defaultProps = {
  location: {
    pathname: "",
  },
};

export default ShopOwner;
