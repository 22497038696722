/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import PropTypes from "prop-types";
import { Button, Col, Form, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import AddCheckedPhoto from "components/CustomUpload/AddCheckedPhoto";
import { useFormik } from "formik";
import * as Yup from "yup";

function AddQCphoto({
  pID,
  setShowModal,
  showModal,
  tableRefetch,
  isFirstSubmit,
  OrderQCImageList,
}) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [imgUpload, setImgUpload] = useState(null);
  const [file, setFile] = useState([]);
  const [maxImageCount, setMaxImageCount] = useState(2);
  const [minImageCount, setMinImageCount] = useState(2);

  const uploadImgCallback = (imgFile) => {
    if (imgFile && Array.isArray(imgFile)) {
      setImgUpload(imgFile);
    } else if (imgFile === null) {
      setImgUpload([]);
    }
  };

  const uploadCombine = (img) => {
    if (img !== null) {
      setFile([...img]);
    } else {
      setFile([]);
    }
  };

  useEffect(() => {
    if (imgUpload !== null) {
      uploadCombine(imgUpload);
    }
  }, [imgUpload]);

  useEffect(() => {
    if (isFirstSubmit && OrderQCImageList === 0) {
      setMaxImageCount(2);
      setMinImageCount(2);
    } else if (!isFirstSubmit && OrderQCImageList === 2) {
      setMaxImageCount(4);
      setMinImageCount(4);
    } else if (!isFirstSubmit && OrderQCImageList === 0) {
      setMaxImageCount(2);
      setMinImageCount(2);
    } else if (
      (!isFirstSubmit && OrderQCImageList < 2) ||
      OrderQCImageList < 6
    ) {
      const remainingImages = 6 - OrderQCImageList;
      const newMinImageCount = remainingImages > 2 ? 4 : remainingImages;
      const newMaxImageCount = remainingImages;
      setMinImageCount(newMinImageCount);
      setMaxImageCount(newMaxImageCount);
    }
  }, [isFirstSubmit, OrderQCImageList]);

  const validationSchema = Yup.object().shape({
    OrderQCImageList: Yup.string().required("Upload Image"),
  });

  const formik = useFormik({
    initialValues: {
      OrderQCImageList: "",
    },
    validationSchema,
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const data2 = new FormData();

    if (file.length < minImageCount) {
      toast.error(`Only ${minImageCount} images can be uploaded`);
      return;
    }
    if (file.length > maxImageCount) {
      toast.error(`Only ${maxImageCount} images can be uploaded`);
      return;
    }

    file.forEach((files) => {
      data2.append("OrderQCImageList", files);
    });

    Axios.post(`/shop/${shopID}/Order/${pID}/QC/Image`, data2)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Photos have been uploaded successfully</p>
          </div>
        );

        setShowModal({
          ...showModal,
          open: false,
        }); // Close the modal
        tableRefetch(); // Refetch the table
      })
      .catch((error) => {
        toast.error(error.response.data.MESSAGE);
      });
  };

  return (
    <div>
      <Label htmlFor="ImagesPostDto">
        Upload Images <span style={{ color: "red" }}> * </span>
      </Label>
      <Form onSubmit={onSubmit} className="mt-4">
        <AddCheckedPhoto uploadImgCallback={uploadImgCallback} />
        {formik.errors.OrderQCImageList ? (
          <p className="errorText text-danger">
            {formik.errors.OrderQCImageList}
          </p>
        ) : (
          ""
        )}
        <Row className="text-left pt-2 font-weight-bold ">
          <Col sm="12">
            <div className="text-center">
              <Button className="btn-sm btn-primary" type="submit">
                Upload
              </Button>
              <Button
                className="ml-3 btn-sm btn-primary"
                onClick={() =>
                  setShowModal({
                    ...showModal,
                    open: false,
                  })
                }
              >
                Cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

AddQCphoto.propTypes = {
  pID: PropTypes.string.isRequired,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  tableRefetch: PropTypes.func.isRequired,
  isFirstSubmit: PropTypes.bool.isRequired,
  OrderQCImageList: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default AddQCphoto;
