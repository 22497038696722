/* eslint-disable consistent-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import ReactDatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PromotionForm from "./MarketingForm/PromotionForm";

createTheme(
  "solarized",
  {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#343a40",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "white",
    },
    button: {
      default: "#2aa198",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "#2aa198",
    },
  },
  "dark"
);

const customStyles = {
  headRow: {
    style: {
      color: "white",
    },
  },
  headCells: {
    style: {
      fontSize: "15px",
      fontWeight: "600",
      textTransform: "uppercase",
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick, onChange }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function Promotion() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const [dataState, setDataState] = useState([]);
  const [modal, setModal] = useState(false);
  const [type, setType] = useState([]);
  const [filterRecord, setFilterRecord] = useState([]);
  const [delID, setDelID] = useState("");
  const [delModal, setDelModal] = useState(false);

  const { data, refetch } = useQuery(["Promotion"], async () =>
    Axios.get(`/shop/${selectShop}/Promotion`, {
      params: {
        order: "DESC",
      },
    })
  );

  const toggleDel = () => {
    setDelModal(!delModal);
  };

  const handleDelete = (id) => {
    setDelID(id);
    toggleDel();
  };

  const deletePromo = () => {
    Axios.delete(`/shop/${selectShop}/Promotion/${delID}`)
      .then(() => {
        toast.success(
          <div>
            <p className="text-white">Successful Deleted Promotion </p>
          </div>
        );
        toggleDel();
        refetch();
      })
      .catch(() => {
        toast.error("Something goes wrong, please try later");
      });
  };

  const Today = moment(new Date()).format("YYYY-MM-DD");

  const column = [
    {
      selector: (row) => {
        const EndDate = moment(row.end, "DD/MM/YYYY").format("YYYY-MM-DD");

        return (
          <>
            {row.noEnd ? (
              <>
                <Button
                  className="addBtn couponTableBtn"
                  onClick={() => {
                    setModal(true);
                    setType({
                      Type: "Edit",
                      ID: row.id,
                    });
                  }}
                >
                  Edit
                </Button>
                <Button
                  className="clearBtn couponTableBtn"
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </Button>
              </>
            ) : (
              <>
                {moment(Today).isAfter(EndDate) ? (
                  ""
                ) : (
                  <>
                    <Button
                      className="addBtn couponTableBtn"
                      onClick={() => {
                        setModal(true);
                        setType({
                          Type: "Edit",
                          ID: row.id,
                        });
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      className="clearBtn couponTableBtn"
                      onClick={() => handleDelete(row.id)}
                    >
                      Delete
                    </Button>
                  </>
                )}
              </>
            )}
            <br />
            <Button
              className="viewBtn couponTableBtn"
              tag={Link}
              to={`/shopOwner/marketing/PromotionDetails/${row.id}`}
              // onClick={() => {
              //   setModal(true);
              // }}
            >
              More Details
            </Button>
          </>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
      width: "200px",
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
      sortField: "Type",
      width: "200px",
    },
    {
      name: "Status",
      selector: (row) => (
        <p
          className="font-weight-bold"
          style={{ color: row.status === "Active" ? "green" : "red" }}
        >
          {row.status}
        </p>
      ),
      sortable: true,
      sortField: "Type",
      width: "190px",
    },
    {
      name: "Description",
      selector: (row) => (
        <Input
          className="pl-3 tableDesc"
          type="textarea"
          value={row.desc}
          disabled
        />
      ),
      sortable: true,
      sortField: "Invoice",
      width: "300px",
    },
    {
      name: "Start Date",
      selector: (row) => row.start,
      sortable: true,
      sortField: "Invoice",
      width: "200px",
    },
    {
      name: "End Date",
      selector: (row) => {
        if (row.noEnd === true) {
          return "No End Date";
        }
        return row.end;
      },
      sortable: true,
      sortField: "Invoice",
      width: "200px",
    },
  ];

  const [dropOpen, setDropOpen] = useState(false);
  const [dropFilter, setDropFilter] = useState("");
  const [searchData, setSearchData] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  const dropdownToggle = () => {
    setDropOpen(!dropOpen);
  };

  const filterData = () => {
    const newData = dataState
      .filter((row) =>
        dateRange[0] !== null
          ? new Date(moment(row.start, "DD/MM/YYYY").toDate()).setHours(
              0,
              0,
              0,
              0
            ) >= new Date(dateRange[0]).setHours(0, 0, 0, 0)
          : row
      )
      .filter((row) =>
        dateRange[1] !== null
          ? new Date(moment(row.end, "DD/MM/YYYY").toDate()).setHours(
              0,
              0,
              0,
              0
            ) <= new Date(dateRange[1]).setHours(0, 0, 0, 0)
          : row
      )
      .filter((row) => {
        if (dropFilter !== "") {
          return row.status.toLowerCase() === dropFilter.toLowerCase();
        }
        return true;
      })
      .filter(
        (row) =>
          row.desc.toLowerCase().includes(searchData.toLowerCase()) ||
          row.type.toLowerCase().includes(searchData.toLowerCase())
      );
    setFilterRecord(newData);
  };

  const resetFilter = () => {
    setDropFilter("");
    refetch();
  };

  useEffect(() => {
    if (data) {
      setDataState(
        data.data.Result.map((prop, idx) => ({
          no: idx,
          id: prop.ID,
          name: prop.Name,
          desc: prop.Description,
          type: prop.Type,
          status: prop.Status ? "Active" : "Inactive",
          start: moment(prop.StartDateTime).format("DD/MM/YYYY"),
          end: moment(prop.EndDateTime).format("DD/MM/YYYY"),
          noEnd: prop.NoEndDate,
        }))
      );

      setFilterRecord(
        data.data.Result.map((prop, idx) => ({
          no: idx,
          id: prop.ID,
          name: prop.Name,
          desc: prop.Description,
          type: prop.Type,
          status: prop.Status ? "Active" : "Inactive",
          start: moment(prop.StartDateTime).format("DD/MM/YYYY"),
          end: moment(prop.EndDateTime).format("DD/MM/YYYY"),
          noEnd: prop.NoEndDate,
        }))
      );
    }
  }, [data]);

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card className="card-dark">
            <CardHeader>
              <CardTitle tag="h4">Promotions</CardTitle>
              <Button
                className="float-right addBtn"
                onClick={() => {
                  setModal(true);
                  setType({ Type: "Create", ID: "" });
                }}
              >
                Create Promotions
              </Button>
            </CardHeader>
            <CardBody>
              <Row xs={1} sm={2} className="my-2">
                <Col>
                  <div>Filter Date: </div>
                  <div className="d-flex">
                    <ReactDatePicker
                      calendarClassName="h-100"
                      wrapperClassName="w-100 "
                      isClearable
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      startDate={dateRange[0]}
                      endDate={dateRange[1]}
                      selectsRange
                      dateFormat="dd/MM/yyyy"
                      customInput={<ExampleCustomInput />}
                    />
                  </div>
                </Col>
              </Row>
              <div className="d-flex align-items-center">
                <span className="mr-2" style={{ fontSize: "16px" }}>
                  Filter:
                </span>

                <Dropdown
                  isOpen={dropOpen}
                  toggle={() => dropdownToggle(!dropOpen)}
                >
                  <DropdownToggle caret color="filter" outline>
                    {dropFilter === "" ? "Status" : dropFilter}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      onClick={() => {
                        setDropFilter("");
                      }}
                    >
                      -
                    </DropdownItem>

                    <DropdownItem onClick={() => setDropFilter("Active")}>
                      Active
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setDropFilter("Inactive");
                      }}
                    >
                      Inactive
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                <Button className="ml-1" onClick={() => filterData()}>
                  Filter
                </Button>
                <Button
                  className="ml-1"
                  color="danger"
                  onClick={() => {
                    resetFilter();
                  }}
                >
                  Reset All Filter
                </Button>
                <Input
                  type="text"
                  placeholder="Search..."
                  className="searchBarPromo ml-2"
                  onChange={(e) => {
                    setSearchData(e.target.value);
                  }}
                />
              </div>
              <DataTable
                columns={column}
                data={filterRecord}
                customStyles={customStyles}
                theme="solarized"
                responsive
                pagination
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <PromotionForm
        modal={modal}
        setModal={setModal}
        type={type}
        refetch={refetch}
      />
      <Modal isOpen={delModal} toggle={toggleDel} size="ml">
        <ModalBody>
          <Button className="float-right closeBtn" onClick={() => toggleDel()}>
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <Container className="text-center">
            <h3 className="mt-3">Delete Promotion</h3>
            <p>Are You sure you wanted to delete this Promotion ?</p>
            <Row>
              <Col>
                <Button
                  className="addBtn delBtn"
                  onClick={() => {
                    deletePromo();
                  }}
                >
                  Yes
                </Button>
              </Col>
              <Col>
                <Button className="clearBtn delBtn" onClick={() => toggleDel()}>
                  No
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Promotion;
