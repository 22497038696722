import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import ImageUpload from "components/CustomUpload/ImageUpload";
import { useMutation } from "react-query";

function ShopAvatar(props) {
  const { setMessage, setVisible, shopData, shopID } = props;
  const Axios = useContext(ApiContext);
  const [smallNotice, setSmallNotice] = useState({
    open: false,
    field: "",
  });
  const [avatarEdit, setAvatarEdit] = useState(false);

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const { mutate, isLoading } = useMutation((data) => {
    // reformat data into correct strcuture
    const data2 = new FormData();
    data2.append("newAvatar", data.newAvatar);
    Axios.post(`/shop/${shopID}/avatar`, data2)
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        let msg = "";
        err.response.data.forEach((element) => {
          msg += `${element.MESSAGE}\n`;
        });
        setMessage({
          data: msg,
          type: "alert-danger",
        });
        onShowAlert();
      });

    onShowAlert();
  });
  const formik = useFormik({
    initialValues: {
      newAvatar: "",
    },

    onSubmit: (data) => {
      setSmallNotice({ open: false });
      mutate(data);
    },
  });

  const imageCallback = (field, file) => {
    formik.values[field] = file;
  };
  const formik2 = useFormik({
    initialValues: {
      newAvatar: shopData.data.ShopAvatar,
    },

    onSubmit: (data) => {
      setSmallNotice({ open: false });
      // reformat data into correct strcuture
      const data2 = new FormData();
      data2.append("newAvatar", data.newAvatar);

      Axios.delete(`/shop/${shopID}/avatar`, data2)
        .then((res) => {
          if (res.status === 200) {
            setMessage({
              data: `${res.data.MESSAGE}`,
              type: "alert-success",
            });
            onShowAlert();
          }
        })
        .catch((err) => {
          setMessage({
            data: err.response.data.MESSAGE,
            type: "alert-danger",
          });
          onShowAlert();
        });
      onShowAlert();
    },
  });

  return (
    <div>
      <Form>
        <Card className="card-dark">
          <CardHeader>
            <FormGroup className="pb-0">
              {!avatarEdit ? (
                <>
                  <a
                    href="#edit"
                    className="float-right font-weight-normal"
                    onClick={() => setAvatarEdit(true)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true" />
                    Edit
                  </a>
                  <CardTitle className="font-weight-bold" tag="h5">
                    Shop Avatar
                  </CardTitle>
                </>
              ) : (
                <CardTitle className="font-weight-bold" tag="h5">
                  Edit Shop Avatar
                </CardTitle>
              )}
            </FormGroup>
          </CardHeader>
          <CardBody>
            {!avatarEdit && (
              <>
                <div className="author ">
                  {shopData.data.ShopAvatar === "" ? (
                    <img
                      alt="..."
                      className="avatar border-gray w-75 h-75"
                      src={require("assets/img/avatar.png")}
                    />
                  ) : (
                    <img
                      alt="..."
                      className="avatar border-gray w-75 h-75"
                      src={`/api/v2/public/Shop/${shopID}/avatar`}
                    />
                  )}
                  <Button
                    onClick={() => {
                      setSmallNotice({ open: true, field: "avatardelete" });
                    }}
                    className="btn-link"
                  >
                    x
                  </Button>
                </div>
                <h5 className="title">{shopData.data.Name}</h5>
                <p className="description">{shopData.data.Description}</p>
              </>
            )}
            {avatarEdit && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="newAvatar">
                      Shop Avatar <span style={{ color: "red" }}> * </span>
                    </Label>
                    <p>
                      <small>
                        <span id="textarea-limited-message">limit in 1mb</span>
                      </small>
                    </p>
                    <ImageUpload
                      imageCallback={imageCallback}
                      field="newAvatar"
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            {avatarEdit && (
              <Row className="text-left pt-2 font-weight-bold justify-content-end">
                <Col sm="9">
                  <div className="text-center">
                    <Button
                      className="mt-3 button-dark-template"
                      disabled={
                        isLoading || Object.values(formik.errors).length > 0
                      }
                      loading={isLoading}
                      onClick={() => {
                        setSmallNotice({ open: true, field: "avatar" });
                      }}
                    >
                      Upload
                    </Button>

                    <Button
                      className=" ml-3 mt-3 button-template"
                      onClick={() => setAvatarEdit(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Form>
      <Modal
        size="sm"
        isOpen={smallNotice.open}
        toggle={() => setSmallNotice({ open: false, field: "" })}
      >
        <ModalBody className="text-center">
          {smallNotice.field === "avatar"
            ? "Confirm to Update Shop Avatar?"
            : "Confirm to Delete Shop Avatar?"}
        </ModalBody>
        <ModalFooter>
          <div className="left-side" style={{ width: "48%" }}>
            <Button
              color="primary"
              type="submit"
              onClick={
                smallNotice.field === "avatar"
                  ? formik.handleSubmit
                  : formik2.handleSubmit
              }
            >
              Confirm
            </Button>
          </div>
          <div className="divider" />
          <div className="right-side" style={{ width: "48%" }}>
            <Button
              color="secondary"
              onClick={() => setSmallNotice({ open: false, field: "" })}
            >
              Cancel
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}
ShopAvatar.propTypes = {
  shopData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  setMessage: PropTypes.string.isRequired,
  setVisible: PropTypes.string.isRequired,
  shopID: PropTypes.string.isRequired,
};
export default ShopAvatar;
