/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-newline */
import { useQuery } from "react-query";
import React, { useContext, useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { toast } from "react-toastify";
import "./Address.css";
import ShopContext from "services/ShopContext";
import AddressFrom from "./AddressForm";

function UserAddrress({ dlvbill, setFocus, userID }) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;
  const [toggleEdit, setToggle] = useState(-1);
  const [addressLimit, setAddressLimit] = useState(2);
  const [delModal, setDelModal] = useState({
    delID: "",
    delStatus: false,
  });
  const [formStatus, setFormStatus] = useState("");
  const [prefilled, setPrefilled] = useState("");
  const [firstLoad, setFirstLoad] = React.useState(true); // for set default address when first time in page
  const { data, refetch } = useQuery(
    `/shop/${shopID}/CustomerService/GetSingleUser/${userID}`,
    async () =>
      Axios.get(`/shop/${shopID}/CustomerService/GetSingleUser/${userID}`).then(
        (res) => {
          if (firstLoad) {
            setFocus({
              Shipping: res.data.DefaultAddress.DefaultShipping
                ? res.data.AddressList.findIndex(
                    (addrs) =>
                      addrs.ID === res.data.DefaultAddress.DefaultShipping
                  )
                : -1,
              Billing: res.data.DefaultAddress.DefaultBilling
                ? res.data.AddressList.findIndex(
                    (addrs) =>
                      addrs.ID === res.data.DefaultAddress.DefaultBilling
                  )
                : -1,
            });
            setFirstLoad(false);
          }
          return res;
        }
      )
  );
  const addressLimitCal = data?.data.AddressList.length;

  const handleToggle = (id, operate, addressinfo) => {
    setFocus((prev) => ({ ...prev, [dlvbill]: id }));
    setToggle(id);
    setFormStatus(operate);
    setPrefilled(addressinfo);
  };
  const handleDelete = () => {
    Axios.delete(`/user/Account/Address/${delModal.delID}`).then(() => {
      setDelModal({ delID: "", delStatus: false });
      toast.success("Deleted successfully");
      setFocus((prev) => ({ ...prev, [dlvbill]: -1 }));

      refetch();
    });
  };

  useEffect(() => {
    if (addressLimitCal !== 0) {
      if (addressLimitCal < 6) {
        setAddressLimit(addressLimitCal + 1);
      } else {
        setAddressLimit(addressLimitCal);
      }
    }
  }, [addressLimitCal]);
  return (
    <div className="deliveryaddr">
      <div id="addressManage">
        <div id="address_form">
          <div
            className="row-grid p-3"
            style={{ backgroundColor: "rgb(247, 241, 229,0.3)" }}
          >
            {data &&
              data.data.AddressList !== [] &&
              [...Array(addressLimit).keys()].map((idx) =>
                data.data.AddressList[idx] ? (
                  <div
                    key={data.data.AddressList[idx].ID}
                    className={`address-fragment m-3 col-grid ${
                      window.matchMedia("only screen and (min-width: 768px)")
                        .matches &&
                      toggleEdit !== -1 &&
                      toggleEdit + 2 === idx &&
                      "d-none"
                    }`}
                  >
                    <Card
                      className={`card-plain mb-0 bg-white p-0 `}
                      style={{
                        boxShadow: "0 0 4px 0px darkgrey",
                        cursor: "pointer",
                      }}
                    >
                      <Collapse isOpen={toggleEdit === idx}>
                        <div className="p-2">
                          <AddressFrom
                            refetch={refetch}
                            handleToggle={handleToggle}
                            formStatus={formStatus}
                            prefilled={prefilled}
                            setPrefilled={setPrefilled}
                            AddressCount={data && data.data.AddressList.length}
                            defaultAddress={data.data.DefaultAddress}
                          />
                        </div>
                      </Collapse>
                      <Collapse isOpen={toggleEdit !== idx}>
                        {Object.values(data.data.DefaultAddress).indexOf(
                          data.data.AddressList[idx].ID
                        ) > -1 && (
                          <CardHeader>
                            {data.data.DefaultAddress.DefaultBilling ===
                              data.data.AddressList[idx].ID && (
                              <span
                                className="badge font-weight-bold float-right ml-1"
                                style={{
                                  backgroundColor: "#F7F1E5",
                                  color: "#000000",
                                }}
                              >
                                Default Billing
                              </span>
                            )}
                            {data.data.DefaultAddress.DefaultShipping ===
                              data.data.AddressList[idx].ID && (
                              <span
                                className="badge font-weight-bold float-right ml-1"
                                style={{
                                  backgroundColor: "#F7F1E5",
                                  color: "#000000",
                                }}
                              >
                                Default Shipping
                              </span>
                            )}
                          </CardHeader>
                        )}
                        <CardBody className="p-3">
                          <FormGroup className="pb-0">
                            <Label
                              className="font-weight-bold megan-text m-0 pt-4"
                              tag="h3"
                              style={{ fontSize: "2.2rem" }}
                            >
                              {data.data.AddressList[idx].Name}
                            </Label>
                          </FormGroup>
                          <div
                            className="font-weight-bolder"
                            style={{ fontSize: "1rem" }}
                          >
                            <div className="pb-2">
                              {data.data.AddressList[idx].ContactNumber}
                            </div>
                            <div>{data.data.AddressList[idx].FirstLine}</div>
                            <div>{data.data.AddressList[idx].SecondLine}</div>
                            <div>
                              {`${data.data.AddressList[idx].Postcode}, ${data.data.AddressList[idx].City}, ${data.data.AddressList[idx].State}, ${data.data.AddressList[idx].Country}`}
                            </div>
                          </div>
                        </CardBody>
                      </Collapse>
                    </Card>
                  </div>
                ) : null
              )}
          </div>
          <Modal
            isOpen={delModal.delStatus}
            toggle={() => setDelModal({ delID: "", delStatus: false })}
          >
            <ModalHeader
              toggle={() => setDelModal({ delID: "", delStatus: false })}
              charCode="X"
            />
            <ModalBody>Are You Sure You Want to Delete it?</ModalBody>
            <ModalFooter>
              <div className="left-side" style={{ width: "48%" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    handleDelete();
                  }}
                >
                  Confirm
                </Button>
              </div>
              <div className="divider" />
              <div className="right-side" style={{ width: "48%" }}>
                <Button
                  color="secondary"
                  onClick={() => setDelModal({ delID: "", delStatus: false })}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
}
UserAddrress.defaultProps = {
  dlvbill: undefined,
};
UserAddrress.propTypes = {
  dlvbill: propTypes.string,
  setFocus: propTypes.func.isRequired,
  userID: propTypes.string.isRequired,
};
export default UserAddrress;
