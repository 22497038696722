/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import React, { useContext } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery } from "react-query";
import { CardBody, Card, Col, Row, Button, Spinner } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";

const labels = ["Gold", "Silver", "Normal"];

const options = {
  maintainAspectRatio: false,
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 1,
    },
  },
  responsive: true,
};

function RewardOverview() {
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;

  const [dateRange, setDateRange] = React.useState([null, null]);
  const [dateFilter, setDateFilter] = React.useState([null, null]);

  const {
    isLoading,
    data: MRewardData,
    isSuccess,
  } = useQuery([dateFilter, `Marketing/Dashboard/MReward`], async () =>
    Axios.get(`/shop/${shopID}/Marketing/Dashboard/MReward`, {
      ...(dateFilter[0] !== null &&
        dateFilter[1] !== null && {
          params: {
            dateFrom: moment(dateFilter[0])
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format(),
            dateTo: moment(dateFilter[1])
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format(),
          },
        }),
    }).then((res) => res.data)
  );

  const data = React.useMemo(() => {
    const datasetData = isSuccess
      ? labels.map((label) => {
          const sale = MRewardData?.PurchaseReport.TierSale.find(
            (tier) => tier.LoyaltyRank.toUpperCase() === label.toUpperCase()
          );
          return sale ? sale.TotalSales : 0;
        })
      : [0, 0, 0];

    return {
      labels,
      datasets: [
        {
          label: "Amount (RM)",
          data: datasetData,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
      ],
    };
  }, [MRewardData, isSuccess]);

  const handleExportToPDF = () => {
    const elementToDownload = document.getElementById("ElementId"); // Replace "ElementId" with the actual ID
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      margin: [50, 50, 50, 50], // Left, Top, Right, Bottom margins
    });
    // Add A4 format header and footer
    doc.setFontSize(12);
    doc.text(
      "Membership Tier Report",
      doc.internal.pageSize.getWidth() / 2,
      20
    );
    doc.text(
      "Membership Tier Report",
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 20
    );
    // Capture the specific element content using html2canvas
    html2canvas(elementToDownload, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 600; // A4 width in landscape (595 for portrait)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = (doc.internal.pageSize.getWidth() - imgWidth) / 2; // Center the image horizontally
      let imgY = (doc.internal.pageSize.getHeight() - imgHeight) / 2; // Center the image vertically
      if (imgY < 0) {
        doc.addPage();
        imgY = 0;
      }
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save("M Rewards Report.pdf");
    });
  };

  return (
    <div className="content">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <CustomCalendar
            setDateRange={setDateRange}
            compareCheckbox={false}
            setRange={() => {}}
          />
          <Button
            className="ml-2"
            onClick={() => {
              setDateFilter(dateRange);
            }}
          >
            Filter
          </Button>
          <Button
            className="ml-2"
            onClick={() => {
              setDateFilter([null, null]);
            }}
          >
            Reset
          </Button>
        </div>
        <Button onClick={handleExportToPDF}>Export</Button>
      </div>
      {isLoading ? (
        <div className="content d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div className="content" id="ElementId">
          <h4>M Rewards</h4>
          <Card className="card-dark">
            <CardBody>
              <Row>
                <Col className="d-flex" xs="auto">
                  <img
                    height={80}
                    src={require("assets/img/icon/add-friend.png")}
                    alt=""
                  />
                  <div className="pl-3">
                    <div style={{ fontSize: "30px" }}>
                      {MRewardData?.UserReport.TotalNewMember}
                    </div>
                    <div style={{ fontSize: "22px" }}>New Member</div>
                  </div>
                </Col>
                <Col className="pl-md-5">
                  <Row xs={1} sm={2} md="3">
                    <Col className="d-flex pt-3" xs="auto">
                      <img
                        width={35}
                        height={35}
                        src={require("assets/img/icon/active-user.png")}
                        alt=""
                      />
                      <div className="pl-3 w-100">
                        {MRewardData?.UserReport.TotalActiveMember}
                        <div>Active Member</div>
                      </div>
                    </Col>
                    <Col className="d-flex pt-3" xs="auto">
                      <img
                        width={35}
                        height={35}
                        src={require("assets/img/icon/delete-user.png")}
                        alt=""
                      />
                      <div className="pl-3 w-100">
                        {MRewardData?.UserReport.TotalInactiveMember}
                        <div>Non Active Member</div>
                      </div>
                    </Col>
                    <Col className="d-flex pt-3" xs="auto">
                      <img
                        width={35}
                        height={35}
                        src={require("assets/img/icon/id-card.png")}
                        alt=""
                      />
                      <div className="pl-3 w-100">
                        {MRewardData?.UserReport.TotalRegisteredMember}
                        <div>Total Registered Member</div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row xs={1} sm="2">
            <Col>
              <Card className="card-dark p-3 text-center">
                <h5>Total Order</h5>
                <CardBody>
                  <Row xs={2}>
                    <Col className="text-center pr-1 pl-0">
                      <div className="rounded border h-100">
                        <h5>
                          {MRewardData?.PurchaseReport.TotalOrderByMember}
                        </h5>
                        <p>Total Order by Member</p>
                      </div>
                    </Col>
                    <Col className="text-center pl-1 pr-0">
                      <div className="rounded border h-100">
                        <h5>
                          {MRewardData?.PurchaseReport.TotalOrderByReferral}
                        </h5>
                        <p>Total Order by Referral</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-dark p-3 text-center">
                <h5>Total Member Sales</h5>
                <CardBody>
                  <Row xs={2}>
                    <Col className="text-center pr-1 pl-0">
                      <div className="rounded border h-100">
                        <h5>
                          RM&nbsp;{MRewardData?.PurchaseReport.TotalSales}
                        </h5>
                        <p>Total Sales by Member</p>
                      </div>
                    </Col>
                    <Col className="text-center pl-1 pr-0">
                      <div className="rounded border h-100">
                        <h5>
                          RM&nbsp;
                          {MRewardData?.PurchaseReport.AverageOrderValue}
                        </h5>
                        <p>Average Order Value</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h3>Sales By Tier</h3>
          <Card className="card-dark">
            <CardBody>
              <Bar options={options} data={data} height={250} />
            </CardBody>
          </Card>

          <h3>Promotion</h3>
          <Row xs={1} sm="2">
            <Col>
              <Card className="card-dark p-3 text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    height={50}
                    src={require("assets/img/icon/coupon.png")}
                    alt=""
                  />
                  <h5 className="mb-0 ml-2">Coupon</h5>
                </div>
                <CardBody>
                  <Row xs={2}>
                    <Col className="text-center pr-1 pl-0">
                      <div className="rounded border h-100">
                        <h5>{MRewardData?.CouponReport.CouponRedeemNo}</h5>
                        <p>Redeem</p>
                      </div>
                    </Col>
                    <Col className="text-center pl-1 pr-0">
                      <div className="rounded border h-100">
                        <h5>{MRewardData?.CouponReport.CouponUsedNo}</h5>
                        <p>Used</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card className="card-dark p-3 text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    height={50}
                    src={require("assets/img/icon/loyalty-program.png")}
                    alt=""
                  />
                  <h5 className="mb-0 ml-2">Loyalty</h5>
                </div>
                <CardBody>
                  <Row xs={2}>
                    <Col className="text-center pr-1 pl-0">
                      <div className="rounded border h-100">
                        <h5>
                          {MRewardData?.CouponReport.LoyaltyCouponRedeemNo}
                        </h5>
                        <p>Redeem</p>
                      </div>
                    </Col>
                    <Col className="text-center pl-1 pr-0">
                      <div className="rounded border h-100">
                        <h5>{MRewardData?.CouponReport.LoyaltyCouponUsedNo}</h5>
                        <p>Used</p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default RewardOverview;
