/* eslint-disable react/forbid-prop-types */
/* eslint-disable default-case */
import React, { useContext } from "react";
import ApiContext from "services/ApiContext";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { useFormik } from "formik";
import * as Yup from "yup";
import ShopContext from "services/ShopContext";
import moment from "moment";
import { toast } from "react-toastify";
import ImageUpload from "components/CustomUpload/ImageUpload";
import BasicTable from "views/tables/BasicTable";
import { useQuery } from "react-query";
import ReactDatePicker from "react-datepicker";
import { PropTypes } from "prop-types";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";

function SummaryMsg({ language, total, bank }) {
  let content;
  switch (language) {
    case "Malay":
      content = (
        <div>
          <p>🙂Jumlah kena dibayar: RM{total} </p>
          <p className="mb-3">
            ❤ Sila memilih cara pembayaran sebelum kita menghantar quotation
            kepada anda.
          </p>
          {bank &&
            bank.length > 0 &&
            bank.map((method, idx) => (
              <p key={method.ID} className="mb-0">
                [{idx + 1}] {method.Name}
              </p>
            ))}
          <p className="mt-3">Anda hendak buat pembayaran melalui?</p>
        </div>
      );
      break;
    case "Chinese":
      content = (
        <div>
          <p>🙂受付金额: RM{total} </p>
          <p className="mb-3">❤ 在我们向您发送报价之前，请选择一种付款方式。</p>
          {bank &&
            bank.length > 0 &&
            bank.map((method, idx) => (
              <p key={method.ID} className="mb-0">
                [{idx + 1}] {method.Name}
              </p>
            ))}
          <p className="mt-3">亲爱的，你想通过哪个方式付款呢？</p>
        </div>
      );
      break;
    case "English":
    default:
      content = (
        <div>
          <p>🙂Amount payable: RM{total} </p>
          <p className="mb-3">
            ❤ Please choose a payment method before we send you a quotation.
          </p>
          {bank &&
            bank.length > 0 &&
            bank.map((method, idx) => (
              <p key={method.ID} className="mb-0">
                [{idx + 1}] {method.Name}
              </p>
            ))}
          <p className="mt-3">You want to make a payment through?</p>
        </div>
      );
      break;
  }
  return <div>{content}</div>;
}

function OrderMsg({
  language,
  payType,
  bankName,
  bankAcc,
  productSelected,
  customer,
  paymentDue,
  goldPrice,
  shipTo,
  shipPrice,
}) {
  let header;
  let payment;
  let footer;

  let totalPrice = 0;

  if (productSelected) {
    productSelected.forEach((item) => {
      totalPrice += item.Price;
    });
  }

  if (payType) {
    switch (language) {
      case "Malay":
        header = (
          <div>
            <h5>
              ❤ Tahniah kepada anda kerana dapat LOCK barang kemas dari live
              hari ini! Berikut merupakan details ya. :)
            </h5>
            <br />
            <div>
              {moment(new Date()).format("DD/MM/YYYY")} {customer}
            </div>
            <hr />
            <div>
              {productSelected &&
                productSelected.map((item) => (
                  <>
                    <div>
                      <p>ITEM NO : {item.ProductNo}</p>
                      <p>Produk : {item.Name}</p>
                      <p>Kod : {item.VariationSKU}</p>
                      <p>Berat : {item.Weight}</p>
                      <p>
                        Harga/grm :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={goldPrice}
                          currency="RM"
                        />
                      </p>
                      <p>
                        Harga Produk :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={item.Price}
                          currency="RM"
                        />
                      </p>
                    </div>
                    {productSelected.length > 1 && <br />}
                  </>
                ))}
            </div>
            {productSelected.length === 1 && <br />}
            <div>
              <p>
                Brg Kms :{" "}
                <CurrencyFormat decimal={2} price={totalPrice} currency="RM" />
              </p>
              <p>
                (+)Penghantaran (WM) :
                <CurrencyFormat
                  decimal={2}
                  price={shipTo === "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
              <p>
                (+)Penghantaran (EM) :
                <CurrencyFormat
                  decimal={2}
                  price={shipTo !== "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
            </div>
            <br />
            <div>
              <p>:D JUMLAH KENA BAYAR :D</p>
              <p>
                West Malaysia :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo === "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
              <p>
                East Malaysia :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo !== "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
            </div>
            <hr />
            <p>BAYAR SEBELUM : </p>
            <p>
              {moment(paymentDue).format("DD-MMM")} &nbsp;
              {moment(paymentDue).format("HH:MM A")}
            </p>
            <br />
          </div>
        );

        footer = (
          <div>
            <hr />
            <p>Sila hantar info penghantaran :</p>
            <p>Nama :</p>
            <p>Nombor :</p>
            <p>E-mel :</p>
            <p>Alamat :</p>
            <br />
            <div>
              <h5>*Peringatan*</h5>
              <p>
                1) Setelah anda membuat pembayaran dan Megan Jewellery menerima
                pembayaran, kami akan mengeluarkan invois ke e-mel anda.
              </p>
              <p>2) LIVE TEAM beroperasi dari Isnin-Jumaat, office hour shj.</p>
              <p>
                3) Jika mempunyai kelambatan, kita mohon maaf atas kesulitan
                yang berlaku.
              </p>
            </div>
            <br />
            <div>
              <p>
                ❤ Terima kasih atas minat anda, sila membuat pembayaran secepat
                mungkin! Kita tak sabar untuk hantar kepada anda ya! :*{" "}
              </p>
            </div>
          </div>
        );

        if (payType.toLowerCase().includes("bank")) {
          payment = (
            <div>
              <h5>❤ Akaun bank</h5>
              <div>MEGAN JEWELLERY SDN BHD</div>
              <div>{bankName}</div>
              <div>{bankAcc}</div>
            </div>
          );
        } else if (payType.toLowerCase().includes("later")) {
          payment = (
            <div>
              <p>Langkah 1: Ketik pada ikon pembayaran dari halaman utama</p>
              <p>
                Langkah 2 : Ketik pada Scan untuk bayar dan benarkan kebenaran
                kamera (untuk penggunaan kali pertama atau jika anda telah
                melumpuhkan kebenaran)
              </p>
              <p>
                Langkah 3 : Imbas kod QR pedagang (Grabpay QR atau Duitnow QR
                boleh diterima)
              </p>
              <p>Langkah 4: Masukkan jumlah yang perlu dibayar</p>
              <p>
                Langkah 5: Pilih cara pembayaran ( bayar sepenuh/bayar bulan
                depan/bayar secara ansuran dlm 4 bulan)
              </p>
              <p>Langkah 6: Pembayaran selesai!</p>

              <div className="d-flex mt-3">
                <img
                  src={require("assets/img/icon/grabpay.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          );
        } else {
          payment = (
            <div>
              <p>Langkah 1: Ketik pada ikon imbas dari halaman utama</p>
              <p>
                Langkah 2 : Benarkan kebenaran kamera (untuk penggunaan kali
                pertama atau jika anda telah melumpuhkan kebenaran)
              </p>
              <p>
                Langkah 3: Imbas Kod QR pedagang (TNG QR atau Duitnow QR boleh
                diterima)
              </p>
              <p>Langkah 4: Masukkan jumlah yang perlu dibayar</p>
              <p>Langkah 5: Masukkan PIN 6 digit anda</p>
              <p>Langkah 6: Pembayaran selesai!</p>

              <Row className="mt-3">
                <Col className="text-center">
                  <img
                    src={require("assets/img/icon/tng.png")}
                    alt=""
                    width="80%"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img
                    src={require("assets/img/icon/tng-step.png")}
                    alt=""
                    className="img-fluid ml-3"
                  />
                </Col>
              </Row>
            </div>
          );
        }
        break;
      case "Chinese":
        header = (
          <div>
            <h5>❤ 恭喜您今天成功在直播中锁定金饰！这是详细信息。:)</h5>
            <br />
            <div>
              {moment(new Date()).format("DD/MM/YYYY")} {customer}
            </div>
            <hr />
            <div>
              {productSelected &&
                productSelected.map((item) => (
                  <>
                    <div>
                      <p>号码 : {item.ProductNo}</p>
                      <p>产品 : {item.Name}</p>
                      <p>代码 : {item.VariationSKU}</p>
                      <p>重量（克）: {item.Weight}</p>
                      <p>
                        价格/克 :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={goldPrice}
                          currency="RM"
                        />
                      </p>
                      <p>
                        产品价格 :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={item.Price}
                          currency="RM"
                        />
                      </p>
                    </div>
                    {productSelected.length > 1 && <br />}
                  </>
                ))}
            </div>
            {productSelected.length === 1 && <br />}
            <div>
              <p>
                总价格 :{" "}
                <CurrencyFormat decimal={2} price={totalPrice} currency="RM" />
              </p>
              <p>
                (+)运费（西马）:
                <CurrencyFormat
                  decimal={2}
                  price={shipTo === "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
              <p>
                (+)运费 （东马）:
                <CurrencyFormat
                  decimal={2}
                  price={shipTo !== "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
            </div>
            <br />
            <div>
              <p>:D 总额 :D</p>
              <p>
                西马 :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo === "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
              <p>
                东马 :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo !== "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
            </div>
            <hr />
            <p>付款效期: </p>
            <p>
              {moment(paymentDue).format("DD-MMM")} &nbsp;
              {moment(paymentDue).format("HH:MM A")}
            </p>
            <br />
          </div>
        );

        footer = (
          <div>
            <hr />
            <p>请与我们分享您的 :</p>
            <p>姓名 :</p>
            <p>电话 :</p>
            <p>电子邮件 :</p>
            <p>地址 :</p>
            <br />
            <div>
              <h5>*注意*</h5>
              <p>
                1) 一旦您的付款完成并且 Megan Jewellery
                收到付款，我们将发invoice到您的电子邮件。
              </p>
              <p>2) LIVE TEAM 仅在周一至周五的办公时间运营。</p>
              <p>3) 如有延误，给您带来的不便，我们深表歉意。</p>
            </div>
            <br />
            <div>
              <p>❤ 感谢您的关注，请尽快付款！我们已准备将货品发送给您！ :* </p>
            </div>
          </div>
        );

        if (payType.toLowerCase().includes("银行")) {
          payment = (
            <div>
              <h5>❤ 银行账户</h5>
              <div>MEGAN JEWELLERY SDN BHD</div>
              <div>{bankName}</div>
              <div>{bankAcc}</div>
            </div>
          );
        } else if (payType.toLowerCase().includes("later")) {
          payment = (
            <div>
              <p>第1步: 点击主页上的付款图标</p>
              <p>
                第2步:
                点击“扫描”进行支付并允许相机权限（首次使用或如果您已禁用权限）
              </p>
              <p>第3步: 扫描商户二维码 (Grabpay二维码或Duitnow二维码均可)</p>
              <p>第4步: 输入要支付的金额</p>
              <p>第5步: 选择付款方式（全额付款/下个月付款/4个月分期付款）</p>
              <p>第6步: 付款完成！</p>

              <div className="d-flex mt-3">
                <img
                  src={require("assets/img/icon/grabpay.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          );
        } else {
          payment = (
            <div>
              <p>第1步: 点击主页中的浏览图标</p>
              <p>第2步: 允许相机权限（首次使用或如果您已禁用权限）</p>
              <p>第3步: 扫描商户二维码(TNG二维码或Duitnow二维码均可)</p>
              <p>第4步: 输入要支付的金额</p>
              <p>第5步: 输入您的 6 位数 PIN 码</p>
              <p>第6步: 付款完成！</p>
              <Row className="mt-3">
                <Col className="text-center">
                  <img
                    src={require("assets/img/icon/tng.png")}
                    alt=""
                    width="80%"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img
                    src={require("assets/img/icon/tng-step.png")}
                    alt=""
                    className="img-fluid ml-3"
                  />
                </Col>
              </Row>
            </div>
          );
        }
        break;
      case "English":
      default:
        header = (
          <div>
            <h5>
              ❤ Congratulations to you for being able to LOCK jewellery from
              live today! Here are the details. :)
            </h5>
            <br />
            <div>
              {moment(new Date()).format("DD/MM/YYYY")} {customer}
            </div>
            <hr />
            <div>
              {productSelected &&
                productSelected.map((item) => (
                  <>
                    <div>
                      <p>ITEM NO : {item.ProductNo}</p>
                      <p>Product : {item.Name}</p>
                      <p>Code : {item.VariationSKU}</p>
                      <p>Weight (g) : {item.Weight}</p>
                      <p>
                        Price/gram :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={goldPrice}
                          currency="RM"
                        />
                      </p>
                      <p>
                        Product Price :{" "}
                        <CurrencyFormat
                          decimal={2}
                          price={item.Price}
                          currency="RM"
                        />
                      </p>
                    </div>
                    {productSelected.length > 1 && <br />}
                  </>
                ))}
            </div>
            {productSelected.length === 1 && <br />}
            <div>
              <p>
                Total :{" "}
                <CurrencyFormat decimal={2} price={totalPrice} currency="RM" />
              </p>
              <p>
                (+)Shipping (WM) :
                <CurrencyFormat
                  decimal={2}
                  price={shipTo === "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
              <p>
                (+)Shipping (EM) :
                <CurrencyFormat
                  decimal={2}
                  price={shipTo !== "WM" ? shipPrice : 0}
                  currency="RM"
                />
              </p>
            </div>
            <br />
            <div>
              <p>:D AMOUNT TO PAY :D</p>
              <p>
                West Malaysia :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo === "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
              <p>
                East Malaysia :
                <CurrencyFormat
                  decimal={2}
                  price={
                    shipTo !== "WM"
                      ? totalPrice + Number(shipPrice)
                      : totalPrice
                  }
                  currency="RM"
                />
              </p>
            </div>
            <hr />
            <p>PAY BEFORE : </p>
            <p>
              {moment(paymentDue).format("DD-MMM")} &nbsp;
              {moment(paymentDue).format("HH:MM A")}
            </p>
            <br />
          </div>
        );

        footer = (
          <div>
            <hr />
            <p>Kindly share us your :</p>
            <p>Name :</p>
            <p>Phone :</p>
            <p>Email :</p>
            <p>Address :</p>
            <br />
            <div>
              <h5>*Remark*</h5>
              <p>
                1) Once you have done payment and Megan Jewellery receive the
                payment, we will issue an invoice to your email.
              </p>
              <p>
                2) LIVE TEAM operates from Monday-Friday, office hours only.
              </p>
              <p>
                3) We apologize for the inconvenience if there is any delay to
                respond your message.
              </p>
            </div>
            <br />
            <div>
              <p>
                ❤ Thank you for your interest, please make payment as soon as
                possible! We are ready to send it to you! :*{" "}
              </p>
            </div>
          </div>
        );

        if (payType.toLowerCase().includes("bank")) {
          payment = (
            <div>
              <h5>❤ Bank Account</h5>
              <div>MEGAN JEWELLERY SDN BHD</div>
              <div>{bankName}</div>
              <div>{bankAcc}</div>
            </div>
          );
        } else if (payType.toLowerCase().includes("later")) {
          payment = (
            <div>
              <p>Step 1: Tap on the payment icon from the home page</p>
              <p>
                Step 2 : Tap on Scan to pay and allow camera permission (for
                first time use or if you have disabled permission)
              </p>
              <p>
                Step 3 : Scan the merchant&apos;s QR code (Grabpay QR or Duitnow
                QR are acceptable)
              </p>
              <p>Step 4: Enter the amount to be paid</p>
              <p>
                Step 5: Choose a payment method (pay in full/pay next month/pay
                in installments in 4 months)
              </p>
              <p>Step 6: Payment complete!</p>

              <div className="d-flex mt-3">
                <img
                  src={require("assets/img/icon/grabpay.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          );
        } else {
          payment = (
            <div>
              <p>Step 1: Tap on the browse icon from the home page</p>
              <p>
                Step 2 : Allow camera permission (for first time use or if you
                have disabled permission)
              </p>
              <p>
                Step 3: Scan the merchant&apos;s QR Code (TNG QR or Duitnow QR
                are acceptable)
              </p>
              <p>Step 4: Enter the amount to be paid</p>
              <p>Step 5: Enter your 6 digit PIN</p>
              <p>Step 6: Payment is complete!</p>
              <Row className="mt-3">
                <Col className="text-center">
                  <img
                    src={require("assets/img/icon/tng.png")}
                    alt=""
                    width="80%"
                    className="img-fluid"
                  />
                </Col>
                <Col>
                  <img
                    src={require("assets/img/icon/tng-step.png")}
                    alt=""
                    className="img-fluid ml-3"
                  />
                </Col>
              </Row>
            </div>
          );
        }
    }
  }

  return (
    <>
      <div>{header}</div>
      <div>{payment}</div>
      <div>{footer}</div>
    </>
  );
}

function CreateQuotation() {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext).selctShopID.ID;

  const [modal, setModal] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modalImg, setModalImg] = React.useState(false);

  const [PaymentDate, setPaymentDate] = React.useState("");
  const [PaymentTime, setPaymentTime] = React.useState("");
  const [shippTo, setShipTo] = React.useState("WM");
  const [shipPrice, setShipPrice] = React.useState(0);
  const [lockedCode, setLockedCode] = React.useState("");
  // record product added
  const [productSelected, setProductSelected] = React.useState([]);

  const [scannedCode, setScannedCode] = React.useState("");
  const [scannedCode2, setScannedCode2] = React.useState("");
  const [scanFromScanner, setScanFromScanner] = React.useState(false);
  const [typingTimeout, setTypingTimeout] = React.useState(0);

  const validationSchema = Yup.object().shape({
    Hostname: Yup.string().required("Hostname is required"),
    LanguageID: Yup.string().required("Language is required"),
    OrderSourceID: Yup.string().required("Order Source is required"),
    PaymentMethodID: Yup.string().required("Payment Method is required"),
    PICQuatation: Yup.string().required("PIC is required"),
    GoldPrice: Yup.string().required("Gold Price is required"),
    ScanProductIDList: Yup.array().min(1, "Product is required"),
    // either paymentdate or paymenttime cannnot null
    PaymentDue: Yup.date().required("Payment Due is required"),
    LiveID: Yup.string().required("Batch is required"),
    CustomerNickName: Yup.string().required("Customer Nick Name is required"),
    LockedMessageImage: Yup.string().required(
      "Locked Message Image is required"
    ),
  });

  const CombineDateAndTime = (date, time) => {
    if (date && time) {
      const mins = `0${time.getMinutes()}`.slice(-2);
      const hours = `0${time.getHours()}`.slice(-2);
      const timeString = `${hours}:${mins}:00`;
      const year = date.getFullYear();
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      const dateString = `${year}-${month}-${day}`;
      const datec = `${dateString}T${timeString}`;
      return new Date(datec);
    }
    return date || time;
  };

  const formik = useFormik({
    initialValues: {},
    validationSchema,

    onSubmit: (data) => {
      formik.setSubmitting(true);
      const data2 = new FormData();
      data2.append("Hostname", data.Hostname);
      data2.append("LanguageID", data.LanguageID);
      data2.append("OrderSourceID", data.OrderSourceID);
      data2.append("PaymentMethodID", data.PaymentMethodID);
      data2.append("PICQuatation", data.PICQuatation);
      data2.append("LiveID", data.LiveID);
      data2.append("GoldPrice", data.GoldPrice);
      data2.append("CustomerNickName", data.CustomerNickName);
      data2.append("Quantity", data.Quantity);
      if (shippTo === "WM") {
        data2.append("WMShippingFee", data.shipPrice || 0);
      } else {
        data2.append("EMShippingFee", data.shipPrice || 0);
      }
      data2.append("CreatedDate", new Date().toISOString());
      data2.append("PaymentDue", data.PaymentDue.toISOString());
      data.ScanProductIDList.forEach((item) => {
        data2.append("ScanProductIDList", item);
      });
      if (data.LockedMessageImage) {
        data2.append("LockedMessageImage", data.LockedMessageImage);
      }

      Axios.post(`/shop/${shopID}/Live/CreateQuotation`, data2).then(() => {
        setModal2(true);
        toast.success("Order created");
      });
      formik.setSubmitting(false);
    },
  });

  const imageCallback = (field, event) => {
    formik.setFieldValue(field, event);
  };

  const { data: orderSouceList, isLoading } = useQuery(
    "AllOrderSource",
    async () =>
      Axios.get("/public/OrderSourceAttribute/AllOrderSource").then((res) => {
        formik.setFieldValue("OrderSourceID", res.data[0].ID);
        return res.data;
      })
  );

  const { data: BatchList } = useQuery(
    `/shop/${shopID}/Live/Dashboard/BatchList`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Live/Dashboard`).then((res) => {
        const temp = res.data.Result.filter(
          (i) => i.ID === moment(new Date()).format("DD/MM/YYYY")
        );
        return temp[0].batchLogList.filter((y) => y.isConfirm === true);
      })
  );

  const { data: languageList } = useQuery("AllLanguagesSetting", async () =>
    Axios.get("/public/LanguageAttributeSetting/AllLanguagesSetting").then(
      (res) => {
        if (!formik.values.LanguageID) {
          // default language is english
          formik.setFieldValue("LanguageID", res.data[0].ID);
          formik.setFieldValue("Language", res.data[0].Attribute);
        }
        return res.data;
      }
    )
  );

  const { data: paymentList } = useQuery(
    ["AllLivePaymentGateways", formik.values.LanguageID],
    async () =>
      Axios.get(
        `/public/PaymentGatewaySetting/AllLivePaymentGateways/${formik.values.LanguageID}`
      ).then((res) => res.data),
    { enabled: formik.values.LanguageID !== undefined }
  );

  const { isLoading: isLoadingTableSN, refetch: refetchSN } = useQuery(
    `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveID}/SN/${
      scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode
    }`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveID}/SN/${
          scanFromScanner ? scannedCode2.replace("Enter", "") : scannedCode
        }`
      ).then((res) => {
        if (res.data.ID) {
          formik.setFieldValue("ScanProductIDList", [
            ...productSelected,
            res.data,
          ]);
          setProductSelected([...productSelected, res.data]);
          toast.success(`Added product: ${res.data.VariationSKU}`);
        } else {
          toast.warning(res.data.MESSAGE);
        }
        return res;
      }),
    { enabled: false }
  );

  const { isLoading: isLoadingTableNo, refetch: refetchNo } = useQuery(
    `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveID}/No/${lockedCode}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetSelectedSNProduct/${formik.values.LiveID}/No/${lockedCode}`
      ).then((res) => {
        if (res.data.ID) {
          setProductSelected([...productSelected, res.data]);
          toast.success(`Added product: ${res.data.VariationSKU}`);
        } else {
          toast.warning(res.data.MESSAGE);
        }
        return res;
      }),
    { enabled: false }
  );

  const { refetch: refetchSumMsg, data: refetchSumMsgData } = useQuery(
    `/shop/${shopID}/Live/GetQuotationSummaryMessage/${formik.values.LiveID}/${formik.values.LanguageID}/${shipPrice}/${formik.values.ScanProductIDList}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/Live/GetQuotationSummaryMessage/${formik.values.LiveID}/${formik.values.LanguageID}/${shipPrice}/${formik.values.ScanProductIDList}`
      )
        .then((res) => {
          setModal(true);
          return res;
        })
        .catch((err) => {
          toast.error(err.response.data.MESSAGE);
        }),
    { enabled: false }
  );

  const addScanItem = (category) => {
    if (category === "locked") {
      if (lockedCode.trim() === "") {
        toast.warning("Cannot be emtpy");
      } else if (
        !productSelected.find((i) => i.ProductNo === lockedCode.trim())
      ) {
        refetchNo();
      } else {
        toast.warning(`This item already added: ${lockedCode}`);
      }
      setLockedCode("");
    }

    if (category === "scanned") {
      if (scannedCode.trim() === "") {
        toast.warning("Cannot be emtpy");
      } else if (
        !productSelected.find((i) => i.VariationSKU === scannedCode.trim())
      ) {
        setScanFromScanner(false);
        refetchSN();
      } else {
        toast.warning(`This item already added: ${scannedCode}`);
      }
      setScannedCode("");
    }
  };

  const addScanItem2 = () => {
    if (scannedCode2.trim() === "") {
      toast.warning("Cannot be emtpy");
    } else if (
      !productSelected.find((i) => i.VariationSKU === scannedCode2.trim())
    ) {
      setScanFromScanner(true);
      refetchSN();
    } else {
      toast.warning(`This item already added: ${scannedCode2}`);
    }
    setScannedCode("");
    setScannedCode2("");
  };

  React.useEffect(() => {
    formik.setFieldValue(
      "ScanProductIDList",
      productSelected.map((i) => i.ProductID)
    );
    formik.setFieldValue("Quantity", productSelected.length);
  }, [refetchNo, refetchSN, productSelected]);

  React.useEffect(() => {
    formik.setFieldValue(
      "PaymentDue",
      CombineDateAndTime(PaymentDate, PaymentTime)
    );
  }, [PaymentDate, PaymentTime]);

  // Used to handle the barcode scanner
  React.useEffect(() => {
    const handleBarcodeScan = async (event) => {
      clearTimeout(typingTimeout);
      const scannedValue = event.key;
      setScannedCode2((prevCode) => prevCode + scannedValue);
      const timeout = setTimeout(() => {}, 200);

      setTypingTimeout(timeout);

      if (scannedValue === "Enter") {
        await addScanItem2();
      }
    };
    document.addEventListener("keypress", handleBarcodeScan);

    return () => {
      document.removeEventListener("keypress", handleBarcodeScan);
    };
  }, [scannedCode2, typingTimeout]);

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="content">
      <Modal toggle={() => setModal(!modal)} isOpen={modal} size="xl">
        <ModalHeader>Summary Message</ModalHeader>
        {paymentList && languageList && refetchSumMsgData && (
          <ModalBody style={{ fontSize: "larger" }}>
            <SummaryMsg
              bank={paymentList}
              language={
                languageList.find((i) => i.ID === formik.values.LanguageID)
                  .Attribute
              }
              total={refetchSumMsgData.data.TotalCost}
            />
          </ModalBody>
        )}
      </Modal>
      <Modal isOpen={modal2} size="xl">
        <ModalHeader>Summary Message</ModalHeader>
        {paymentList &&
          languageList &&
          refetchSumMsgData &&
          formik.values.PaymentMethodID && (
            <ModalBody style={{ fontSize: "larger" }}>
              <OrderMsg
                paymentDue={formik.values.PaymentDue}
                goldPrice={formik.values.GoldPrice}
                shipTo={shippTo}
                shipPrice={shipPrice}
                customer={formik.values.CustomerNickName}
                productSelected={productSelected}
                language={
                  languageList.find((i) => i.ID === formik.values.LanguageID)
                    .Attribute
                }
                payType={
                  paymentList.find(
                    (i) => i.ID === formik.values.PaymentMethodID
                  ).Name
                }
                bankName={refetchSumMsgData.data.ShopBankName}
                bankAcc={refetchSumMsgData.data.ShopBankAccountNo}
              />
            </ModalBody>
          )}
        <ModalFooter>
          <Button onClick={() => setModal2(false)} className="mr-2">
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={modalImg} size="xl" toggle={() => setModalImg(!modalImg)}>
        <ModalHeader>Image Upload</ModalHeader>

        <ImageUpload
          imageCallback={imageCallback}
          field="LockedMessageImage"
          perviouseUpload={formik.values.LockedMessageImage}
        />
      </Modal>
      <Form>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Host&apos;s Name</Label>
              <Input
                className={` ${
                  formik.errors.Hostname ? "is-invalid" : "is-valid"
                }`}
                name="Hostname"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.Hostname}
              />
              <div className="text-danger">
                {formik.errors.Hostname ? formik.errors.Hostname : null}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            {orderSouceList?.length > 0 && (
              <FormGroup>
                <Label>Order Source</Label>
                <Input
                  name="OrderSourceID"
                  type="select"
                  onChange={formik.handleChange}
                  defaultValue={orderSouceList[0].ID}
                >
                  {orderSouceList?.map((ordersource) => (
                    <option value={ordersource.ID}>
                      {ordersource.Attribute}
                    </option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.OrderSourceID
                    ? formik.errors.OrderSourceID
                    : null}
                </div>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>PIC Quotation</Label>
              <Input
                className={` ${
                  formik.errors.PICQuatation ? "is-invalid" : "is-valid"
                }`}
                name="PICQuatation"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.PICQuatation}
              />
              <div className="text-danger">
                {formik.errors.handleChange ? formik.errors.handleChange : null}
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Customer&apos;s NickName </Label>
              <div className="d-flex">
                <Input
                  type="text"
                  name="CustomerNickName"
                  onChange={formik.handleChange}
                  value={formik.values.CustomerNickName}
                />
                <Button
                  className="btn-icon my-0 ml-2"
                  type="button"
                  onClick={() => setModalImg(true)}
                >
                  <i className="fa fa-cloud-upload" />
                </Button>
              </div>
              <div className="text-danger">
                {formik.errors.CustomerNickName
                  ? formik.errors.CustomerNickName
                  : null}
              </div>
              <div className="text-danger">
                {formik.errors.LockedMessageImage
                  ? formik.errors.LockedMessageImage
                  : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Date & Time</Label>
              <div className="d-flex">
                <Input
                  name="CreatedDate"
                  type="text"
                  disabled
                  value={moment(new Date()).format("DD/MM/YYYY")}
                />
                <Input
                  className="ml-2"
                  name="CreatedDate"
                  type="text"
                  disabled
                  value={moment(new Date()).format("HH:mm a")}
                />
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Gold Price/g RM</Label>
              <Input
                className={` ${
                  formik.errors.GoldPrice ? "is-invalid" : "is-valid"
                }`}
                name="GoldPrice"
                type="number"
                step="0.01"
                onChange={formik.handleChange}
                value={formik.values.GoldPrice}
              />
              <div className="text-danger">
                {formik.errors.GoldPrice ? formik.errors.GoldPrice : null}
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Payment Due</Label>
              <div className="d-flex">
                <ReactDatePicker
                  selected={PaymentDate}
                  onChange={(update) => {
                    setPaymentDate(update);
                  }}
                  isClearable
                  wrapperClassName="w-100"
                  customInput={<Input />}
                  placeholderText="for Date"
                />

                <ReactDatePicker
                  onChange={(update) => {
                    setPaymentTime(update);
                  }}
                  placeholderText="for Time"
                  selected={PaymentTime}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  isClearable
                  wrapperClassName="w-100 ml-2"
                  customInput={<Input />}
                />
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Shipping Fee</Label>
              <div className="d-flex">
                <Input
                  type="select"
                  onChange={(e) => setShipTo(e.target.value)}
                  value={shippTo}
                >
                  <option value="WM">West M</option>
                  <option value="EM">East M</option>
                </Input>
                <Input
                  className="ml-2"
                  type="number"
                  step="0.01"
                  onChange={(e) => setShipPrice(e.target.value)}
                  value={shipPrice}
                />
              </div>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            {languageList && languageList.length > 0 && (
              <FormGroup>
                <Label>Language</Label>
                <Input
                  name="LanguageID"
                  type="select"
                  onChange={formik.handleChange}
                >
                  {languageList.map((i) => (
                    <option value={i.ID} key={i.ID}>
                      {i.Attribute}
                    </option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.LanguageID ? formik.errors.LanguageID : null}
                </div>
              </FormGroup>
            )}
          </Col>
          <Col md="6" xs="12">
            {BatchList && BatchList.length > 0 ? (
              <FormGroup>
                <Label>Batch</Label>
                <Input
                  type="select"
                  name="LiveID"
                  onChange={formik.handleChange}
                  value={formik.values.LiveID}
                >
                  <option value="">-</option>
                  {BatchList.map((i) => (
                    <option value={i.ID}>{i.batchName}</option>
                  ))}
                </Input>
                <div className="text-danger">
                  {formik.errors.LiveID ? formik.errors.LiveID : null}
                </div>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>Batch</Label>
                <Input type="text" value="No Batch Created" disabled />
              </FormGroup>
            )}
          </Col>
        </Row>
        <Row>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Quantity</Label>
              <Input
                className={` ${
                  formik.errors.Quantity ? "is-invalid" : "is-valid"
                }`}
                name="Quantity"
                type="number"
                disabled
                value={formik.values.Quantity}
              />
            </FormGroup>
          </Col>
          <Col md="6" xs="12">
            <FormGroup>
              <Label>Locked Item</Label>
              <Row>
                <Col className="d-flex">
                  <Input
                    placeholder="Locked No"
                    type="text"
                    onChange={(e) => {
                      setLockedCode(e.target.value);
                    }}
                    // onKeyPress={(e) => handleKeyPress(e)}
                    value={lockedCode}
                  />
                  <Button
                    disabled={!formik.values.LiveID}
                    onClick={() => addScanItem("locked")}
                    className="my-0"
                  >
                    Add
                  </Button>
                </Col>
                <Col className="d-flex">
                  <Input
                    placeholder="Product SN"
                    type="text"
                    onChange={(e) => {
                      setScannedCode(e.target.value);
                    }}
                    value={scannedCode}
                  />
                  <Input
                    type="hidden"
                    id="barcodeScanner"
                    value={scannedCode2}
                    onChange={(e) => setScannedCode2(e.target.value)}
                  />
                  <Button
                    disabled={!formik.values.LiveID}
                    onClick={() => addScanItem("scanned")}
                    className="my-0"
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </FormGroup>
          </Col>
        </Row>
        <BasicTable
          progressPending={isLoadingTableSN || isLoadingTableNo}
          progressComponent={
            <div className="py-5">
              <Spinner />
            </div>
          }
          data={productSelected}
          columns={[
            {
              name: "",
              // eslint-disable-next-line react/no-unstable-nested-components
              selector: (_, idx) => (
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  onClick={() => {
                    productSelected.splice(idx, 1);
                    setProductSelected([...productSelected]);
                  }}
                >
                  <span aria-hidden="true" className="text-white">
                    &times;
                  </span>
                </button>
              ),
            },
            {
              name: "No",
              selector: (row) => row.ProductNo,
              sortable: true,
            },
            {
              name: "SN",
              selector: (row) => row.VariationSKU,
              sortable: true,
            },
            {
              name: "Name",
              selector: (row) => row.Name,
              sortable: true,
            },
            {
              name: "SKU",
              selector: (row) => row.ProductSKU,
              sortable: true,
            },
            {
              name: "Weight",
              selector: (row) => row.Weight,
              sortable: true,
            },
            {
              name: "Size",
              selector: (row) => row.Size,
              sortable: true,
            },
            {
              name: "Discounted Price",
              selector: (row) => row.Price,
              sortable: true,
            },
          ]}
        />

        {formik.values.ScanProductIDList &&
          formik.values.ScanProductIDList.length > 0 && (
            <Row xs={1} md="2">
              <Col>
                <FormGroup>
                  <Label>Summary Message</Label>
                  <Button
                    className="ml-2"
                    onClick={() => {
                      refetchSumMsg();
                    }}
                    disabled={formik.values.ScanProductIDList.length <= 0}
                  >
                    Before Quotation
                  </Button>
                </FormGroup>
              </Col>
              <Col>
                {paymentList && paymentList.length > 0 ? (
                  <FormGroup>
                    <Label>Payment Method</Label>
                    <Input
                      name="PaymentMethodID"
                      type="select"
                      onChange={formik.handleChange}
                    >
                      <option value="">-</option>
                      {paymentList.map((method) => (
                        <option value={method.ID}>{method.Name}</option>
                      ))}
                    </Input>
                    <Button
                      className="mt-3 button-dark-template"
                      disabled={
                        Object.values(formik.errors).length > 0 ||
                        productSelected.length <= 0
                      }
                      onClick={formik.handleSubmit}
                    >
                      {formik.isSubmitting ? <Spinner /> : "Generate Quotation"}
                    </Button>
                  </FormGroup>
                ) : (
                  <FormGroup>
                    <Label>Batch</Label>
                    <Input type="text" value="No Batch Created" disabled />
                  </FormGroup>
                )}
              </Col>
            </Row>
          )}
      </Form>
    </div>
  );
}

SummaryMsg.propTypes = {
  language: PropTypes.string.isRequired,
  total: PropTypes.arrayOf([PropTypes.object]).isRequired,
  bank: PropTypes.number.isRequired,
};
OrderMsg.propTypes = {
  language: PropTypes.string.isRequired,
  payType: PropTypes.string.isRequired,
  bankName: PropTypes.string.isRequired,
  bankAcc: PropTypes.string.isRequired,
  productSelected: PropTypes.arrayOf([PropTypes.object]).isRequired,
  customer: PropTypes.object.isRequired,
  paymentDue: PropTypes.string.isRequired,
  goldPrice: PropTypes.number.isRequired,
  shipTo: PropTypes.string.isRequired,
  shipPrice: PropTypes.number.isRequired,
};

export default CreateQuotation;
