import React from "react";
import PropTypes from "prop-types";
// reactstrap components
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

// const items = [
//   {
//     src: `${require("assets/img/faces/ayo-ogunseinde-2.jpg")}`,
//   },
//   {
//     src: `${require("assets/img/faces/clem-onojeghuo-2.jpg")}`,
//   },
// ];

function Sliders(props) {
  const { itemImg } = props;

  // carousel - slider
  const [itemsSlider, setItemsSlider] = React.useState([]);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === itemsSlider.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? itemsSlider.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  React.useEffect(() => {
    const temp = [];
    if (itemImg) {
      itemImg.forEach((element) => {
        temp.push({ src: `${element.ImageUrl}` });
      });
      setItemsSlider(temp);
    }
  }, [itemImg]);

  return (
    <>
      {/* ********* HEADER 3 ********* */}
      <div className="page-carousel">
        <div className="filter" />
        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
          <CarouselIndicators
            items={itemsSlider}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
          {itemsSlider.map((item) => (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
            >
              <div className="filter" />
              <img
                className="d-block w-100"
                src={item.src}
                alt=""
                style={{
                  objectFit: "cover",
                  maxHeight: "300px",
                }}
              />
            </CarouselItem>
          ))}
          <a
            className="left carousel-control carousel-control-prev"
            data-slide="prev"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              previous();
            }}
            role="button"
          >
            <i className="fa fa-angle-left" aria-hidden="true" />
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="right carousel-control carousel-control-next"
            data-slide="next"
            href="#pablo"
            onClick={(e) => {
              e.preventDefault();
              next();
            }}
            role="button"
          >
            <i className="fa fa-angle-right" aria-hidden="true" />
            <span className="sr-only">Next</span>
          </a>
        </Carousel>
      </div>
      {/* ********* END HEADER 3 ********* */}
    </>
  );
}

Sliders.propTypes = {
  itemImg: PropTypes.func.isRequired,
};

export default Sliders;
