import React, { useState, useRef } from "react";
import { Button, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

function ImageUploader({ onImageUpload }) {
  const [images, setImages] = useState([]);
  const inputRef = useRef(null);

  const handleImageChange = (e) => {
    const uploadedImages = Array.from(e.target.files);

    if (images.length + uploadedImages.length > 3) {
      toast.warning("You can only upload up to 3 images.");
      inputRef.current.value = ""; // Reset the input field
      return;
    }

    const fileTypes = ["image/png", "image/jpeg", "image/jpg"];
    const uploadedImagesArray = [];

    uploadedImages.forEach((image) => {
      if (!fileTypes.includes(image.type)) {
        toast.warning("Please upload only image files.");
        inputRef.current.value = ""; // Reset the input field
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const imageData = {
          file: image,
          url: reader.result,
        };
        uploadedImagesArray.push(imageData);
        if (uploadedImagesArray.length === uploadedImages.length) {
          setImages((prevImages) => [...prevImages, ...uploadedImagesArray]);
          onImageUpload([...images, ...uploadedImagesArray]); // Passes all uploaded images as an array
        }
      };
      reader.readAsDataURL(image);
      // onImageUpload([...images, ...uploadedImagesArray]); // Passes all uploaded images as an array
    });
    inputRef.current.value = ""; // Reset the input field after processing
  };

  const handleImageRemove = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  return (
    <div className="text-center">
      <input
        style={{ textAlignLast: "center" }}
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        multiple
        ref={inputRef}
        onChange={handleImageChange}
      />
      <Row className="my-3">
        {images.map((image, index) => (
          <Col key={(Math.random() + 1).toString(36).substring(7)}>
            <img src={image.url} alt={`Uploaded ${index}`} />
            <Button onClick={() => handleImageRemove(index)}>Remove</Button>
          </Col>
        ))}
      </Row>
    </div>
  );
}

ImageUploader.propTypes = {
  onImageUpload: PropTypes.func.isRequired,
};

export default ImageUploader;
