import React, { useContext } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import "./packaging.css";
import ReactDatePicker from "react-datepicker";
import BasicTable from "views/tables/BasicTable";
import moment from "moment";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick, onChange }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      onChange={onChange}
      placeholder="Date Range for filter"
      style={{ marginTop: "10px", marginBottom: "10px", padding: "10px" }}
    >
      {children}
    </Input>
  )
);

function PackSummaryDB() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateSearch, setDateSearch] = React.useState([null, null]);
  const [dateRange2, setDateRange2] = React.useState([null, null]);
  const [startDate2, endDate2] = dateRange2;
  const [dateSearch2, setDateSearch2] = React.useState([null, null]);

  const [tableStatus, setTableStatus] = React.useState([]);
  const [tableLogsitic, setTableLogistic] = React.useState([]);
  const [tableReturn, setTableReturn] = React.useState([]);
  const [tableTradeShip, setTableTradeShip] = React.useState([
    {
      label: "Orders",
      total: 0,
    },
    {
      label: "Trade In",
      total: 0,
    },
  ]);

  const {
    isLoading: isLoadingSales,
    data: salesData,
    isError: isErrorSales,
  } = useQuery(
    [`/shop/${shopID}/Order/Sale/Dashboard`, dateSearch2],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/Sale/Dashboard`, {
        ...(dateSearch2[0] &&
          dateSearch2[1] && {
            params: {
              dateFrom: moment(dateSearch2[0]).format(),
              dateTo: moment(dateSearch2[1]).format(),
            },
          }),
      }).then((res) => res.data)
  );

  const { isLoading: isLoadingReturn, isError: isErrorReturn } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`, {
        ...(dateSearch2[0] &&
          dateSearch2[1] && {
            params: {
              dateFrom: moment(dateSearch2[0]).format(),
              dateTo: moment(dateSearch2[1]).format(),
            },
          }),
      }).then((res) => {
        const temp2 = [];

        res.data.forEach((obj) => {
          const combinedObj2 = temp2.find((item) => item.label === obj.Status);
          if (combinedObj2) {
            combinedObj2.total += 1;
          } else {
            temp2.push({
              label: obj.Status,
              total: 1,
            });
          }
        });
        setTableReturn(temp2);

        return res;
      })
  );

  const { isLoading: isLoadingOrder, isError: isErrorOrder } = useQuery(
    [`/shop/${shopID}/Packaging/Order`, dateSearch],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/Packaging`, {
        params: {
          dateFrom: dateSearch[0],
          dateTo: dateSearch[1],
        },
      }).then((res) => {
        const combinedObjects = [
          {
            label: "Checked",
            total: 0,
          },
          {
            label: "Packaging Received",
            total: 0,
          },
          {
            label: "Delivered",
            total: 0,
          },
        ];
        const temp2 = [];

        res.data.Result.forEach((obj) => {
          const statusLabel = obj.ShopOrderStatus;
          const combinedObj = combinedObjects.find(
            (item) => item.label === statusLabel
          );
          if (combinedObj) {
            combinedObj.total += 1;
          }

          const logisticLabel = obj.Courier;
          const logisticLabel2 = obj.DeliveryType;

          const combinedObj2 = temp2.find(
            (item) =>
              item.label === logisticLabel || item.label === logisticLabel2
          );
          if (combinedObj2) {
            combinedObj2.total += 1;
          } else {
            temp2.push({
              label: obj.Courier.trim() === "" ? obj.DeliveryType : obj.Courier,
              total: 1,
            });
          }
        });
        setTableStatus(combinedObjects);

        setTableLogistic(temp2);

        return res;
      })
  );

  const { isLoading: isLoadingShip, isError: isErrorShip } = useQuery(
    [`/shop/${shopID}/ShipBack`, dateSearch2],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`, {
        ...(dateSearch2[0] &&
          dateSearch2[1] && {
            params: {
              dateFrom: moment(dateSearch2[0]).format(),
              dateTo: moment(dateSearch2[1]).format(),
            },
          }),
        params: {
          internalStatus: "Pending Transfer To Dispatch",
        },
      }).then((res) =>
        Axios.get(`/shop/${shopID}/GetAllTradeInRequest`, {
          params: {
            internalStatus: "Trade In Parcel Pending Transferred to Dispatch",
          },
        }).then((res2) => {
          const result = res.data;
          res2.data?.ShopTradeInRequestGetAllDtoList?.forEach((element) => {
            result.push({
              ID: element.TradeInID,
              Invoice: element.TradeInID,
              CreatedDate: element.CreatedDate,
              Name: element.FullName,
              OrderSource: "Website",
              Contact: element.Contact,
              Logistic:
                element.MeganShipBackinfo === null
                  ? element.Logistic
                  : element.MeganShipBackinfo.Logistic,
              TrackingNo:
                element.MeganShipBackinfo === null
                  ? element.TrackingNo
                  : element.MeganShipBackinfo.TrackingNo,
              Status:
                element.Status === "TradeIn Pending"
                  ? element.Status
                  : element.TradeInProductList[0].InternalStatus,
            });
          });

          setTableTradeShip((prev) =>
            prev.map((obj) =>
              obj.label === "Orders" ? { ...obj, total: result.length } : obj
            )
          );
          return result;
        })
      )
  );

  const { isLoading: isLoadingTrade, isError: isErrorTrade } = useQuery(
    [`/shop/${shopID}/Packaging/GetAllTradeInRequest`, dateSearch2],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetAllTradeInRequest`, {
        ...(dateSearch2[0] &&
          dateSearch2[1] && {
            params: {
              dateFrom: moment(dateSearch2[0]).format(),
              dateTo: moment(dateSearch2[1]).format(),
            },
          }),
        params: {
          internalStatus: "Trade In Parcel Transferred to Packaging",
        },
      }).then((res2) => {
        const result = res2.data;
        setTableTradeShip((prev) =>
          prev.map((obj) =>
            obj.label === "Trade In"
              ? { ...obj, total: result.TotalCount }
              : obj
          )
        );
        return res2;
      })
  );

  if (
    isLoadingSales ||
    isLoadingOrder ||
    isLoadingReturn ||
    isLoadingShip ||
    isLoadingTrade
  ) {
    return (
      <div className="content">
        <Spinner />
      </div>
    );
  }

  if (
    isErrorSales ||
    isErrorOrder ||
    isErrorReturn ||
    isErrorShip ||
    isErrorTrade
  ) {
    return (
      <div className="content">
        <h3>Something goes wrong, try refresh the page</h3>
      </div>
    );
  }

  const searchDate = () => {
    setDateSearch(dateRange);
  };
  const searchDate2 = () => {
    setDateSearch2(dateRange2);
  };

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Row className="h-100" style={{ minWidth: "380px" }}>
        <Col>
          <Card className="card-dark border" style={{ minWidth: "350px" }}>
            <CardHeader>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  key="dateSelect1"
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  customInput={<ExampleCustomInput />}
                />
                <Button onClick={() => searchDate()}>Filter</Button>
              </div>
            </CardHeader>
            <CardBody>
              <div className="border my-3">
                <BasicTable
                  data={tableStatus}
                  columns={[
                    {
                      name: "Order",
                      selector: (row) => row.label,
                      wrap: true,
                    },
                    {
                      name: "Total Order",
                      selector: (row) => row.total,
                      wrap: true,
                    },
                  ]}
                  progressPending={isLoadingOrder}
                />
              </div>
              <div className="border my-3">
                <BasicTable
                  data={tableLogsitic}
                  columns={[
                    {
                      name: "Logistic",
                      selector: (row) => row.label,
                      wrap: true,
                    },
                    {
                      name: "Total Order",
                      selector: (row) => row.total,
                      wrap: true,
                    },
                  ]}
                  progressPending={isLoadingOrder}
                />
              </div>
              <div className="border my-3">
                <BasicTable
                  data={tableReturn}
                  columns={[
                    {
                      name: "Return",
                      selector: (row) => row.label,
                      wrap: true,
                    },
                    {
                      name: "Total Parcel",
                      selector: (row) => row.total,
                      wrap: true,
                    },
                  ]}
                  progressPending={isLoadingOrder}
                />
              </div>
              <div className="border my-3">
                <BasicTable
                  data={tableTradeShip}
                  columns={[
                    {
                      name: "Ship Back",
                      selector: (row) => row.label,
                      wrap: true,
                    },
                    {
                      name: "Total Parcel",
                      selector: (row) => row.total,
                      wrap: true,
                    },
                  ]}
                  progressPending={isLoadingShip || isLoadingTrade}
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card className="card-stats card-dark border">
            <CardHeader>
              <h2 className="text-center pt-2">Marketing Campaign</h2>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  key="dateSelect2"
                  clear
                  calendarClassName="h-100"
                  wrapperClassName="w-100"
                  isClearable
                  onChange={(update) => {
                    setDateRange2(update);
                    if (update === null) {
                      setDateSearch2([null, null]);
                    }
                  }}
                  close
                  startDate={startDate2}
                  endDate={endDate2}
                  selectsRange
                  maxDate={new Date()}
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button onClick={() => searchDate2()}>Filter</Button>
              </div>
            </CardHeader>
            <CardBody
              style={{
                overflowY: "scroll",
                height: "calc(100vh - 123px)",
              }}
            >
              {salesData?.Result.CouponList?.length === 0 ? (
                <div className="text-center">No Campaign</div>
              ) : (
                salesData &&
                salesData?.Result.CouponList?.map((couponList) => (
                  <div key={couponList.ID}>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <img
                            src={require("assets/img/bg/uriel-soberanes.jpg")}
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p
                            className="card-category text-left text-white font-weight-bold"
                            style={{ fontSize: "12px" }}
                          >
                            Details of Campaign:
                          </p>
                          <p
                            className="text-left text-white"
                            style={{ fontSize: "12px" }}
                          >
                            {couponList.Description}
                          </p>

                          <p />
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-2 pb-2">
                      <p className="card-category text-left text-white">
                        Discounts/ Gifts:
                      </p>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="card-category text-center bg-info">
                            <p className="text-white pl-3 pr-3">
                              {couponList.Code}
                            </p>
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p
                              className="card-category text-left text-white font-weight-bold"
                              style={{ fontSize: "12px" }}
                            >
                              {couponList.Name}
                            </p>
                            <p
                              className="text-left text-white"
                              style={{ fontSize: "12px" }}
                            >
                              <ul className="pl-3">
                                <li>
                                  {couponList.EnableMinPurchaseAmount === false
                                    ? "With no minimum order"
                                    : `With RM${couponList.MinPurchaseAmount} minimum order`}
                                </li>
                                <li>
                                  {`Duration ${moment(
                                    couponList.StartDateTime
                                  ).format("DD MMM YYYY")} - ${moment(
                                    couponList.EndDateTime
                                  ).format("DD MMM YYYY")}`}
                                </li>
                              </ul>
                            </p>

                            <p />
                          </div>
                        </Col>
                      </Row>
                      <hr
                        style={{
                          backgroudColor: "#51BCDA",
                          border: "1px solid #51BCDA",
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
              {salesData?.Result.PromotionList.length === 0
                ? null
                : salesData?.Result.PromotionList.map((promoList) => (
                    <div key={promoList.ID} className="p-3">
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <img
                              src={require("assets/img/bg/uriel-soberanes.jpg")}
                              alt=""
                            />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p
                              className="card-category text-left text-white font-weight-bold"
                              style={{ fontSize: "12px" }}
                            >
                              Details of Campaign:
                            </p>
                            <p
                              className="text-left text-white"
                              style={{ fontSize: "12px" }}
                            >
                              {promoList.Description}
                            </p>

                            <p />
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-2 pb-2">
                        <p className="card-category text-left text-white">
                          Discounts/ Gifts:
                        </p>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="card-category text-center bg-info">
                              <p className="text-white pl-3 pr-3">
                                {promoList.Name}
                              </p>
                            </div>
                            <div className="d-flex flex-wrap">
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; website
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Shopee
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Retails
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Facebook
                              </p>
                              <p
                                className="card-category text-left text-white mr-2"
                                style={{ lineHeight: "1px" }}
                              >
                                <i
                                  className="fa fa-bookmark"
                                  aria-hidden="true"
                                  style={{ color: "#51BCDA" }}
                                />
                                &nbsp; Lazada
                              </p>
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p
                                className="card-category text-left text-white font-weight-bold"
                                style={{ fontSize: "12px" }}
                              >
                                {promoList.Name}
                              </p>
                              <p
                                className="text-left text-white"
                                style={{ fontSize: "12px" }}
                              >
                                <ul className="pl-3">
                                  <li>
                                    {promoList.Discount
                                      .EnableMinPurchaseAmount === false
                                      ? "With no minimum order"
                                      : `With RM${promoList.Discount.MinPurchaseAmount} minimum order`}
                                  </li>
                                  <li>
                                    {`Duration ${moment(
                                      promoList.StartDateTime
                                    ).format("DD MMM YYYY")} - ${moment(
                                      promoList.EndDateTime
                                    ).format("DD MMM YYYY")}`}
                                  </li>
                                </ul>
                              </p>

                              <p />
                            </div>
                          </Col>
                        </Row>
                        <hr
                          style={{
                            backgroudColor: "#51BCDA",
                            border: "1px solid #51BCDA",
                            width: "100%",
                          }}
                        />
                      </div>
                    </div>
                  ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PackSummaryDB;
