import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import ApiContext from "./ApiContext";
import UserContext from "./UserContext";

const ShopContext = React.createContext(null);

export function ShopProvider({ children }) {
  const { user } = useContext(UserContext);
  const Axios = useContext(ApiContext);

  // selctShopID is refer to the shop ID that is focusing currently
  const [selctShopID, setSelctShopID] = React.useState(
    JSON.parse(localStorage.getItem("selectSID")) !== null
      ? JSON.parse(localStorage.getItem("selectSID"))
      : null
  );

  const [department, setDepartment] = React.useState(
    JSON.parse(localStorage.getItem("deptProfile")) !== null
      ? JSON.parse(localStorage.getItem("deptProfile"))
      : null
  );

  // const { isSuccess, data } = useQuery(
  //   `User`,
  //   async () => Axios.get(`/user/Account`),
  //   {
  //     enabled: user !== null, // only load if user !== null
  //   }
  // );

  const { isSuccess, data } = useQuery(
    `User`,
    async () => Axios.get(`/shop/${selctShopID.ID}/Account`),
    {
      enabled: user !== null, // only load if user !== null
    }
  );

  const { isSuccess: isShopSuccess, data: shopData } = useQuery(
    "shop",
    async () => Axios.get(`/public/Shop`)
  );

  const focusShopID = async (shop) => {
    localStorage.setItem("selectSID", JSON.stringify(shop));
    setSelctShopID(shop);
  };

  // ShopIDs is refer to all the shops own by the seller
  // const ShopIDs = useMemo(() => {
  //   if (isSuccess && data.data.IsSeller) {
  //     localStorage.setItem(
  //       "selectSID",
  //       JSON.stringify(data.data.ShopManageable[0])
  //     );
  //     return data.data.ShopManageable;
  //   }
  //   return null;
  // }, [data]);

  const ShopIDs = useMemo(() => {
    if (isShopSuccess && shopData.data.Result) {
      localStorage.setItem(
        "selectSID",
        JSON.stringify(shopData.data.Result[0])
      );
      return shopData.data.Result;
    }
    return null;
  }, [shopData]);

  const deptProfile = useMemo(() => {
    if (isSuccess && data.data) {
      localStorage.setItem(
        "deptProfile",
        JSON.stringify(data.data.ShopMemberDepartmentInfo)
      );
      return data.data.ShopMemberDepartmentInfo;
    }
    return null;
  }, [data]);

  // for default, if the ShopIDs is loaded first time, auto select the first as default selectedID
  React.useEffect(() => {
    if (selctShopID === null && ShopIDs !== null) {
      localStorage.setItem("selectSID", JSON.stringify(ShopIDs[0]));
      setSelctShopID(ShopIDs[0]);
    }
  }, [ShopIDs]);

  React.useEffect(() => {
    if (department === null && deptProfile !== null && user !== null) {
      localStorage.setItem("deptProfile", JSON.stringify(deptProfile));
      setDepartment(department);
    }
  }, [department, user]);

  const contextValue = useMemo(
    () => ({
      ShopIDs,
      selctShopID,
      setSelctShopID,
      focusShopID,
      department,
    }),
    [user, ShopIDs, selctShopID]
  );

  return (
    <ShopContext.Provider value={contextValue}>{children}</ShopContext.Provider>
  );
}

ShopProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ShopContext;
