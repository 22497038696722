import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup, Col, Label, Row } from "reactstrap";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ResetPasswordLink from "./ResetPasswordLink";

function UserProfile(props) {
  const { userDetails } = props;
  const [leftRight, setLeftRight] = useState({
    Ring: false,
    Bangles: false,
    Bracelet: false,
  }); // default false = left

  const focusImg = 0;
  //   const detailEdit = false;

  const HandImgs = [
    require("assets/img/Page/Profile/left_hand_1.png"),
    require("assets/img/Page/Profile/left_hand_2.png"),
    require("assets/img/Page/Profile/left_hand_3.png"),
    require("assets/img/Page/Profile/left_hand_4.png"),
    require("assets/img/Page/Profile/left_hand_5.png"),
    require("assets/img/Page/Profile/left_twist.png"),
    require("assets/img/Page/Profile/right_hand_1.png"),
    require("assets/img/Page/Profile/right_hand_2.png"),
    require("assets/img/Page/Profile/right_hand_3.png"),
    require("assets/img/Page/Profile/right_hand_4.png"),
    require("assets/img/Page/Profile/right_hand_5.png"),
    require("assets/img/Page/Profile/right_twist.png"),
  ];

  const focusField = (e) => {
    e.target.parentElement.classList.toggle("opacity-50");
  };

  return (
    <div className="content m-3 mt-5">
      <Row
        className="justify-content-between m-3"
        style={{
          fontSize: "15px",
          border: "1px solid #51BCDA",
          borderRadius: "11px",
        }}
      >
        <Col md="5">
          <div className="m-3">
            <p>User ID : {userDetails?.data.ID}</p>
            <p>Status : {userDetails?.data.Status}</p>
            <p>Role : {userDetails?.data.Role}</p>
            <p>
              Available E-wallet : &nbsp;
              <CurrencyFormat
                decimal={2}
                price={userDetails?.data.Ewallet.toFixed(2)}
                currency="MYR"
              />
            </p>
            <p>
              NewsLetter : &nbsp;
              {userDetails?.data.NewsLetter === false ? (
                <span>No Subscription</span>
              ) : (
                <span>Subscriped</span>
              )}
            </p>
          </div>
        </Col>
        <Col md="5">
          <div className="m-3">
            <p>Name : {userDetails?.data.FullName}</p>
            <p>Email : {userDetails?.data.Email}</p>
            <p>Contact : {userDetails?.data.Contact}</p>
            <p>Gender : {userDetails?.data.Gender}</p>
            <p>DOB : {userDetails?.data.DateOfBirth}</p>
            <Button
              id="itemhover"
              key={userDetails?.data.ID}
              className="pl-2 pr-2 btn btn-primary"
              tag={Link}
              to={`/shopowner/loyaltyInfoUser/view/${userDetails?.data.ID}`}
            >
              Loyalty Point Statement
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-between mt-5">
        <Col md="12">
          {/* Ring size */}
          <Row
            className=" pt-2 font-weight-bold m-3"
            style={{
              border: "1px solid #51BCDA",
              borderRadius: "11px",
            }}
          >
            <Col className="align-self-center" sm="3">
              <Label htmlFor="RingSize" className="text-white">
                Ring Size{" "}
              </Label>
            </Col>
            <Col sm="9">
              <div className="size-edit-box" tabIndex={-2}>
                <div>
                  <div className="font-weight-bold text-center">
                    What ring size you wear? (in cm)
                  </div>
                </div>
                <div className="text-center">
                  <ButtonGroup>
                    <Button
                      className={
                        leftRight.Ring
                          ? "button-template"
                          : "button-dark-template"
                      }
                      onClick={() =>
                        setLeftRight({ ...leftRight, Ring: false })
                      }
                    >
                      Left Hand
                    </Button>
                    <Button
                      className={
                        !leftRight.Ring
                          ? "button-template"
                          : "button-dark-template"
                      }
                      onClick={() => setLeftRight({ ...leftRight, Ring: true })}
                    >
                      Right Hand
                    </Button>
                  </ButtonGroup>
                </div>
                <Row
                  className="text-center align-items-center p-3 "
                  onFocus={(e) => {
                    focusField(e);
                  }}
                  onBlur={(e) => {
                    focusField(e);
                  }}
                >
                  <Col sm="12">
                    <div className="pt-2 font-weight-bold d-flex justify-content-center">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Middle Finger</Label>
                      </div>
                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightMiddleFinger}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftMiddleFinger}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col sm="12" md="5">
                    <div className="pt-2 font-weight-bold d-flex justify-content-end">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Ring Finger</Label>
                      </div>

                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightRingFinger}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftRingFinger}
                        </div>
                      )}
                    </div>
                    <div className="pt-2 font-weight-bold d-flex justify-content-end">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Little Finger</Label>
                      </div>

                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightLittleFinger}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftLittleFinger}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="2" sm="12" className="hand-img">
                    <img
                      className="img-fluid"
                      src={HandImgs[leftRight.Ring ? 6 + focusImg : focusImg]}
                      alt=""
                    />
                  </Col>
                  <Col sm="12" md="5">
                    <div className="pt-2 font-weight-bold d-flex justify-content-right">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Pointer Finger</Label>
                      </div>

                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightPointerFinger}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftPointerFinger}
                        </div>
                      )}
                    </div>
                    <div className="pt-2 font-weight-bold d-flex justify-content-right">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Thumb Finger</Label>
                      </div>

                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightThumbFinger}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftThumbFinger}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Bangles size */}
          <Row
            className=" pt-2 font-weight-bold m-3"
            style={{
              border: "1px solid #51BCDA",
              borderRadius: "11px",
            }}
          >
            <Col className="align-self-center" sm="3">
              <Label htmlFor="RingSize" className="text-white">
                Bangles Size
              </Label>
            </Col>
            <Col sm="9">
              <div className="size-edit-box">
                <div>
                  <div className="font-weight-bold text-center">
                    What bangles size you wear? (in cm)
                  </div>
                  <div className="text-center">
                    <ButtonGroup>
                      <Button
                        className={
                          leftRight.Bangles
                            ? "button-template"
                            : "button-dark-template"
                        }
                        onClick={() =>
                          setLeftRight({ ...leftRight, Bangles: false })
                        }
                      >
                        Left Hand
                      </Button>
                      <Button
                        className={
                          !leftRight.Bangles
                            ? "button-template"
                            : "button-dark-template"
                        }
                        onClick={() =>
                          setLeftRight({ ...leftRight, Bangles: true })
                        }
                      >
                        Right Hand
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                <Row className="text-center justify-content-end p-3 ">
                  <Col md="2" sm="12" className="hand-img">
                    <img
                      className="img-fluid"
                      src={HandImgs[leftRight.Bangles ? 6 + 5 : 5]}
                      alt=""
                    />
                  </Col>
                  <Col sm="12" md="5">
                    <div className="pt-2 font-weight-bold d-flex justify-content-right">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Diameter</Label>
                      </div>
                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightBangleSize}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftBangleSize}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Bracelet size */}
          <Row
            className=" pt-2 font-weight-bold m-3"
            style={{
              border: "1px solid #51BCDA",
              borderRadius: "11px",
            }}
          >
            <Col className="align-self-center" sm="3">
              <Label htmlFor="RingSize" className="text-white">
                {" "}
                Bracelet Size{" "}
              </Label>
            </Col>
            <Col sm="9">
              <div className="size-edit-box">
                <div>
                  <div className="font-weight-bold text-center">
                    What bracelet size you wear? (in cm)
                  </div>
                  <div className="text-center">
                    <ButtonGroup>
                      <Button
                        className={
                          leftRight.Bracelet
                            ? "button-template"
                            : "button-dark-template"
                        }
                        onClick={() =>
                          setLeftRight({ ...leftRight, Bracelet: false })
                        }
                      >
                        Left Hand
                      </Button>
                      <Button
                        className={
                          !leftRight.Bracelet
                            ? "button-template"
                            : "button-dark-template"
                        }
                        onClick={() =>
                          setLeftRight({ ...leftRight, Bracelet: true })
                        }
                      >
                        Right Hand
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
                <Row className="text-center justify-content-end p-3 ">
                  <Col md="2" sm="12" className="hand-img">
                    <img
                      className="img-fluid"
                      src={HandImgs[leftRight.Bracelet ? 6 + 5 : 5]}
                      alt=""
                    />
                  </Col>
                  <Col sm="12" md="5">
                    <div className="pt-2 font-weight-bold d-flex justify-content-right ">
                      <div className="align-self-center pr-2">
                        <Label className="mb-0">Length</Label>
                      </div>
                      {focusImg === 2 ? (
                        <div>
                          {userDetails?.data.HandMeasurement.RightBraceletSize}
                        </div>
                      ) : (
                        <div>
                          {userDetails?.data.HandMeasurement.LeftBraceletSize}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row
            className=" pt-2 font-weight-bold m-3"
            style={{
              border: "1px solid #51BCDA",
              borderRadius: "11px",
            }}
          >
            <Col>
              <div>Password Reset :</div>
              <ResetPasswordLink email={userDetails?.data.Email} />
              <small className="mb-5">
                Sent &nbsp;{userDetails?.data.FullName} a link to reset their
                password. This will not change their password, nor will it force
                a change
              </small>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
UserProfile.propTypes = {
  userDetails: PropTypes.string.isRequired,
};

export default UserProfile;
