/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";

// reactstrap components
import { Col, Button, Row, Spinner, Table, Fade } from "reactstrap";

// core components
import BasicTable from "views/tables/BasicTable";
import "./live.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import html2canvas from "html2canvas";
import moment from "moment";
import { toast } from "react-toastify";

function InvoiceDetail() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [pdfProcessing, setPdfProcessing] = React.useState(false);
  const { type, id } = useParams();
  const history = useHistory();

  const printRef = React.useRef();

  const { isLoading, data, isSuccess, error, isError } = useQuery(
    [`/shop/${shopID}/Invoice`, type, id],
    async () =>
      shopID !== "0" && type === "marketplace"
        ? Axios.get(`/shop/${shopID}/MarketPlace/Order/${id}`).then(
            (res) => res.data.Result
          )
        : Axios.get(`/shop/${shopID}/Live/Order/${id}`).then(
            (res) => res.data.Result
          )
  );

  const exportPDF = async () => {
    const content = printRef.current;
    content.classList.add("pdf-white");

    setPdfProcessing(true);
    html2canvas(content).then((canvas) => {
      // Few necessary setting options
      const imgWidth = 208;
      // const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const contentDataURL = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "letter"); // A4 size page of PDF
      pdf.addImage(contentDataURL, "PNG", 5, 10, imgWidth, 80);

      content.classList.remove("pdf-white");
      pdf.save(`${type}_Invoice_${moment(new Date()).format("DD-MM-YYYY")}`);
      setPdfProcessing(false); // Generated PDF
    });
  };

  // const exportPDF = async () => {
  //   if (shopID !== "0") {
  //     const pdfFile = await Axios.get(
  //       `/shop/${shopID}/MarketPlace/MarketPlaceDetails/${id}/PDF`
  //     ).catch((err) => toast.error(err.response.data.MESSAGE));

  //     const alink = document.createElement("a");
  //     alink.href = pdfFile.data;
  //     alink.target = "_blank";
  //     alink.download = `${type}_${moment(new Date(), "DDMMYYYY")}.pdf`;
  //     alink.setAttribute(
  //       "download",
  //       `${type}_${moment(new Date(), "DDMMYYYY")}.pdf`
  //     );
  //     alink.click();
  //   }
  // };

  if (isLoading || pdfProcessing) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Fade>
          <Spinner />
        </Fade>
      </div>
    );
  }
  if (isError) {
    return (
      <div className="content align-items-center justify-content-center">
        <h3>{error.response.data.MESSAGE}</h3>
        <p>Something goes wrong, try refresh the page</p>
      </div>
    );
  }

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2"
        onClick={() => history.goBack()}
        size="sm"
      >
        Back
      </Button>
      <div className="border">
        <div ref={printRef} id="print-area" className="mt-2 m-3">
          <h3>Invoice</h3>
          <Row>
            <Col xs="12" sm="6">
              <div>Date: {moment(data.CreatedDate).format("DD/MM/YYYY")}</div>
              <div>Order Number: {data.OrderID}</div>
              <div>Invoice Number: {data.Invoice}</div>
            </Col>
            <Col xs="12" sm="6" className="mt-3 mt-sm-0">
              <div className="text-sm-last">Status: {data.OrderStatus}</div>
            </Col>
          </Row>

          {/* special case */}
          {type === "order" && (
            <div>
              <Row className="py-3">
                <Col xs="12" sm="6">
                  <div className="font-weight-bold">
                    <u>Shipping Details</u>
                  </div>
                  <div>{data.ShippingAddress.FirstLine}</div>
                  <div>{data.ShippingAddress.SecondLine}</div>
                  <div>{data.ShippingAddress.Postcode}</div>
                  <div>{data.ShippingAddress.City}</div>
                  <div>{data.ShippingAddress.State}</div>
                  <div>{data.ShippingAddress.Country}</div>
                </Col>
                <Col xs="12" sm="6" className="mt-3 mt-sm-0">
                  <div className="font-weight-bold">
                    <u>Billing Details</u>
                  </div>
                  <div>{data.BillingAddress.FirstLine}</div>
                  <div>{data.BillingAddress.SecondLine}</div>
                  <div>{data.BillingAddress.Postcode}</div>
                  <div>{data.BillingAddress.City}</div>
                  <div>{data.BillingAddress.State}</div>
                  <div>{data.BillingAddress.Country}</div>
                </Col>
              </Row>
              <Row>
                <Col xs="12" sm="6" md="3" className="mt-3 mt-sm-0">
                  <div className="font-weight-bold">
                    <u>Email</u>
                  </div>
                  <div>{data.Email}</div>
                </Col>
                <Col xs="12" sm="6" md="3" className="mt-3 mt-sm-0">
                  <div className="font-weight-bold">
                    <u>Contact</u>
                  </div>
                  <div>{data.Contact}</div>
                </Col>
                <Col xs="12" sm="6" md="3" className="mt-3 mt-sm-0">
                  <div className="font-weight-bold">
                    <u>Payment Method</u>
                  </div>
                  <div>{data.PaymntMethod}</div>
                </Col>
                <Col xs="12" sm="6" md="3" className="mt-3 mt-sm-0">
                  <div className="font-weight-bold">
                    <u>Shipping</u>
                  </div>
                  <div>RM{data.ShippingFee}</div>
                </Col>
              </Row>
            </div>
          )}
          {type === "marketplace" && (
            <div className="mt-3">
              <div>Name: {data.Name}</div>
              <div>Order Source: {data.OrderSource}</div>
            </div>
          )}

          {isSuccess && (
            <div id="packaging-report" className="mt-5 w-100 overflow-auto">
              <Table bordered style={{ minWidth: "450px", color: "white" }}>
                <tbody>
                  <tr
                    className="text-center text-white"
                    style={{ backgroundColor: "#899499" }}
                  >
                    <td>PRODUCT</td>
                    <td>QUANTITY</td>
                    <td>TOTAL</td>
                  </tr>
                  {data.ProductList.map((row) => (
                    <tr key={row.VariationSKU}>
                      <td>
                        <div className="py-2 text-left">
                          <div>
                            {row.Name}: {row.VariationSKU}
                          </div>
                          <div>Weight: {row.Weight}</div>
                          <div>Size: {row.Size}</div>
                        </div>
                      </td>
                      <td className="text-center">{row.Quantity}</td>
                      <td className="text-center">{row.Price}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="text-right">Total</td>
                    <td className="text-center">{data.Quantity}</td>
                    <td className="text-center">{data.TotalOrderCost}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button onClick={() => exportPDF()}>Export PDF</Button>
      </div>
    </div>
  );
}

export default InvoiceDetail;
