import React, { useContext, useState } from "react";
import ApiContext from "services/ApiContext";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useMutation } from "react-query";

function ShopTag(props) {
  const { setMessage, setVisible, shopData, shopID } = props;
  const Axios = useContext(ApiContext);
  const [tagEdit, setTagEdit] = useState(false);
  const [tagField, setTagField] = useState("");

  const onShowAlert = () => {
    setVisible(true);
    window.setTimeout(() => {
      setVisible(false);
    }, 3000);
  };

  const addTag = () => {
    Axios.post(`/shop/${shopID}/Tag`, tagField, {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        setMessage({
          data: `${err.response.data.MESSAGE}`,
          type: "alert-danger",
        });
        onShowAlert();
      });
  };
  const { mutate, isLoading } = useMutation(addTag);
  const handleSubmit = async (e) => {
    e.preventDefault();
    await mutate(addTag);
  };
  const deleteTag = async (tg) => {
    Axios.delete(`/shop/${shopID}/Tag`, {
      headers: {
        "content-type": "application/json",
      },
      data: tg,
    })
      .then((res) => {
        if (res.status === 200) {
          setMessage({
            data: `${res.data.MESSAGE}`,
            type: "alert-success",
          });
          onShowAlert();
        }
      })
      .catch((err) => {
        let msg = "";
        err.response.data.forEach((element) => {
          msg += `${element.MESSAGE}\n`;
        });
        setMessage({
          data: msg,
          type: "alert-danger",
        });
        onShowAlert();
      });
  };

  return (
    <div>
      <Form>
        <Card className="card-dark">
          <CardHeader>
            <FormGroup className="pb-0">
              {!tagEdit ? (
                <>
                  <a
                    href="#edit"
                    className="float-right font-weight-normal"
                    onClick={() => setTagEdit(true)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true" />
                    Add
                  </a>
                  <CardTitle className="font-weight-bold" tag="h5">
                    Shop Tag
                  </CardTitle>
                </>
              ) : (
                <CardTitle className="font-weight-bold" tag="h5">
                  Add Shop Tag
                </CardTitle>
              )}
            </FormGroup>
          </CardHeader>
          <CardBody>
            {!tagEdit && (
              <div className="">
                <Col lg="6" md="12" sm="6" className="">
                  {shopData.data.ProductTags.map((t) => (
                    <ul>
                      <li>
                        {t}
                        <Button
                          className="btn-link text-danger"
                          value={t}
                          onClick={(e) => {
                            deleteTag(e.target.value);
                          }}
                        >
                          x
                        </Button>
                      </li>
                    </ul>
                  ))}
                </Col>
              </div>
            )}
            {tagEdit && (
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Label htmlFor="newtags">Shop tags</Label>
                    <Input
                      className=""
                      name="newtags"
                      type="text"
                      value={tagField}
                      onChange={(e) => {
                        setTagField(e.target.value);
                      }}
                      plaintext={!tagEdit}
                      readOnly={!tagEdit}
                    />
                    <div className="text-center pt-2 font-weight-bold justify-content-end">
                      <Button
                        className="mt-3 button-dark-template"
                        disabled={isLoading}
                        loading={isLoading}
                        onClick={handleSubmit}
                      >
                        Upload
                      </Button>

                      <Button
                        className=" ml-3 mt-3 button-template"
                        onClick={() => setTagEdit(false)}
                      >
                        Cancel
                      </Button>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </CardBody>
        </Card>
      </Form>
    </div>
  );
}
ShopTag.propTypes = {
  shopData: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  setMessage: PropTypes.string.isRequired,
  setVisible: PropTypes.string.isRequired,
  shopID: PropTypes.string.isRequired,
};
export default ShopTag;
