/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  Modal,
  ModalHeader,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customerservice.css";
import moment from "moment";
import CustomButton from "components/CustomButton/CustomButton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import CSInvoiceOrder from "./CSInvoiceOrder";
import AddRefundImage from "./AddRefundImage";
import ReturnRefundImage from "./ReturnRefundImage";

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function ReturnRefund() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);

  const [dropItemList, setDropItemList] = React.useState({
    orderSource: [],
    status: [],
  });

  const [dropFilter, setDropFilter] = React.useState({
    logistic: "Logistic",
    status: "Status",
  });

  const [dropOpen, setDropOpen] = React.useState("");

  const dropDownOptionGet = (data, id) => {
    const set = new Set();
    data.data.forEach((row) => {
      if (row[id] !== "") set.add(row[id]);
    });
    return [...set.values()];
  };
  const { isLoading, data, isSuccess, refetch } = useQuery(
    `/shop/${shopID}/GetFilteredReturnRequest`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/GetFilteredReturnRequest`).then((res) => {
        setDropItemList({
          logistic: dropDownOptionGet(res, "Logistic"),
          status: dropDownOptionGet(res, "Status"),
        });
        setFilterDataResult(res.data.reverse());
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const columns = [
    {
      name: "Order No",
      selector: (row) => <p>{`#${row.OrderID}`}</p>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Order Date",
      selector: (row) => (
        <div>
          <p>{moment(row.OrderCreatedDate).format("DD/MM/YYYY")}</p>
          {moment(row.OrderCreatedDate).format("hh:mm A")}
        </div>
      ),
    },
    {
      name: "Reference No",
      selector: (row) => <p>{`#${row.ID}`}</p>,
      sortable: true,
      wrap: true,
    },
    {
      name: "Return Request Date",
      selector: (row) => (
        <div>
          <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
          {moment(row.CreatedDate).format("hh:mm A")}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: "Contact No",
      selector: (row) => row.Contact,
      sortable: true,
      wrap: true,
    },
    {
      name: "Logistic",
      selector: (row) => (
        <div>
          <p>{row.Logistic}</p>
          {row.TrackingNo}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Product",
      selector: (row) => (
        <div>
          <p>{row.Product.ProductEngName}</p>
          {row.Product.VariationList.map((item) => (
            <>
              <p>{item.VariationSKU}</p>
              <p>{`Weight(g): ${item.Weight}`}</p>
            </>
          ))}
        </div>
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => row.Status,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => {
        const [invoiceModal, setInvoiceModal] = useState({
          orderID: "",
          open: false,
        });

        const [showModal, setShowModal] = useState({
          rID: "",
          open: false,
        });
        const [imageModal, setImageModal] = useState({
          rID: "",
          open: false,
        });

        if (!row) return null;

        return (
          <div>
            <CustomButton
              onClick={() =>
                setInvoiceModal({
                  orderID: row.OrderID,
                  open: !invoiceModal.open,
                })
              }
              icon="fa fa-file-text"
              title="Invoice"
            />

            {row.Status === "Item Received from Customer" ? (
              <p>
                Photos
                <CustomButton
                  onClick={() =>
                    setShowModal({
                      rID: row.ID,
                      open: !showModal.open,
                    })
                  }
                  icon=""
                  title=""
                  text="Add"
                  color="btn-primary"
                  size="btn-sm"
                />
              </p>
            ) : (
              <CustomButton
                onClick={() =>
                  setImageModal({
                    rID: row.ID,
                    open: !showModal.open,
                  })
                }
                icon="fa fa-picture-o"
                title="Photos"
              />
            )}

            {row.Status === "Refund Completed" ? (
              <Button
                id="itemhover"
                color="link"
                key={row.ID}
                className="pl-0 pr-0"
                tag={Link}
                to={`/shopowner/ReturnRefund/viewRefunddetails/${row.OrderID}/${row.ID}`}
              >
                <i
                  className="fa fa-search-plus"
                  aria-hidden="true"
                  style={{ color: "white", fontSize: "18px" }}
                  title="Order Detais"
                />
              </Button>
            ) : null}

            {/* Modal for invoice detail */}
            <Modal
              size="xl"
              isOpen={invoiceModal.open}
              toggle={() =>
                setInvoiceModal({
                  ...invoiceModal,
                  open: !invoiceModal.open,
                })
              }
            >
              <div>
                <CustomButton
                  onClick={() =>
                    setInvoiceModal({
                      ...invoiceModal,
                      open: false,
                    })
                  }
                >
                  x
                </CustomButton>
              </div>

              <ModalBody>
                <CSInvoiceOrder invoiceID={invoiceModal.orderID} />
              </ModalBody>
            </Modal>
            {/* End of Modal for invoice details */}

            {/* Modal for Add CS image */}
            <Modal
              size="lg"
              isOpen={showModal.open}
              toggle={() =>
                setShowModal({
                  ...showModal,
                  open: !showModal.open,
                })
              }
            >
              <ModalHeader>
                <Button
                  className="btn btn-primary float-right"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setShowModal({
                      ...showModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
                Add Photos
              </ModalHeader>
              <ModalBody>
                <AddRefundImage
                  refetch={refetch}
                  rID={showModal.rID}
                  setShowModal={setShowModal}
                  showModal={showModal}
                />
              </ModalBody>
            </Modal>
            {/* End of Modal for Add CS image */}
            <Modal
              size="md"
              isOpen={imageModal.open}
              toggle={() =>
                setImageModal({
                  ...imageModal,
                  open: !imageModal.open,
                })
              }
            >
              <div>
                <Button
                  className="btn btn-primary float-right mr-5"
                  style={{ fontSize: "12px" }}
                  size="sm"
                  onClick={() =>
                    setImageModal({
                      ...imageModal,
                      open: false,
                    })
                  }
                >
                  x
                </Button>
              </div>

              <ModalBody>
                <ReturnRefundImage rID={imageModal.rID} />
              </ModalBody>
            </Modal>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const dropdownToggle = (filter) => {
    if (filter === dropOpen) {
      setDropOpen("");
    } else {
      setDropOpen(filter);
    }
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setDropFilter({
      logistic: "Logistic",
      status: "Status",
    });
    setFilterDataResult(data.data);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          dropFilter.logistic !== "Logistic"
            ? item.Logistic === dropFilter.logistic
            : item
        )
        .filter((item) =>
          dropFilter.status !== "Status"
            ? item.Status === dropFilter.status
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.OrderID &&
              item.OrderID.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Refund Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Filter:
            </span>
            <Dropdown
              isOpen={dropOpen === "Logistic"}
              toggle={() => dropdownToggle("Logistic")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.logistic}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      logistic: "Logistic",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.logistic?.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, logistic: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropOpen === "Status"}
              toggle={() => dropdownToggle("Status")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.status}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      status: "Status",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.status.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, status: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button className="ml-1" onClick={() => filterData()}>
              Filter
            </Button>
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default ReturnRefund;
