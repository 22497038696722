import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import PropTypes from "prop-types";

function BasicTable({ columns, data, ...parameter }) {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
        padding: "2rem",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  return (
    <DataTable
      {...parameter}
      columns={columns}
      data={data}
      customStyles={customStyles}
      theme="solarized"
    />
  );
}
BasicTable.defaultProps = {
  parameter: undefined,
};

BasicTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array, undefined])
    .isRequired,
  columns: PropTypes.arrayOf.isRequired,
  parameter: PropTypes.node,
};

export default BasicTable;
