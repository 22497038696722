/* eslint-disable new-cap */
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

function orderReport() {
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const { data: orreport } = useQuery(
    [`/shop/${shopID}/Order/Sale/Order/Report`],
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/Sale/Order/Report`, {
        params: {
          limitPerPage: 9999,
        },
      })
  );
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  function handleSearchInputChange(event) {
    setSearchInput(event.target.value);
  }
  const filteredData = orreport?.data.Result.filter((Oreport) => {
    const searchTerms = Object.values(Oreport).join(" ").toLowerCase();
    return searchTerms.includes(searchInput.toLowerCase());
  });
  useEffect(() => {
    setSearchResults(filteredData);
  }, [searchInput, filteredData]);

  return (
    <div className="content ">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        tag={Link}
        to="/shopOwner/sales/dashboard"
        size="sm"
      >
        Back
      </Button>

      <div className="content">
        <input
          type="text"
          onChange={handleSearchInputChange}
          placeholder=" Search..."
          style={{
            backgroundColor: "#27293D",
            borderBottom: "1px solid #51BCDA",
            width: "25%",
            height: "40px",
            color: "white",
          }}
          className="m-3 border-right-0 border-top-0 border-left-0"
        />
        <Card className="card-stats card-dark">
          <CardHeader>
            <CardTitle tag="h4">Picked Item</CardTitle>
          </CardHeader>
          <CardBody>
            <Table bordered>
              <thead
                style={{
                  backgroundColor: "#51BCDA",
                  color: "white",
                }}
              >
                <tr className="text-center">
                  <th className="border-right">Order No Invoice </th>
                  <th className="border-right">Categories</th>
                  <th className="border-right">Serial Number</th>
                  <th className="border-right">Size</th>
                  <th className="border-right">Wight(gm)</th>
                  <th className="border-right">Status</th>
                  <th className="border-right">PIC Received</th>
                  <th>PIC Rejected</th>
                </tr>
              </thead>
              <tbody>
                {searchResults?.map((Oreport) => (
                  <tr className="text-center" key={Oreport.OrderID}>
                    <td>
                      <p>{`# ${Oreport.OrderID}`}</p>
                      <p>{Oreport.Invoice}</p>
                    </td>
                    <td>{Oreport.Category}</td>
                    <td>{Oreport.SerialNo}</td>
                    <td>{Oreport.Size}</td>
                    <td>{Oreport.Weight}</td>
                    <td>{Oreport.Status}</td>
                    <td>{Oreport.AcceptedBy}</td>
                    <td>{Oreport.RejectedBy}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

export default orderReport;
