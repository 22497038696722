import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { Button } from "reactstrap";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function ImageUpload(props) {
  const { avatar, imageCallback, field, perviouseUpload, reset, setReset } =
    props;
  const reader = new FileReader();
  const [file, setFile] = React.useState(perviouseUpload);

  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    avatar ? defaultAvatar : defaultImage
  );
  if (perviouseUpload) {
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(perviouseUpload);
  }
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    const filePtr = e.target.files[0];
    reader.onloadend = () => {
      if (Array.isArray(file)) {
        setFile([...file, filePtr]);
      } else {
        // Handle the case where file is not an array
        setFile([file, filePtr]);
      }
      if (imagePreviewUrl === defaultImage) {
        setImagePreviewUrl([{ url: reader.result }]);
      } else {
        setImagePreviewUrl([...imagePreviewUrl, { url: reader.result }]);
      }
    };
    reader.readAsDataURL(filePtr);
    e.target.value = null;

    // pass back data
    imageCallback(field, filePtr);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  const handleRemove = () => {
    setImagePreviewUrl(avatar ? defaultAvatar : defaultImage);
    setFile(null);
    fileInput.current.value = null;
    imageCallback(field, undefined);
    setReset(false);
  };

  React.useEffect(() => {
    if (reset) {
      handleRemove();
    }
  }, [reset, perviouseUpload]);

  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={`thumbnail${avatar ? " img-circle" : ""}`}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null ? (
          <Button
            className="btn-round"
            color="default"
            outline
            onClick={handleClick}
          >
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button
              className="btn-round"
              outline
              color="default"
              onClick={handleClick}
            >
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button
              color="danger"
              className="btn-round btn-link"
              onClick={handleRemove}
            >
              <i className="fa fa-times" />
              Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.defaultProps = {
  avatar: undefined,
  perviouseUpload: null,
  reset: false,
};

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  perviouseUpload: PropTypes.node,
  imageCallback: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  reset: PropTypes.bool,
  setReset: PropTypes.func.isRequired,
};

export default ImageUpload;
