import React, { useContext, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import ShopContext from "services/ShopContext";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { toast } from "react-toastify";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import UploadImage from "./UploadImage";
import UpdateMerchandiseInfo from "./UpdateMerchandiseInfo";

function ViewMerchandiseDetails() {
  const { id } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { data: merchandise } = useQuery(
    `/shop/${shopID}/Merchandise/GetSingleMerchandise/${id}`,
    async () =>
      Axios.get(`/shop/${shopID}/Merchandise/GetSingleMerchandise/${id}`)
  );

  const [updateImage, setUpdateImage] = useState(false);
  const [updateInfo, setUpdateInfo] = useState(false);

  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  const handleRemoveImg = (mid) => {
    // Implement logic to send the orderID, shopID, and imageID to the API DELETE endpoint
    Axios.delete(
      `/shop/${shopID}/Merchandise/RemoveSingleImage/${merchandise?.data.ID}/${mid}`
    )
      .then((response) => {
        // Display alert message once the request is successful
        toast.success("Image Deleted", response.data.MESSAGE);
      })
      .catch((error) => {
        // Handle the error if the request fails
        toast.error("Error Deleting Image", error.response.data.MESSAGE);
      });
  };

  return (
    <div className="content">
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      {updateInfo ? (
        <UpdateMerchandiseInfo
          Mdetail={merchandise?.data}
          merchandiseID={merchandise?.data.ID}
          setUpdateInfo={setUpdateInfo}
        />
      ) : (
        <Card className="card-stats card-dark">
          <CardBody className="mt-3">
            <p className="bg-info p-2 pl-4">Merchandise Information</p>
            <Row>
              <Col md="6">
                <Card
                  className="card-stats card-dark"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>{`ID : ${merchandise?.data.ID}`}</p>
                    {merchandise?.data.Status === true ? (
                      <p>
                        Status : <span className="text-success">Active</span>
                      </p>
                    ) : (
                      <p>
                        Status :<span className="text-danger">Deactivated</span>
                      </p>
                    )}
                    <p>{`SKU : ${merchandise?.data.SKU}`}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card
                  className="card-stats card-dark"
                  style={{ border: "1px solid #51BCDA" }}
                >
                  <CardBody className="text-white">
                    <p>{`Stock Count: ${merchandise?.data.StockCount}`}</p>
                    <p>
                      Price: &nbsp;
                      <CurrencyFormat
                        decimal={2}
                        price={merchandise?.data.Price.toFixed(2)}
                        currency="MYR"
                      />
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div>
              <Row>
                <Col md="6">
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>{`English Name: ${merchandise?.data.EngName}`}</p>
                      <p>{`Description: ${merchandise?.data.EngDescription}`}</p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  <Card
                    className="card-stats card-dark"
                    style={{ border: "1px solid #51BCDA" }}
                  >
                    <CardBody className="text-white">
                      <p>{`Malay Name: ${merchandise?.data.EngName}`}</p>
                      <p>{`Description: ${merchandise?.data.MalDescription}`}</p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            <Card className="card-stats card-dark">
              <CardBody className="mt-3">
                {merchandise?.data.AttachmentUrlList.map((img) => (
                  <div className="imgPreview mr-2">
                    <Button
                      type="button"
                      className="removeImg"
                      onClick={() => handleRemoveImg(img.ImageID)}
                    >
                      <i className="fa fa-times-circle removeIcon" />
                    </Button>
                    <img
                      className="VimgCenter"
                      alt="..."
                      src={img.ImageUrl}
                      width="80px"
                      height="80px"
                    />
                  </div>
                ))}
                <div>
                  {!updateImage ? (
                    <a
                      style={{ fontSize: "12px" }}
                      href="#edit"
                      className="font-weight-bold text-center text-white float-right mr-5 pr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateImage(true);
                      }}
                    >
                      <Button className="btn btn-primary">Add New Image</Button>
                    </a>
                  ) : (
                    <>
                      <a
                        style={{ fontSize: "12px" }}
                        href="#edit"
                        className="font-weight-bold text-center text-white float-right mr-5 pr-1 border-bottom"
                        onClick={(e) => {
                          e.preventDefault();
                          setUpdateImage(false);
                        }}
                      >
                        X
                      </a>
                      <div className="text-center">
                        <UploadImage
                          mID={merchandise?.data.ID}
                          setUpdateImage={setUpdateImage}
                        />
                      </div>
                    </>
                  )}
                  {!updateInfo ? (
                    <a
                      style={{ fontSize: "12px" }}
                      href="#edit"
                      className="font-weight-bold text-center text-white float-right mr-5 pr-1"
                      onClick={(e) => {
                        e.preventDefault();
                        setUpdateInfo(true);
                      }}
                    >
                      <Button className="btn btn-secondary">
                        Edit Merchandise Information
                      </Button>
                    </a>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      )}
    </div>
  );
}

export default ViewMerchandiseDetails;
