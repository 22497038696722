/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from "react";
import { Input, Button, Form, Col, Row, FormGroup, Label } from "reactstrap";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import ApiContext from "services/ApiContext";
import { toast } from "react-toastify";

function AddressForm(props) {
  const {
    refetch,
    handleToggle,
    prefilled,
    formStatus,
    setPrefilled,
    defaultAddress,
    AddressCount,
  } = props;

  const Axios = useContext(ApiContext);
  const [loading, setLoading] = useState(false);
  const [defaultChck, setDefaultChck] = useState({
    shipping: defaultAddress.DefaultShipping === prefilled.ID,
    billing: defaultAddress.DefaultBilling === prefilled.ID,
  });

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required("Fullname is required"),
    ContactNumber: Yup.string()
      .required("Contact is required")
      .matches(/^(\+?6?01)[0-9]-?[0-9]{7,8}$/, "Contact is invalid"),
    FirstLine: Yup.string().required("Address is required"),
    SecondLine: Yup.string(),
    City: Yup.string().required("City is required"),
    Postcode: Yup.string()
      .required("Postcode is required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(5, "Must be exactly 5 digits")
      .max(5, "Must be exactly 5 digits"),
    State: Yup.string().required("State is required"),
  });

  const defaultSetting = (id) => {
    Axios.put("/user/Account/DefaultAddress", {
      DefaultBilling: defaultChck.billing ? id : null,
      DefaultShipping: defaultChck.shipping ? id : null,
    })
      .then(() => {
        toast.success("Default Updated successfully");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Name: prefilled !== "" ? prefilled.Name : "",
      ContactNumber: prefilled !== "" ? prefilled.ContactNumber : "",
      FirstLine: prefilled !== "" ? prefilled.FirstLine : "",
      City: prefilled !== null ? prefilled.City : "",
      SecondLine: prefilled !== "" ? prefilled.SecondLine : "",
      Postcode: prefilled !== "" ? prefilled.Postcode : "",
      State: prefilled !== "" ? prefilled.State : "",
    },
    validationSchema,

    onSubmit: (data, { resetForm }) => {
      const data2 = data;
      setLoading(true);
      if (formStatus === "Edit") {
        Axios.put(`/user/Account/Address/${prefilled.ID}`, data2)
          .then(() => {
            setPrefilled(data);
            toast.success("Detail Updated successfully");
            handleToggle(-1, "Add", "");
            resetForm();
            setLoading(false);
            refetch();
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
          });

        if (Object.values(defaultChck).some((value) => value === true)) {
          defaultSetting(prefilled.ID);
        }
      } else if (formStatus === "Add") {
        if (AddressCount >= 6) {
          toast.warn("Address Limit Reached");
        } else {
          Axios.post("/user/Account/Address", {
            ...data,
            DefaultShipping: defaultChck.shipping,
            DefaultBilling: defaultChck.billing,
          })
            .then(() => {
              setLoading(false);
              toast.success("Created successfully");
              handleToggle(-1, "Add", "");
              resetForm();
              refetch();
            })
            .catch((err) => {
              toast.error(err.message);
              setLoading(false);
            });
        }
      }
    },
  });
  const buttonTxt = formStatus === "Add" ? "Create" : "Update";

  return (
    <div>
      <h4
        className="text-center mt-2 mb-1 font-weight-bold"
        style={{ height: "39px" }}
      >
        {formStatus === "Add" ? "New Address" : "Edit Address"}
      </h4>
      <Form>
        <div className="text-left pt-2 font-weight-bold">
          <Input
            className={formik.errors.Name ? "is-invalid" : "is-valid"}
            onChange={formik.handleChange}
            type="text"
            name="Name"
            value={formik.values.Name}
            placeholder="FULLNAME"
          />
          <div className="text-danger">
            {formik.errors.Name ? formik.errors.Name : null}
          </div>
        </div>
        <div className="text-left pt-2 font-weight-bold">
          <Input
            className={formik.errors.ContactNumber ? "is-invalid" : "is-valid"}
            onChange={formik.handleChange}
            name="ContactNumber"
            type="text"
            value={formik.values.ContactNumber}
            placeholder="CONTACT NUMBER"
          />
          <div className="text-danger">
            {formik.errors.ContactNumber ? formik.errors.ContactNumber : null}
          </div>
        </div>
        <div>
          <div className="text-left pt-2 font-weight-bold">
            <Input
              className={
                formik.errors.FirstLine ? "is-invalid mb-2" : "is-valid mb-2"
              }
              placeholder="Address 01"
              name="FirstLine"
              onChange={formik.handleChange}
              value={formik.values.FirstLine}
            />
            <div className="text-danger">
              {formik.errors.FirstLine ? formik.errors.FirstLine : null}
            </div>
            <Input
              className={formik.errors.SecondLine ? "is-invalid" : "is-valid"}
              placeholder="Address 02"
              onChange={formik.handleChange}
              name="SecondLine"
              value={formik.values.SecondLine}
            />
            <div className="text-danger">
              {formik.errors.SecondLine ? formik.errors.SecondLine : null}
            </div>
          </div>
        </div>

        <div className="text-left pt-2 font-weight-bold">
          <Row>
            <Col>
              <Input
                className={formik.errors.Postcode ? "is-invalid" : "is-valid"}
                placeholder="Postcode / ZIP"
                maxLength="5"
                pattern="[0-9]+"
                onChange={formik.handleChange}
                value={formik.values.Postcode}
                name="Postcode"
              />
              <div className="text-danger">
                {formik.errors.Postcode ? formik.errors.Postcode : null}
              </div>
            </Col>
            <Col className="pl-0">
              <Input
                className={formik.errors.State ? "is-invalid" : "is-valid"}
                placeholder="State"
                type="select"
                onChange={formik.handleChange}
                name="State"
                value={formik.values.State}
              >
                <option value="">Select one from below</option>
                <option value="JOHOR">Johor</option>
                <option value="KEDAH">Kedah</option>
                <option value="KELANTAN">Kelantan</option>
                <option value="KUALA LUMPUR">Kuala Lumpur</option>
                <option value="MELAKA">Melaka</option>
                <option value="NEGERI SEMBILAN">Negeri Sembilan</option>
                <option value="PAHANG">Pahang</option>
                <option value="PERAK">Perak</option>
                <option value="PERLIS">Perlis</option>
                <option value="PULAU PINANG">Pulau Pinang</option>
                <option value="PUTRAJAYA">Putrajaya</option>
                <option value="SABAH">Sabah</option>
                <option value="SARAWAK">Sarawak</option>
                <option value="SELANGOR">Selangor</option>
                <option value="TERENGGANU">Terengganu</option>
              </Input>
              <div className="text-danger">
                {formik.errors.State ? formik.errors.State : null}
              </div>
            </Col>
          </Row>
        </div>
        <div className="text-left pt-2 font-weight-bold">
          <Row>
            <Col>
              <Input
                className={formik.errors.City ? "is-invalid" : "is-valid"}
                placeholder="City"
                onChange={formik.handleChange}
                value={formik.values.City}
                name="City"
              />
              <div className="text-danger">
                {formik.errors.City ? formik.errors.City : null}
              </div>
            </Col>
            <Col className="pl-0">
              <div className="text-left font-weight-bold">
                <Input readOnly placeholder="Malaysia" />
              </div>
            </Col>
          </Row>
        </div>
        <div
          className="text-left pt-2 font-weight-bold"
          style={{ marginLeft: "1.25rem" }}
        >
          <FormGroup checked={defaultChck.billing} className="pb-0 mb-0">
            {defaultAddress.DefaultBilling !== prefilled.ID && (
              <Label checked={defaultChck.billing} tag="p">
                <Input
                  type="checkbox"
                  onClick={() => {
                    setDefaultChck((prev) => ({
                      ...prev,
                      billing: !defaultChck.billing,
                    }));
                  }}
                />
                Set Default Billing
              </Label>
            )}
          </FormGroup>

          <FormGroup checked={defaultChck.shipping}>
            {defaultAddress.DefaultShipping !== prefilled.ID && (
              <Label checked={defaultChck.shipping} tag="p">
                <Input
                  type="checkbox"
                  onClick={() => {
                    setDefaultChck((prev) => ({
                      ...prev,
                      shipping: !defaultChck.shipping,
                    }));
                  }}
                />
                Set Default Shipping
              </Label>
            )}
          </FormGroup>
        </div>
        <div className="text-center">
          <Button
            id="viewButton"
            className="btn-sm  mt-2"
            disabled={loading}
            onClick={formik.handleSubmit}
          >
            {loading ? "Loading..." : buttonTxt}
          </Button>

          <Button
            id="viewButton"
            className="btn-sm  ml-3 mt-2"
            onClick={() => {
              handleToggle(-1, "Add", "");
            }}
          >
            {loading ? "Loading..." : "Cancel"}
          </Button>
        </div>
      </Form>
      {/* <Footer/> */}
    </div>
  );
}
AddressForm.defaultProps = {
  AddressCount: 0,
};

AddressForm.propTypes = {
  refetch: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  setPrefilled: PropTypes.func.isRequired,
  formStatus: PropTypes.string.isRequired,
  prefilled: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    .isRequired,
  defaultAddress: PropTypes.object.isRequired,
  AddressCount: PropTypes.number,
};

export default AddressForm;
