/* eslint-disable react/no-unstable-nested-components */
import { React, useContext, useEffect, useState } from "react";
import CurrencyFormat from "react-currency-format";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import { Card, Col, Row } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import Filter from "views/components/Filter";

function SalesSummary() {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [dataState, setDataState] = useState([]);
  const [totalRow, setTotalRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState("DESC");
  const [sortBy, setSortBy] = useState(null);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [userId, setUserId] = useState("");
  const [totalCost, setTotalCost] = useState("");

  const { data } = useQuery(
    ["FinanceSummary", fromDate, toDate, sort, sortBy],
    async () =>
      Axios.get(`/shop/${shopID}/Order/Finance`, {
        params: {
          dateFrom: fromDate,
          dateTo: toDate,
          order: sort,
          orderBy: sortBy,
          // orderSource: source,
          limitPerPage: "10000",
        },
      })
  );

  const { data: userData } = useQuery(["OrderUser", userId], async () =>
    Axios.get(`/shop/${shopID}/Order/Finance/ByUserId`, {
      params: {
        userID: userId,
      },
    })
  );

  const column = [
    {
      name: "Date of INV",
      selector: (row) => row.orderDate,
      sortable: true,
      sortField: "CreatedDate",
      width: "170px",
    },
    {
      name: "Order ID No",
      selector: (row) => row.orderID,
      sortable: true,
      sortField: "_id",
      width: "220px",
    },
    {
      name: "Sales Invoice No",
      selector: (row) => row.invoice,
      sortable: true,
      sortField: "Invoice",
      width: "180px",
    },

    {
      name: "User ID",
      selector: (row) => row.name,
      sortable: true,
      sortField: "Name",
      width: "270px",
    },
    {
      name: "Total",
      sortable: true,
      sortField: "Total",
      selector: (row) => (
        <CurrencyFormat
          decimalScale={2}
          value={parseFloat(row.total).toFixed(2)}
          displayType="text"
          thousandSeparator
          prefix="RM "
        />
      ),
      width: "120px",
    },
    {
      name: "Item SN No",
      sortable: true,
      sortField: "SN",
      selector: (row) => row.SN,
      width: "180px",
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => row.status,
      width: "240px",
    },
    {
      name: "Posted/Processed by Finance",
      sortable: true,
      sortField: "Bill",
      selector: (row) => row.BillStatus,
      width: "210px",
    },
    {
      name: "Payment Method",
      sortable: true,
      sortField: "PaymentGateway",
      selector: (row) => row.payment,
      width: "210px",
    },
    {
      name: "Ewallet",
      sortable: true,
      sortField: "Ewallet",
      selector: (row) => row.Ewallet,
      width: "210px",
    },
  ];

  useEffect(() => {
    if (userId === "") {
      if (data && data.data.Result.length !== 0) {
        setTotalCost(data.data.Result.TotalOrderCost);
        setTotalRow(data.data.Count);
        setDataState(
          data.data.Result.OrderList.map((prop) => ({
            id: prop.ID,
            orderID: <p color="info">#{prop.ID}</p>,
            invoice: prop.Invoice,
            orderDate: new Date(prop.OrderDate).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            name: prop.Email,
            status: prop.ShopOrderStatus,
            total: prop.TotalOrderCost,
            payment: prop.PaymentGateway,
            SN: prop.ItemSN,
            BillStatus: prop.BillStatus,
            Ewallet: prop.EwalletUsed,
          }))
        );
      } else {
        setTotalCost([]);
        setTotalRow([]);
        setDataState([]);
      }
    } else if (userId !== "") {
      if (userData && userData.data.Result.length !== 0) {
        setTotalCost(userData.data.Result.TotalOrderCost);
        setTotalRow(userData.data.Count);
        setDataState(
          userData.data.Result.map((prop) => ({
            id: prop.ID,
            orderID: <p color="info">#{prop.ID}</p>,
            invoice: prop.Invoice,
            orderDate: new Date(prop.OrderDate).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            name: prop.Email,
            status: prop.ShopOrderStatus,
            total: `RM ${prop.TotalOrderCost}`,
            payment: prop.PaymentGateway,
            SN: prop.ItemSN,
            BillStatus: prop.BillStatus,
            Ewallet: prop.EwalletUsed,
          }))
        );
      } else {
        setTotalCost([]);
        setTotalRow([]);
        setDataState([]);
      }
    }
  }, [data, userId, userData]);

  // const handlePageChange = (page) => {
  //   setLoading(true);
  //   setPageParam(page);
  //   setLoading(false);
  // };

  // const handlePerRowsChange = async (newPerPage) => {
  //   setLoading(true);
  //   setLimitPage(newPerPage);
  //   setLoading(false);
  // };

  const handleSort = async (columns, sortDirection) => {
    setLoading(true);
    setSort(sortDirection.toUpperCase());
    setSortBy(columns.sortField);
    setLoading(false);
  };

  return (
    <Card className="card-dark cardSplit pl-3 pr-3 pb-3">
      <Row>
        <Col md="7">
          <div>
            <Row className="mt-2 mb-2">
              <Col md="3" className="mt-2 mb-2">
                <p>Sales (RM) : </p>
              </Col>
              <Col>
                {data && (
                  <p className="mt-2">
                    <CurrencyFormat
                      decimalScale={2}
                      value={parseFloat(totalCost).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      prefix=""
                    />
                  </p>
                )}
              </Col>
            </Row>
            <Row className="mt-2 mb-4">
              <Col md="3" className="mt-2">
                <p>Order Item No. : </p>
              </Col>
              <Col>
                <p className="mt-2">{totalRow}</p>
              </Col>
            </Row>
            <Filter
              fromDate={fromDate}
              toDate={toDate}
              setFromDate={setFromDate}
              setToDate={setToDate}
              setUserId={setUserId}
            />
          </div>
        </Col>
      </Row>
      <DataTable
        columns={column}
        data={dataState}
        customStyles={customStyles}
        theme="solarized"
        highlightOnHover
        selectableRows
        responsive
        pagination
        progressPending={loading}
        onSort={handleSort}
        sortServer
      />
    </Card>
  );
}

export default SalesSummary;
