import React, { useContext, useEffect, useState } from "react";
import { Col, Input, InputGroup, Row } from "reactstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Filter(props) {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const { fromDate, toDate, setFromDate, setToDate, setUserId, userId } = props;
  const [userSearch, setUserSearch] = useState([]);

  const filterByMonth = (month) => {
    const year = new Date().getFullYear();

    const startDate = moment([year, month]).format();
    setFromDate(startDate);

    const endDate = moment(startDate).endOf("month").format();
    setToDate(endDate);
  };

  const formatResult = (item) => (
    <div className="result-wrapper">
      <span className="result-span text-capitalize searchContent" type="button">
        {item.Name}
      </span>
    </div>
  );

  const handleOnSearch = async (string) => {
    if (string === "") {
      // eslint-disable-next-line no-param-reassign
      string = "a";
    }

    const search = await Axios.get(`/shop/${shopID}/Account/Cache`, {
      params: {
        search: string,
      },
    }).then((res) => {
      const temp1 = [];
      res.data.map((searches) => {
        temp1.push({
          ID: searches.ID,
          Name: searches.Email,
        });
        return temp1;
      });
      setUserSearch([...temp1]);
    });
    return search;
  };

  const handleOnSelect = (item) => {
    setUserId(item.ID);
  };

  useEffect(() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = today.getMonth();

    const firstDay = moment([year, month]).format();
    setFromDate(firstDay);

    const lastDay = moment(firstDay).endOf("month").format();
    setToDate(lastDay);
  }, []);

  return (
    <>
      <Row className="mt-2 mb-2">
        <Col md="3" className="mt-2">
          <p>Filter By Date : </p>
        </Col>
        <Col>
          <Row>
            <Col md="5" xs="5">
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => setFromDate(moment(e.target.value).format())}
                />
              </InputGroup>
            </Col>
            <p className="mt-2 ml-2">To</p>
            <Col>
              <InputGroup className="no-border dateFilter">
                <Input
                  className="searchBar"
                  type="date"
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e) => setToDate(moment(e.target.value).format())}
                />
              </InputGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-2 mb-2">
        <Col md="3" className="mt-2">
          <p>Filter By Month : </p>
        </Col>
        <Col>
          <InputGroup className="no-border filterMonth">
            <Input
              type="select"
              className="filterMonth"
              onChange={(e) => filterByMonth(e.target.value)}
            >
              <option value="" disabled selected>
                Filter By Months
              </option>
              {months.map((month, idx) => (
                <option style={{ backgroundColor: "grey" }} value={idx}>
                  {month}
                </option>
              ))}
            </Input>
          </InputGroup>
        </Col>
      </Row>
      {userId !== null && (
        <Row className="mt-2 mb-2">
          <Col md="3" className="mt-2">
            <p>Filter By User ID : </p>
          </Col>
          <Col>
            <div className="search-container ">
              <div className="searchBox">
                <ReactSearchAutocomplete
                  id="searchBox"
                  items={userSearch}
                  formatResult={formatResult}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onClear={() => setUserId("")}
                  fuseOptions={{ keys: ["Name"] }}
                  resultStringKeyName="Name"
                  styling={{ zIndex: 1 }}
                  placeholder="Filter By User ID..."
                />
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

Filter.defaultProps = {
  setFromDate: [],
  setToDate: [],
  setUserId: [],
  userId: [],
};

Filter.propTypes = {
  fromDate: PropTypes.string.isRequired,
  toDate: PropTypes.string.isRequired,
  setFromDate: PropTypes.func,
  setToDate: PropTypes.func,
  setUserId: PropTypes.func,
  userId: PropTypes.string,
};

export default Filter;
