/* eslint-disable new-cap */
/* eslint-disable no-unused-vars */
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import React, { useContext, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";

function MembershipTier() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  const [labelName, setLabelName] = React.useState("");
  const [total1, setTotal1] = React.useState(0);
  const [total2, setTotal2] = React.useState(0);
  const [selectedPeriod, setSelectedPeriod] = useState("Day");
  const [selectedKey, setSelectedKey] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [range, setRange] = useState([null, null]);

  const handleExportToPDF = () => {
    const elementToDownload = document.getElementById("ElementId"); // Replace "ElementId" with the actual ID
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
      margin: [50, 50, 50, 50], // Left, Top, Right, Bottom margins
    });
    // Add A4 format header and footer
    doc.setFontSize(12);
    doc.text(
      "Membership Tier Report",
      doc.internal.pageSize.getWidth() / 2,
      20
    );
    doc.text(
      "Membership Tier Report",
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 20
    );
    // Capture the specific element content using html2canvas
    html2canvas(elementToDownload, {
      scale: 2,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 600; // A4 width in landscape (595 for portrait)
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const imgX = (doc.internal.pageSize.getWidth() - imgWidth) / 2; // Center the image horizontally
      let imgY = (doc.internal.pageSize.getHeight() - imgHeight) / 2; // Center the image vertically
      if (imgY < 0) {
        doc.addPage();
        imgY = 0;
      }
      doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
      doc.save("Membership Tier Report.pdf");
    });
  };

  const { data } = useQuery(
    `/shop/${shopID}/Marketing/Dashboard/MembershipTier`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Marketing/Dashboard/MembershipTier`).then(
        (res) => {
          setFilterDataResult(res.data);
          return res;
        }
      ),
    { refetchOnWindowFocus: true }
  );

  const resetFilter = () => {
    setDateRange([null, null]);
    setRange([null, null]);
    setIsChecked(false);
    setFilterDataResult(data.data);
  };

  const handleFilter = async () => {
    const res = await Axios.get(
      `/shop/${shopID}/Marketing/Dashboard/MembershipTier`,
      {
        params: {
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),
          isCompare: isChecked,
          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
        },
      }
    );
    setFilterDataResult(res.data);
  };

  let tableType;
  let loyaltyRank;
  let tablePeriod = selectedPeriod;

  if (selectedKey === "GTS") {
    tableType = "TotalSales";
    loyaltyRank = "GOLD";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "GTO") {
    tableType = "TotalOrderCount";
    loyaltyRank = "GOLD";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "GAV") {
    tableType = "OrderAverage";
    loyaltyRank = "GOLD";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "GNS") {
    tableType = "NewSales";
    loyaltyRank = "GOLD";
    tablePeriod = selectedPeriod;
  }
  if (selectedKey === "STS") {
    tableType = "TotalSales";
    loyaltyRank = "SILVER";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "STO") {
    tableType = "TotalOrderCount";
    loyaltyRank = "SILVER";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "SAV") {
    tableType = "OrderAverage";
    loyaltyRank = "SILVER";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "SNS") {
    tableType = "NewSales";
    loyaltyRank = "SILVER";
    tablePeriod = selectedPeriod;
  }
  if (selectedKey === "NTS") {
    tableType = "TotalSales";
    loyaltyRank = "NORMAL";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "NTO") {
    tableType = "TotalOrderCount";
    loyaltyRank = "NORMAL";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "NAV") {
    tableType = "OrderAverage";
    loyaltyRank = "NORMAL";
    tablePeriod = selectedPeriod;
  } else if (selectedKey === "NNS") {
    tableType = "NewSales";
    loyaltyRank = "NORMAL";
    tablePeriod = selectedPeriod;
  }

  const { data: graphMember, isSuccess } = useQuery(
    [
      "graphMember",
      startDate,
      endDate,
      range,
      isChecked,
      tableType,
      loyaltyRank,
      tablePeriod,
    ],
    () =>
      Axios.get(`/shop/${shopID}/Marketing/Dashboard/MembershipTier/Graph`, {
        params: {
          dateFrom: moment(startDate)
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format(),
          dateTo: moment(endDate)
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format(),
          dateFrom2:
            (range &&
              moment(range[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format()) ||
            null,
          dateTo2:
            (range &&
              moment(range[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
                .format()) ||
            null,
          isCompare: isChecked,
          tableType,
          loyaltyRank,
          tablePeriod,
        },
      })
  );

  useEffect(() => {
    if (graphMember) {
      let datasets;
      let datasets2;
      let formattedDates;

      if (graphMember.data.Graph1) {
        // Update the graph with the API graphMemberponse

        datasets = [
          {
            label: "Gold",
            data: graphMember.data.Graph1.map((gdts) => gdts.Value),
            borderColor: "rgba(74, 189, 219,1)",
            backgroundColor: "rgba(74, 189, 219,1)",
          },
        ];
        // get the date ex: 2023-354
        if (graphMember.data.Graph1[0]?.ID.Day) {
          // Handle day format
          const dates = graphMember.data.Graph1.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (graphMember.data.Graph1[0]?.ID.Month) {
          formattedDates = graphMember.data.Graph1.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (graphMember.data.Graph1[0]?.ID.Week) {
          formattedDates = graphMember.data.Graph1.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      if (graphMember.data.Graph2) {
        datasets2 = [
          {
            label: "Gold",
            data: graphMember.data.Graph2.map((gdts) => gdts.Value),
            borderColor: "rgba(248, 192, 192,1)",
            backgroundColor: "rgba(248, 192, 192,1)",
          },
        ];

        // get the date ex: 2023-354
        if (graphMember.data.Graph2[0]?.ID.Day) {
          // Handle day format
          const dates = graphMember.data.Graph2.map(
            (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
          );
          const sortedDates = dates.sort((a, b) => {
            const [yearA, dayOfYearA] = a.split("-");
            const [yearB, dayOfYearB] = b.split("-");

            const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
            const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

            return dateA - dateB;
          });
          formattedDates = sortedDates.map((dateString) => {
            const [year, dayOfYear] = dateString.split("-");
            const date = new Date(year, 0);
            date.setDate(dayOfYear);
            return `${date.getDate()} ${date.toLocaleString("default", {
              month: "short",
            })} ${date.getFullYear()}`;
          });
        } else if (graphMember.data.Graph2[0]?.ID.Month) {
          formattedDates = graphMember.data.Graph2.map((monthData) => {
            const monthDate = new Date(
              monthData.ID.Year,
              monthData.ID.Month - 1,
              1
            );
            return `${monthDate.toLocaleString("default", {
              month: "long",
            })} ${monthData.ID.Year}`;
          });
        } else if (graphMember.data.Graph2[0]?.ID.Week) {
          formattedDates = graphMember.data.Graph2.map((weekData) => {
            const weekStartDate = new Date(
              weekData.ID.Year,
              0,
              (weekData.ID.Week - 1) * 7
            );
            const weekEndDate = new Date(
              weekData.ID.Year,
              0,
              weekData.ID.Week * 7 - 1
            );
            return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
              "default",
              { month: "short" }
            )} ${weekEndDate.getFullYear()}`;
          });
        }
      }
      const newDatas = {
        labels: formattedDates,
        datasets: [...datasets, ...(datasets2 || [])],
      };
      setDatas(newDatas);

      setLabelName(graphMember?.data.LoyaltyRankName);
      setTotal1(graphMember?.data.Graph1TotalPurchase);
      setTotal2(graphMember?.data.Graph2TotalPurchase);
    }
  });

  const options = {
    responsive: true, // Make the chart responsive
    maintainAspectRatio: false, // Prevent the chart from maintaining aspect ratio
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        offset: true,
        type: "linear",
        display: true,
        position: "left",
        ticks: {
          color: "white",
        },
      },

      x: {
        ticks: {
          color: "white",
        },
      },
    },
  };
  const lineGraphData = {
    ...datas,
    datasets: [
      {
        label: datas && datas?.datasets && datas?.datasets[0].label,
        data: datas && datas?.datasets && datas?.datasets[0].data,
        borderColor: datas && datas?.datasets && datas?.datasets[0].borderColor,
        yAxisID: "y",
      },
      ...(datas && datas?.datasets && datas?.datasets[1]?.data
        ? [
            {
              label: `Prev date ${
                datas && datas?.datasets && datas?.datasets[1].label
              } `,
              data: datas && datas?.datasets && datas?.datasets[1].data,
              borderColor:
                datas && datas?.datasets && datas?.datasets[1].borderColor,
              yAxisID: "y",
            },
          ]
        : []),
    ],
  };

  return (
    <div
      className="content px-3 w-100"
      style={{ overflowX: "auto" }}
      id="ElementId"
    >
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardBody className="mb-5">
          <div className="d-flex flex-wrap">
            <CustomCalendar
              setDateRange={setDateRange}
              compareCheckbox
              setRange={setRange}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
              style={{ width: "250px" }}
            />
            <div className="d-flex flex-wrap m-1">
              <Button color="secondary" onClick={() => handleFilter()}>
                Filter
              </Button>
              <Button color="danger" onClick={() => resetFilter()}>
                Reset All Filter
              </Button>
              <Button onClick={handleExportToPDF} color="secondary">
                Export to PDF
              </Button>
            </div>
          </div>
          <h2 className="m-4">Membership Tier Report</h2>

          <div className="m-5">
            <Row>
              <Col md="4">
                <Card
                  className="h-100"
                  style={{ minWidth: "350px", backgroundColor: "#C19A6B" }}
                >
                  <CardBody>
                    <h1 className="card-title text-center mt-3">Gold</h1>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8" sm={12}>
                <Row className="mt-3 justify-content-end">
                  <Col md={4} sm={4} xs={4}>
                    <p>Total Sales</p>
                    <p>Total Order</p>
                    <p>Average Order Value</p>
                    <p>New Sales</p>
                  </Col>
                  <Col md={4} sm={4} xs={4}>
                    <p
                      onClick={() => {
                        setSelectedKey("GTS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.GoldTierReport?.TotalSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.GoldTierReport?.TotalSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.GoldTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } bg-${
                            filterDataResult.GoldTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded cursor-pointer`}
                        >
                          {filterDataResult.GoldTierReport?.TotalSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("GTO");
                      }}
                      aria-hidden="true"
                    >
                      {filterDataResult.GoldTierReport?.TotalSalesCount}
                      {filterDataResult.GoldTierReport?.TotalSalesCountDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.GoldTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.GoldTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.GoldTierReport?.TotalSalesCountDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("GAV");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.GoldTierReport?.AverageSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.GoldTierReport?.AverageSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.GoldTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.GoldTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.GoldTierReport?.AverageSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("GNS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.GoldTierReport?.NewSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.GoldTierReport?.NewSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.GoldTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.GoldTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.GoldTierReport?.NewSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <Card
                  className="bg-secondary h-100"
                  style={{ minWidth: "350px" }}
                >
                  <CardBody>
                    <h1 className="card-title text-center mt-3">Silver</h1>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Row className="mt-3 justify-content-end">
                  <Col md={4} sm={4} xs={4}>
                    <p>Total Sales</p>
                    <p>Total Order</p>
                    <p>Average Order Value</p>
                    <p>New Sales</p>
                  </Col>
                  <Col md={4} sm={4} xs={4}>
                    <p
                      onClick={() => {
                        setSelectedKey("STS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.SilverTierReport?.TotalSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.SilverTierReport?.TotalSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.SilverTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.SilverTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.SilverTierReport?.TotalSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("STO");
                      }}
                      aria-hidden="true"
                    >
                      {filterDataResult.SilverTierReport?.TotalSalesCount}
                      {filterDataResult.SilverTierReport
                        ?.TotalSalesCountDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.SilverTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.SilverTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.SilverTierReport?.TotalSalesCountDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("SAV");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.SilverTierReport?.AverageSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.SilverTierReport?.AverageSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.SilverTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.SilverTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.SilverTierReport?.AverageSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("SNS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.SilverTierReport?.NewSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.SilverTierReport?.NewSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.SilverTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.SilverTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.SilverTierReport?.NewSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md="4">
                <Card
                  className="h-100"
                  style={{ minWidth: "350px", backgroundColor: "#FFC0CB" }}
                >
                  <CardBody>
                    <h1 className="card-title text-center mt-3">Normal</h1>
                  </CardBody>
                </Card>
              </Col>
              <Col md="8">
                <Row className="mt-3 justify-content-end">
                  <Col md={4} sm={4} xs={4}>
                    <p>Total Sales</p>
                    <p>Total Order</p>
                    <p>Average Order Value</p>
                    <p>New Sales</p>
                  </Col>
                  <Col md={4} sm={4} xs={4}>
                    <p
                      onClick={() => {
                        setSelectedKey("NTS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.NormalTierReport?.TotalSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.NormalTierReport?.TotalSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.NormalTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.NormalTierReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.NormalTierReport?.TotalSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("NTO");
                      }}
                      aria-hidden="true"
                    >
                      {filterDataResult.NormalTierReport?.TotalSalesCount}
                      {filterDataResult.NormalTierReport
                        ?.TotalSalesCountDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.NormalTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.NormalTierReport?.TotalSalesCountDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.NormalTierReport?.TotalSalesCountDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("NAV");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.NormalTierReport?.AverageSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.NormalTierReport?.AverageSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.NormalTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.NormalTierReport?.AverageSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.NormalTierReport?.AverageSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                    <p
                      onClick={() => {
                        setSelectedKey("NNS");
                      }}
                      aria-hidden="true"
                    >
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult.NormalTierReport?.NewSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                      {filterDataResult.NormalTierReport?.NewSalesDiff && (
                        <small
                          className={`m-2 p-1 border border-${
                            filterDataResult.NormalTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          }
                          bg-${
                            filterDataResult.NormalTierReport?.NewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                        >
                          {filterDataResult.NormalTierReport?.NewSalesDiff.replace(
                            /^-/,
                            ""
                          ).replace(/%/, "")}
                          %
                        </small>
                      )}
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <hr />
          <div className="mt-5 mb-5" style={{ height: "450px" }}>
            <div className="d-flex justify-content-between">
              <h6>{labelName}</h6>

              <h6 className="d-flex align-items-center justify-content-between">
                {!dateRange ||
                (dateRange[0] === null && dateRange[1] === null) ? (
                  ""
                ) : (
                  <div
                    className="square-box m-2"
                    style={{
                      backgroundColor: "rgba(74, 189, 219, 0.5)",
                      width: "20px",
                      height: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white",
                    }}
                  />
                )}

                {!dateRange || (dateRange[0] === null && dateRange[1] === null)
                  ? ""
                  : `${moment(dateRange[0]).format("YYYY-MM-DD")}-${moment(
                      dateRange[1]
                    ).format("YYYY-MM-DD")}`}
                {total1 === 0 ? null : (
                  <p className="m-2">{`- MYR ${total1.toFixed(2)}`}</p>
                )}
              </h6>

              {range ? (
                <>
                  <h6>vs</h6>
                  <h6 className="d-flex align-items-center justify-content-between">
                    <span
                      className="square-box m-2"
                      style={{
                        backgroundColor: "rgba(248, 192, 192,1)",
                        opacity: 50,
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "white",
                      }}
                    />
                    {range
                      ? `${moment(range[0]).format("YYYY-MM-DD")}-${moment(
                          range[1]
                        ).format("YYYY-MM-DD")}`
                      : ""}
                    {total2 === 0 ? null : <p>{`-MYR ${total2.toFixed(2)}`}</p>}
                  </h6>
                </>
              ) : null}

              <select
                className="text-dark"
                onChange={(e) => {
                  setSelectedPeriod(e.target.value);
                }}
                style={{
                  marginBottom: "10px",
                  width: "10%",
                  borderRadius: "5px",
                }}
              >
                <option value="Day">By Day</option>
                <option value="Week">By Week</option>
                <option value="Month">By Month</option>
              </select>
            </div>
            <hr />
            {isSuccess && datas && (
              <Line data={lineGraphData} options={options} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default MembershipTier;
