/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";

// import BarcodeScannerComponent from "react-qr-barcode-scanner";

function DispatchList(props) {
  const { tab, data, refetch } = props;

  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;

  const [selectedTo, setSelectedTo] = useState([]);
  const [selectedFrom, setSelectedFrom] = useState([]);
  const [SendToPackaging, setSendToPackaging] = useState([]);
  const [GetFromPackaging, setGetFromPackaging] = useState([]);

  const [calTotalTo, setCalTotalTo] = useState(0);
  const [calTotalFrom, setCalTotalFrom] = useState(0);

  const selectAllTo = () => {
    if (tab === "returnRefund" && data) {
      const filterTo = data.filter(
        (row) => row.Status === "Item Transferred to Dispatch"
      );
      const tempTo = [];
      filterTo.map((item) =>
        tempTo.push({ OrderID: item.OrderID, ID: item.ID })
      );
      setSelectedTo(tempTo);
    }

    if (tab === "courier" && data) {
      const filteredData = data.filter(
        (row) =>
          row.DeliveryType === "Courier" &&
          row.LatestOrderStatus === "Order Shipped Out"
      );
      const temp = [];
      filteredData.map((item) =>
        temp.push({ OrderID: item.ID, ID: item.TrackingCode })
      );
      setSelectedTo(temp);
    }

    if (tab === "selfPickup" && data) {
      const filteredData = data.filter(
        (row) =>
          row.DeliveryType === "Self-Pickup" &&
          row.LatestOrderStatus === "Order Shipped Out"
      );
      const temp = [];
      filteredData.map((item) =>
        temp.push({ OrderID: item.ID, ID: item.TrackingCode })
      );
      setSelectedTo(temp);
    }

    if (tab === "tradeIn" && data) {
      const filterTo = data.filter((row) =>
        row.TradeInProductList.some(
          (item) =>
            item.InternalStatus ===
            "Trade In Parcel Transferred to Dispatch From CS"
        )
      );
      const tempTo = [];
      filterTo.map((item) =>
        tempTo.push({ OrderID: item.TradeInID, ID: item.TrackingCode })
      );
      setSelectedTo(tempTo);
    }
  };

  const selectAllFrom = () => {
    if (tab === "returnRefund" && data) {
      const filterFrom = data.filter(
        (row) => row.Status === "Dispatch Received"
      );

      const tempFrom = [];
      filterFrom.map((item) =>
        tempFrom.push({ OrderID: item.OrderID, ID: item.ID })
      );
      setSelectedFrom(tempFrom);
    }

    if (tab === "tradeIn" && data) {
      const filterFrom = data.filter((row) =>
        row.TradeInProductList.some(
          (item) =>
            item.InternalStatus ===
            "Trade In Parcel Pending Transferred to Dispatch"
        )
      );
      const tempFrom = [];
      filterFrom.map((item) =>
        tempFrom.push({ OrderID: item.TradeInID, ID: item.TrackingCode })
      );
      setSelectedFrom(tempFrom);
    }
  };

  const handleSelectTo = (order, id) => {
    if (selectedTo.length !== 0) {
      setSelectedTo([...selectedTo, { OrderID: order, ID: id }]);
    } else {
      setSelectedTo([{ OrderID: order, ID: id }]);
    }
  };

  const handleSelectFrom = (order, id) => {
    if (selectedFrom.length !== 0) {
      setSelectedFrom([...selectedFrom, { OrderID: order, ID: id }]);
    } else {
      setSelectedFrom([{ OrderID: order, ID: id }]);
    }
  };

  const handleRemoveTo = (id) => {
    const indexToRemove = selectedTo.findIndex((row) => row.OrderID === id);
    if (indexToRemove !== -1) {
      selectedTo.splice(indexToRemove, 1);
      setSelectedTo([...selectedTo]);
    }
  };

  const handleRemoveFrom = (id) => {
    const indexToRemove = selectedFrom.findIndex((row) => row.OrderID === id);
    if (indexToRemove !== -1) {
      selectedFrom.splice(indexToRemove, 1);
      setSelectedFrom([...selectedFrom]);
    }
  };

  const handleDropOff = () => {
    if (tab === "returnRefund") {
      if (selectedTo.length !== 0) {
        selectedTo.forEach((row) => {
          Axios.put(`/shop/${selectShop}/TransferToPackaging/${row.ID}`, true, {
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(() => {
              alert("Sent to Packaging");
              refetch();
            })
            .catch((error) => {
              // Handle error
              toast.error(error.response.data.MESSAGE);
            });
        });
        setSelectedTo([]);
      }

      if (selectedFrom.length !== 0) {
        selectedFrom.forEach((row) => {
          Axios.put(
            `/shop/${selectShop}/DispatchCompleteShipment/${row.ID}`,
            true,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
            .then(() => {
              alert("Sent to Logistics");
              refetch();
            })
            .catch((error) => {
              // Handle error
              alert(error.response.data.MESSAGE);
            });
        });
        setSelectedFrom([]);
      }
    }

    if (tab === "tradeIn") {
      if (selectedTo.length !== 0) {
        selectedTo.forEach((row) => {
          Axios.put(
            `/shop/${selectShop}/TradeInTransferToPackaging/${row.OrderID}`
          )
            .then(() => {
              alert("Sent to Packaging");
              refetch();
            })
            .catch((error) => {
              // Handle error
              alert(error.response.data.MESSAGE);
            });
        });
        setSelectedTo([]);
      }

      if (selectedFrom.length !== 0) {
        selectedFrom.forEach((row) => {
          Axios.put(`/shop/${selectShop}/TradeInShippedBack/${row.OrderID}`)
            .then(() => {
              alert("Sent to Logistics");
              refetch();
            })
            .catch((error) => {
              // Handle error
              alert(error.response.data.MESSAGE);
            });
        });
        setSelectedFrom([]);
      }
    }

    if (tab === "courier" || tab === "selfPickup") {
      if (selectedTo.length !== 0) {
        selectedTo.forEach((row) => {
          Axios.put(`/shop/${selectShop}/Order/Dispatch/Order`, {
            OrderID: row.OrderID,
            TrackingNo: tab === "selfPickup" ? null : row.ID,
            OrderStatus: "Completed-Logistic",
          })
            .then(() => {
              alert(
                `Sent to ${tab === "courier" ? "Logistic" : "Customer Service"}`
              );
              refetch();
            })
            .catch((error) => {
              // Handle error
              alert(error.response.data.MESSAGE);
            });
        });
        setSelectedTo([]);
      }
    }
  };

  useEffect(() => {
    if (tab === "returnRefund" && data) {
      setSendToPackaging(
        data.filter(
          (row) =>
            row.Status === "Item Transferred to Dispatch" ||
            row.Status === "Item Received from Customer"
        )
      );
      setCalTotalTo(
        data.filter(
          (row) =>
            row.Status === "Item Transferred to Dispatch" ||
            row.Status === "Item Received from Customer"
        ).length
      );

      setGetFromPackaging(
        data.filter(
          (row) =>
            row.Status === "Dispatch Received" ||
            row.Status === "Pending Transfer To Dispatch"
        )
      );
      setCalTotalFrom(
        data.filter(
          (row) =>
            row.Status === "Dispatch Received" ||
            row.Status === "Pending Transfer To Dispatch"
        ).length
      );
    }

    if (tab === "tradeIn" && data) {
      setSendToPackaging(
        data.filter((item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
              "Trade In Parcel Transferred to Dispatch From CS"
          )
        )
      );
      setCalTotalTo(
        data.filter((item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
              "Trade In Parcel Transferred to Dispatch From CS"
          )
        ).length
      );

      setGetFromPackaging(
        data.filter((item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
              "Trade In Parcel Pending Transferred to Dispatch"
          )
        )
      );
      setCalTotalFrom(
        data.filter((item) =>
          item.TradeInProductList.some(
            (items) =>
              items.InternalStatus ===
              "Trade In Parcel Pending Transferred to Dispatch"
          )
        ).length
      );
    }

    if (tab === "courier" || tab === "selfPickup") {
      if (data) {
        setCalTotalTo(data.length);
      }
    }
  }, [data]);

  return (
    <>
      {/* {tab === "courier" ? (
        <Button className="scanBtn" onClick={() => setModal(true)}>
          Scan
        </Button>
      ) : (
        ""
      )} */}
      <Button className="selectAll" onClick={selectAllTo}>
        Select All
      </Button>

      {/* Return Refund / Trade In */}
      {tab === "returnRefund" || tab === "tradeIn" ? (
        <>
          <p className="dispatchTitle text-center p-2">
            To Packaging ({calTotalTo})
          </p>
          <Row classname={calTotalTo > 2 && `packageScroll`}>
            {SendToPackaging.length !== 0 ? (
              SendToPackaging.map((row) => (
                <Col md="6" xs="12">
                  <Card
                    className={`packageCard ${
                      tab === "returnRefund"
                        ? selectedTo.some(
                            (item) => item.OrderID === row.OrderID
                          )
                          ? "selected"
                          : ""
                        : tab === "tradeIn"
                        ? selectedTo.some(
                            (item) => item.OrderID === row.TradeInID
                          )
                          ? "selected"
                          : ""
                        : ""
                    }`}
                    onClick={() =>
                      tab === "returnRefund" &&
                      row.Status === "Item Transferred to Dispatch"
                        ? selectedTo.some(
                            (item) => item.OrderID === row.OrderID
                          )
                          ? handleRemoveTo(row.OrderID)
                          : handleSelectTo(row.OrderID, row.ID)
                        : tab === "tradeIn"
                        ? selectedTo.some(
                            (item) => item.OrderID === row.TradeInID
                          )
                          ? handleRemoveTo(row.TradeInID)
                          : handleSelectTo(row.TradeInID, row.TrackingNo)
                        : "disabled"
                    }
                  >
                    <CardBody>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">
                            Tracking No :{" "}
                          </p>
                        </Col>
                        <Col md="8" xs="6">
                          <p className="dispatchText">{row.TrackingNo}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">
                            Logistics :{" "}
                          </p>
                        </Col>
                        <Col md="8" xs="6">
                          <p className="dispatchText">{row.Logistic}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">Status :</p>
                        </Col>
                        <Col md="8" xs="6">
                          <p
                            style={{ color: "goldenrod" }}
                            className="dispatchText"
                          >
                            {tab === "returnRefund" &&
                            row.Status === "Item Received from Customer"
                              ? "Pending Collection from MEGAN"
                              : row.Status === "Item Transferred to Dispatch"
                              ? "Pending Drop Off to Packaging"
                              : tab === "tradeIn"
                              ? "Pending Drop Off to Packaging"
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col md="12" xs="12" className="text-center m-2 p-2">
                <p className="font-weight-bold">No Available Packages</p>
              </Col>
            )}
          </Row>
          <Button className="selectAll" onClick={selectAllFrom}>
            Select All
          </Button>
          <p className="dispatchTitle text-center p-2">
            From Packaging ({calTotalFrom})
          </p>
          <Row className={calTotalFrom > 2 && `packageScroll`}>
            {GetFromPackaging.length !== 0 ? (
              GetFromPackaging.map((row) => (
                <Col md="6" xs="12">
                  <Card
                    className={`packageCard ${
                      tab === "returnRefund"
                        ? selectedFrom.some(
                            (item) => item.OrderID === row.OrderID
                          )
                          ? "selected"
                          : ""
                        : selectedFrom.some(
                            (item) => item.OrderID === row.TradeInID
                          )
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      tab === "returnRefund" &&
                      row.Status === "Dispatch Received"
                        ? selectedFrom.some(
                            (item) => item.OrderID === row.OrderID
                          )
                          ? handleRemoveFrom(row.OrderID)
                          : handleSelectFrom(row.OrderID, row.ID)
                        : tab === "tradeIn"
                        ? selectedFrom.some(
                            (item) => item.OrderID === row.TradeInID
                          )
                          ? handleRemoveFrom(row.TradeInID)
                          : handleSelectFrom(row.TradeInID, row.TrackingNo)
                        : "disabled"
                    }
                  >
                    <CardBody>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">
                            {row.Logistic !== "Drop Off"
                              ? "Tracking No : "
                              : "Order No :"}
                          </p>
                        </Col>
                        <Col md="8" xs="6">
                          <p className="dispatchText">
                            {row.ShipBackInfo?.Logistic !== "Drop Off"
                              ? row.ShipBackInfo?.TrackingNo
                              : row.ID}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">
                            Logistic :{" "}
                          </p>
                        </Col>
                        <Col md="8" xs="6">
                          <p className="dispatchText">
                            {row.ShipBackInfo?.Logistic}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">Status :</p>
                        </Col>
                        <Col md="8" xs="6">
                          <p
                            style={{ color: "goldenrod" }}
                            className="dispatchText"
                          >
                            {tab === "returnRefund" &&
                            row.Status === "Dispatch Received" &&
                            row.ShipBackInfo?.Logistic !== "Drop Off"
                              ? "Pending Ship Out"
                              : row.Status === "Pending Transfer To Dispatch"
                              ? "Pending Colleciton from Packaging"
                              : tab === "tradeIn"
                              ? "Pending Ship Out"
                              : "Pending Drop Off to MEGAN"}
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col md="12" xs="12" className="text-center m-2 p-2">
                <p className="font-weight-bold">No Available Packages</p>
              </Col>
            )}
          </Row>
        </>
      ) : (
        <Row className={calTotalTo > 2 && `packageScroll`}>
          {data && data?.length !== 0 ? (
            data?.map((row) => (
              <Col md="6" xs="12">
                <Card
                  className={`packageCard ${
                    selectedTo.some((item) => item.OrderID === row.ID)
                      ? "selected"
                      : ""
                  }`}
                  onClick={() =>
                    row.LatestOrderStatus === "Order Shipped Out"
                      ? selectedTo.some((item) => item.OrderID === row.ID)
                        ? handleRemoveTo(row.ID)
                        : handleSelectTo(row.ID, row.TrackingCode)
                      : "disabled"
                  }
                >
                  <CardBody>
                    <Row>
                      <Col md="4" xs="6">
                        <p className="float-right dispatchText">
                          {tab === "courier" ? "Tracking No :" : "Order No :"}
                        </p>
                      </Col>
                      <Col md="8" xs="6">
                        {tab === "courier" ? (
                          <p className="dispatchText">{row.TrackingCode}</p>
                        ) : (
                          <p className="dispatchText">{row.ID}</p>
                        )}
                      </Col>
                    </Row>
                    {tab === "courier" ? (
                      <Row>
                        <Col md="4" xs="6">
                          <p className="float-right dispatchText">Logistic</p>
                        </Col>
                        <Col md="8" xs="6">
                          <p className="dispatchText">{row.Courier}</p>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    <Row>
                      <Col md="4" xs="6">
                        <p className="float-right dispatchText">Status :</p>
                      </Col>
                      <Col md="8" xs="6">
                        <p
                          style={{ color: "goldenrod" }}
                          className="dispatchText"
                        >
                          {row.LatestOrderStatus === "Order Shipped Out" &&
                          row.DeliveryType === "Courier"
                            ? "Pending Ship Out"
                            : row.LatestOrderStatus === "Order Shipped Out" &&
                              row.DeliveryType === "Self-Pickup"
                            ? "Pending Drop Off to MEGAN"
                            : "Pending Pickup From Packaging"}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <Col md="12" xs="12" className="text-center m-2 p-2">
              <p className="font-weight-bold">No Available Packages</p>
            </Col>
          )}
        </Row>
      )}
      <Row>
        <Col>
          <Button className="dropOffBtn" onClick={handleDropOff}>
            Drop Off
          </Button>
        </Col>
      </Row>

      {/* <Modal isOpen={modal} toggle={() => setModal(false)}>
        <ModalBody>
          <BarcodeScannerComponent
            width="100%"
            height="100%"
            stopStream={stopStream}
            onUpdate={(err, result) => {
             
              if (result) {
                setData(result.text);
                setStopStream(true);
              } else {
                setData("Not Found");
              }
            }}
            videoConstraints={videoConstraints}
          />
          <p>Result : {data}</p>
        </ModalBody>
      </Modal> */}
    </>
  );
}

DispatchList.propTypes = {
  tab: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  data: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};

export default DispatchList;
