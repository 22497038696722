/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ViewBatchDetail from "./TableDetailIDisplay";

function LiveDB() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [dropdowns, setDropdowns] = React.useState([]);
  const [modal, setModal] = React.useState({
    isOpen: false,
    type: "",
    batchID: "",
    batchName: "",
  });

  const handleItemClick = (id, value, name) => {
    const updatedDropdowns = dropdowns.map((dropdown) =>
      dropdown.id === id
        ? { ...dropdown, selectedValue: value, display: name }
        : dropdown
    );
    setDropdowns(updatedDropdowns);
  };

  const { isLoading, data, isError } = useQuery(
    `/shop/${shopID}/Live/Dashboard`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Live/Dashboard`).then((res) => {
        const temp = [];
        res.data.Result.forEach((element) => {
          temp.push({
            id: element.ID,
            selectedValue: "",
            display: "",
          });
        });
        setDropdowns(temp);
        return res;
      })
  );

  const columns = [
    {
      name: "Date",
      selector: (row) => row.ID,
      sortable: true,
      wrap: true,
    },
    {
      name: "Batch",
      selector: (row) => row.count,
      sortable: true,
      wrap: true,
    },
    {
      name: "Action",
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) =>
        row.count > 0 ? (
          <div className="d-flex w-100">
            <UncontrolledDropdown className="w-100">
              <DropdownToggle caret className="w-100">
                {(dropdowns.find((e) => e.id === row.ID) &&
                  dropdowns.find((e) => e.id === row.ID).display) ||
                  "Select Batch"}
              </DropdownToggle>
              <DropdownMenu container="body">
                {row.batchLogList.map((batch) => (
                  <DropdownItem
                    onClick={() => {
                      handleItemClick(row.ID, batch.ID, batch.batchName);
                    }}
                  >
                    {batch.batchName}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            {dropdowns.find((e) => e.id === row.ID) &&
              dropdowns.find((e) => e.id === row.ID).display && (
                <Button
                  className="btn-icon mx-2"
                  color="primary"
                  onClick={() => {
                    setModal({
                      isOpen: true,
                      type: "Scanned",
                      batchID: dropdowns.find((e) => e.id === row.ID)
                        .selectedValue,
                      batchName: dropdowns.find((e) => e.id === row.ID).display,
                    });
                  }}
                >
                  <img src={require("assets/img/icon/scanned.png")} alt="" />
                </Button>
              )}
            {dropdowns.find((e) => e.id === row.ID) &&
              dropdowns.find((e) => e.id === row.ID).display && (
                <Button
                  className="btn-icon"
                  color="primary"
                  onClick={() => {
                    setModal({
                      isOpen: true,
                      type: "Batch",
                      batchID: dropdowns.find((e) => e.id === row.ID)
                        .selectedValue,
                      batchName: dropdowns.find((e) => e.id === row.ID).display,
                    });
                  }}
                >
                  <img src={require("assets/img/icon/filelist.png")} alt="" />
                </Button>
              )}
          </div>
        ) : (
          <div>Emtpy Batch</div>
        ),
      wrap: true,
      grow: 4,
    },
  ];

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <h3>Something goes wrong, try refresh the page</h3>
      </div>
    );
  }

  return (
    <div className="content">
      <Modal
        isOpen={modal.isOpen}
        toggle={() =>
          setModal({
            isOpen: false,
            type: "",
            batchID: "",
            batchName: "",
          })
        }
        size="xl"
        style={{ maxWidth: "1400px" }}
      >
        <ModalHeader>{`${modal.type}: ${modal.batchName}`}</ModalHeader>
        <ModalBody>
          <ViewBatchDetail
            type={modal.type}
            batchID={modal.batchID}
            batchName={modal.batchName}
          />
        </ModalBody>
      </Modal>
      <div>
        <Button tag={Link} to="/shopOwner/Live-batch-generate/1">
          Create Batch
        </Button>
        <Button tag={Link} to="/shopOwner/Live-create-quotation">
          Create Quotation
        </Button>
        <Button tag={Link} to="/shopOwner/Live-create-invoice">
          Create Invoice
        </Button>
      </div>
      <div className="border p-3">
        <BasicTable columns={columns} data={data.data.Result} pagination />
      </div>
    </div>
  );
}

export default LiveDB;
