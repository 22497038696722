/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { Doughnut } from "react-chartjs-2";

// reactstrap components
import {
  Card,
  Badge,
  Nav,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Row,
  Col,
  Label,
  NavbarBrand,
  Spinner,
} from "reactstrap";
import BasicTable from "views/tables/BasicTable";
import "./rewards.css";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import moment from "moment";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// core components

const badge = {
  Gold: "warning",
  Silver: "info",
  Normal: "primary",
  Active: "success",
  Inactive: "danger",
};

const Options = {
  responsive: true,
  maintainAspectRatio: true,
  plugins: {
    tooltip: {
      enabled: true,
    },
    legend: {
      position: "bottom",
      display: false,
    },
  },
};

function RewardMemberDetail() {
  const { id } = useParams();
  const Axios = useContext(ApiContext);
  const shopID =
    useContext(ShopContext).selctShopID &&
    useContext(ShopContext).selctShopID.ID;
  const [verticalTabs, setVerticalTabs] = React.useState("1");

  const { isLoading, data: MembershipData } = useQuery(
    `Marketing/Membership/Detail/${id}`,
    async () =>
      Axios.get(`/shop/${shopID}/Marketing/Membership/${id}`).then(
        (res) => res.data
      )
  );

  const GraphData = React.useMemo(
    () => ({
      labels: ["Current Point", "Point to Next Rank"],
      datasets: [
        {
          data: MembershipData
            ? [MembershipData?.Points, MembershipData?.PointsToUpgrade]
            : [0, 0],
          cutout: "60%",
          backgroundColor: ["lightskyblue", "#51CBCE"],
          borderWidth: 5,
          borderColor: ["#27293D"],
          hoverOffset: 4,
        },
      ],
      text: "Total: 9000+",
    }),
    [MembershipData]
  );

  const plugins = React.useMemo(
    () => [
      {
        beforeDraw(chart) {
          const { ctx } = chart;
          const { height } = chart;

          const text = `RM ${
            MembershipData ? MembershipData?.PointsToUpgrade : 0
          }`;

          const sidePadding = 20;
          const sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          const stringWidth = ctx.measureText(text).width;
          const elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          const widthRatio = elementWidth / stringWidth;
          const newFontSize = Math.floor(30 * widthRatio);
          const elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          const fontSizeToUse = Math.min(newFontSize, elementHeight);

          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

          const fontSize = (height / 230).toFixed(2);
          ctx.font = `${fontSize}em Verdana`;
          ctx.fillStyle = "#ffffff";
          ctx.textBaseline = "middle";

          ctx.textAlign = "center";
          ctx.font = `${fontSizeToUse}px Arial`;

          ctx.fillText(text, centerX, centerY);
          ctx.save();
        },
      },
    ],
    [MembershipData]
  );

  if (isLoading) {
    return (
      <div className="content d-flex align-items-center justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <div className="d-flex">
        <NavbarBrand
          className="p-2 rounded"
          style={{ color: "white", backgroundColor: "lightskyblue" }}
        >
          {MembershipData.Address !== null &&
            MembershipData.FullName.charAt(0).toUpperCase()}
        </NavbarBrand>
        <h3 className="mr-2">{MembershipData?.FullName}</h3>
        <Badge pill color={badge?.Silver} style={{ height: "fit-content" }}>
          {MembershipData?.RankName}
        </Badge>
        <Badge
          pill
          color={badge[MembershipData?.Status]}
          style={{ height: "fit-content" }}
        >
          {MembershipData?.Status}
        </Badge>
      </div>
      <div className="d-flex">
        <span>{MembershipData?.Email}</span>
        <span className="pl-3">{MembershipData?.Contact}</span>
      </div>
      <div className="mt-3 float-right">
        <Link
          to="/shopOwner/marketing/rewards/membership"
          className="btn btn-primary"
        >
          <i className="fa fa-undo" aria-hidden="true" />
          Back
        </Link>
      </div>
      <Nav className="nav-underline my-3">
        <NavItem>
          <NavLink
            role="tab"
            className={verticalTabs === "1" ? "active" : ""}
            onClick={() => setVerticalTabs("1")}
          >
            Summary
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            role="tab"
            className={verticalTabs === "2" ? "active" : ""}
            onClick={() => setVerticalTabs("2")}
          >
            Order History
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={verticalTabs}>
        <TabPane tabId="1">
          <h5>Account Overview</h5>
          <Card className="card-dark my-2">
            <div className="p-3">
              <Row xs={1} sm={2} md={4}>
                <Col>
                  <Label>Date of Birth</Label>
                  <p>
                    {moment(MembershipData?.DateOfBirth).format("DD MMM YYYY")}
                  </p>
                </Col>
                <Col>
                  <Label>Age</Label>
                  <p>{MembershipData?.Age}</p>
                </Col>
                <Col>
                  <Label>Address</Label>
                  <p>
                    {MembershipData.Address !== null &&
                      Object.values(MembershipData.Address)
                        .filter((val) => val !== "")
                        .join(", ")}
                  </p>
                </Col>
                <Col>
                  <Label>Join Date</Label>
                  <p>
                    {moment(MembershipData?.JoinDate).format("DD MMM YYYY")} (
                    {moment(MembershipData?.JoinDate, "YYYYMMDD").fromNow()})
                  </p>
                </Col>
              </Row>
            </div>
          </Card>

          <Card className="card-dark mt-3">
            <div className="p-3">
              <Row>
                <Col xs="auto" lg="3">
                  <Label>Required Amount</Label>
                  <div style={{ maxWidth: "200px" }}>
                    <Doughnut
                      data={GraphData}
                      options={Options}
                      plugins={plugins}
                    />
                    <p className="text-center">To Upgrade</p>
                  </div>
                </Col>
                <Col>
                  <Row xs={1} sm={2} lg={4} className="h-100">
                    <Col className="d-flex justify-content-between flex-column">
                      <Label>Current Point</Label>
                      <p>{MembershipData.Points}</p>
                      <span />
                    </Col>
                    <Col className="d-flex justify-content-between flex-column">
                      <Label>Last Purchase Date</Label>
                      <p>
                        {moment(MembershipData.LastPurchaseDate).format(
                          "DD MMM YYYY"
                        )}
                      </p>
                      <span />
                    </Col>
                    <Col className="d-flex justify-content-between flex-column">
                      <Label>Last Purchase Amount</Label>
                      <p>RM{MembershipData.LastPurchaseAmount}</p>
                      <span />
                    </Col>
                    <Col className="d-flex justify-content-between flex-column">
                      <Label>Total Referral Purchase</Label>
                      <p>RM{MembershipData.TotalReferralPurchase}</p>
                      <span />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>

          <Row className="h-100">
            <Col>
              <Card className="d-flex p-2 card-dark mb-2">
                <Label>Total Order Amount</Label>
                <div>RM{MembershipData.TotalOrderAmount}</div>
              </Card>
              <Card className="d-flex p-2 card-dark my-2">
                <Label>Average Order Value</Label>
                <div>RM{MembershipData.AverageOrderValue}</div>
              </Card>
              <Card className="d-flex p-2 card-dark my-2">
                <Label>Total Order</Label>
                <div>{MembershipData.TotalOrder}</div>
              </Card>
              <Card className="d-flex p-2 card-dark mt-2 mb-0">
                <Label>Last Order Amount</Label>
                <div>RM{MembershipData.LastOrderAmount}</div>
              </Card>
            </Col>

            <Col>
              <Row className="h-100">
                <Col>
                  <Card className="d-flex h-100 p-2 card-dark mb-2 justify-content-center align-items-center">
                    <Label>Loyalty Voucher</Label>
                    {MembershipData.LoyaltyCoupon !== null &&
                      MembershipData.LoyaltyCoupon.map((c) => <div>{c}</div>)}
                  </Card>
                </Col>
                <Col>
                  <Card className="d-flex h-100 p-2 card-dark mb-2 justify-content-center align-items-center">
                    <Label>Promo Code</Label>
                    {MembershipData.Coupon !== null &&
                      MembershipData.Coupon.map((c) => <div>{c}</div>)}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <BasicTable
            columns={[
              {
                name: "Date",
                selector: (row) =>
                  moment(row.CreatedDate).format("DD MMM YYYY"),
                sortable: true,
                wrap: true,
              },
              {
                name: "Invoice No",
                selector: (row) => row.Invoice,
                sortable: true,
                wrap: true,
              },
              {
                name: "Variation SKU",
                selector: (row) => row.VariationSKU,
                sortable: true,
                wrap: true,
              },
              {
                name: "Total Cost",
                selector: (row) => `RM${row.TotalOrderCost}`,
                sortable: true,
                wrap: true,
              },
            ]}
            data={(MembershipData && MembershipData.OrderHistory) || []}
          />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default RewardMemberDetail;
