/* eslint-disable no-restricted-globals */
import React, { useContext, useState } from "react";
import { Line, Pie } from "react-chartjs-2";
import { useQuery } from "react-query";
import {
  Col,
  Row,
  Nav,
  NavItem,
  Card,
  Button,
  CardBody,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";
import "../departments/sales/sales.css";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import "./marketingDash.css";
import CustomCalendar from "components/CustomCalender/CustomerCalender";
import moment from "moment";
import { toast } from "react-toastify";

const options = {
  responsive: true, // Make the chart responsive
  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      type: "linear",
      display: true,
      position: "left",
    },

    x: {
      ticks: {
        color: "white",
      },
    },
  },
};

function MarketingDash() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [datas, setDatas] = React.useState([]);
  // const [tabData, setTabData] = React.useState([]);
  // const [color, setColor] = React.useState("");
  const [labelName, setLabelName] = React.useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("Day");
  const [isChecked, setIsChecked] = useState(false);
  const [range, setRange] = useState([null, null]);

  const { data, isSuccess } = useQuery(
    `/shop/${shopID}/Marketing/Dashboard`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Marketing/Dashboard`).then((res) => {
        setFilterDataResult(res.data);
        return res;
      }),
    { refetchOnWindowFocus: true }
  );

  const resetFilter = () => {
    setDateRange([null, null]);
    setRange([null, null]);
    setIsChecked(false);
    setFilterDataResult(data.data);
  };

  const handleFilter = async () => {
    const res = await Axios.get(`/shop/${shopID}/Marketing/Dashboard`, {
      params: {
        dateFrom: moment(startDate)
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .format(),
        dateTo: moment(endDate)
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
          })
          .format(),
        isCompare: isChecked,
        dateFrom2:
          (range &&
            moment(range[0])
              .set({
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0,
              })
              .format()) ||
          null,
        dateTo2:
          (range &&
            moment(range[1])
              .set({
                hour: 23,
                minute: 59,
                second: 59,
                millisecond: 999,
              })
              .format()) ||
          null,
      },
    });
    setFilterDataResult(res.data);
  };
  const handleTabSelect = (selectedKey) => {
    // Define the data values based on the selected tab

    // Define the data values based on the selected tab
    let tableType;
    let tableSubType;
    let tablePeriod;
    if (selectedKey === "newMember") {
      tableType = "User";
      tableSubType = "New User";
      tablePeriod = selectedPeriod;
    } else if (selectedKey === "activeMember") {
      tableType = "User";
      tableSubType = "Active User";
      tablePeriod = selectedPeriod;
    } else if (selectedKey === "nonActiveMember") {
      tableType = "User";
      tableSubType = "Inactive User";
      tablePeriod = selectedPeriod;
    }

    // Make the API call with the selected tab and parameters
    const apiUrl = `/shop/${shopID}/Marketing/Dashboard/Graph`;
    const params = {
      dateFrom: moment(startDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .format(),
      dateTo: moment(endDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 999,
        })
        .format(),

      dateFrom2:
        (range &&
          moment(range[0])
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .format()) ||
        null,
      dateTo2:
        (range &&
          moment(range[1])
            .set({
              hour: 23,
              minute: 59,
              second: 59,
              millisecond: 999,
            })
            .format()) ||
        null,
      isCompareGraph: isChecked,
      tableType,
      tableSubType,
      tablePeriod,
    };

    // Make the API call with the parameters
    Axios.get(apiUrl, { params })
      .then((res) => {
        let datasets;
        let datasets2;
        let formattedDates;

        if (res.data.Graph1) {
          // Update the graph with the API response

          if (selectedKey === "newMember") {
            datasets = [
              {
                label: "New Member",
                data: res.data.Graph1.map((gdts) => gdts.NewUsers),
                borderColor: "rgba(74, 189, 219,1)",
                backgroundColor: "rgba(74, 189, 219,1)",
              },
            ];
          } else if (selectedKey === "activeMember") {
            datasets = [
              {
                label: "Active Member",
                data: res.data.Graph1.map((gdts) => gdts.ActiveUsers),
                borderColor: "rgba(74, 189, 219,1)",
                backgroundColor: "rgba(74, 189, 219,1)",
              },
            ];
          } else if (selectedKey === "nonActiveMember") {
            datasets = [
              {
                label: "Non Active Member",
                data: res.data.Graph1.map((gdts) => gdts.InactiveUsers),
                borderColor: "rgba(74, 189, 219,1)",
                backgroundColor: "rgba(74, 189, 219,1)",
              },
            ];
          }
          // get the date ex: 2023-354
          if (res.data.Graph1[0].ID.Day) {
            // Handle day format
            const dates = res.data.Graph1.map(
              (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
            );
            const sortedDates = dates.sort((a, b) => {
              const [yearA, dayOfYearA] = a.split("-");
              const [yearB, dayOfYearB] = b.split("-");

              const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
              const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

              return dateA - dateB;
            });
            formattedDates = sortedDates.map((dateString) => {
              const [year, dayOfYear] = dateString.split("-");
              const date = new Date(year, 0);
              date.setDate(dayOfYear);
              return `${date.getDate()} ${date.toLocaleString("default", {
                month: "short",
              })} ${date.getFullYear()}`;
            });
          } else if (res.data.Graph1[0].ID.Month) {
            formattedDates = res.data.Graph1.map((monthData) => {
              const monthDate = new Date(
                monthData.ID.Year,
                monthData.ID.Month - 1,
                1
              );
              return `${monthDate.toLocaleString("default", {
                month: "long",
              })} ${monthData.ID.Year}`;
            });
          } else if (res.data.Graph1[0].ID.Week) {
            formattedDates = res.data.Graph1.map((weekData) => {
              const weekStartDate = new Date(
                weekData.ID.Year,
                0,
                (weekData.ID.Week - 1) * 7
              );
              const weekEndDate = new Date(
                weekData.ID.Year,
                0,
                weekData.ID.Week * 7 - 1
              );
              return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
                "default",
                { month: "short" }
              )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
                "default",
                { month: "short" }
              )} ${weekEndDate.getFullYear()}`;
            });
          }
        }
        if (res.data.Graph2) {
          if (selectedKey === "newMember") {
            datasets2 = [
              {
                label: "New Member",
                data: res.data.Graph2.map((gdts) => gdts.NewUsers),
                borderColor: "rgba(248, 192, 192,1)",
                backgroundColor: "rgba(248, 192, 192,1)",
              },
            ];
          } else if (selectedKey === "activeMember") {
            datasets2 = [
              {
                label: "Active Member",
                data: res.data.Graph2.map((gdts) => gdts.ActiveUsers),
                borderColor: "rgba(248, 192, 192,1)",
                backgroundColor: "rgba(248, 192, 192,1)",
              },
            ];
          } else if (selectedKey === "nonActiveMember") {
            datasets2 = [
              {
                label: "Non Active Member",
                data: res.data.Graph2.map((gdts) => gdts.InactiveUsers),
                borderColor: "rgba(248, 192, 192,1)",
                backgroundColor: "rgba(248, 192, 192,1)",
              },
            ];
          }
          if (res.data.Graph2[0].ID.Day) {
            // Handle day format
            const dates = res.data.Graph2.map(
              (gdt1) => `${gdt1.ID.Year}-${gdt1.ID.Day}`
            );
            const sortedDates = dates.sort((a, b) => {
              const [yearA, dayOfYearA] = a.split("-");
              const [yearB, dayOfYearB] = b.split("-");

              const dateA = new Date(Number(yearA), 0, Number(dayOfYearA));
              const dateB = new Date(Number(yearB), 0, Number(dayOfYearB));

              return dateA - dateB;
            });
            formattedDates = sortedDates.map((dateString) => {
              const [year, dayOfYear] = dateString.split("-");
              const date = new Date(year, 0);
              date.setDate(dayOfYear);
              return `${date.getDate()} ${date.toLocaleString("default", {
                month: "short",
              })} ${date.getFullYear()}`;
            });
          } else if (res.data.Graph2[0].ID.Month) {
            formattedDates = res.data.Graph2.map((monthData) => {
              const monthDate = new Date(
                monthData.ID.Year,
                monthData.ID.Month - 1,
                1
              );
              return `${monthDate.toLocaleString("default", {
                month: "long",
              })} ${monthData.ID.Year}`;
            });
          } else if (res.data.Graph2[0].ID.Week) {
            formattedDates = res.data.Graph2.map((weekData) => {
              const weekStartDate = new Date(
                weekData.ID.Year,
                0,
                (weekData.ID.Week - 1) * 7
              );
              const weekEndDate = new Date(
                weekData.ID.Year,
                0,
                weekData.ID.Week * 7 - 1
              );
              return `${weekStartDate.getDate()} ${weekStartDate.toLocaleString(
                "default",
                { month: "short" }
              )} ${weekStartDate.getFullYear()} - ${weekEndDate.getDate()} ${weekEndDate.toLocaleString(
                "default",
                { month: "short" }
              )} ${weekEndDate.getFullYear()}`;
            });
          }
        }
        const newDatas = {
          labels: formattedDates,
          datasets: [...datasets, ...(datasets2 || [])],
        };
        setDatas(newDatas);

        const graphlabel = datasets[0].label;
        setLabelName(graphlabel);
        // const graphColor = datasets2[0].borderColor;
        // setColor(graphColor);
        // const tabDt = datasets2[0].data;
        // setTabData(tabDt);
      })
      .catch((error) => {
        toast.error(`Error! ${error.message}`);
      });
  };

  const lineGraphData = {
    ...datas,
    datasets: [
      {
        label: datas && datas?.datasets && datas?.datasets[0].label,
        data: datas && datas?.datasets && datas?.datasets[0].data,
        borderColor: datas && datas?.datasets && datas?.datasets[0].borderColor,
        yAxisID: "y",
      },
      ...(datas && datas?.datasets && datas?.datasets[1]?.data
        ? [
            {
              label: `Prev date ${
                datas && datas?.datasets && datas?.datasets[1].label
              } `,
              data: datas && datas?.datasets && datas?.datasets[1].data,
              borderColor:
                datas && datas?.datasets && datas?.datasets[1].borderColor,
              yAxisID: "y",
            },
          ]
        : []),
    ],
  };

  const totlewebsitesale =
    filterDataResult?.PurchaseReport?.PurchaseList.filter(
      (item) => item.OrderSource === "WebSite"
    ).map((item) => item.TotalSales.toFixed(2));

  const totleEcommercesale =
    filterDataResult?.PurchaseReport?.PurchaseList.filter(
      (item) => item.OrderSource !== "WebSite"
    )
      .reduce((accumulator, item) => accumulator + item.TotalSales, 0)
      .toFixed(2);

  const totalWebsiteOrder =
    filterDataResult?.PurchaseReport?.OrderSourceNoOfSaleList.filter(
      (item) => item.OrderSource === "WebSite"
    ).map((item) => item.NoOfSale);

  const totalEcommerOrder =
    filterDataResult?.PurchaseReport?.OrderSourceNoOfSaleList.filter(
      (item) => item.OrderSource !== "WebSite"
    ).reduce((accumulator, item) => accumulator + item.NoOfSale, 0);

  const totalOrder =
    filterDataResult?.PurchaseReport?.OrderSourceNoOfSaleList.reduce(
      (accumulator, item) => accumulator + item.NoOfSale,
      0
    );

  const Data = {
    labels: ["Website Sales", "E-Commerce Sales"],
    datasets: [
      {
        data: [totlewebsitesale, totleEcommercesale],
        backgroundColor: ["lightskyblue", "#51CBCE"],
        borderWidth: 5,
        borderColor: ["#27293D"],
        hoverOffset: 4,
      },
    ],
  };

  const Options = {
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        position: "bottom",
        display: true,
        labels: {
          generateLabels: () => {
            if (Data.labels.length && Data.datasets.length) {
              return Data.labels.map((label, i) => {
                const dataset = Data.datasets[0];
                const value = dataset.data[i];
                return {
                  text: `${label}: RM ${value}`,
                  fillStyle: dataset.backgroundColor[i],
                  fontColor: "white",
                  hidden: isNaN(dataset.data[i]) || dataset.data[i] === 0,
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
    },
  };

  return (
    <div className="content w-100">
      <Card className="card-dark">
        <CardBody>
          <Row>
            <Col md={12}>
              <h2>Marketing Dashboard</h2>
            </Col>
          </Row>

          <Row xs={1} sm={2} className="my-2 mb-5 mt-5">
            <Col md="12">
              <div className="d-flex">
                <p className="mr-4">Date: </p>
                <div className="mb-2">
                  <CustomCalendar
                    setDateRange={setDateRange}
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                    setRange={setRange}
                    compareCheckbox
                  />
                </div>
              </div>

              <Button
                className="ml-1"
                color="secondary"
                onClick={() => handleFilter()}
              >
                Filter
              </Button>
              <Button
                className="ml-1"
                color="danger"
                onClick={() => resetFilter()}
              >
                Reset All Filter
              </Button>

              <Col />
            </Col>
          </Row>
          {/* end date filter */}
          <div>
            <Row>
              <Col md={8}>
                <Nav tabs className="">
                  <NavItem>
                    <div
                      className={`nav-link bg-warning pl-5 pr-5 pt-3 pb-3 text-center text-dark mr-3 
                     
                      `}
                      style={{ borderRadius: "5px", lineHeight: "1px" }}
                      onClick={() => handleTabSelect("newMember")}
                      aria-hidden="true"
                    >
                      <div className="d-flex justify-content-center">
                        <h2 className="text-center font-weight-bold ">
                          {filterDataResult?.UserReport?.TotalNewMember}
                        </h2>
                        {filterDataResult?.UserReport?.TotalNewMemberDiff ? (
                          <small
                            className={`m-2 p-2 text-right h-100 text-white border border-${
                              filterDataResult?.UserReport?.TotalNewMemberDiff.startsWith(
                                "-"
                              )
                                ? "red"
                                : "success"
                            }
                          bg-${
                            filterDataResult?.UserReport?.TotalNewMemberDiff.startsWith(
                              "-"
                            )
                              ? "red"
                              : "success"
                          } rounded`}
                          >
                            {filterDataResult?.UserReport?.TotalNewMemberDiff.replace(
                              /^-/,
                              ""
                            ).replace(/%/, "")}
                            %
                          </small>
                        ) : null}
                      </div>
                      New Member
                    </div>
                  </NavItem>
                  <NavItem>
                    <div
                      className={`nav-link bg-success pl-5 pr-5 pt-3 pb-3 text-center text-dark mr-3 
                      
                      
                      `}
                      style={{ borderRadius: "5px", lineHeight: "1px" }}
                      onClick={() => handleTabSelect("activeMember")}
                      aria-hidden="true"
                    >
                      <div className="d-flex justify-content-center">
                        <h2 className="font-weight-bold ">
                          {filterDataResult?.UserReport?.TotalActiveMember}
                        </h2>
                        {filterDataResult?.UserReport?.TotalActiveMemberDiff ? (
                          <small
                            className={`m-2 p-2 text-right h-100 text-white border border-${
                              filterDataResult?.UserReport?.TotalActiveMemberDiff.startsWith(
                                "-"
                              )
                                ? "red"
                                : "success"
                            }
                          bg-${
                            filterDataResult?.UserReport?.TotalActiveMemberDiff.startsWith(
                              "-"
                            )
                              ? "red"
                              : "success"
                          } rounded`}
                          >
                            {filterDataResult?.UserReport?.TotalActiveMemberDiff.replace(
                              /^-/,
                              ""
                            ).replace(/%/, "")}
                            %
                          </small>
                        ) : null}
                      </div>
                      Active Members
                    </div>
                  </NavItem>
                  <NavItem>
                    <div
                      className="nav-link pl-5 pr-5 pt-3 pb-3 text-center text-dark"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "pink",
                        lineHeight: "1px",
                      }}
                      onClick={() => handleTabSelect("nonActiveMember")}
                      aria-hidden="true"
                    >
                      <div className="d-flex justify-content-center">
                        <h2 className="font-weight-bold ">
                          {filterDataResult?.UserReport?.TotalInactiveMember}
                        </h2>
                        {filterDataResult?.UserReport
                          ?.TotalInactiveMemberDiff ? (
                          <small
                            className={`m-2 p-2 text-right h-100 text-white border border-${
                              filterDataResult?.UserReport?.TotalInactiveMemberDiff.startsWith(
                                "-"
                              )
                                ? "danger"
                                : "success"
                            }
                          bg-${
                            filterDataResult?.UserReport?.TotalInactiveMemberDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                          >
                            {filterDataResult?.UserReport?.TotalInactiveMemberDiff.replace(
                              /^-/,
                              ""
                            ).replace(/%/, "")}
                            %
                          </small>
                        ) : null}
                      </div>
                      Non-Active Members
                    </div>
                  </NavItem>
                </Nav>
                <hr style={{ width: "100%", color: "white" }} />
              </Col>
              <Col md={4}>
                <div
                  className="text-left pt-3 pb-3"
                  style={{ borderRadius: "5px", lineHeight: "2px" }}
                >
                  <div className="d-flex justify-content">
                    <h2 className="font-weight-bold">
                      {filterDataResult?.UserReport?.TotalRegisteredMember}
                    </h2>
                    {filterDataResult?.UserReport?.TotalRegisteredMemberDiff ? (
                      <small
                        className={`m-2 p-2 text-right h-100 text-white border border-${
                          filterDataResult?.UserReport?.TotalRegisteredMemberDiff.startsWith(
                            "-"
                          )
                            ? "red"
                            : "success"
                        }
                          bg-${
                            filterDataResult?.UserReport?.TotalRegisteredMemberDiff.startsWith(
                              "-"
                            )
                              ? "red"
                              : "success"
                          } rounded`}
                      >
                        {filterDataResult?.UserReport?.TotalRegisteredMemberDiff.replace(
                          /^-/,
                          ""
                        ).replace(/%/, "")}
                        %
                      </small>
                    ) : null}
                  </div>
                  Total Registered Members
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <div
                  className="text-center pl-5 pr-5 pt-3 pb-3"
                  style={{
                    borderRadius: "5px",
                    lineHeight: "2px",
                    backgroundColor: "lightskyblue",
                  }}
                >
                  <div className="d-flex justify-content-center">
                    <h3 className="font-weight-bold">
                      <CurrencyFormat
                        decimal={2}
                        price={filterDataResult?.PurchaseReport?.TotalSales.toFixed(
                          2
                        )}
                        currency="RM"
                      />
                    </h3>
                    {filterDataResult?.PurchaseReport?.TotalSalesDiff ? (
                      <small
                        className={`m-2 p-2 text-right h-100 text-white border border-${
                          filterDataResult?.PurchaseReport?.TotalSalesDiff.startsWith(
                            "-"
                          )
                            ? "danger"
                            : "success"
                        }
                          bg-${
                            filterDataResult?.PurchaseReport?.TotalSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                      >
                        {filterDataResult?.PurchaseReport?.TotalSalesDiff.replace(
                          /^-/,
                          ""
                        ).replace(/%/, "")}
                        %
                      </small>
                    ) : null}
                  </div>
                  Total Sales
                </div>
                <Row className="">
                  <Col md="6">
                    <div className="text-center p-2 mt-2 new-sales">
                      <div className="d-flex justify-content-center">
                        <p className="font-weight-bold">
                          <CurrencyFormat
                            decimal={2}
                            price={filterDataResult?.PurchaseReport?.TotalNewSales.toFixed(
                              2
                            )}
                            currency="RM"
                          />
                        </p>
                        {filterDataResult?.PurchaseReport?.TotalNewSalesDiff ? (
                          <small
                            className={`m-1 mt-0 mb-0 text-right h-100 text-white border border-${
                              filterDataResult?.PurchaseReport?.TotalNewSalesDiff.startsWith(
                                "-"
                              )
                                ? "danger"
                                : "success"
                            }
                          bg-${
                            filterDataResult?.PurchaseReport?.TotalNewSalesDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                          >
                            {filterDataResult?.PurchaseReport?.TotalNewSalesDiff.replace(
                              /^-/,
                              ""
                            ).replace(/%/, "")}
                            %
                          </small>
                        ) : null}
                      </div>
                      New Sales
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="text-center p-2 mt-2 avg-order">
                      <div className="d-flex justify-content-center">
                        <p className="font-weight-bold">
                          <CurrencyFormat
                            decimal={2}
                            price={filterDataResult?.PurchaseReport?.TotalAverageOrderValue.toFixed(
                              2
                            )}
                            currency="RM"
                          />
                        </p>
                        {filterDataResult?.PurchaseReport
                          ?.TotalAverageOrderValueDiff ? (
                          <small
                            className={`m-1 text-right h-100 text-white border border-${
                              filterDataResult?.PurchaseReport?.TotalAverageOrderValueDiff.startsWith(
                                "-"
                              )
                                ? "danger"
                                : "success"
                            }
                          bg-${
                            filterDataResult?.PurchaseReport?.TotalAverageOrderValueDiff.startsWith(
                              "-"
                            )
                              ? "danger"
                              : "success"
                          } rounded`}
                          >
                            {filterDataResult?.PurchaseReport?.TotalAverageOrderValueDiff.replace(
                              /^-/,
                              ""
                            ).replace(/%/, "")}
                            %
                          </small>
                        ) : null}
                      </div>
                      Average Order Value
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <div style={{ height: "200px", width: "225px" }}>
                  <Pie data={Data} options={Options} />
                </div>
              </Col>
              <Col md={4}>
                <div>
                  <Row>
                    <Col md="4">
                      <div className="text-center p-3 mt-2 ecommerce-order">
                        <div className="d-flex justify-content-center">
                          <h5 className="font-weight-bold">
                            {totalEcommerOrder}
                          </h5>
                        </div>

                        <small>E-Commerverse Order</small>
                      </div>
                      <div className="text-center p-3 mt-3 website-order">
                        <div className="d-flex justify-content-center">
                          <h5 className="font-weight-bold">
                            {totalWebsiteOrder}
                          </h5>
                        </div>

                        <small className="text-break">Website Order</small>
                      </div>
                      <hr style={{ width: "100%", color: "white" }} />
                      <div className="text-center">
                        <small>Total Order</small>
                        <h5 className="font-weight-bold">{totalOrder}</h5>
                      </div>
                    </Col>
                    <Col md="8">
                      <div className="text-center category-sales pt-3 pb-3">
                        <p className="font-weight-bold mb-3">
                          Product Sold By Category
                        </p>

                        {filterDataResult?.PurchaseReport?.CategoryNoOfSales.map(
                          (category) => (
                            <Row className="pb-2">
                              <Col md="8">{category.Name}</Col>
                              <Col md="4">
                                <div className="d-flex justify-content-center">
                                  {category.NoOfSale}
                                  {category.NoOfSaleDiff ? (
                                    <small
                                      className={`m-1 text-right h-100 text-white border border-${
                                        category.NoOfSaleDiff.startsWith("-")
                                          ? "danger"
                                          : "success"
                                      }
                          bg-${
                            category.NoOfSaleDiff.startsWith("-")
                              ? "danger"
                              : "success"
                          } rounded`}
                                    >
                                      {category.NoOfSaleDiff.replace(
                                        /^-/,
                                        ""
                                      ).replace(/%/, "")}
                                      %
                                    </small>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={12} className="mt-5 mb-5">
                <div className="d-flex justify-content-between">
                  <h6>{labelName}</h6>
                  <h6>
                    {!dateRange ||
                    (dateRange[0] === null && dateRange[1] === null) ? (
                      ""
                    ) : (
                      <div className="d-flex">
                        <div
                          className="square-box m-2"
                          style={{
                            backgroundColor: "rgba(74, 189, 219, 0.5)",
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                          }}
                        />
                        <p className="mt-2">
                          {moment(dateRange[0]).format("YYYY-MM-DD")} ~ &nbsp;
                          {moment(dateRange[1]).format("YYYY-MM-DD")}
                        </p>
                      </div>
                    )}
                  </h6>

                  {range ? (
                    <>
                      <h6>vs</h6>

                      <h6 className="d-flex">
                        <span
                          className="square-box m-2"
                          style={{
                            backgroundColor: "rgba(248, 192, 192,1)",
                            opacity: 50,
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                          }}
                        />
                        <p className="mt-2">
                          {range
                            ? `${moment(range[0]).format(
                                "YYYY-MM-DD"
                              )} ~ ${moment(range[1]).format("YYYY-MM-DD")}`
                            : ""}
                        </p>
                      </h6>
                    </>
                  ) : null}

                  <select
                    className="text-dark"
                    onChange={(e) => {
                      setSelectedPeriod(e.target.value);
                    }}
                    style={{
                      marginBottom: "10px",
                      width: "10%",
                      borderRadius: "5px",
                    }}
                  >
                    <option value="Day">By Day</option>
                    <option value="Week">By Week</option>
                    <option value="Month">By Month</option>
                  </select>
                </div>

                {isSuccess && datas && (
                  <Line data={lineGraphData} options={options} />
                )}
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default MarketingDash;
