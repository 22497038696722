/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  Modal,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./customerservice.css";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import CustomButton from "components/CustomButton/CustomButton";
import CSPreviewOrder from "./CSPreviewOrder";
import CSInvoiceOrder from "./CSInvoiceOrder";

const columns = [
  {
    name: "Order&Invoice No",
    selector: (row) => (
      <div className="m-2">
        <p>{`#${row.ID}`}</p>
        <p>{row.Invoice}</p>
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "DateTime",
    selector: (row) => (
      <div>
        <p>{moment(row.OrderDate).format("DD/MM/YYYY")}</p>
        {moment(row.OrderDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Source",
    selector: (row) => row.OrderSource,
    sortable: true,
    wrap: true,
  },
  {
    name: "Name",
    selector: (row) => row.Name,
    sortable: true,
    wrap: true,
  },
  {
    name: "Coupon",
    selector: (row) =>
      row.CouponApplied.length === 0 ? row.CouponApplied.Code : null,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        {" "}
        <CurrencyFormat
          decimal={2}
          price={row.TotalOrderCost.toFixed(2)}
          currency="MYR"
        />
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Logistic",
    selector: (row) =>
      row.DeliveryType === "Courier" ? (
        <div>
          <p> {row.Courier}</p>
          {row.TrackingCode}
        </div>
      ) : (
        row.DeliveryType
      ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => row.ShopOrderStatus,
    sortable: true,
    wrap: true,
  },
  {
    name: "LoyaltyPoints",
    selector: (row) => <div>{`${row.LoyaltyPointsEarned} Points`}</div>,
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [previewModal, setPreviewModal] = useState({
        orderID: "",
        open: false,
      });
      const [invoiceModal, setInvoiceModal] = useState({
        orderID: "",
        open: false,
      });

      return (
        <div>
          <CustomButton
            onClick={() =>
              setPreviewModal({
                orderID: row.ID,
                open: !previewModal.open,
              })
            }
            icon="fa fa-eye"
            title="View"
          />

          <CustomButton
            onClick={() =>
              setInvoiceModal({
                orderID: row.ID,
                open: !invoiceModal.open,
              })
            }
            icon="fa fa-file-text"
            title="Invoice"
          />

          <CustomButton
            to={`/shopowner/customerservice/vieworderdetails/${row.ID}`}
            icon="fa fa-search-plus"
            title="Order Details"
          />

          <Modal
            size="lg"
            isOpen={previewModal.open}
            toggle={() =>
              setPreviewModal({
                ...previewModal,
                open: !previewModal.open,
              })
            }
          >
            <div>
              <Button
                className="btn btn-primary float-right mr-5"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setPreviewModal({
                    ...previewModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
            </div>

            <ModalBody>
              <CSPreviewOrder
                previewID={previewModal.orderID}
                setPreviewModal={setPreviewModal}
                previewModal={previewModal}
              />
            </ModalBody>
          </Modal>
          <Modal
            size="xl"
            isOpen={invoiceModal.open}
            toggle={() =>
              setInvoiceModal({
                ...invoiceModal,
                open: !invoiceModal.open,
              })
            }
          >
            <div>
              <Button
                className="btn btn-primary float-right mr-5"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setInvoiceModal({
                    ...invoiceModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
            </div>

            <ModalBody>
              <CSInvoiceOrder invoiceID={invoiceModal.orderID} />
            </ModalBody>
          </Modal>
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function CSOrder() {
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const [dropItemList, setDropItemList] = React.useState({
    orderSource: [],
    logistic: [],
    status: [],
  });

  const [dropFilter, setDropFilter] = React.useState({
    orderSource: "Order Source",
    logistic: "Logistic",
    status: "Status",
  });

  const [dropOpen, setDropOpen] = React.useState("");

  const dropDownOptionGet = (data, id) => {
    const set = new Set();
    data.data.Result.forEach((row) => {
      if (row[id] !== "") set.add(row[id]);
    });
    return [...set.values()];
  };
  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/Order/CS`,
    async () =>
      shopID !== "0" &&
      Axios.get(`/shop/${shopID}/Order/CS`, {
        params: {
          limitPerPage: "1000000",
        },
      }).then((res) => {
        setDropItemList({
          orderSource: dropDownOptionGet(res, "OrderSource"),
          logistic: dropDownOptionGet(res, "Courier"),
          status: dropDownOptionGet(res, "ShopOrderStatus"),
        });
        const reversedData = res.data.Result.reverse();
        setFilterDataResult(reversedData);
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const dropdownToggle = (filter) => {
    if (filter === dropOpen) {
      setDropOpen("");
    } else {
      setDropOpen(filter);
    }
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setDropFilter({
      orderSource: "Order Source",
      logistic: "Logistic",
      status: "Status",
    });
    setSearchQuery("");
    setFilterDataResult(data.data.Result);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data.Result.filter((item) => {
        const orderDate = new Date(item.OrderDate);
        const startDateTime =
          startDate !== null
            ? new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              )
            : null;
        const endDateTime =
          endDate !== null
            ? new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              )
            : null;
        return (
          (startDate === null || orderDate >= startDateTime) &&
          (endDate === null || orderDate <= endDateTime)
        );
      })
        .filter((item) =>
          dropFilter.orderSource !== "Order Source"
            ? item.OrderSource === dropFilter.orderSource
            : item
        )
        .filter((item) =>
          dropFilter.logistic !== "Logistic"
            ? item.Courier === dropFilter.logistic
            : item
        )
        .filter((item) =>
          dropFilter.status !== "Status"
            ? item.ShopOrderStatus === dropFilter.status
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  const columnsForTable = [
    "Order&Invoice No",
    "OrderDate",
    "OrderSource",
    "Name",
    "Coupon",
    "Amount",
    "Logistic",
    "Status",
    "LoyaltyPoints",
  ];

  const convertToCSV = (data2) => {
    const header = [
      "Order&Invoice No",
      "OrderDate",
      "OrderSource",
      "Name",
      "Coupon",
      "Amount",
      "Logistic",
      "Status",
      "LoyaltyPoints",
    ];
    const csv = [
      header.join(","),
      ...data2.map((row) =>
        header
          .map((fieldName) => {
            if (fieldName === "Order&Invoice No") {
              return `#${row.ID} ${row.Invoice}`;
            }
            if (fieldName === "DateTime") {
              return JSON.stringify(
                moment(row.OrderDate).format("DD/MM/YYYY hh:mm A")
              );
            }
            if (fieldName === "Coupon") {
              return JSON.stringify(
                row.CouponApplied.length === 0 ? row.CouponApplied.Code : null
              );
            }
            if (fieldName === "Amount") {
              return `"RM ${row.TotalOrderCost.toFixed(2).replace(
                /(\d)(?=(\d{3})+\.)/g,
                "$1,"
              )}"`; // add double quotes and format as string
            }
            if (fieldName === "Logistic") {
              const logisticText = `${row.DeliveryType} ${row.Courier || ""} ${
                row.TrackingCode || ""
              }`;
              return logisticText;
            }
            if (fieldName === "LoyaltyPoints") {
              return JSON.stringify(row.LoyaltyPointsEarned);
            }
            return row[fieldName];
          })
          .join(",")
      ),
    ].join("\n");
    return csv;
  };

  const downloadCSV = (jsonData) => {
    const csv = convertToCSV(jsonData);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(csvBlob);
      link.setAttribute("href", url);
      link.setAttribute("download", "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportPDF = (jsondata) => {
    const doc = new jsPDF({ orientation: "landscape", size: "A4" });
    doc.setFontSize(12);

    const bodydata = jsondata.map((d) =>
      columnsForTable.map((e) => {
        let value = d[e];
        if (e === "Logistic") {
          const logisticText = `${d.DeliveryType} ${d.Courier || ""} ${
            d.TrackingCode || ""
          }`;
          value = logisticText;
        } else if (e === "Order&Invoice No") {
          value = `#${d.ID} ${d.Invoice}`;
        } else if (e === "DateTime") {
          value = JSON.stringify(
            moment(d.OrderDate).format("DD/MM/YYYY hh:mm A")
          );
        } else if (e === "Coupon") {
          value = JSON.stringify(
            d.CouponApplied.length === 0 ? d.CouponApplied.Code : null
          );
        } else if (e === "Amount") {
          value = `"RM ${d.TotalOrderCost.toFixed(2).replace(
            /(\d)(?=(\d{3})+\.)/g,
            "$1,"
          )}"`;
        } else if (e === "Status") {
          value = JSON.stringify(d.ShopOrderStatus);
        } else if (e === "LoyaltyPoints") {
          value = JSON.stringify(d.LoyaltyPointsEarned);
        }
        return value;
      })
    );

    autoTable(doc, {
      head: [columns.map((c) => c.name)],
      body: bodydata,
    });
    doc.save("table.pdf");
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">All Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Filter:
            </span>
            <Dropdown
              isOpen={dropOpen === "OrderSource"}
              toggle={() => dropdownToggle("OrderSource")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.orderSource}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      orderSource: "Order Source",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.orderSource.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, orderSource: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropOpen === "Logistic"}
              toggle={() => dropdownToggle("Logistic")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.logistic}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      logistic: "Logistic",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.logistic.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, logistic: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown
              isOpen={dropOpen === "Status"}
              toggle={() => dropdownToggle("Status")}
            >
              <DropdownToggle caret color="filter" outline>
                {dropFilter.status}
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem
                  onClick={() => {
                    setDropFilter({
                      ...dropFilter,
                      status: "Status",
                    });
                  }}
                >
                  -
                </DropdownItem>
                {dropItemList.status.map((x) => (
                  <DropdownItem
                    onClick={() => {
                      setDropFilter({ ...dropFilter, status: x });
                    }}
                  >
                    {x}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Button className="ml-1" onClick={() => filterData()}>
              Filter
            </Button>
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>
            <Button
              outline
              color="filter"
              onClick={() =>
                exportPDF(
                  selectedData.length > 0 ? selectedData : filterDataResult
                )
              }
            >
              Export PDF
            </Button>
            <Button
              outline
              color="filter"
              onClick={() => {
                downloadCSV(
                  selectedData.length > 0 ? selectedData : filterDataResult
                );
              }}
            >
              Export CSV
            </Button>
          </div>
          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                selectableRows
                onSelectedRowsChange={handleSelectRows}
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default CSOrder;
