/* eslint-disable no-return-assign */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import ApiContext from "services/ApiContext";
import { useQuery } from "react-query";
import { useFormik } from "formik";
import { MultiSelect } from "react-multi-select-component";
import * as Yup from "yup";
import ShopContext from "services/ShopContext";
import { toast } from "react-toastify";
import Switch from "react-ios-switch";

function CollectNcollabForm(props) {
  const Axios = useContext(ApiContext);
  const shopID = useContext(ShopContext);
  const selectShop = shopID.selctShopID.ID;
  const { type, modal, setModal, refetch, editType } = props;

  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [originProduct, setOriginProduct] = useState([]);
  const [starredProd, setStarredProd] = useState([]);
  const [originStarred, setOriginStarred] = useState([]);

  const [bannerDesk, setBannerDesk] = useState("");
  const [productImg, setProductImg] = useState([]);

  const [template, setTemplate] = useState("1");
  const [temSize, setTemSize] = useState(0);
  const [description, setDescription] = useState([]);
  const [onHomepage, setOnHomepage] = useState(false);
  const [status, setStatus] = useState(true);

  const { isSuccess, data } = useQuery("ProductList", async () =>
    Axios.get(`/public/Product/GetAllProductWithoutImage`)
  );

  const { data: CollectNcollab, refetch: editRefetch } = useQuery(
    ["CollectNcollab", editType],
    async () =>
      Axios.get(`/shop/${selectShop}/${type}/GetSingle${type}/${editType.ID}`),
    { enabled: editType.Type === "Edit" }
  );

  const toggle = () => {
    setModal(!modal);
    setBannerDesk("");
    setProductList([]);
    setProductImg([]);
    setDescription([]);
    setTemplate("1");
  };

  const bannerDeskRef = React.createRef();
  const imgListRef = React.createRef();

  useEffect(() => {
    const temp = [];
    if (isSuccess && data) {
      data.data.map((prop) => {
        temp.push({
          label: prop.ProductName,
          value: prop.ProductID,
          SKU: prop.ProductSKU,
        });
        setProducts(temp);
        return temp;
      });
    }
  }, [data]);

  const handleSelect = (selectedList) => {
    setProductList(selectedList);
  };

  const handleStarred = (selectedList) => {
    setStarredProd(selectedList);
  };

  const handleBannerDeskClick = () => {
    bannerDeskRef.current.click();
  };

  const handleImgListClick = () => {
    imgListRef.current.click();
  };

  const handleBannerDesk = (e) => {
    setBannerDesk({
      File: e.target.files[0],
      URL: URL.createObjectURL(e.target.files[0]),
    });
    e.target.value = null;
  };

  const handleProductImg = (e) => {
    const productFiles = e.target.files;
    const temp = [];
    for (let i = 0; i < productFiles.length; i++) {
      if (i >= 5) {
        break;
      }
      const file = productFiles[i];
      temp.push({ File: file, URL: URL.createObjectURL(file) });
    }
    if (productImg.length === 0) {
      setProductImg(temp);
    } else if (productImg.length < 5) {
      setProductImg([...productImg, ...temp]);
    }

    e.target.value = null;
  };

  const handleRemoveImg = (idx) => {
    productImg.splice(idx, 1);
    setProductImg([...productImg]);
  };

  const handleDescription = (desc, idx) => {
    const updatedDescription = description.map((item) => {
      const key = Object.keys(item)[0];
      if (key === `Desc${idx}`) {
        return { [key]: desc };
      }
      return item;
    });

    if (
      updatedDescription.some((item) => Object.keys(item)[0] === `Desc${idx}`)
    ) {
      setDescription(updatedDescription);
    } else {
      setDescription([...description, { [`Desc${idx}`]: desc }]);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(`Please Enter ${type} Name`),
  });

  const formik = useFormik({
    initialValues: {
      name: CollectNcollab ? CollectNcollab.data[`${type}Name`] : "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (dataM) => {
      const data2 = new FormData();

      const temp = [];
      productImg.forEach((item) => {
        temp.push(item.File);
      });

      data2.append("Name", dataM.name);
      data2.append("Status", status);
      data2.append("TemplateNumber", template);

      productList.forEach((prodList) => {
        data2.append("ProductIDList", prodList.value);
        if (type === "Collection") {
          data2.append("starredProductIDList", prodList.value);
        }
      });

      if (type === "Collaboration") {
        data2.append("Avatar", temp[0]);
        starredProd.forEach((starredList) => {
          data2.append("starredProductIDList", starredList.value);
        });
      }

      if (type === "Collection") {
        data2.append("CollectionBannerList", bannerDesk.File);
        data2.append("Avatar", temp[0]);
      }

      description.forEach((desc, idx) => {
        const key = `Desc${idx}`;
        data2.append(`${type}DescriptionList`, desc[key]);
      });

      temp.forEach((files) => {
        data2.append(`${type}FileList`, files);
      });

      data2.append("ShowOnHomepage", onHomepage);

      if (editType.Type === "Add") {
        Axios.post(`/shop/${selectShop}/${type}`, data2)
          .then(() => {
            toast.success(
              <div>
                <p className="text-white">Successful Created {type} </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });
      }

      if (editType.Type === "Edit") {
        const editDesc = [];
        description.forEach((desc, idx) => {
          const key = `Desc${idx}`;
          editDesc.push(desc[key]);
        });

        const isEqual =
          originProduct.length === productList.length &&
          originProduct.every((value, index) => {
            const obj2 = productList[index];
            return value.value === obj2.value && value.label === obj2.label;
          });

        const isEqualStarred =
          originStarred.length === starredProd.length &&
          originStarred.every((value, index) => {
            const obj2 = starredProd[index];
            return value.value === obj2.value && value.label === obj2.label;
          });

        Axios.put(`/shop/${selectShop}/${type}/${editType.ID}`, {
          Name: dataM.name,
          Status: status,
          TemplateNumber: template,
          DescriptionList: editDesc,
          ShowOnHomepage: onHomepage,
        })
          .then(() => {
            toast.success(
              <div>
                <p className="text-white">Successful Edited {type} </p>
              </div>
            );
            toggle();
            refetch();
          })
          .catch(() => {
            toast.error("Something goes wrong, please try later");
          });

        if (!isEqual) {
          const toAdd = productList.filter(
            (obj2) => !originProduct.some((obj1) => obj1.value === obj2.value)
          );
          const toRemove = originProduct.filter(
            (obj1) => !productList.some((obj2) => obj2.value === obj1.value)
          );

          if (toAdd.length !== 0) {
            const tempAdd = [];
            toAdd.forEach((item) => {
              tempAdd.push(item.value);
            });
            Axios.post(
              `/shop/${selectShop}/${type}/${editType.ID}/Products`,
              tempAdd
            );
          }

          if (toRemove.length !== 0) {
            const tempRemove = [];
            toRemove.forEach((item) => {
              tempRemove.push(item.value);
            });
            Axios.delete(
              `/shop/${selectShop}/${type}/${editType.ID}/Products`,
              {
                headers: {
                  "content-type": "application/json",
                },
                data: tempRemove,
              }
            );
          }
        }

        if (!isEqualStarred && type === "Collaboration") {
          const toAdd = starredProd.filter(
            (obj2) => !originStarred.some((obj1) => obj1.value === obj2.value)
          );
          const toRemove = originStarred.filter(
            (obj1) => !starredProd.some((obj2) => obj2.value === obj1.value)
          );

          if (toAdd.length !== 0) {
            toAdd.forEach((item) => {
              Axios.post(
                `/shop/${selectShop}/${type}/${editType.ID}/StarredProduct/${item.value}`
              );
            });
          }

          if (toRemove.length !== 0) {
            toRemove.forEach((item) => {
              Axios.delete(
                `/shop/${selectShop}/${type}/${editType.ID}/StarredProduct/${item.value}`
              );
            });
          }
        }
      }
    },
  });

  const dragImg = useRef(0);
  const dragOverImg = useRef(0);

  function handleSort() {
    if (productImg.length !== 0) {
      const imgClone = [...productImg];
      const temp = imgClone[dragImg.current];
      imgClone[dragImg.current] = imgClone[dragOverImg.current];
      imgClone[dragOverImg.current] = temp;

      setProductImg(imgClone);
    }
  }

  useEffect(() => {
    if (type === "Collection") {
      setTemSize(3);
    } else if (type === "Collaboration") {
      setTemSize(2);
    }
  }, []);

  useEffect(() => {
    if (CollectNcollab) {
      setStatus(CollectNcollab.data.Status);
      setTemplate(`${CollectNcollab.data.TemplateNumber}`);

      if (CollectNcollab.data.TemplateNumber !== 3) {
        setDescription([{ Desc0: CollectNcollab.data.DescriptionList[0] }]);
      } else if (CollectNcollab.data.TemplateNumber === 3) {
        const temp = [];
        CollectNcollab.data.DescriptionList.map((item, idx) =>
          temp.push({ [`Desc${idx}`]: item })
        );
        setDescription(temp);
      }

      if (CollectNcollab.data[`${type}ProductList`].length !== 0) {
        const temp = [];
        CollectNcollab.data[`${type}ProductList`].map((item) =>
          temp.push({
            label: item.ProductName,
            value: item.ProductID,
            SKU: item.ProductSKU,
          })
        );
        setProductList(temp);
        setOriginProduct(temp);

        if (type === "Collaboration") {
          const temp2 = [];
          CollectNcollab.data.StarredProductList.map((item) =>
            temp2.push({
              label: item.ProductName,
              value: item.ProductID,
              SKU: item.ProductSKU,
            })
          );
          setStarredProd(temp2);
          setOriginStarred(temp2);
        }
      }
    }
  }, [CollectNcollab]);

  useEffect(() => {
    if (editType.Type === "Edit") {
      editRefetch();
    }
  }, [modal]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} size="xl">
        <ModalBody className="text-center">
          <Button className="float-right closeBtn" onClick={() => toggle()}>
            <i className="nc-icon nc-simple-remove" />
          </Button>
          <h3 className="mt-3">
            {editType.Type} {type}
          </h3>
          <Form onSubmit={formik.handleSubmit}>
            <Container className="modalContainer">
              <Row className="mt-3">
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Status :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Switch
                    className="float-left mt-3"
                    checked={status}
                    onChange={() => setStatus(!status)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Template :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    type="select"
                    className="modalInput"
                    value={template}
                    onChange={(e) => {
                      e.target.value !== template ? setDescription([]) : "";
                      setTemplate(e.target.value);
                    }}
                  >
                    <option>Select Template...</option>
                    {[...Array(temSize).keys()].map((idx) => (
                      <option value={idx + 1}>{idx + 1}</option>
                    ))}
                  </Input>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      Show On Homepage :
                    </Label>
                  </div>
                </Col>
                <Col md="1">
                  <Input
                    className="modalCheck"
                    type="checkbox"
                    checked={onHomepage}
                    onChange={() => setOnHomepage(!onHomepage)}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div>
                    <Label tag="h5" className="float-right modalLabel">
                      {type} Name :
                    </Label>
                  </div>
                </Col>
                <Col>
                  <Input
                    id="name"
                    name="name"
                    type="text"
                    className="modalInput"
                    placeholder={`${type} Name...`}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                  {formik.errors.name ? (
                    <p className="errorText float-left">{formik.errors.name}</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
              {template === "3" && type === "Collection" ? (
                [...Array(3).keys()].map((idx) => (
                  <Row>
                    <Col md="4">
                      <div>
                        <Label tag="h5" className="float-right modalLabel">
                          Collection Description {idx + 1} :
                        </Label>
                      </div>
                    </Col>
                    <Col>
                      <Input
                        type="textarea"
                        className="modalInput pt-1 pl-2"
                        placeholder={`${type} Description...`}
                        value={description[idx]?.[`Desc${idx}`]}
                        onChange={(e) => handleDescription(e.target.value, idx)}
                      />
                    </Col>
                  </Row>
                ))
              ) : (
                <Row>
                  <Col md="4">
                    <div>
                      <Label tag="h5" className="float-right modalLabel">
                        {type} Description :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    <Input
                      type="textarea"
                      className="modalInput pt-1 pl-2"
                      placeholder={`${type} Description...`}
                      value={description[0]?.Desc0}
                      onChange={(e) => handleDescription(e.target.value, 0)}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md="4">
                  <div>
                    <Label
                      tag="h5"
                      className="float-right modalLabel"
                      for="exampleFile"
                    >
                      List of Products :
                    </Label>
                  </div>
                </Col>
                <Col>
                  {isSuccess && products.length !== 0 && (
                    <MultiSelect
                      className="mt-2 pt-1 multiDrop"
                      options={products}
                      value={productList}
                      onChange={handleSelect}
                      labelledBy="Select Products..."
                    />
                  )}
                </Col>
                <span className="pt-4 pr-2 font-weight-bold">
                  {`( ${productList.length} products )`}
                </span>
              </Row>
              <span className=" pl-5 ml-5" style={{ color: "red" }}>
                ** Product load will take a few seconds. Please wait till the
                list is visible , maximum product to upload 500 items
              </span>
              {type === "Collaboration" ? (
                <Row>
                  <Col md="4">
                    <div>
                      <Label
                        tag="h5"
                        className="float-right modalLabel"
                        for="exampleFile"
                      >
                        Starred Products :
                      </Label>
                    </div>
                  </Col>
                  <Col>
                    {isSuccess && products.length !== 0 && (
                      <MultiSelect
                        className="mt-2 pt-1 multiDrop"
                        options={productList}
                        value={starredProd}
                        onChange={handleStarred}
                        labelledBy="Select Products..."
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {editType.Type !== "Edit" && (
                <>
                  {type === "Collection" ? (
                    <Row className="mt-2">
                      <Col md="4">
                        <div>
                          <Label tag="h5" className="float-right modalLabel">
                            Banner Image :
                          </Label>
                        </div>
                      </Col>
                      <Col>
                        {bannerDesk ? (
                          <div>
                            <button
                              type="button"
                              className="removeImg"
                              onClick={() => setBannerDesk("")}
                            >
                              <i className="fa fa-times-circle removeIcon" />
                            </button>
                            <img
                              src={bannerDesk.URL}
                              className="float-left mt-1 bannerImg"
                            />
                          </div>
                        ) : (
                          <Button
                            className="float-left"
                            onClick={() => handleBannerDeskClick()}
                          >
                            Upload
                          </Button>
                        )}
                        <input
                          className="uploadImg"
                          type="file"
                          accept="image/*"
                          onChange={handleBannerDesk}
                          ref={bannerDeskRef}
                        />
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <Row className="mb-3">
                    <Col md="4">
                      <div>
                        <Label
                          tag="h5"
                          className="float-right modalLabel"
                          for="exampleFile"
                        >
                          <p className="float-right">{type} Image :</p>
                          <br />
                          <p className="disclaimer ">
                            {template === "1" && type === "Collaboration"
                              ? "(Require of 4 images)"
                              : "(Require of 5 images)"}
                          </p>
                        </Label>
                      </div>
                    </Col>
                    <Col>
                      <Button
                        className="float-left"
                        onClick={() => handleImgListClick()}
                      >
                        Upload
                      </Button>
                      <input
                        type="file"
                        className="uploadImg"
                        accept="image/*"
                        multiple
                        ref={imgListRef}
                        onChange={handleProductImg}
                      />
                      {productImg.length !== 0 && (
                        <Button
                          className="float-left clearBtn"
                          onClick={() => setProductImg([])}
                        >
                          Clear All
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}

              <Row className="text-center mb-4">
                {productImg.map((img, idx) => (
                  <Col md="4">
                    <div
                      draggable
                      onDragStart={() => (dragImg.current = idx)}
                      onDragEnter={() => (dragOverImg.current = idx)}
                      onDragEnd={handleSort}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <p className="imgNo">{idx + 1}</p>
                      <button
                        type="button"
                        className="imgListRemove"
                        onClick={() => handleRemoveImg(idx)}
                      >
                        <i className="fa fa-times-circle removeIcon" />
                      </button>
                      <img src={img.URL} className="mt-1 imgList" />
                    </div>
                  </Col>
                ))}
              </Row>
              <Button
                className="submitBtn"
                onClick={() => formik.handleSubmit()}
                disabled={
                  editType.Type === "Edit"
                    ? productList.length > 1000
                    : productList.length > 500
                }
              >
                Submit
              </Button>
            </Container>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}

CollectNcollabForm.defaultProps = {
  modal: false,
  editType: "",
};

CollectNcollabForm.propTypes = {
  type: PropTypes.string.isRequired,
  modal: PropTypes.bool,
  setModal: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
  editType: PropTypes.object,
};

export default CollectNcollabForm;
