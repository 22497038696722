import moment from "moment";
import React, { useContext, useEffect, useState } from "react"; // useState
import CurrencyFormat from "react-currency-format";
import DataTable, { createTheme } from "react-data-table-component";
import { useQuery } from "react-query";
import { Card, Col, Nav, NavItem, Row, TabContent, TabPane } from "reactstrap";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import Filter from "views/components/Filter";

function Ewallet() {
  createTheme(
    "solarized",
    {
      text: {
        primary: "white",
        secondary: "white",
      },
      background: {
        default: "#343a40",
      },
      context: {
        background: "#cb4b16",
        text: "#FFFFFF",
      },
      divider: {
        default: "white",
      },
      button: {
        default: "#2aa198",
        hover: "rgba(0,0,0,.08)",
        focus: "rgba(255,255,255,.12)",
        disabled: "rgba(255, 255, 255, .34)",
      },
      sortFocus: {
        default: "#2aa198",
      },
    },
    "dark"
  );

  const customStyles = {
    headRow: {
      style: {
        color: "white",
      },
    },
    headCells: {
      style: {
        fontSize: "15px",
        fontWeight: "600",
        textTransform: "uppercase",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);
  // const [totalRow, setTotalRow] = useState([]);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [userId, setUserId] = useState("");
  const [dataState, setDataState] = useState([]);
  const [summaryDataState, setSummaryDataState] = useState([]);
  const { data } = useQuery(["EwalletOrder", fromDate, toDate], async () =>
    Axios.get(`/shop/${shopID}/Order/Finance/Ewallet`, {
      params: {
        dateFrom: fromDate,
        dateTo: toDate,
      },
    })
  );

  const { data: summaryData } = useQuery(
    ["EwalletSummary", fromDate, toDate],
    async () =>
      Axios.get(`/shop/${shopID}/Order/Finance/EwalletSummary`, {
        params: {
          dateFrom: fromDate,
          dateTo: toDate,
        },
      })
  );

  const { data: userData } = useQuery(["EwalletUser", userId], async () =>
    Axios.get(`/shop/${shopID}/Order/Finance/EwalletByUserID`, {
      params: {
        userID: userId,
      },
    })
  );

  const column = [
    {
      name: "No",
      selector: (row) => row.no,
      sortable: true,
      sortField: "no",
      width: "120px",
    },
    {
      name: "Date of EW",
      selector: (row) => row.date,
      sortable: true,
      sortField: "Invoice",
      width: "180px",
    },
    {
      name: "Nature",
      selector: (row) => row.nature,
      sortable: true,
      sortField: "CreatedDate",
      width: "280px",
    },
    {
      name: "User ID",
      sortable: true,
      sortField: "Name",
      selector: (row) => row.userID,
      width: "350px",
    },
    {
      name: "Opening",
      sortable: true,
      sortField: "ShopOrderCostnShipping",
      selector: (row) => row.opening,
      width: "180px",
    },
    {
      name: "Add",
      sortable: true,
      sortField: "ShopOrderCostnShipping",
      selector: (row) => row.add,
      width: "180px",
    },
    {
      name: "Less",
      sortable: true,
      sortField: "PaymentGateway",
      selector: (row) => row.less,
      width: "180px",
    },
    {
      name: "Closing",
      sortable: true,
      selector: (row) => row.closing,
      width: "180px",
    },
    {
      name: "Approval",
      sortable: true,
      selector: (row) => row.approval,
      width: "350px",
    },
    {
      name: "Authoriser",
      sortable: true,
      selector: (row) => row.authoriser,
      width: "350px",
    },
    {
      name: "Remark (Finance/Inv/GD)",
      sortable: true,
      selector: (row) => row.remark,
      width: "350px",
    },
    {
      name: "Acc Code",
      sortable: true,
      selector: (row) => row.accCode,
      width: "200px",
    },
  ];

  const summaryColumn = [
    {
      name: "No",
      sortable: true,
      selector: (row) => row.no,
      width: "120px",
    },
    {
      name: "User ID",
      sortable: true,
      selector: (row) => row.email,
    },
    {
      name: "Closing(RM)",
      sortable: true,
      selector: (row) => row.closing,
    },
  ];

  useEffect(() => {
    if (userId === "") {
      if (data && data.data.Result !== null) {
        // setTotalRow(data.data.Count);
        setDataState(
          data.data.Result.EWalletLogList.map((prop) => ({
            no: prop.No,
            date: moment(prop.CreatedDate).format("DD.MM.YY"),
            nature: prop.Nature,
            userID: prop.Email,
            opening: prop.Opening,
            add: prop.Add,
            less: prop.Less,
            closing: prop.Closing,
            approval: prop.Approval,
            authoriser: prop.Authoriser,
            remark: prop.Remark,
            accCode: prop.AccCode,
          }))
        );
      } else {
        setDataState([]);
      }
    } else if (userId !== "") {
      if (userData && userData.data.Result !== null) {
        // setTotalRow(data.data.Count);
        setDataState(
          userData.data.Result.EWalletLogList.map((prop) => ({
            no: prop.No,
            date: moment(prop.CreatedDate).format("DD.MM.YY"),
            nature: prop.Nature,
            userID: prop.Email,
            opening: prop.Opening,
            add: prop.Add,
            less: prop.Less,
            closing: prop.Closing,
            approval: prop.Approval,
            authoriser: prop.Authoriser,
            remark: prop.Remark,
            accCode: prop.AccCode,
          }))
        );
      } else {
        setDataState([]);
      }
    }
  }, [data, userId, userData]);

  useEffect(() => {
    if (summaryData && summaryData.data.Result !== null) {
      // setTotalRow(data.data.Count);
      setSummaryDataState(
        summaryData.data.Result.map((prop, idx) => ({
          no: idx + 1,
          email: prop.Email,
          closing: prop.Closing,
        }))
      );
    } else {
      setSummaryDataState([]);
    }
  }, [summaryData]);

  return (
    <div className="content">
      <Nav justified className="mb-4">
        <NavItem
          className={activeTab === "1" ? "active alt-tab" : "alt-tab"}
          onClick={() => {
            toggle("1");
          }}
        >
          E-Wallet
        </NavItem>
        <NavItem
          className={activeTab === "2" ? "active alt-tab" : "alt-tab"}
          onClick={() => {
            toggle("2");
          }}
        >
          E-Wallet Summary
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card className="card-dark cardSplit pl-3 pr-3 pb-3">
            <Row>
              <Col md="7">
                <div>
                  <Row className="mt-2 mb-2">
                    <Col md="3" className="mt-2 mb-3">
                      <p>E-Wallet (RM) : </p>
                    </Col>
                    <Col>
                      {data && (
                        <p className="mt-2">
                          <CurrencyFormat
                            decimalScale={2}
                            value={parseFloat(
                              data.data.Result?.TotalEWallet
                            ).toFixed(2)}
                            displayType="text"
                            thousandSeparator
                            prefix=""
                          />
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Filter
                    fromDate={fromDate}
                    toDate={toDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    setUserId={setUserId}
                  />
                </div>
              </Col>
            </Row>
            <DataTable
              customStyles={customStyles}
              theme="solarized"
              data={dataState}
              columns={column}
              pagination
            />
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card className="card-dark cardSplit pl-3 pr-3 pb-3">
            <Row>
              <Col md="7">
                <Filter
                  fromDate={fromDate}
                  toDate={toDate}
                  setFromDate={setFromDate}
                  setToDate={setToDate}
                  setUserId={setUserId}
                  userId={null}
                />
              </Col>
            </Row>
            <DataTable
              className="mt-3"
              customStyles={customStyles}
              theme="solarized"
              columns={summaryColumn}
              data={summaryDataState}
            />
          </Card>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default Ewallet;
