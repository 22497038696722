import React, { useContext } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Alert,
} from "reactstrap";
import { useForm, useField, splitFormProps } from "react-form";
import UserContext from "services/UserContext";
import { useHistory, Link } from "react-router-dom";

const InputField = React.forwardRef((props, ref) => {
  // Let's use splitFormProps to get form-specific props
  const [field, fieldOptions, rest] = splitFormProps(props);
  // Use the useField hook with a field and field options
  // to access field state

  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField(field, fieldOptions);

  // Build the field
  return (
    <>
      <Input {...getInputProps({ ref, ...rest, required: true })} />
      {/*
        Let's inline some validation and error information
        for our field
      */}
      {isTouched && error ? (
        <strong className="text-danger text-center w-100">{error}</strong>
      ) : null}
    </>
  );
});

function Login() {
  const { login } = useContext(UserContext);
  const { Form } = useForm();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState();
  const [message, setMessage] = React.useState();
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });
  async function submit(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await login(data.email, data.password);
      history.push("/");
    } catch (error) {
      if (error.response.status === 400) {
        setMessage({
          data: "Email or Password is incorrect.",
          type: "alert-danger",
          color: "danger",
        });
      } else {
        setMessage({
          data: "Error occured, please try again later",
          type: "alert-danger",
          color: "danger",
        });
      }
    } finally {
      setLoading(false);
    }
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function handleSubmit(e) {
    const newdata = { ...data };
    newdata[e.target.id] = e.target.value;
    setData(newdata);
  }

  React.useEffect(() => {
    document.body.classList.toggle("login-page");
    return function cleanup() {
      document.body.classList.toggle("login-page");
    };
  });
  return (
    <div className="login-page">
      <Container>
        {message && (
          <Alert
            color={message.color}
            isOpen={visible}
            toggle={() => setVisible(false)}
            className={`alert font-weight-bold  d-flex white-text ${message.type}`}
            role="alert"
          >
            <b>{message.data}</b>
          </Alert>
        )}
        <Row>
          <Col className="ml-auto mr-auto" lg="4" md="6">
            <Form className="form" onSubmit={(e) => submit(e)}>
              <Card className="card-login">
                <CardHeader>
                  <CardHeader>
                    <h3 className="header text-center">Login</h3>
                  </CardHeader>
                </CardHeader>
                <CardBody>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputField
                      placeholder="First Name..."
                      onChange={(e) => handleSubmit(e)}
                      field="email"
                      id="email"
                      value={data.email}
                      type="email"
                      validate={async (value) => {
                        if (!value) {
                          return "Email is required";
                        }
                        if (!validateEmail(value)) {
                          return "Please enter a valid email addresss";
                        }
                        return false;
                      }}
                    />
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputField
                      onChange={(e) => handleSubmit(e)}
                      field="password"
                      id="password"
                      value={data.password}
                      placeholder="Password"
                      type="password"
                      autoComplete="off"
                      validate={(value) => {
                        if (!value) {
                          return "Password is required";
                        }
                        return false;
                      }}
                    />
                  </InputGroup>
                  <div>
                    <Link
                      to="/auth/forgot-password"
                      className="float-right text-dark font-weight-bold"
                    >
                      Forgot Password
                    </Link>
                  </div>
                </CardBody>
                <CardFooter>
                  <Button
                    block
                    type="submit"
                    className="btn-round mb-3"
                    color="warning"
                    loading={loading ? 1 : undefined}
                  >
                    Get Started
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        className="full-page-background"
        style={{
          background: `url(${require("assets/img/bg/download.jpg")})`,
        }}
      />
    </div>
  );
}

InputField.displayName = "InputField";
export default Login;
