import React, { useState } from "react";
import { Nav, NavItem, TabContent, TabPane } from "reactstrap";
import SalesSummary from "./SalesSummary";
import SalesChart from "./SalesChart";

function FinanceSalesReport() {
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav justified className="mb-4">
        <NavItem
          className={activeTab === "1" ? "active alt-tab" : "alt-tab"}
          onClick={() => {
            toggle("1");
          }}
        >
          Sales Summary
        </NavItem>
        <NavItem
          className={activeTab === "2" ? "active alt-tab" : "alt-tab"}
          onClick={() => {
            toggle("2");
          }}
        >
          Sales Chart
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SalesSummary />
        </TabPane>
        <TabPane tabId="2">
          <SalesChart />
        </TabPane>
      </TabContent>
    </div>
  );
}

export default FinanceSalesReport;
