/* eslint-disable new-cap */
import React, { useContext, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Input,
  Button,
  ModalBody,
  Modal,
} from "reactstrap";

// core components
import { useQuery } from "react-query";
import ApiContext from "services/ApiContext";
import ShopContext from "services/ShopContext";
import BasicTable from "views/tables/BasicTable";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../customerService/customerservice.css";
import moment from "moment";
import CurrencyFormat from "components/CurrencyFormat/CurrencyFormat";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
// linked pages
import CSInvoiceOrder from "../customerService/CSInvoiceOrder";

const columns = [
  {
    name: "Order&Invoice No",
    selector: (row) => (
      <div className="m-2">
        <p>{`#${row.ID}`}</p>
        <p>{row.Invoice}</p>
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "DateTime",
    selector: (row) => (
      <div>
        <p>{moment(row.CreatedDate).format("DD/MM/YYYY")}</p>
        {moment(row.CreatedDate).format("hh:mm A")}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Order Source",
    selector: (row) => row.OrderSource,
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => (
      <div>
        <CurrencyFormat
          decimal={2}
          price={row.ShopOrderCostnShipping.toFixed(2)}
          currency="MYR"
        />
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Coupon",
    selector: (row) => row.CouponApplied.map((code) => code.Code),
    sortable: true,
    wrap: true,
  },
  {
    name: "Logistic",
    selector: (row) => (
      <div>
        <p>{row.Courier}</p>
        <p>{row.TrackingCode}</p>
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: "Status",
    selector: (row) => row.OrderStatus[+1].Status,
    sortable: true,
    wrap: true,
  },
  {
    name: "Action",
    selector: (row) => {
      const [invoiceModal, setInvoiceModal] = useState({
        orderID: "",
        open: false,
      });

      if (!row) return null;

      return (
        <div>
          <Button
            id="itemhover"
            color="link"
            key={row.ID}
            className="pl-0 pr-0"
            onClick={() =>
              setInvoiceModal({
                orderID: row.ID,
                open: !invoiceModal.open,
              })
            }
          >
            <i
              className="fa fa-file-text"
              aria-hidden="true"
              style={{ color: "white", fontSize: "15px" }}
              title="Invoice"
            />
          </Button>
          <Button
            id="itemhover"
            color="link"
            key={row.ID}
            className="pl-0 pr-0"
            tag={Link}
            to={`/shopowner/customerservice/vieworderdetails/${row.ID}`}
          >
            <i
              className="fa fa-search-plus"
              aria-hidden="true"
              style={{ color: "white", fontSize: "18px" }}
              title="Order Detais"
            />
          </Button>
          {/* Modal for invoice detail */}
          <Modal
            size="xl"
            isOpen={invoiceModal.open}
            toggle={() =>
              setInvoiceModal({
                ...invoiceModal,
                open: !invoiceModal.open,
              })
            }
          >
            <div>
              <Button
                className="btn btn-primary float-right mr-5"
                style={{ fontSize: "12px" }}
                size="sm"
                onClick={() =>
                  setInvoiceModal({
                    ...invoiceModal,
                    open: false,
                  })
                }
              >
                x
              </Button>
            </div>

            <ModalBody>
              <CSInvoiceOrder invoiceID={invoiceModal.orderID} />
            </ModalBody>
          </Modal>
          {/* End of Modal for invoice details */}
        </div>
      );
    },
  },
];

const ExampleCustomInput = React.forwardRef(
  // eslint-disable-next-line react/prop-types
  ({ value, children, onClick }, ref) => (
    <Input
      ref={ref}
      value={value}
      onClick={onClick}
      placeholder="Date Range for filter"
      className="h-100"
    >
      {children}
    </Input>
  )
);

function UserStatement() {
  const { userID } = useParams();
  const shopID = useContext(ShopContext).selctShopID.ID;
  const Axios = useContext(ApiContext);

  const [searchQuery, setSearchQuery] = React.useState("");
  const [dateRange, setDateRange] = React.useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [filterDataResult, setFilterDataResult] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState([]);

  const { isLoading, data, isSuccess } = useQuery(
    `/shop/${shopID}/CustomerService/GetSingleUserOrderHistory/${userID}`,
    async () =>
      shopID !== "0" &&
      Axios.get(
        `/shop/${shopID}/CustomerService/GetSingleUserOrderHistory/${userID}`,
        {
          params: {
            limitPerPage: "1000000",
          },
        }
      ).then((res) => {
        const reversedData = res.data?.reverse();
        setFilterDataResult(reversedData);
        return res;
      }),
    { refetchOnWindowFocus: false }
  );

  const resetFilter = () => {
    setDateRange([null, null]);

    setFilterDataResult(data.data);
  };

  const handleSelectRows = ({ selectedRows }) => {
    setSelectedData(selectedRows);
  };

  const filterData = () => {
    if (isSuccess) {
      const finalData = data.data
        .filter((item) =>
          startDate !== null
            ? new Date(item.CreatedDate) >=
              new Date(
                moment(startDate).set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
              ).getTime()
            : item
        )
        .filter((item) =>
          endDate !== null
            ? new Date(item.CreatedDate) <=
              new Date(
                moment(endDate).set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                  millisecond: 999,
                })
              ).getTime()
            : item
        )
        .filter(
          (item) =>
            (item.Name &&
              item.Name.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.Invoice &&
              item.Invoice.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (item.ID &&
              item.ID.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      setFilterDataResult(finalData);
    }
  };

  const columnsForTable = [
    "Order&Invoice No",
    "DateTime",
    "OrderSource",
    "Amount",
    "Coupon",
    "Logistic",
    "Status",
  ];

  const exportPDF = (jsondata) => {
    const doc = new jsPDF({ orientation: "landscape", size: "A4" });
    doc.setFontSize(12);

    const bodydata = jsondata.map((d) =>
      columnsForTable.map((e) => {
        let value = d[e];
        if (e === "Logistic") {
          const logisticText = `${d.PickupShopID || ""} ${d.Courier || ""} ${
            d.TrackingCode || ""
          }`;
          value = logisticText;
        } else if (e === "Order&Invoice No") {
          value = `#${d.ID} ${d.Invoice}`;
        } else if (e === "DateTime") {
          value = JSON.stringify(
            moment(d.CreatedDate).format("DD/MM/YYYY hh:mm A")
          );
        } else if (e === "Coupon") {
          value = JSON.stringify(
            d.CouponApplied.length === 0 ? d.CouponApplied.Code : null
          );
        } else if (e === "Amount") {
          value = `"RM ${d.ShopOrderCostnShipping.toFixed(2).replace(
            /(\d)(?=(\d{3})+\.)/g,
            "$1,"
          )}"`;
        } else if (e === "Status") {
          value = JSON.stringify(d.OrderStatus[+1].Status);
        }
        return value;
      })
    );

    autoTable(doc, {
      head: [columns.map((c) => c.name)],
      body: bodydata,
    });
    doc.save("table.pdf");
  };

  const history = useHistory();
  const handleBackClick = () => {
    history.goBack();
  };

  if (isLoading) {
    return (
      <Col className="text-center" md="12">
        <div className="uil-reload-css reload-background mr-1 align-center">
          <div className="" />
        </div>
      </Col>
    );
  }
  return (
    <div className="content px-3 w-100" style={{ overflowX: "auto" }}>
      <Button
        style={{ backgroundColor: "#51BCDA" }}
        className="mt-2 p-2 ml-5"
        onClick={handleBackClick}
        size="sm"
      >
        Back
      </Button>
      <Card className="card-dark" style={{ minWidth: "350px" }}>
        <CardHeader>
          <CardTitle tag="h4">Statement</CardTitle>
        </CardHeader>
        <CardBody>
          <Row xs={1} sm={2} className="my-2">
            <Col>
              <div>Filter Date: </div>
              <div className="d-flex">
                <ReactDatePicker
                  calendarClassName="h-100"
                  wrapperClassName="w-100 "
                  isClearable
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  dateFormat="dd/MM/yyyy"
                  customInput={<ExampleCustomInput />}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Filter
                </Button>
              </div>
            </Col>
            <Col className="mt-2 mt-sm-0">
              <div>Search: </div>
              <div className="d-flex">
                <Input
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
                <Button className="ml-1  my-0" onClick={() => filterData()}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Filter:
            </span>
            <Button
              className="ml-1"
              color="danger"
              onClick={() => resetFilter()}
            >
              Reset All Filter
            </Button>
          </div>
          <div className="d-flex align-items-center">
            <span className="mr-2" style={{ fontSize: "16px" }}>
              Bulk Action:
            </span>
            <Button
              outline
              color="filter"
              onClick={() =>
                exportPDF(
                  selectedData.length > 0 ? selectedData : filterDataResult
                )
              }
            >
              Export PDF
            </Button>
          </div>

          {isSuccess && data && (
            <div id="" className="mt-5">
              <BasicTable
                pagination
                id=""
                selectableRows
                onSelectedRowsChange={handleSelectRows}
                subHeaderWrap
                progressPending={isLoading}
                data={filterDataResult}
                columns={columns || []}
              />
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default UserStatement;
